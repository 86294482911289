import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Avatar, Card, CardHeader, CardContent } from '@mui/material';
import { dateUtil } from '../../../../../utils/date';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { appointmentApi } from '../../../../../utils/services/appointments.api';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../../../../utils/user';
import { providerUtil } from '../../../../../utils/provider';
import { SmallInfoText } from '../../../../shared/Typography';

const styles = (theme) => ({
  root: {},
  fill: {
    flex: '1 1 auto',
  },
  cardHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'auto',
    },
  },
  card: {
    width: '240px',
    minWidth: '240px',
    maxWidth: '240px',
    margin: '12px 24px 12px 0',
    backgroundColor: '#F6F6F6',
    border: '0 solid #ffffff',
    borderRadius: '6px',
  },

  cardSelected: {
    width: '240px',
    minWidth: '240px',
    maxWidth: '240px',
    margin: '12px 24px 12px 0',
    backgroundColor: theme.palette.primary.main,
    border: '0 solid #ffffff',
    borderRadius: '6px',
  },

  cardHeader: {},

  cardSelectedHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#ffffff',
  },

  cardHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
  },

  cardHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
  },
  cardSelectedHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#FFF',
  },
  cardBody: {
    paddingTop: '0',
    paddingBottom: '6px !important',
  },

  cardBodyLine: {
    display: 'flex',
    marginBottom: '14px',
  },

  cardBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
  },
  cardSelectedBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
  },

  cardBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
    paddingLeft: '18px',
  },
  cardSelectedBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
    paddingLeft: '18px',
  },

  buttonHolder: {
    marginTop: '18px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '48px',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  iconLarge: {
    fontSize: '18px',
    color: '#FFC300',
  },
  iconLargeWhite: {
    fontSize: '18px',
    color: '#fff',
  },
});

class ProviderCard extends Component {
  state = {
    initialising: true,
    summary: undefined,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { bloc, person } = this.props;
    const { appointment, organisation } = bloc.subject.value;

    const now = new Date();
    const nextWeek = new Date(new Date().setDate(now.getDate() + 14));
    let _start = format(now, DATE_FORMAT);
    let _end = format(nextWeek, DATE_FORMAT);

    this.setState({
      organisation: organisation,
    });

    appointmentApi
      .getServiceScheduleSummary(_start, _end, appointment.service, appointment.provider, person.id)
      .then((value) => {
        let firstSlot = 'more than 2 weeks wait';
        if (value.data.firstOpenSlot) {
          firstSlot = dateUtil.parseDate(value.data.firstOpenSlot);
        }

        this.setState({
          initialising: false,
          schedule: value.data,
          firstSlot: firstSlot,
        });
      });
  }

  componentWillUnmount() {}

  render() {
    const { classes, person, selected } = this.props;

    const { organisation, schedule, firstSlot } = this.state;

    return selected === person.id
      ? this.renderCardSelected(classes, person, organisation, schedule, firstSlot)
      : this.renderCard(classes, person, organisation, schedule, firstSlot);
  }

  renderCard = (classes, _person, organisation, schedule, firstSlot) => {
    return (
      <Card onClick={this.props.onClick} className={classes.card} variant="outlined">
        <CardHeader
          classes={{
            title: classes.cardHeaderTitle,
            subheader: classes.cardHeaderTitleSubtitle,
          }}
          className={classes.cardHeader}
          avatar={
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              src={`https://storage.googleapis.com/dh-public-production/jovive/${_person.id}.png`}
            >
              {_person.name.given.slice(0, 1)}
            </Avatar>
          }
          title={this.__formatName(_person)}
          subheader=""
        />
        <CardContent className={classes.cardBody}>
          <div className={classes.cardBodyLine}>
            <LocationOnIcon
              classes={{
                fontSizeLarge: classes.iconLarge,
              }}
              fontSize={'large'}
              color={'primary'}
            />
            <SmallInfoText sx={{ paddingLeft: '18px' }}>
              {providerUtil.formatOrganisationAddress(organisation)}
            </SmallInfoText>
          </div>
          <div className={classes.cardBodyLine}>
            <span className={classes.cardBodyLineIcon}>
              <QueryBuilderIcon
                classes={{
                  fontSizeLarge: classes.iconLarge,
                }}
                fontSize={'large'}
                color={'primary'}
              />
            </span>
            <SmallInfoText sx={{ paddingLeft: '18px' }}>
              {firstSlot ? dateUtil.longFriendlyOutputExcludeYear(firstSlot) : 'Loading...'}
            </SmallInfoText>
          </div>
        </CardContent>
      </Card>
    );
  };

  renderCardSelected = (classes, _person, organisation, schedule, firstSlot) => {
    return (
      <Card onClick={this.props.onClick} className={classes.cardSelected} variant="outlined">
        <CardHeader
          classes={{
            title: classes.cardSelectedHeaderTitle,
            subheader: classes.cardSelectedHeaderTitleSubtitle,
          }}
          className={classes.cardHeader}
          avatar={
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              src={`https://storage.googleapis.com/dh-public-production/jovive/${_person.id}.png`}
            >
              {_person.name.given.slice(0, 1)}
            </Avatar>
          }
          title={this.__formatName(_person)}
          subheader=""
        />
        <CardContent className={classes.cardBody}>
          <div className={classes.cardBodyLine}>
            <LocationOnIcon
              classes={{
                fontSizeLarge: classes.iconLargeWhite,
              }}
              fontSize={'large'}
              color={'primary'}
            />
            <span className={classes.cardSelectedBodyLineText}>
              {providerUtil.formatOrganisationAddress(organisation)}
            </span>
          </div>
          <div className={classes.cardBodyLine}>
            <span className={classes.cardSelectedBodyLineIcon}>
              <QueryBuilderIcon
                classes={{
                  fontSizeLarge: classes.iconLargeWhite,
                }}
                fontSize={'large'}
              />
            </span>
            <span className={classes.cardSelectedBodyLineText}>
              {firstSlot ? dateUtil.longFriendlyOutputExcludeYear(firstSlot) : 'Loading...'}
            </span>
          </div>
        </CardContent>
      </Card>
    );
  };

  __formatName = (_person) => {
    let name = _person.name.given + ' ' + _person.name.family;
    if (_person.name.suffix) {
      name += ', ' + _person.name.suffix;
    }

    return name;
  };
}

export default withStyles(styles)(ProviderCard);
