import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import ChatBubble from '../../shared/ChatBubble';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Loading } from '../../../shared-library';
import { FormattedMessage } from 'react-intl';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../shared/Typography';
import PageContainer from '../../common/PageContainer';

import {
  DefaultDisconnectedPageFooter,
  ScrollableBox,
  ScrollableQuinnContainer,
  ScrollableWrapper,
} from '../../common/ScrollableContainer';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class Notice extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = {
      ...this.bloc.subject.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    const { initialised, appointment } = this.state;

    if (!initialised) {
      return (
        <PageContainer loading={false} onBack={() => {}}>
          <ScrollableQuinnContainer
            messageId={`paymentmethod.notice.loading.bubble`}
            message="I found a notice for your appointment type."
          >
            <Grid container direction="column" justifyContent="space-between" flex="1">
              <Loading isFullscreen={false} />
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.props.history.goBack();
        }}
      >
        <ScrollableQuinnContainer
          messageId={`paymentmethod.${appointment.service}.notice.bubble`}
          message="Thanks, our coordinator will contact you shortly."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <H2TextTitleContent>
                  <FormattedMarkdown
                    id={`paymentmethod.${appointment.service}.notice.text`}
                    defaultMessage={
                      'Our Travel Test coordinator will contact you shortly to gather more information and to make sure we cater for your specific travel/event plans.\n' +
                      '\n' +
                      'All the information you provided so far will be available for the coordinator.'
                    }
                  />
                </H2TextTitleContent>
              </Box>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Button type="button" variant="contained" color="primary" onClick={this.bloc.submit}>
            <FormattedMessage
              id="paymentmethod.notice.submit.button.label"
              defaultMessage={'NEXT'}
            />
          </Button>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(Notice));
