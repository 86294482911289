import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { appointmentApi } from '../../utils/services/appointments.api';
import { AnalyticsEvent } from '../../utils/events';
import { notificationService } from '../../utils/notification';
import { QUINN_SCHEDULED, routeUtil } from '../../utils/route.name';
import { logger } from '../../utils/logging';

export class Bloc extends DecodedBloc {
  appointmentApi;

  constructor(props) {
    super({ ...props });
    this.appointmentApi = props.appointmentApi;
  }

  initialise = () => {
    const { appointmentId } = this.subject.value;

    appointmentApi.getAppointmentStatus(appointmentId).then(
      (value) => {
        const appointment = value.data;

        this.__updateSubject({
          appointment: appointment,
        });

        setTimeout(() => {
          this.__publishEvent(BlocEvent.NAVIGATE_TO, {
            url: routeUtil.buildBookingPaymentMethodRouteWithDraftAppointmentID(
              appointmentId,
              QUINN_SCHEDULED,
            ),
          });
        }, 200);
      },
      (reason) => {
        this.logAnalyticsEvent(AnalyticsEvent.QUINN_ROUTING, { error: reason });
        notificationService.error(
          'Unable to load available clinics. Please refresh to try again and if the problem persists please call the clinic.',
        );

        setTimeout(() => {
          this.__publishEvent(BlocEvent.NAVIGATE_TO, {
            url: routeUtil.buildBookingPaymentMethodRouteWithDraftAppointmentID(
              appointmentId,
              QUINN_SCHEDULED,
            ),
          });
        }, 200);
      },
    );
  };
}

export class Constants {}

export class BlocEvent {
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
