import * as React from 'react';
import { RelationShipForm } from './RelationShipForm';


export const EmergencyContact = ({ emergency = {}, loading, handleSubmit, handleBack, setDirty }) => {
  return (
    <RelationShipForm
      messageId="update.user.bubble.emergencyContact"
      message="Please update or confirm your emergency contact."
      formName={"emergency.contact"}
      formDefaultTitle={"5 of 5 Personal Information - Emergency Contact"}
      label="Emergency Contact"
      loading={loading}
      data={emergency}
      handleSubmit={handleSubmit}
      handleBack={handleBack}
      setDirty={setDirty}
    />
  );

};
