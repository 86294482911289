import * as rxjs from 'rxjs';
import { notificationService } from '../../utils/notification';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import React from 'react';
import { insurancesApi } from '../../utils/services/insurances.api';
import { paymentApi } from '../../utils/services/payment.api';
import { registrationApi } from '../../utils/services/register.api';
import { Bloc as StripeBloc } from './Stripe/bloc';
import { Bloc as AccountBloc } from './Account/bloc';
import { routeUtil } from '../../utils/route.name';
import Stripe from './Stripe';
import Account from './Account';
import { providerUtil } from '../../utils/provider';
import { appointmentApi } from '../../utils/services/appointments.api';
import { uriStorage } from '../../utils/storage';
import { providerStorage } from '../../utils/provider.qs';
import { FormattedMessage } from 'react-intl';
import { logger } from '../../utils/logging';

export class Bloc {
  insurancesApi;
  appointmentApi;
  accountApi;
  paymentApi;

  registrationApi;

  constructor(options) {
    this.appointmentApi = options.appointmentApi;
    this.accountApi = options.accountApi;
    this.paymentApi = paymentApi;
    this.insurancesApi = insurancesApi;
    this.registrationApi = registrationApi;

    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      loading: true,
      loadingData: true,
      appointmentId: options.appointmentId,
      appointmentType: options.appointmentType,
    });

    this.events = new rxjs.Subject();

    this.__initialise(options.appointmentId);
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  __initialise = (appointmentId) => {
    const command = { command: 'determine_appointment_cost' };

    Promise.all([
      this.appointmentApi.command(appointmentId, command),
      this.paymentApi.getPaymentMethods(),
    ]).then(
      (objects) => {
        const paymentDetails = objects[0].data;
        const appointmentStatus = paymentDetails.appointment;
        const paymentMethods = objects[1].data.items;

        this.__updateSubject({
          appointment: appointmentStatus,
          paymentMethods: paymentMethods,
          paymentDetails: paymentDetails,
          loading: false,
          loadingData: false,
        });

        if (['unknown'].includes(paymentDetails.paymentType)) {
          logger.debug('Unknown payment type.');
          this.__onAccountReviewException();
          return paymentApi.skipPayment(appointmentId);
        }

        if (['paid', 'employer'].includes(paymentDetails.paymentType)) {
          this.__redirectToConfirmation(appointmentStatus);
          return;
        }

        this.__initialisePayment(paymentDetails, appointmentStatus, paymentMethods);

        this.events.next({
          type: BlocEvent.INITIALISED,
          data: {
            appointment: appointmentStatus,
            paymentDetails: paymentDetails,
          },
        });
      },
      (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR, {});
        notificationService.error(
          'Unable to load your appointment status. Please try refreshing your browser and if the problem persists call the clinic.',
        );
      },
    );
  };

  isWalkin = () => providerStorage.isWalkin();

  __initialisePayment = (paymentDetails, appointment, paymentMethods) => {
    this.appointmentApi
      .getServiceProviderSummaryForService(appointment.provider, appointment.service)
      .then(
        (result) => {
          const service = providerUtil.lookupService(result.data.items, appointment.service);

          if (appointment.service.includes('-UC') || appointment.type === 'VIRTUAL') {
            const bloc = new AccountBloc(
              {
                paymentDetails: paymentDetails,
                appointment: appointment,
                paymentMethods: paymentMethods,
                service: service,
                isWalkin: this.isWalkin(),
              },
              this.__onAccountReviewConfirm,
              this.__onAccountReviewException,
            );

            bloc.initialise();

            this.__updateSubject({
              service: service,
              component: <Account bloc={bloc} />,
            });
          }
        },
        (reason) => {
          notificationService.error(
            'Error loading the appointments location. Please contact the clinic to complete your payment.',
          );
        },
      );
  };

  __onAccountReviewConfirm = (amount, accountAmount, visitAmount) => {
    const { appointment, paymentDetails, paymentMethods } = this.subject.value;

    if (amount <= 0) {
      this.__updateSubject({
        component: undefined,
        message: 'redirecting',
      });
      this.__redirectToConfirmation(appointment);
      return;
    }

    const bloc = new StripeBloc(
      {
        insuranceApi: this.insurancesApi,
        paymentApi: this.paymentApi,
        codesetApi: this.registrationApi,
        appointmentApi: this.appointmentApi,
        appointment: appointment,
        paymentDetails: paymentDetails,
        amount: amount,
        accountAmount: accountAmount,
        visitAmount: visitAmount,
      },
      (next) => {
        if (next === 'exception') {
          this.__onAccountReviewException();
        } else {
          this.__redirectToConfirmation(appointment);
        }
      },
      () => {
        this.__initialisePayment(paymentDetails, appointment, paymentMethods);
      },
    );

    bloc.initialise();

    this.__updateSubject({
      component: <Stripe bloc={bloc} />,
    });
  };

  __onAccountReviewException = () => {
    const { appointment } = this.subject.value;

    uriStorage.clearPath(true);
    this.events.next({
      type: BlocEvent.NAVIGATE_TO,
      url: routeUtil.buildAppointmentCheckinException(appointment.id),
    });
  };

  __redirectToConfirmation = (appointment) => {
    let toRoute = routeUtil.buildAppointmentCheckinConfirmation(appointment.id);

    if (appointment.type === 'VIRTUAL') {
      toRoute = routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
        appointment.id,
        appointment.type,
      );

      uriStorage.clearPath(true);
      this.events.next({
        type: BlocEvent.NAVIGATE_TO,
        url: toRoute,
      });
    } else if (appointment.status === 'WAITING') {
      uriStorage.clearPath(true);
      this.events.next({
        type: BlocEvent.NAVIGATE_TO,
        url: toRoute,
      });
    } else {
      appointmentApi.checkInAppointment(appointment.id).then(
        (checkinAppointment) => {
          uriStorage.clearPath(true);
          this.events.next({
            type: BlocEvent.NAVIGATE_TO,
            url: toRoute,
          });
        },
        (reason) => notificationService.error('Unable to checkin your appointment'),
      );
    }
  };

  seeFrontDeskWording = () => {
    const { appointment } = this.subject.value;

    if (appointment?.type === 'VIRTUAL') {
      return (
        <FormattedMessage id={`payment.account.virtual.button.end`} defaultMessage={'Pay later'} />
      );
    }

    return (
      <FormattedMessage
        id={`payment.account.inperson.button.end`}
        defaultMessage={'End virtual registration'}
      />
    );
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
