import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

import { authService } from './utils/auth';
import { analyticsEventLogger } from './utils/events';
import { globalBloc } from './components/global.bloc';
import DevApp from './components/Dev';
import DecodedAnalytics from './heapInit';

DecodedAnalytics.initialize(process.env.REACT_APP_HEAP_ID);

let config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const root = ReactDOM.createRoot(document.getElementById('root'));

firebase.initializeApp(config);

authService.initialise(firebase.auth());
analyticsEventLogger.initialise(firebase.analytics());
globalBloc.initialise();

`${process.env.REACT_APP_MODE}` !== 'dev'
  ? root.render(
      <Router>
        <App opentokClientUrl="https://static.opentok.com/v2/js/opentok.min.js" />
      </Router>,
    )
  : root.render(
      <Router>
        <DevApp opentokClientUrl="https://static.opentok.com/v2/js/opentok.min.js" />
      </Router>,
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
