import { Grid } from '@mui/material';
import PageContainer from '../../common/PageContainer';
import { ScrollableQuinnContainer } from '../../common/ScrollableContainer';
import * as React from 'react';
import { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Loading from '../../shared/Loading';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class Initialising extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <PageContainer loading={false} onBack={() => {}} hideBack={true}>
        <ScrollableQuinnContainer
          messageId="payment.loading.bubble"
          message="I am just checking your account."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Loading isFullscreen={false} />
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(Initialising);
