import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { uriStorage } from '../../../utils/storage';
import InpersonWalkin from './InpersonWalkin';
import Appointment from './Appointment';
import { BookingBloc, BookingBlocEvent, RescheduleBookingContext } from './bloc';
import Reservation from './Reservation';
import Loading from '../../shared/Loading';
import { routeUtil } from '../../../utils/route.name';
import { appointmentApi } from '../../../utils/services/appointments.api';

const styles = (theme) => ({});

const initState = {
  initialising: true,
  loading: true,
  activeStep: 0,
  prevAppointment: null,
  serviceCode: 'test',
  selectedProvider: '',
};

class RescheduleBooking extends Component {
  stateSubscription;

  constructor(props) {
    super(props);
    this.state = initState;
    // this.flow = this.selectFlow()

    let { draftId, appointmentType } = props.match.params;
    this.bloc = new BookingBloc(draftId, appointmentType);

    this.__handleStateUpdate = this.__handleStateUpdate.bind(this);
  }

  componentDidMount() {
    uriStorage.setCurrentPath(this.props.match.url);
    this.stateSubscription = this.bloc.subscribeToState(this.__handleStateUpdate);
    this.eventSubscription = this.bloc.subscribeToEvents(this.__handleEvent);

    appointmentApi.getAppointmentStatus(this.props.match.params.appointmentId).then((response) => {
      this.setState((prevState) => ({
        ...prevState,
        prevAppointment: response.data,
      }));
    });
  }

  componentWillUnmount() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  __handleEvent = (_event) => {
    const { data } = _event;

    let newState = {};

    switch (_event.type) {
      case BookingBlocEvent.INITIALISED: {
        newState.loading = false;
        newState.flow = this.__selectFlow(
          this.__determineFlowCode(data.appointment, data.availabilityStaff, data.overrides),
        );
        break;
      }
      case BookingBlocEvent.SWITCH_BOOKING_TYPE: {
        newState.loading = false;
        newState.flow = this.__selectFlow(
          this.__determineFlowCode(data.appointment, data.availabilityStaff, data.overrides),
        );
        newState.activeStep = 0;
        break;
      }
      default:
        break;
    }

    this.setState({
      ...newState,
    });
  };

  __determineFlowCode = (appointment, availabilityStaff, overrides) => {
    if (!overrides?.type && appointment.type.includes('WALK_IN')) return 'walkin';

    if (overrides?.type === 'walkin') return 'walkin';

    return availabilityStaff.length > 0 ? 'appointment' : 'reservation';
  };

  __handleStateUpdate = (state) => {
    this.setState({
      ...state,
    });
  };

  handleBackAtStepZero = () => {
    const { appointmentId, appointmentType } = this.props.match.params;
    this.props.history.push(
      routeUtil.buildBookingDetailsRouteWithAppointmentID(appointmentId, appointmentType),
    );
  };

  __selectFlow = (type) => {
    let { draftId } = this.props.match.params;

    let flowComponents = {
      appointmentSchedule: () => (
        <Appointment
          {...this.state}
          {...{ draftId }}
          bloc={this.bloc}
          handleBackToStepZero={this.handleBackAtStepZero}
          handleBack={this.handleBack}
          setLoading={this.setLoading}
        />
      ),
      appointmentReservation: () => (
        <Reservation
          {...this.state}
          {...{ draftId }}
          bloc={this.bloc}
          handleBackToStepZero={this.handleBackAtStepZero}
          handleBack={this.handleBack}
          setLoading={this.setLoading}
        />
      ),
      inpersonWalkin: () => (
        <InpersonWalkin
          {...this.state}
          {...{ draftId }}
          bloc={this.bloc}
          handleBackToStepZero={this.handleBackAtStepZero}
          handleNext={this.handleNext}
          setLoading={this.setLoading}
        />
      ),
    };

    switch (type) {
      case 'appointment':
        return [flowComponents.appointmentSchedule];
      case 'reservation':
        return [flowComponents.appointmentReservation];
      case 'walkin':
        return [flowComponents.inpersonWalkin];
      default:
        throw new Error('Unsupported flow!');
    }
  };

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  setLoading = (value) => {
    this.setState({
      loading: value,
    });
  };

  handleNextWithSelectedProvider = (providerId) => {
    this.setState((state) => ({
      selectedProvider: providerId,
      activeStep: state.activeStep + 1,
    }));
  };

  getStepContent = (step, flow) => {
    return flow[step];
  };

  render() {
    const { activeStep, initialising, flow, prevAppointment } = this.state;

    const sProvider =
      prevAppointment && prevAppointment.participants
        ? prevAppointment.participants.find((p) => p.role === 'S_PROVIDER')
        : null;
    const selectedProvider = sProvider ? sProvider.identifier.value : undefined;

    return (
      <RescheduleBookingContext.Provider value={{ ...prevAppointment, selectedProvider }}>
        {initialising || !flow ?
            <Loading /> :
            this.getStepContent(activeStep, flow)()
        }
      </RescheduleBookingContext.Provider>
    );
  }
}

export default withStyles(styles)(RescheduleBooking);
