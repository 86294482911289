import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import { dateUtil } from '../../../../../utils/date';
import { compareAsc } from 'date-fns';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../../utils/events';
import BaseWidget from './BaseWidget';
import Typography from '@mui/material/Typography';
import { RescheduleBookingContext } from '../../bloc';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { TimeIntervalButton } from '../../components/TimeIntervals';

const styles = (theme) => ({
  root: {},
  dates: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '55vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'unset',
      paddingTop: '24px',
    },
  },
  fill: {
    flex: '1 1 auto',
  },
  cardHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'auto',
    },
  },
  buttonHolder: {
    marginTop: '18px',
  },
  button: {
    paddingLeft: '0',
    paddingRight: '0',
    marginRight: '24px',
    marginBottom: '24px',
    width: '145px',
    maxWidth: '145px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '145px',
    },
  },
  buttonIcon: {
    marginRight: '12px',
    color: '#ffffff',
  },
  unselectedButton: {
    backgroundColor: '#F6F6F6',
    paddingLeft: '0',
    paddingRight: '0',
    marginRight: '24px',
    marginBottom: '24px',
    width: '145px',
    maxWidth: '145px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '145px',
    },
  },
  unselectedButtonIcon: {
    marginRight: '12px',
    color: '#F29202',
  },
  submitButton: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  progressHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'hidden',
    },
  },
  progress: {
    color: '#F29202',
  },
  errorText: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '32px',

    color: '#FF7700',
  },
});

class TimeSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialising: true,
    };

    this.__selectTime = this.__selectTime.bind(this);
  }

  componentDidMount() {
    this.props.bloc.loadSelectedDayAvailability();
    this.stateSubscription = this.props.bloc.subscribeToState(this.__handleStateUpdate);
  }

  componentWillUnmount() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  componentDidUpdate() {
    if (
      !this.state.selectedSlot &&
      this.context?.start &&
      new Date(this.state.booking.selectedDate).getDate() === new Date(this.context.start).getDate()
    ) {
      this.setState((prevState) => ({
        ...prevState,
        selectedSlot: this.context.start,
      }));
    }
  }

  __handleStateUpdate = (state) => {
    const { initialising } = this.state;

    let started = initialising;

    let newProperties = {};

    if (initialising && state.booking?.availability) {
      started = false;
      const start = new Date(state.booking.selectedDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(state.booking.selectedDate);
      end.setHours(23, 59, 59, 999);

      const now = new Date();

      let intervals = [];

      if (state.booking.availability.results.length > 0) {
        state.booking.availability.results[0].intervals.forEach((_interval) => {
          _interval.renderDate = dateUtil.parseDate(_interval.start);
          if (
            now.getTime() < _interval.renderDate.getTime() &&
            dateUtil.encloses(_interval.renderDate, start, end) &&
            _interval.availableSlots &&
            _interval.availableSlots.length > 0
          ) {
            intervals.push(_interval);
          }
        });

        intervals.sort((slot1, slot2) => {
          return compareAsc(slot1.renderDate, slot2.renderDate);
        });
      }

      newProperties.intervals = intervals;

      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_LOADED, {});
    }

    this.setState({
      ...state,
      ...newProperties,
      initialising: started,
    });
  };

  __selectTime = (slotId) => {
    const $this = this;
    return () => {
      $this.setState({
        selectedSlot: slotId,
      });
    };
  };
  __submit = () => {
    if (this.state.selectedSlot && !this.state.processing) {
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {});

      this.props.bloc.setBookingTime(this.state.selectedSlot, true);
      this.setState({
        processing: true,
      });
      this.timer = setTimeout(() => {
        this.setState({ processing: false });
      }, 5000);
    }
  };

  render() {
    const { classes } = this.props;

    const { initialising, processing, intervals, selectedSlot } = this.state;

    return (
      <BaseWidget
        initialising={initialising}
        processing={processing}
        submitEnabled={selectedSlot && !processing}
        submit={this.__submit}
        submitLabel={'SELECT'}
      >
        <Grid row xs={12} className={classes.cardHolder}>
          <div className={`${classes.dates}`}>
            {intervals && intervals.length > 0 ? (
              intervals.map((_interval) => this.__renderInterval(classes, _interval, selectedSlot))
            ) : (
              <Typography className={classes.errorText} variant="body2" component={'div'}>
                <FormattedMarkdown
                  id={'booking.appointment.schedule.chart.unavailable'}
                  defaultMessage={'No time slots are available for selected date.'}
                />
              </Typography>
            )}
          </div>
        </Grid>
      </BaseWidget>
    );
  }

  __renderInterval(classes, interval, selectedSlot) {
    let date = dateUtil.parseDate(interval.start);

    const hour = date.getHours();

    return (
      <>
        {interval.availableSlots &&
          interval.availableSlots.map((_slot) => (
            <TimeIntervalButton
              key={`btn-slot-${_slot.slotId}`}
              onClick={this.__selectTime(_slot.slotId)}
              isSelected={_slot.slotId === selectedSlot}
            >
              {this.__formatTimeDisplay(hour, _slot)}
            </TimeIntervalButton>
          ))}
      </>
    );
  }

  __formatTimeDisplay = (hour, _slot) => {
    const timeOfDay = hour < 12 ? 'am' : 'pm';
    let displayHour = hour % 12;
    if (displayHour === 0) displayHour = 12;

    return `${displayHour}:${_slot.start.split(':')[1]} ${timeOfDay}`;
  };
}

TimeSelection.contextType = RescheduleBookingContext;

export default withStyles(styles)(TimeSelection);
