import common from './common.json';
import patientplus from './patientplus.json';

const patientplusMessages = process.env.NODE_ENV !== 'production' ? patientplus : {};
const commonMessages = process.env.NODE_ENV !== 'production' ? common : {};

export const messages =
  process.env.NODE_ENV !== 'production'
    ? {
        ...commonMessages,
        ...patientplusMessages,
      }
    : {
        ...process.env.REACT_APP_COMMON_CONTENTS,
        ...process.env.REACT_APP_TENANT_CONTENTS,
      };
