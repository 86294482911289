import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router-dom';
import { FormControl, Grid, InputLabel, Select, styled, TextField } from '@mui/material';
import DecodedComponent from '../shared/DecodedComponent';
import { PageContent, PageFooter } from '../common/ScrollableContainer';
import DecodedButton from '../common/DecodedButton';
import { H1TextTitleSecondary, H2TextTitleContent } from '../shared/Typography';
import MenuItem from '@mui/material/MenuItem';

const styles = (theme) => ({});

const initState = {
  initialising: true,
};

class Administration extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = { ...initState, ...props.bloc.subject.value };
    this.bloc = props.bloc;

    this.initialise();
  }

  initialise = async () => {
    const { providerId } = this.state;
    if (providerId) {
      await this.bloc.initializeBackendClientAndTerminal(providerId);
    }
  };

  __handleLocationChange = (e) => {
    this.bloc.setLocation(e.target.value);
  };

  __handleTextChange = (e) => {
    this.bloc.setValue(e.target.name, e.target.value);
  };

  render() {
    const { initialising, token, providers, providerId, terminals, terminalId } = this.state;

    if (!providers) {
      return <></>;
    }

    return (
      <>
        <PageContent>
          <Grid spacing={3} container>
            <Grid item xs={12} sx={{ margin: '64px 0' }}>
              <H1TextTitleSecondary sx={{ textAlign: 'center' }}>
                Kiosk settings
              </H1TextTitleSecondary>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id={'providerId-label'} name={'providerId'}>
                  Location
                </InputLabel>
                <Select
                  labelId="providerId-label"
                  id={'providerId'}
                  name={'providerId'}
                  value={providerId}
                  label="Location"
                  onChange={this.__handleLocationChange}
                >
                  {providers.map((provider) => (
                    <MenuItem value={provider.identifier}>{provider.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {terminals && terminals.length > 0 ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id={'providerId-label'} name={'providerId'}>
                    Terminal
                  </InputLabel>
                  <Select
                    labelId="terminalId-label"
                    id={'terminalId'}
                    name={'terminalId'}
                    value={terminalId}
                    label="terminal"
                    onChange={this.__handleTextChange}
                  >
                    {terminals.map((terminal) => (
                      <MenuItem value={terminal.id}>{terminal.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </PageContent>
        <PageFooter>
          <Grid spacing={3} container>
            <Grid item xs={6}>
              <DecodedButton onClick={this.bloc.save}>SAVE</DecodedButton>
            </Grid>
            <Grid item xs={6}>
              <DecodedButton onClick={this.bloc.clear}>CLEAR</DecodedButton>
            </Grid>
          </Grid>
        </PageFooter>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Administration));
