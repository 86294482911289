import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material';
import { providerStorage } from '../../utils/provider.qs';

const kiosk = providerStorage.isKiosk();
const commonTheme = createTheme({
  root: {
    fontFamily: 'Roboto, "sans-serif"',
    minHeight: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },

  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(', '),
    h2: {
      fontWeight: 400,
      fontSize: kiosk ? '2.2rem' : '2.0rem',
      lineHeight: '1.167',
    },
    body1: {
      fontSize: kiosk ? '1.8rem' : '1.6rem',
      lineHeight: '1.75',
      primary: '#71675E',
      color: '#71675E',
    },
    body2: {
      fontSize: kiosk ? '1.8rem' : '1.6rem',
      lineHeight: '1.5',
      primary: '#71675E',
      color: '#71675E',
    },
    bodyText: {
      fontSize: kiosk ? '1.8rem' : '1.6rem',
      lineHeight: '1.5',
      primary: '#71675E',
      color: '#71675E',
    },
  },

  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: '#ffffff',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: kiosk ? '1.4rem' : '1.2em',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        outlined: {
          border: '0',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'color .01s, background-color .3s',
          minHeight: '45px',
          width: '100%',
          boxSizing: 'border-box',
          maxWidth: '350px',
          borderRadius: '10px',
          padding: '.61111em 2.22222em',
          fontWeight: '700',
          fontSize: '1.5rem',
          lineHeight: '1.222',
          textAlign: 'center',
          textDecoration: 'none',
          textTransform: 'none',
          verticalAlign: 'baseline',
          '@media (max-width: 600px)': {
            fontSize: '1.25rem',
          },
        },

        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
          '&:hover': {
            boxShadow: 'none',
          },
        },

        textPrimary: {},
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          '@media (min-width: 600px)': {
            maxWidth: '1029px',
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiPickersDay-root': {
            fontSize: '14px',
          },
          '& .MuiTypography-caption': {
            fontSize: '14px',
            color: '#1F8B9F',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
  },
});

//TODO: Add font sizes here and remove from components.
const patientPlusTheme = responsiveFontSizes(
  createTheme(commonTheme, {
    palette: {
      mode: 'light',
      primary: {
        light: '#EEF7F9',
        main: '#1F8B9F',
      },
      secondary: {
        main: '#FF5100',
      },
      text: {
        primary: '#71675E',
      },
      action: {
        hover: '#1F8B9F',
      },
      accent: {
        main: '#FF5100',
        contrastText: '#ffffff',
      },
    },

    components: {
      MuiListItem: {
        styleOverrides: {
          button: {
            '&:hover': {
              color: '#ffffff',
              backgroundColor: '#1F8B9F',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            '&:hover': {
              color: '#ffffff',
              backgroundColor: '#1F8B9F',
            },
            '&[data-focus="true"]': {
              color: '#ffffff',
            },
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            color: '#ffffff',
            backgroundColor: '#1F8B9F',
            boxShadow: 'none',
            '@media (hover: hover)': {
              '&:hover': {
                color: '#ffffff',
                backgroundColor: '#1b7887',
                boxShadow: 'none',
              },
            },
            '@media (hover: none)': {
              '&:hover': {
                color: '#ffffff',
                backgroundColor: '#1F8B9F',
                boxShadow: 'none',
              },
            },
          },

          containedSecondary: {
            color: '#71675E',
            backgroundColor: '#ffffff',
            border: '1px solid #71675E',
            '@media (hover: hover)': {
              '&:hover': {
                color: '#71675E',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: 'none',
              },
            },
            '@media (hover: none)': {
              '&:hover': {
                color: '#ffffff',
                backgroundColor: '#FF9900',
                boxShadow: 'none',
              },
            },
          },

          text: {
            '&:hover': {
              color: '#FF9900',
            },
          },

          textSecondary: {
            '&:hover': {
              color: '#FD1F54',
            },
          },
        },
      },
    },
  }),
);

export const getThemeBasedOnTenant = () => {
  return patientPlusTheme;
};
