import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';

import { Route, Switch, withRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import './App.css';

import Login from '../Login';
import Secure from '../Secure';

import { PrivateRoute } from '../../utils/protected.route';
import LinkHandler from '../Link';
import LocalNotifications from '../shared/Notifications';
import PostEncounter from '../Secure/VirtualClinic/PostEncounter';
import {
  INVALID_ROUTE,
  CALLBACK_ROUTE,
  LINK_ROUTE,
  LOGIN_ROUTE,
  QUINN_TERMINATE_ROUTE,
  ROOT_ROUTE,
  VIRTUAL_CLINIC_POST_ENCOUNTER_ROUTE,
  KIOSK_ROUTE,
  TEST,
} from '../../utils/route.name';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isAfter, isBefore, isValid, parse, subYears } from 'date-fns';
import { messages } from '../../utils/intl/messages';
import Loading from '../shared/Loading';
import { globalBloc } from '../global.bloc';
import PostTerminate from '../Secure/Assistant/PostTerminate';
import { userInfoUtil } from '../../utils/user';
import { getThemeBasedOnTenant } from './themes';
import CallbackHandler from '../Callback';
import Invalid from '../Invalid';
import KioskAdmin from '../KioskAdmin';
import { providerStorage } from '../../utils/provider.qs';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { authService } from '../../utils/auth';
import { uriStorage } from '../../utils/storage';
import { logger } from '../../utils/logging';
import { IdleTimer } from './IdleTimer';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';

const styles = {
  root: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    maxHeight: '100%',
  },
};

const TIMEOUT = 60 * 30;
const KIOSK_TIMEOUT = 60 * 5;
const LEEWAY = 30;

class App extends Component {
  globalStateSubscription;

  constructor(props) {
    super(props);
    this.state = {
      initialising: true,
      countdownSeconds: LEEWAY,
      idle: false,
      open: false,
    };

    this.__watchGlobalState = this.__watchGlobalState.bind(this);
    this.idleTimer = null;
    this.countDownTimer = null;
    this.onPrompt = this._onPrompt.bind(this);
    this.onPresenceChange = this._onPresenceChange.bind(this);
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.onCountdown = this._countdown.bind(this);
    this.onCancelIdleTimeout = this._cancelIdleTimeout.bind(this);
    this.idleTimeout = providerStorage.isKiosk() ? KIOSK_TIMEOUT : TIMEOUT;
  }

  componentDidMount() {
    logger.info('Starting idle timer.');

    if (process.env.REACT_APP_HTTPS !== 'http') {
      window.location.protocol === 'http:' &&
        (window.location.href = window.location.href.replace(/^http:/, 'https:'));
    }

    this.globalStateSubscription = globalBloc.subscribeToState(this.__watchGlobalState);

    ValidatorForm.addValidationRule('isDateValid', (value) => {
      const date = parse(value, 'MM/dd/yyyy', new Date());
      if (!isValid(date)) return false;

      const now = new Date();

      return isBefore(date, now) && isAfter(date, subYears(now, 130));
    });

    ValidatorForm.addValidationRule('isValidName', (value) => {
      return userInfoUtil.validName(value.trim());
    });
  }

  componentWillUnmount() {
    if (this.countDownTimer) {
      clearInterval(this.countDownTimer);
      this.countDownTimer = undefined;
    }
  }

  __watchGlobalState = (state) => {
    this.setState({
      ...state,
    });
  };

  _onPrompt(e) {
    const hideIdle = sessionStorage.getItem('hide-idle') || '';

    if (hideIdle !== 'true') {
      if (!this.countDownTimer) {
        this.countDownTimer = setInterval(this.onCountdown, 1000);
      }
      this.setState({
        idle: true,
      });
      analyticsEventLogger.log(AnalyticsEvent.IDLE_SHOW, {});
    }
  }

  _onAction(e) {}

  _onActive(e) {}

  _onPresenceChange(presence) {
    logger.debug('presence changed', presence);
  }

  _onIdle(e) {
    logger.debug('idle changed', e);

    analyticsEventLogger.log(AnalyticsEvent.IDLE_SHOW, { status: 'expired' });
  }

  _countdown = () => {
    const { countdownSeconds } = this.state;

    if (countdownSeconds < 0) {
      this.doLogout();
    } else {
      this.setState({
        countdownSeconds: countdownSeconds - 1,
      });
    }
  };

  _cancelIdleTimeout = () => {
    this.idleTimer.reset();
    clearInterval(this.countDownTimer);
    this.countDownTimer = undefined;
    this.setState({
      idle: false,
      countdownSeconds: LEEWAY,
    });
  };

  doLogout = () => {
    authService
      .logout()
      .then(
        () => {
          logger.info('Log out');
        },
        (reason) => {
          logger.error('Error logging out');
        },
      )
      .finally(() => {
        uriStorage.clearPath();
        providerStorage.clearProvider();
        window.location = '/';
      });
  };

  render() {
    const { classes } = this.props;
    const { initialising, idle, countdownSeconds } = this.state;

    if (initialising) {
      return (
        <>
          <Loading shotTimeout={true} />
        </>
      );
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getThemeBasedOnTenant()}>
          <IntlProvider locale="en" messages={messages}>
            <div className={classes.root}>
              <CssBaseline />
              <IdleTimer
                ref={(ref) => {
                  this.idleTimer = ref;
                }}
                element={document}
                onPresenceChange={this.onPresenceChange}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onPrompt={this.onPrompt}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * this.idleTimeout}
                promptTimeout={1000 * 30}
              >
                <Switch>
                  <Route path={INVALID_ROUTE} component={Invalid} />
                  <Route path={KIOSK_ROUTE} component={KioskAdmin} />
                  <Route path={LINK_ROUTE} component={LinkHandler} />
                  <Route path={CALLBACK_ROUTE} component={CallbackHandler} />
                  <Route path={LOGIN_ROUTE} component={Login} />
                  <Route path={VIRTUAL_CLINIC_POST_ENCOUNTER_ROUTE} component={PostEncounter} />
                  <Route path={QUINN_TERMINATE_ROUTE} component={PostTerminate} />
                  <PrivateRoute path={ROOT_ROUTE} component={Secure} />
                </Switch>
              </IdleTimer>
              <Dialog
                open={idle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'Session Expiring'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    You have {countdownSeconds > 0 ? countdownSeconds : 0} second(s) to extend your
                    session.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={(e) => {
                      if (countdownSeconds <= 0) {
                        this.doLogout();
                      } else {
                        this.onCancelIdleTimeout();
                      }
                    }}
                    variant="contained"
                    color="primary"
                    autoFocus
                  >
                    {countdownSeconds <= 0 ? <>Restart</> : <>Extend</>}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <LocalNotifications />
          </IntlProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default withStyles(styles)(withRouter(App));
