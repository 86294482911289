import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import ChatBubble from '../../../shared/ChatBubble';

import { QUINN_ROUTE, QUINN_SCHEDULED, routeUtil } from '../../../../utils/route.name';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';
import DateSelection from './components/DateSelection';
import TimeSelection from './components/TimeSelection';
import ProviderSelection from './components/ProviderSelection';
import { BookingBlocEvent } from '../bloc';
import Confirmation from './components/Confirmation';
import PageContainer from '../../../shared/Container';
import { uriStorage } from '../../../../utils/storage';

const styles = (theme) => ({
  content: {
    flex: '1',
    paddingTop: '2em',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textItem: {
    flex: '1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontWeight: '700',
  },
  questionText: {
    color: '#20516A',
    fontWeight: '700',
  },
  noteText: {
    color: '#000000',
    fontSize: '1.4em',
    paddingTop: '18px',
  },
  header: {
    marginBottom: '24px',
  },
  cardHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'auto',
    },
  },
  card: {
    width: '240px',
    minWidth: '240px',
    maxWidth: '240px',
    margin: '12px 24px 12px 0',
    backgroundColor: '#F6F6F6',
    border: '0 solid #ffffff',
    borderRadius: '6px',
  },

  cardHeader: {},
  cardHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
  },
  cardHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
  },
  cardBody: {
    paddingTop: '0',
    paddingBottom: '6px !important',
  },

  cardBodyLine: {
    display: 'flex',
    marginBottom: '14px',
  },

  cardBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
  },

  cardBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
    paddingLeft: '18px',
  },

  buttonHolder: {
    marginTop: '18px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '48px',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  iconLarge: {
    fontSize: '18px',
  },
});

class Scheduled extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.__lookupStep(0),
      providers: [],
    };

    this.__handleStateUpdate = this.__handleStateUpdate.bind(this);
  }

  componentDidMount() {
    this.props.setLoading(false);

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RESERVATION_OPEN, {
      appointmentId: this.props.draftId,
    });

    this.stateSubscription = this.props.bloc.subscribeToState(this.__handleStateUpdate);
    this.eventSubscription = this.props.bloc.subscribeToEvents(this.__watchEvents);
  }

  componentWillUnmount() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  __handleStateUpdate = (state) => {
    this.setState({
      ...state,
    });
  };

  __watchEvents = (event) => {
    const { type } = event;
    if (type === BookingBlocEvent.DOCTOR_SELECTED) {
      this.setState({
        ...this.__lookupStep(1),
      });
    } else if (type === BookingBlocEvent.DATE_SELECTED) {
      this.setState({
        ...this.__lookupStep(2),
      });
    } else if (type === BookingBlocEvent.TIME_SELECTED) {
      this.setState({
        ...this.__lookupStep(3),
      });
    } else if (type === BookingBlocEvent.BOOKING_CONFIRMED) {
      this.props.history.push(
        routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
          this.state.appointment.id,
          QUINN_SCHEDULED,
        ),
      );
    }
  };

  __back = () => {
    this.setState({
      ...this.__lookupStep(this.state.step - 1),
    });
  };

  __backToConversation = () => {
    uriStorage.clearPath(true);
    this.props.history.push(QUINN_ROUTE);
  };

  __lookupStep = (step) => {
    switch (`${step}`) {
      case '0':
        return {
          step: step,
          message: null,
          component: <ProviderSelection bloc={this.props.bloc} />,
        };
      case '1':
        return {
          step: step,
          message: 'Please select your preferred appointment date.',
          component: <DateSelection bloc={this.props.bloc} />,
        };
      case '2':
        return {
          step: step,
          message: 'Please select your preferred appointment time.',
          component: <TimeSelection bloc={this.props.bloc} />,
        };
      default:
        return {
          step: step,
          message: 'Please check your appointment details and confirm.',
          component: <Confirmation bloc={this.props.bloc} />,
        };
    }
  };

  render() {
    let { classes, loading, handleBackToStepZero } = this.props;
    let { step, message, component } = this.state;

    return (
      <PageContainer
        loading={loading}
        handleBack={step !== 0 ? this.__back : handleBackToStepZero ?? this.__backToConversation}
      >
        {message && (
          <div className={classes.header}>
            <ChatBubble messageId="booking.appointment.schedule.bubble" message={message} />
          </div>
        )}
        <div className={classes.content}>{component}</div>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(Scheduled));
