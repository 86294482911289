import { FormattedMarkdown } from '@decodedhealth/react-library';
import { Grid, Typography } from '@mui/material';
import React, { Component } from 'react';
import { HeadingText } from '../../common/HeadingText';
import withStyles from "@mui/styles/withStyles";
import DecodedComponent from "../../shared/DecodedComponent";
import {BookingWizardConsumer} from "../BookingWizard/booking-wizard-context";
import {BookingBlocEvent} from "../bloc";
import {H2TextTitleContent} from "../../shared/Typography";
import Loading from "../../../shared-library/components/Loading";

const GridItem = (props) => <Grid {...props} item />;

const styles = (theme) => ({
    root: {},
});

class OccHealthWalkinConfirmation extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.context.bookingBloc;
        this.state = { };
    }

    componentDidMount() {
        super.componentDidMount();
        setTimeout(async () => {

            let _default = new Date();
            _default.setMinutes(_default.getMinutes() + 10);
            this.bloc.setWalkinDetails(_default);
            await this.bloc.confirmWalkin();
            await this.bloc.submit();
        }, 5000);
    }

    render() {

        return (<Loading isFullscreen={false} />);
    }
}

export default withStyles(styles)((props) => (
    <BookingWizardConsumer>
        {(value) => {
            return <OccHealthWalkinConfirmation context={value} {...props} />;
        }}
    </BookingWizardConsumer>
));
