import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Route, Switch, withRouter } from 'react-router-dom';

import { authService } from '../../utils/auth';
import { PrivateRoute } from '../../utils/protected.route';
import { secureEventService } from './service/secure.event.service';

import IdleTimer from 'react-idle-timer';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from '@mui/material';
import VirtualClinic from './VirtualClinic';
import Account from './Account';
import { uriStorage } from '../../utils/storage';
import PaymentCheck from './PaymentCheck';
import PostBooking from './PostBooking';
import {
  ACCOUNT_PAYMENT_DETAILS_ROUTE,
  BOOKING_PAYMENT_METHODS_REDIRECT_ROUTE,
  ACCOUNT_PAYMENT_METHOD_ROUTE,
  BOOKING_STATUS_ROUTE,
  BOOKING_CHECKIN_ROUTE,
  BOOKING_CONFIRMATION_ROUTE,
  BOOKING_DETAILS_ROUTE,
  BOOKING_LIST_ROUTE,
  BOOKING_ROUTE,
  POST_BOOKING_ROUTE,
  QUINN_ROUTE,
  REGISTRATION_ROUTE,
  routeUtil,
  VIRTUAL_CLINIC_ROUTE,
  BOOKING_PAYMENT_METHODS_ROUTE,
  BOOKING_RESCHEDULE_ROUTE,
  BOOKING_PAYMENT_ROUTE,
  APPOINTMENT_CHECKIN_CONFIRMATION,
  APPOINTMENT_CHECKIN_EXCEPTION,
  USER_INFORMATION_ROUTE,
} from '../../utils/route.name';
import { providerStorage } from '../../utils/provider.qs';
import Loading from '../shared/Loading';
import Registration from '../Registration';
import { consumerApi } from '../../utils/services/consumers.api';
import BookingList from './BookingList';
import { globalBloc } from '../global.bloc';
import AssistantChat from './Assistant';
import BookingDetails from './BookingDetail';
import RescheduleBooking from './Booking/RescheduleBooking';
import { Booking } from '../Booking/Booking';
import BookingPaymentMethod from '../BookingPaymentMethod';
import Payment from '../Payment';
import CheckinConfirmation from '../CheckinConfirmation';
import BookingStatus from './BookingStatus';
import BookingCheckin from './BookingCheckin';
import CheckinException from '../CheckinException';
import UserUpdate from './UserUpdate';
import PaymentMethodRouting from '../PaymentMethodRouting';
import { userAnalyticData } from '../../utils/userAnalytics/userAnalyticData';
import { appointmentApi } from '../../utils/services/appointments.api';
import { logger } from '../../utils/logging';

const styles = (theme) => ({
  root: {
    display: 'flex',
    maxHeight: '100%',
    height: '100%',
    width: '100%',
    overscrollBehavior: 'contain',
    touchAction: 'none',
  },
  drawerContent: {
    width: '100%',
  },
});

class Secure extends React.Component {
  subscription;

  state = {
    loading: true,
    quinnChat: globalBloc.quinnChat(),
    user: {},
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let { history } = this.props;
    let user = authService.getUser();

    this.setState({
      user: user,
    });

    this.subscription = secureEventService.registerStateCallback(this._handleEvent);

    uriStorage.setUpStorage();

    const currentPath = uriStorage.getCurrentPath();

    if (this.props.location.pathname?.startsWith('/callback')) {
      this.setState({ loading: false });
    } else if (
      currentPath !== '' &&
      currentPath !== '/register' &&
      !uriStorage.isOnRightPath(this.props.match.url)
    ) {
      this.setState({ loading: false });
      history.replace(uriStorage.getCurrentPath());
    } else {
      consumerApi
        .getPersonSummary()
        .then((result) => {
          this.setState({ loading: false });

          //--> check if user has a single appointment to redirect to the booking status page
          appointmentApi.getAppointmentForDate(new Date()).then((response) => {
            if (response.data.items.length === 1 && response.data.items[0].id) {
              history.replace(
                routeUtil.buildBookingStatusRouteWithAppointmentID(response.data.items[0].id),
              );
            }
          });

          userAnalyticData({
            id: result.data.id,
            dateOfBirth: result.data.dob,
            firstName: result.data.name.given,
          });

          if (result.data) {
            if (this.props.location.search.includes('preserve=true')) {
              this.setState({ loading: false });
            } else {
              history.replace(BOOKING_LIST_ROUTE);
            }
          } else {
            history.replace(REGISTRATION_ROUTE, this.props.location.state);
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          history.replace(REGISTRATION_ROUTE, this.props.location.state);
        });
    }
  }

  componentWillUnmount() {
    secureEventService.stopRemotePublishing();
    this.subscription.unsubscribe();
  }

  _handleEvent = (event) => {};

  render() {
    const { classes } = this.props;

    const { loading } = this.state;

    if (loading) {
      return <Loading shotTimeout={true} />;
    }

    let routes = (
      <>
        <PrivateRoute path={REGISTRATION_ROUTE} component={Registration} />
        <PrivateRoute path={VIRTUAL_CLINIC_ROUTE} component={VirtualClinic} />
        <PrivateRoute path={QUINN_ROUTE} component={AssistantChat} />
        <PrivateRoute path={USER_INFORMATION_ROUTE} component={UserUpdate} />
        <PrivateRoute path={BOOKING_LIST_ROUTE} component={BookingList} />
        <PrivateRoute path={POST_BOOKING_ROUTE} component={PostBooking} />
        <PrivateRoute
          path={BOOKING_PAYMENT_METHODS_REDIRECT_ROUTE}
          component={PaymentMethodRouting}
        />
        <PrivateRoute path={BOOKING_PAYMENT_METHODS_ROUTE} component={BookingPaymentMethod} />
        <PrivateRoute path={BOOKING_PAYMENT_ROUTE} component={Payment} />
        <PrivateRoute path={BOOKING_STATUS_ROUTE} component={BookingStatus} />
        <PrivateRoute path={BOOKING_CHECKIN_ROUTE} component={BookingCheckin} />
        <PrivateRoute path={BOOKING_CONFIRMATION_ROUTE} component={PaymentCheck} />
        <PrivateRoute path={BOOKING_ROUTE} component={Booking} />
        <PrivateRoute path={BOOKING_DETAILS_ROUTE} component={BookingDetails} />
        <PrivateRoute path={BOOKING_RESCHEDULE_ROUTE} component={RescheduleBooking} />
        <PrivateRoute path={ACCOUNT_PAYMENT_METHOD_ROUTE} component={Account} />
        <PrivateRoute path={APPOINTMENT_CHECKIN_CONFIRMATION} component={CheckinConfirmation} />
        <PrivateRoute path={APPOINTMENT_CHECKIN_EXCEPTION} component={CheckinException} />
      </>
    );

    return (
      <div className={classes.root}>
        <Switch>{routes}</Switch>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Secure));
