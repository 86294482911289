import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { providerStorage } from '../../../utils/provider.qs';

const kiosk = providerStorage.isKiosk();

export const H1TextTitle = styled(Typography, { variant: 'h1' })(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '3.33rem !important',
  lineHeight: '32px',
}));

export const H1TextTitleContent = styled(Typography, { variant: 'h1' })(({ theme }) => ({
  color: '#273A3E',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '3.33rem !important',
  lineHeight: '32px',
}));

export const H1TextTitleSecondary = styled(Typography, { variant: 'h1' })(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '3.33rem !important',
  lineHeight: '32px',
}));

export const H2TextTitle = styled(Typography, { variant: 'h2' })(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: kiosk ? '1.85rem' : '1.65rem !important',
  lineHeight: '32px',
}));

export const H2TextTitleContent = styled(Typography, { variant: 'h1' })(({ theme, sx }) => ({
  ...sx,
  color: '#273A3E',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: kiosk ? '1.8rem !important' : '1.6rem !important',
  lineHeight: '28px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem !important',
  },
}));

export const H2TextTitleSecondary = styled(Typography, { variant: 'h2' })(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: kiosk ? '1.85rem' : '1.65rem !important',
  lineHeight: '32px',
}));

export const SmallInfoText = styled(Typography)(({ theme, sx }) => ({
  ...sx,
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '1.2rem !important',
  lineHeight: '18px',
  color: '#5C5D5F',
}));

export const TextBody = styled(Typography, { variant: 'body' })(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '1.8rem !important',
  lineHeight: '26px',
  color: '#777777',
}));

export const TextBody2Standard = styled(Typography, { variant: 'body2' })(
  ({ theme, primary, secondary, md, sx }) => ({
    ...sx,
    color: primary
      ? theme.palette.primary.main
      : secondary
      ? theme.palette.secondary.main
      : undefined,
    fontSize: '1.7rem !important',
    ...(md && {
      [theme.breakpoints.down('md')]: {
        fontSize: '1.8em',
        textAlign: 'center',
      },
    }),
  }),
);
