import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../../utils/events';
import BaseWidget from './BaseWidget';
import { serviceUtil } from '../../../../../utils/service';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../../../shared/Typography';
import { globalBloc } from '../../../../global.bloc';

const styles = (theme) => ({
  root: {},
  fill: {
    flex: '1 1 auto',
  },
  cardHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'auto',
    },
  },
  card: {
    width: '270px',
    minWidth: '270px',
    maxWidth: '270px',
    margin: '12px 24px 12px 0',
    backgroundColor: '#F6F6F6',
    border: '0 solid #ffffff',
    borderRadius: '6px',
  },

  cardSelected: {
    width: '240px',
    minWidth: '240px',
    maxWidth: '240px',
    margin: '12px 24px 12px 0',
    backgroundColor: theme.palette.primary.main,
    border: '0 solid #ffffff',
    borderRadius: '6px',
  },

  cardHeader: {},

  cardSelectedHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#ffffff',
  },

  cardHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
  },

  cardHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
  },
  cardSelectedHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#FFF',
  },
  cardBody: {
    paddingTop: '24px',
    paddingBottom: '6px !important',
  },

  cardBodyLine: {
    display: 'flex',
    marginBottom: '14px',
  },

  cardBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
  },
  cardSelectedBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
  },
  cardBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
    paddingLeft: '18px',
  },
  cardSelectedBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
    paddingLeft: '18px',
  },

  buttonHolder: {
    marginTop: '18px',
  },
  button: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  iconLarge: {
    fontSize: '18px',
  },
  iconLargeWhite: {
    fontSize: '18px',
    color: '#fff',
  },
  documents: {
    paddingTop: '14px',
    paddingBottom: '14px',
    lineHeight: '14px',
  },
  checkBox: {
    transform: 'scale(1.5)',
    marginRight: '1em',
    marginLeft: '0.5em',
  },
  locationTerm: {
    color: theme.palette.primary.main,
  },
});

const GridItem = (props) => <Grid {...props} item />;

class Confirmation extends Component {
  constructor(props) {
    super(props);

    const { bloc } = this.props;
    const { staff, booking, appointment, organisation } = bloc.subject.value;

    this.state = {
      initialising: true,
      staff: staff,
      booking: booking,
      start: bloc.extractSlotStartDate(booking),
      appointment: appointment,
      organisation: organisation,
      serviceName: 'Loading',
    };

    this.__submit = this.__submit.bind(this);
    this.__renderConfirmationMessage = this.__renderConfirmationMessage.bind(this);
  }

  componentDidMount() {
    serviceUtil.friendlyName(this.state.appointment.service).then(
      (value) => this.setState({ serviceName: value }),
      (reason) => this.setState({ serviceName: 'Appointment' }),
    );

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_LOADED, {});
  }

  componentWillUnmount() {}

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  __submit = () => {
    if (!this.state.processing) {
      let data = {};
      if (this.state.appointment.service.includes('_MDS')) {
        data.consents = [
          { system: 'decoded/consent', code: 'cosmeticTerms', value: `${this.state.consented}` },
        ];
      }
      this.props.bloc.confirmAppointment(data);
      this.setState({
        processing: true,
      });
      this.timer = setTimeout(() => {
        this.setState({ processing: false });
      }, 5000);
    }
  };

  __renderConfirmationMessage = () => {
    if (globalBloc.isTelehealth()) {
      return (
        <H2TextTitleContent component={GridItem}>
          <FormattedMarkdown
            id="interaction.appointment.reservation.confirmation.heading"
            defaultMessage="You will be texted 5 minutes before your selected appointment time with a link to join your telehealth visit."
          />
        </H2TextTitleContent>
      );
    }
    return (
      <>
        <H2TextTitleContent component={GridItem}>
          <FormattedMarkdown
            id="interaction.appointment.reservation.confirmation.heading"
            defaultMessage="Your reservation is an estimated time and not an appointment."
          />
        </H2TextTitleContent>
        <H2TextTitleContent component={GridItem}>
          <FormattedMarkdown
            id="interaction.appointment.reservation.confirmation.message1"
            defaultMessage="Please know that we make every effort to see you promptly, however, wait times can vary. Patients with serious conditions may be seen first."
          />
        </H2TextTitleContent>
        <H2TextTitleContent component={GridItem}>
          <FormattedMarkdown
            id="interaction.appointment.reservation.confirmation.message2"
            defaultMessage="Thanks for your understanding."
          />
        </H2TextTitleContent>
      </>
    );
  };

  render() {
    const { classes, message, messageId } = this.props;

    const { processing } = this.state;

    return (
      <BaseWidget
        initialising={false}
        processing={processing}
        submitEnabled={true}
        submit={this.__submit}
        submitLabel={'Confirm'}
        message={message}
        messageId={messageId}
      >
        <Grid container direction="column">
          {this.__renderConfirmationMessage()}
        </Grid>
      </BaseWidget>
    );
  }
}

export default withStyles(styles)(Confirmation);
