import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { Box, FormControlLabel } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { FormattedMessage } from 'react-intl';
import Link from '@mui/material/Link';
import PageContainer from '../../common/PageContainer';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { Checkbox } from '../../shared/components/Checkbox';
import { withTheme } from '@mui/styles';
import ScrollableContainer, { PageContent, PageFooter } from '../../common/ScrollableContainer';
import { providerStorage } from '../../../utils/provider.qs';
import { H2TextTitleContent } from '../../shared/Typography';

import QRCode from 'react-qr-code';
import DecodedButton, { DecodedSubmitButton } from '../../common/DecodedButton';
import KioskWelcome from './KioskWelcome/KioskWelcome';

const styles = (theme) => ({
  grid: {
    flex: '1',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textItem: {
    flex: '1',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    textAlign: 'left',
    paddingTop: '3em',
    width: '100%',
    flex: '1',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2em',
    },
  },
  amount: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '5.6em',
    lineHeight: '1.214',
    color: '#0F0F0F',
    marginBottom: '0.44643em',
  },
  confirmation: {
    width: '100%',
    lineHeight: '1.75',
    color: '#575757',
    paddingTop: '0.5em',
    textAlign: 'center',
    marginBottom: '1em',
    fontWeight: '500',
    fontSize: '2em',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'unset',
    },
  },
  opening: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    width: '100%',
    lineHeight: '1.75',
    paddingTop: '0.5em',
    textAlign: 'left',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  kioskOpening: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    lineHeight: '1.75',
    paddingTop: '0.5em',
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      marginBottom: '0',
    },
  },
  instructionList: {
    width: '100%',
    lineHeight: '1.75',
    fontWeight: '500',
    color: '#575757',
    paddingTop: '2.8em',
    paddingBottom: '2.2em',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0em',
      paddingBottom: '0.5em',
    },
  },
  list: {
    width: '100%',
    lineHeight: '1.75',
    fontWeight: '500',
    color: '#575757',
    paddingTop: '0.5em',
    textAlign: 'left',
  },
  order: {
    fontSize: '1.5em',
    color: '#F37421',
    paddingRight: '0.8em',
    fontWeight: '600',
  },
  warning: {
    width: '100%',
    lineHeight: '1.75',
    color: '#1A0C00',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  button: {
    width: '100%',
    paddingTop: '2.5em',
    paddingBottom: '2.5em',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: '1.5em',
      paddingBottom: '1.5em',
    },
  },
  checkBox: {
    transform: 'scale(1.5)',
    marginRight: '1em',
    marginLeft: '0.5em',
  },
  locationTerm: {
    color: theme.palette.primary.main,
  },
});

class Welcome extends Component {
  constructor(props) {
    super(props);

    const isKiosk = providerStorage.isKiosk();
    const isWalkin = providerStorage.getCurrentProvider() !== '';

    this.state = {
      isKiosk: isKiosk,
      isWalkin: isWalkin,
      showCheckbox: false, //!(isKiosk || isWalkin),
    };
  }

  componentDidMount() {
    sessionStorage.setItem('hide-idle', 'true');
  }

  componentWillUnmount() {
    sessionStorage.removeItem('hide-idle');
  }

  render() {
    let { classes, acceptLocationTerm, handleNext, handleCheckboxChange, loading, location } =
      this.props;

    let { isKiosk, isWalkin, showCheckbox } = this.state;

    return (
      <>
        {isKiosk ? (
          <KioskWelcome handleNext={handleNext} />
        ) : (
          <PageContainer isEnd={true} loading={loading}>
            <ScrollableContainer applyFormat={true}>
              <PageContent>
                <H2TextTitleContent
                  sx={{ marginBottom: '0' }}
                  className={isKiosk ? classes.kioskOpening : classes.opening}
                >
                  <FormattedMarkdown
                    id="welcome.bubble"
                    defaultMessage="In a few steps we can complete the registration for your visit today"
                  />
                </H2TextTitleContent>
                {this.getStandardWelcome(classes)}
              </PageContent>
              <PageFooter>
                <ValidatorForm autoComplete="off" onSubmit={handleNext} className={classes.form}>
                  {showCheckbox ? (
                    <FormControlLabel
                      style={{ fontSize: '1.2em', textAlign: 'left' }}
                      control={
                        <Checkbox
                          required
                          checked={acceptLocationTerm}
                          onChange={handleCheckboxChange}
                          name="acceptLocationTerm"
                          color="primary"
                          className={classes.checkBox}
                        />
                      }
                      label={
                        <div className={classes.locationTerm}>
                          <FormattedMarkdown
                            id="welcome.location.confirmation"
                            defaultMessage="Please confirm that you will be physically located in the state of Louisana at the time of your visit."
                          />
                        </div>
                      }
                    />
                  ) : null}

                  <div className={classes.button}>
                    <DecodedSubmitButton id={'lets-start-btn'}>LET'S START!</DecodedSubmitButton>
                  </div>

                  <div className={classes.warning}>
                    {isWalkin ? (
                      <Typography
                        variant="body1"
                        component={'div'}
                        sx={(theme) => ({
                          color: theme.palette.accent.main,
                        })}
                      >
                        <FormattedMarkdown
                          id="global.instruction.emergency.atLocation"
                          defaultMessage=" If this is a medical emergency, please see one of our team members immediately."
                        />
                      </Typography>
                    ) : (
                      <Typography
                        variant="body1"
                        component={'div'}
                        sx={(theme) => ({
                          color: theme.palette.accent.main,
                        })}
                      >
                        <FormattedMarkdown
                          id="global.instruction.emergency"
                          defaultMessage="If this is a medical emergency , please call 911 immediately."
                        />
                      </Typography>
                    )}
                  </div>
                </ValidatorForm>
              </PageFooter>
            </ScrollableContainer>
          </PageContainer>
        )}
      </>
    );
  }

  getKioskWelcome(classes) {
    const { history } = this.props;

    return (
      <>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              // position: 'absolute',
              // top: '50%',
              // left: '50%',
              // transform: 'translate(-50%, -50%)',
              // zIndex: 1,
            }}
          >
            <QRCode
              size={200}
              value={`${window.location.protocol}//${
                window.location.host
              }/link/qr/${providerStorage.getCurrentProvider()}`}
            />
          </Box>
          <Box
            sx={{
              paddingBottom: '12px',
            }}
          >
            <H2TextTitleContent sx={{ textAlign: 'center' }}>
              <FormattedMarkdown
                id={`login.welcome.kiosk.message`}
                defaultMessage={`Please scan the QR code with your mobile device or select 'Let's Start' to continue.\n\n `}
              />
            </H2TextTitleContent>
          </Box>
        </Box>
      </>
    );
  }

  getStandardWelcome(classes) {
    return (
      <>
        <div>
          <Typography variant="body2" component={'div'} className={classes.confirmation}>
            <FormattedMessage id="welcome.instruction.note" defaultMessage=" " />

            <FormattedMessage id="welcome.instruction.link.ref">
              {(refChunks) =>
                refChunks[0] !== '_' ? (
                  <Link
                    rel="noopener noreferrer"
                    target="_blank"
                    href={refChunks}
                    underline="hover"
                  >
                    <FormattedMessage id="welcome.instruction.link.text">
                      {(text) => (text[0] !== '_' ? text : null)}
                    </FormattedMessage>
                  </Link>
                ) : null
              }
            </FormattedMessage>

            <FormattedMessage id="welcome.instruction.note.cont">
              {(text) => (text[0] !== '_' ? text : null)}
            </FormattedMessage>
          </Typography>
        </div>

        <FormattedMessage
          id="welcome.instruction.guide"
          defaultMessage="The process to book an urgent care visit takes about 5 minutes:"
        >
          {(text) =>
            text[0] !== ' ' &&
            text[0] !== '_' && (
              <React.Fragment>
                <div className={classes.instructionList}>
                  <Typography variant="body2" component={'div'}>
                    {text[0]}
                  </Typography>
                </div>

                <FormattedMessage
                  id="welcome.instruction.one"
                  defaultMessage="Complete a basic registration."
                >
                  {(text) =>
                    text[0] !== ' ' &&
                    text[0] !== '_' && (
                      <div className={classes.list}>
                        <Typography variant="body2" component={'div'}>
                          <span className={classes.order}>1</span>
                          {text[0]}
                        </Typography>
                      </div>
                    )
                  }
                </FormattedMessage>

                <FormattedMessage
                  id="welcome.instruction.two"
                  defaultMessage="Tell me why you’d like to be seen."
                >
                  {(text) =>
                    text[0] !== ' ' &&
                    text[0] !== '_' && (
                      <div className={classes.list}>
                        <Typography variant="body2" component={'div'}>
                          <span className={classes.order}>2</span>
                          {text[0]}
                        </Typography>
                      </div>
                    )
                  }
                </FormattedMessage>

                <FormattedMessage
                  id="welcome.instruction.three"
                  defaultMessage="Choose a visit location and time."
                >
                  {(text) =>
                    text[0] !== ' ' &&
                    text[0] !== '_' && (
                      <div className={classes.list}>
                        <Typography variant="body2" component={'div'}>
                          <span className={classes.order}>3</span>
                          {text[0]}
                        </Typography>
                      </div>
                    )
                  }
                </FormattedMessage>
              </React.Fragment>
            )
          }
        </FormattedMessage>
      </>
    );
  }
}

export default withTheme(withStyles(styles)(withRouter(Welcome)));
