import * as rxjs from 'rxjs';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { addHours, compareAsc, format, parse } from 'date-fns';
import { DATE_FORMAT } from '../../../utils/user';
import { notificationService } from '../../../utils/notification';
import { dateUtil } from '../../../utils/date';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { errorResolver } from '../../../utils/error.resolver';
import { createContext } from 'react';
import { globalBloc } from '../../global.bloc';

export class BookingBloc {
  constructor(appointmentId, appointmentType) {
    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      appointmentId: appointmentId,
      appointmentType: appointmentType,
      booking: {},
      systemProperties: [],
      isTelehealth: globalBloc.isTelehealth(),
    });

    this.events = new rxjs.Subject();

    this.__initialise(appointmentId);
  }

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  __initialise = (appointmentId) => {
    appointmentApi.getAppointmentStatus(appointmentId).then((value) => {
      const appointment = value.data;

      let now = new Date();
      let start = format(now, DATE_FORMAT);
      let end = format(new Date(now.setDate(now.getDate() + 14)), DATE_FORMAT);

      Promise.all([
        appointmentApi.getServiceStaffWithPermission(
          appointment.provider,
          `organisation:service-code:${appointment.service}`,
        ),
        appointmentApi.getServiceScheduleSummary(
          start,
          end,
          appointment.service,
          appointment.provider,
        ),
        appointmentApi.getAvailableProviders(),
      ]).then(
        (multiple) => {
          const staff = multiple[0].data;
          const schedule = multiple[1].data;
          const organisation = multiple[2].data.items.filter(
            (_provider) => _provider.id === appointment.provider,
          )[0];

          this.subject.next({
            ...this.subject.value,
            appointment: appointment,
            organisation: organisation,
            availabilitySummary: schedule,
            availabilityStaff: staff.items,
            initialising: false,
          });

          this.events.next({
            type: BookingBlocEvent.INITIALISED,
            data: {
              availabilityStaff: staff.items,
              appointment: appointment,
            },
          });
        },
        (reason) => {
          this.subject.next({
            ...this.subject.value,
            appointment: appointment,
            availabilitySummary: undefined,
            initialising: false,
          });

          notificationService.error(
            'Error loading providers and schedules. Please refresh and if the problem persists call the clinic.',
          );
        },
      );
    });
  };

  searchServiceSchedule = (date) => {
    const { appointment } = this.subject.value;

    this.subject.next({
      ...this.subject.value,
      loading: true,
    });

    let selectedDate = format(date, DATE_FORMAT);

    appointmentApi
      .getAvailableAppointmentSchedule(selectedDate, appointment.provider, appointment.service)
      .then((value) => {
        this.subject.next({
          ...this.subject.value,
          loading: false,
          selectedDate: selectedDate,
          serviceSlots: value.data,
          schedulingIntervals: this._constructScheduleData(
            value.data.results[0].intervals,
            selectedDate,
          ).sort((a, b) => compareAsc(a.parsedTime, b.parsedTime)),
        });
      });
  };

  _constructScheduleData = (intervalData, selectedDate) => {
    const compareDate =
      typeof selectedDate === 'object' ? format(selectedDate, DATE_FORMAT) : selectedDate;

    return intervalData
      .map((interval) => {
        const scheduleIntervalHour = parse(interval.start, "yyyy-MM-dd'T'HH:mm:ssX", new Date());
        const hourDisplay = `${format(scheduleIntervalHour, 'h aa')}`;

        let slots = [];

        if (interval.availableSlots) {
          interval.availableSlots.forEach((_slot) => {
            slots.push({
              id: _slot.slotId,
              display: `${format(scheduleIntervalHour, 'hh')}:${_slot.start.split(':')[1]} ${format(
                scheduleIntervalHour,
                'aa',
              )}`,
              start: _slot.start,
            });
          });
        }

        const startDate = format(scheduleIntervalHour, DATE_FORMAT);

        return {
          start: scheduleIntervalHour,
          startDate: startDate,
          // waitTimes: interval.waitTimes.current,
          waitTimes: interval.current,
          capacity: interval.capacity,

          parsedTime: scheduleIntervalHour,
          hourDisplay: hourDisplay,
          slots: slots,
        };
      })
      .filter((interval) => {
        return interval.startDate === compareDate;
      });
  };

  setDoctor = (doctor, doctorDetail) => {
    const { booking } = this.subject.value;

    if (booking.doctor !== doctor) {
      let newBooking = { ...booking };
      newBooking.doctor = doctor;
      newBooking.doctorDetail = doctorDetail;
      newBooking.reminderTime = undefined;

      this.subject.next({
        ...this.subject.value,
        booking: newBooking,
        calendarSummary: undefined,
      });
    }

    this.events.next({ type: BookingBlocEvent.DOCTOR_SELECTED, data: {} });

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_PROVIDER_SELECT, {});
  };

  loadSelectedDoctorSchedule = () => {
    const { appointment, booking, calendarSummary } = this.subject.value;

    if (calendarSummary) {
      this.subject.next({
        ...this.subject.value,
        calendarSummary: calendarSummary,
        randomInt: Math.random(),
      });
      return;
    }

    const now = new Date();
    const nextPeriod = new Date(new Date().setDate(now.getDate() + 14));
    const flowingPeriod = new Date(new Date().setDate(now.getDate() + 28));
    const anotherPeriod = new Date(new Date().setDate(now.getDate() + 42));
    const lastPeriod = new Date(new Date().setDate(now.getDate() + 54));

    let start1 = format(now, DATE_FORMAT);
    let end1 = format(nextPeriod, DATE_FORMAT);
    let start2 = format(nextPeriod, DATE_FORMAT);
    let end2 = format(flowingPeriod, DATE_FORMAT);
    let start3 = format(flowingPeriod, DATE_FORMAT);
    let end3 = format(anotherPeriod, DATE_FORMAT);
    let start4 = format(anotherPeriod, DATE_FORMAT);
    let end4 = format(lastPeriod, DATE_FORMAT);

    Promise.all([
      appointmentApi.getServiceScheduleSummary(
        start1,
        end1,
        appointment.service,
        appointment.provider,
        booking.doctor,
      ),
      appointmentApi.getServiceScheduleSummary(
        start2,
        end2,
        appointment.service,
        appointment.provider,
        booking.doctor,
      ),
      appointmentApi.getServiceScheduleSummary(
        start3,
        end3,
        appointment.service,
        appointment.provider,
        booking.doctor,
      ),
      appointmentApi.getServiceScheduleSummary(
        start4,
        end4,
        appointment.service,
        appointment.provider,
        booking.doctor,
      ),
    ]).then(
      (value) => {
        let earliest = undefined;
        let slots = [];

        for (let i = 0; i < value.length; i++) {
          let data = value[i].data;
          if (!earliest) {
            earliest = new Date(Date.parse(data.firstOpenSlot));
          } else {
            const compare = new Date(Date.parse(data.firstOpenSlot));
            if (compare.getTime() < earliest.getTime()) {
              earliest = compare;
            }
          }

          data.schedule.forEach((_item) => {
            slots.push(_item);
          });
        }

        let nextSummary = {
          earliest: earliest,
          slots: slots,
        };

        this.subject.next({
          ...this.subject.value,
          calendarSummary: nextSummary,
        });

        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {});
      },
      (reason) => {
        notificationService.error(
          'Error loading available times for provider. Please refresh. If the problem persists please contact the clinic.',
        );
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {
          status: 'error',
          message: `${reason}`,
        });
      },
    );
  };

  loadServiceSchedule = () => {
    const { appointment, calendarSummary } = this.subject.value;

    if (calendarSummary) {
      this.subject.next({
        ...this.subject.value,
        calendarSummary: calendarSummary,
        randomInt: Math.random(),
      });
      return;
    }

    const now = new Date();
    const nextPeriod = new Date(new Date().setDate(now.getDate() + 14));
    const flowingPeriod = new Date(new Date().setDate(now.getDate() + 28));
    const anotherPeriod = new Date(new Date().setDate(now.getDate() + 42));
    const lastPeriod = new Date(new Date().setDate(now.getDate() + 54));

    let start1 = format(now, DATE_FORMAT);
    let end1 = format(nextPeriod, DATE_FORMAT);
    let start2 = format(nextPeriod, DATE_FORMAT);
    let end2 = format(flowingPeriod, DATE_FORMAT);
    let start3 = format(flowingPeriod, DATE_FORMAT);
    let end3 = format(anotherPeriod, DATE_FORMAT);
    let start4 = format(anotherPeriod, DATE_FORMAT);
    let end4 = format(lastPeriod, DATE_FORMAT);

    Promise.all([
      appointmentApi.getServiceScheduleSummary(
        start1,
        end1,
        appointment.service,
        appointment.provider,
      ),
      appointmentApi.getServiceScheduleSummary(
        start2,
        end2,
        appointment.service,
        appointment.provider,
      ),
      appointmentApi.getServiceScheduleSummary(
        start3,
        end3,
        appointment.service,
        appointment.provider,
      ),
      appointmentApi.getServiceScheduleSummary(
        start4,
        end4,
        appointment.service,
        appointment.provider,
      ),
    ]).then(
      (value) => {
        let earliest = undefined;
        let slots = [];

        for (let i = 0; i < value.length; i++) {
          let data = value[i].data;
          if (!earliest) {
            earliest = new Date(Date.parse(data.firstOpenSlot));
          } else {
            const compare = new Date(Date.parse(data.firstOpenSlot));
            if (compare.getTime() < earliest.getTime()) {
              earliest = compare;
            }
          }

          data.schedule.forEach((_item) => {
            slots.push(_item);
          });
        }

        let nextSummary = {
          earliest: earliest,
          slots: slots,
        };

        this.subject.next({
          ...this.subject.value,
          calendarSummary: nextSummary,
        });

        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {});
      },
      (reason) => {
        notificationService.error(
          'Error loading available times for appointment type. Please refresh. If the problem persists please contact the clinic.',
        );
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {
          status: 'error',
          message: `${reason}`,
        });
      },
    );
  };

  setSelectedDate = (date) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedDate = date;
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });

    this.events.next({ type: BookingBlocEvent.DATE_SELECTED, data: {} });
  };

  setReminderTime = (interval) => {
    const { booking, appointment } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.reminderTime = interval;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });

    appointmentApi.updateAppointment(appointment.id, this.__createAppointmentRequestData()).then(
      (value) => {
        this.events.next({ type: BookingBlocEvent.REMINDER_SELECTED, data: {} });
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_REMINDER_SELECT, {});
      },
      (reason) => {
        notificationService.error(
          'There was a problem selecting the desired time. Please select another time. If the problem persists please contact the clinic.',
        );
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_REMINDER_SELECT, {
          status: 'error',
          message: `${reason.message}`,
        });
      },
    );
  };

  loadSelectedDayAvailability = () => {
    const { appointment, booking } = this.subject.value;

    appointmentApi
      .getAvailableAppointmentSchedule(
        booking.selectedDate,
        appointment.provider,
        appointment.service,
        booking.doctor,
      )
      .then((value) => {
        let newBooking = { ...booking, availability: value.data, reminderTimer: undefined };

        this.subject.next({
          ...this.subject.value,
          booking: newBooking,
          schedulingIntervals: this._constructScheduleData(
            value.data.results[0].intervals,
            booking.selectedDate,
          ).sort((a, b) => compareAsc(a.parsedTime, b.parsedTime)),
        });
      });
  };

  setBookingTime = (datetime, save) => {
    const { booking, appointment } = this.subject.value;

    let newBooking = { ...booking, selectedSlot: datetime, reminderTime: undefined };

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });

    if (save) {
      appointmentApi.updateAppointment(appointment.id, this.__createAppointmentRequestData()).then(
        (value) => {
          this.events.next({ type: BookingBlocEvent.TIME_SELECTED, data: {} });
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {});
        },
        (reason) => {
          notificationService.error(
            'There was a problem selecting the desired time. Please select another time. If the problem persists please contact the clinic.',
          );
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {
            status: 'error',
            message: `${reason.message}`,
          });
        },
      );
    } else {
      this.events.next({ type: BookingBlocEvent.TIME_SELECTED, data: {} });
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {});
    }
  };

  __createAppointmentRequestData = () => {
    const { appointment, booking, isTelehealth } = this.subject.value;

    const selectedStart = this.extractSlotStartDate(booking);

    let participants = [
      {
        role: 'O_SRV_PROV',
        identifier: {
          value: appointment.provider,
        },
      },
    ];

    if (booking.doctor) {
      participants.push({
        role: 'S_PROVIDER',
        identifier: {
          value: booking.doctor,
        },
      });
    }

    return {
      service: {
        code: {
          value: appointment.service,
        },
        channel: {
          value: appointment.type,
        },
      },
      slot: {
        intervalStart: selectedStart,
      },
      reminder: booking.reminderTime || (isTelehealth ? 5 : 60),
      participants: participants,
    };
  };

  extractSlotStartDate(booking) {
    const interval = booking.availability.results
      .flatMap((_result) => _result.intervals)
      .filter((_interval) => {
        if (!_interval.availableSlots) return false;
        if (_interval.start === booking.selectedSlot) return true;
        const arr = _interval.availableSlots.filter(
          (_slot) => _slot.slotId === booking.selectedSlot,
        );
        return arr.length > 0;
      })[0];

    const slot = interval.availableSlots.filter((_slot) => _slot.slotId === booking.selectedSlot);

    const selectedStart = dateUtil.parseDate(interval.start);
    if (slot.length > 0) {
      selectedStart.setMinutes(slot[0].start.split(':')[1]);
    }
    return selectedStart;
  }

  confirmAppointment = (data) => {
    const { appointment } = this.subject.value;

    if (appointment.type === 'VIRTUAL') {
      appointmentApi.confirmAppointment(appointment.id, {}).then(
        (response) => {
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_VIRTUAL_SCHEDULE_CONFIRM_SUCCESS, {
            appointmentId: appointment.id,
          });
          this.events.next({ type: BookingBlocEvent.BOOKING_CONFIRMED, data: {} });
        },
        (reason) => {
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_VIRTUAL_SCHEDULE_CONFIRM_ERROR, {
            status: 'error',
            appointmentId: appointment.id,
            message: `${reason}`,
          });
          notificationService.error(errorResolver.resolveBookingConfirmationError(reason));
        },
      );
    } else {
      appointmentApi.confirmAppointment(appointment.id, data).then(
        (value) => {
          this.events.next({ type: BookingBlocEvent.BOOKING_CONFIRMED, data: {} });
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT, {
            appointmentId: appointment.id,
          });
        },
        (reason) => {
          notificationService.error(
            'There was a problem confirming your appointment. Please try again or contact the clinic if the problem persists.',
          );
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT, {
            status: 'error',
            message: `${reason}`,
          });
        },
      );
    }
  };

  switchType = (newType) => {
    const { availabilityStaff, appointment } = this.subject.value;

    if (newType === 'reservation' && appointment.type === 'IN_PERSON_WALK_IN') {
      appointment.type = 'IN_PERSON';
    }

    this.subject.next({
      ...this.subject.value,
      appointment: appointment,
    });

    this.events.next({
      type: BookingBlocEvent.SWITCH_BOOKING_TYPE,
      data: {
        availabilityStaff: availabilityStaff,
        appointment: appointment,
        overrides: { type: newType },
      },
    });

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_TYPE_SWITCH, {
      appointmentId: appointment.id,
    });
  };
}

export class BookingBlocEvent {
  static INITIALISED = 'INITIALISED';
  static SWITCH_BOOKING_TYPE = 'SWITCH_BOOKING_TYPE';
  static DOCTOR_SELECTED = 'DOCTOR_SELECTED';
  static DATE_SELECTED = 'DATE_SELECTED';
  static TIME_SELECTED = 'TIME_SELECTED';
  static REMINDER_SELECTED = 'REMINDER_SELECTED';
  static BOOKING_CONFIRMED = 'BOOKING_CONFIRMED';
}

export const RescheduleBookingContext = createContext(undefined);
