import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import { dateUtil } from '../../../../../utils/date';
import { compareAsc, isAfter } from 'date-fns';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../../utils/events';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';

import BaseWidget from './BaseWidget';
import { RescheduleBookingContext } from '../../bloc';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { TimeIntervalButton } from '../../components/TimeIntervals';

const styles = (theme) => ({
  root: {},
  dates: {
    paddingTop: '16px',
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '55vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '24px',
    },
  },
  fill: {
    flex: '1 1 auto',
  },
  subtext: {
    padding: '0 0 24px 0',
  },
  graph: {
    position: 'relative',
    width: '700px',
    minWidth: '90%',
    maxWidth: '700px',
    minHeight: '140px',
    maxHeight: '300px',
    [theme.breakpoints.down('md')]: {
      minHeight: '140px',
      maxHeight: '140px',
      maxWidth: '70vw',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '140px',
      maxHeight: '140px',
      maxWidth: '85vw',
    },
  },
  backdrop: {
    margin: '0 0 3.5em 4em',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.12)',
    zIndex: theme.zIndex.drawer + 1,
    color: '#0000006b',
  },
  cardHolder: {
    display: 'contents',
    paddingBottom: '24px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'auto',
    },
  },
  buttonHolder: {
    marginTop: '18px',
  },
  button: {
    paddingLeft: '0',
    paddingRight: '0',
    marginRight: '24px',
    marginBottom: '24px',
    width: '145px',
    maxWidth: '145px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '145px',
    },
  },
  buttonIcon: {
    marginRight: '12px',
    color: '#ffffff',
  },
  unselectedButton: {
    backgroundColor: '#F6F6F6',
    paddingLeft: '0',
    paddingRight: '0',
    marginRight: '24px',
    marginBottom: '24px',
    width: '145px',
    maxWidth: '145px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '145px',
    },
  },
  unselectedButtonIcon: {
    marginRight: '12px',
    color: '#FFC300',
  },
  progressHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'hidden',
    },
  },
  progress: {
    color: '#FFC300',
  },
  errorText: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '32px',
    color: '#FF7700',
  },
});

class TimeSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialising: true,
      schedulingIntervals: [],
    };

    this.__selectTime = this.__selectTime.bind(this);
  }

  componentDidMount() {
    this.props.bloc.loadSelectedDayAvailability();
    this.stateSubscription = this.props.bloc.subscribeToState(this.__handleStateUpdate);
  }

  componentWillUnmount() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  componentDidUpdate() {
    if (
      !this.state.selectedSlot &&
      this.context?.start &&
      new Date(this.state.booking.selectedDate).getDate() === new Date(this.context.start).getDate()
    ) {
      this.setState((prevState) => ({
        ...prevState,
        selectedSlot: this.context.start,
      }));
    }
  }

  __handleStateUpdate = (state) => {
    const { initialising } = this.state;

    let started = initialising;

    let newProperties = {
      schedulingIntervals: state.schedulingIntervals || [],
    };

    if (initialising && state.booking?.availability) {
      started = false;
      const start = new Date(state.booking.selectedDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(state.booking.selectedDate);
      end.setHours(23, 59, 59, 999);

      const now = new Date();

      let intervals = [];

      if (state.booking.availability.results.length > 0) {
        state.booking.availability.results[0].intervals.forEach((_interval) => {
          _interval.renderDate = dateUtil.parseDate(_interval.start);
          if (
            now.getTime() < _interval.renderDate.getTime() &&
            dateUtil.encloses(_interval.renderDate, start, end) &&
            _interval.availableSlots &&
            _interval.availableSlots.length > 0
          ) {
            intervals.push(_interval);
          }
        });

        intervals.sort((slot1, slot2) => {
          return compareAsc(slot1.renderDate, slot2.renderDate);
        });
      }

      newProperties.intervals = intervals;

      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_LOADED, {});
    }

    this.setState({
      ...state,
      ...newProperties,
      initialising: started,
    });
  };

  __selectTime = (slotId) => {
    const $this = this;
    return () => {
      $this.setState({
        selectedSlot: slotId,
      });
    };
  };
  __submit = () => {
    if (this.state.selectedSlot && !this.state.processing) {
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {});

      this.props.bloc.setBookingTime(this.state.selectedSlot);
      this.setState({
        processing: true,
      });
      this.timer = setTimeout(() => {
        this.setState({ processing: false });
      }, 5000);
    }
  };

  __isIntervalAvailable = (interval) => {
    const now = new Date();
    return interval.capacity > 0 && isAfter(interval.parsedTime, now);
  };

  renderCellColour = (entry, index) => {
    let colour = index === this.state.activeIndex ? '#F37421' : '#F37421';
    return !this.__isIntervalAvailable(entry) ? '#FFC300' : colour;
  };

  render() {
    const { classes, theme, message, messageId } = this.props;

    const { initialising, processing, loading, intervals, selectedSlot, schedulingIntervals } =
      this.state;

    const availableIntervals = schedulingIntervals.filter(this.__isIntervalAvailable);

    const available = intervals && intervals.length > 0;

    return (
      <BaseWidget
        initialising={initialising}
        processing={processing}
        submitHidden={!intervals || intervals.length === 0}
        submitEnabled={selectedSlot && !processing}
        submit={this.__submit}
        submitLabel={'SELECT'}
        message={message}
        messageId={messageId}
      >
        <Grid>
          {available && (
            <Grid row xs={12} className={classes.subtext}>
              <Typography variant="body2" component={'div'}>
                <FormattedMarkdown
                  id={'interaction.appointment.reservation.selecttime.subtext.0'}
                  defaultMessage={'You can see the approximated waiting on the graph below.'}
                />
              </Typography>
            </Grid>
          )}

          {available && (
            <Grid row xs={12} className={classes.graph}>
              <ResponsiveContainer width="100%" height="100%" minHeight={'140px'}>
                <BarChart
                  data={availableIntervals.length > 0 ? schedulingIntervals : availableIntervals}
                  width={500}
                  height={300}
                  margin={{ top: 5, right: 10, bottom: 5, left: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="hourDisplay" interval="preserveStartEnd" />
                  <YAxis width={40} ticks={[0, 10, 20, 30, 40, 50, 60]} />
                  <Tooltip
                    cursor={availableIntervals.length > 0}
                    active={availableIntervals.length > 0}
                  />
                  <Bar
                    isAnimationActive={false}
                    name="Wait Time (minutes)"
                    dataKey="waitTimes"
                    fill={theme.palette.accent.main}
                  >
                    {schedulingIntervals.map((entry, index) => (
                      <Cell fill={theme.palette.accent.main} key={`cell-${index}`} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>

              <div>
                {!loading && availableIntervals.length === 0 && (
                  <Backdrop className={classes.backdrop} open={true}>
                    <Typography variant="body2" component={'div'}>
                      <FormattedMarkdown
                        id={'booking.appointment.schedule.chart.unavailable'}
                        defaultMessage={'No session available'}
                      />
                    </Typography>
                  </Backdrop>
                )}
              </div>
            </Grid>
          )}

          <Grid row xs={12} className={classes.cardHolder}>
            <div className={`${classes.dates}`}>
              {intervals && intervals.length > 0 ? (
                intervals.map((_interval) =>
                  this.__renderInterval(classes, _interval, selectedSlot),
                )
              ) : (
                <Typography className={classes.errorText} variant="body2" component={'div'}>
                  <FormattedMarkdown
                    id={'booking.appointment.schedule.chart.unavailable'}
                    defaultMessage={'No time slots are available for selected date.'}
                  />
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
      </BaseWidget>
    );
  }

  __renderInterval(classes, interval, selectedSlot) {
    let date = dateUtil.parseDate(interval.start);

    const hour = date.getHours();

    if (interval.capacity === 0) {
      return <></>;
    }

    return (
      <TimeIntervalButton
        key={`btn-slot-${interval.start}`}
        onClick={this.__selectTime(interval.start)}
        isSelected={selectedSlot === interval.start}
      >
        {this.__formatTimeDisplay(hour)}
      </TimeIntervalButton>
    );
  }

  __formatTimeDisplay = (hour) => {
    const timeOfDay = hour < 12 ? 'am' : 'pm';
    let displayHour = hour % 12;
    if (displayHour === 0) displayHour = 12;

    return `${displayHour}:00 ${timeOfDay}`;
  };
}

TimeSelection.contextType = RescheduleBookingContext;

export default withStyles(styles, { withTheme: true })(TimeSelection);
