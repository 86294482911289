import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router-dom';
import PageContainer from '../../common/PageContainer';
import { Grid, styled } from '@mui/material';
import { BOOKING_LIST_ROUTE, routeUtil } from '../../../utils/route.name';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { ScrollableQuinnContainer } from '../../common/ScrollableContainer';
import DecodedComponent from '../../shared/DecodedComponent';
import { Bloc } from './bloc';
import { serviceUtil } from '../../../utils/service';
import { Loading } from '../../../shared-library';
import Manual from './Manual';
import Standard from './Standard';
import SelfPay from './SelfPay';
import Subscription from './Subscription';
import OccHealth from './OccHealth';
import IdentityDocument from '../IdentityDocument';

const styles = (theme) => ({
  grid: {
    flex: '1',
    paddingTop: '2em',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '1em',
    marginLeft: '1em',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
    },
  },
  textItem: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  checkinText: {
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'center',
    color: '#71675E',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingTop: '3em',
    },
  },
  estimationText: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    color: '#71675E',
    paddingTop: '2.4em',
    paddingBottom: '3em',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingBottom: '1.5em',
      paddingTop: 0,
    },
  },
  phoneNumberText: {
    color: '#71675E',
    fontWeight: '500',
    fontSize: 18,
    textAlign: 'center',
    paddingBottom: '2.3em',
    flexDirection: 'row',
    alignContent: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingBottom: '3em',
    },
  },
  phoneNumber: {
    color: theme.palette.primary.main,
  },
  noteText: {
    fontWeight: '550',
    fontSize: 14,
    textAlign: 'center',
    color: '#1A0C00',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      marginTop: '4em',
    },
  },
  waitingTime: {
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  queueNumber: {
    fontWeight: '700',
    color: theme.palette.primary.main,
    fontSize: '10em',
  },
  directionLink: {
    fontSize: '1.2em',
    color: '#fffff',
  },
  directionLinkText: {
    fontSize: '1.3em',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkBox: {
    fontWeight: 700,
    textDecoration: 'none',
    paddingBottom: '5em',
    paddingTop: '3em',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '4em',
    },
  },
  icon: {
    width: '1.8em',
    paddingRight: '0.3em',
  },
  homeLink: {
    fontSize: '2em',
    fontWeight: '1',
  },
  progress: {
    color: '#F29202',
  },
  cancelLinkText: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  getDirection: {
    flexDirection: 'row',
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  getDirectionIcon: {
    marginRight: 10,
    color: theme.palette.primary.main,
  },
  phoneNumberMargin: {
    margin: 0,
  },
});

const initState = {
  loading: false,
  checkinSuccess: false,
  checkinAvailable: true,
  queueNumber: '_',
  waitTime: 'unknown',
  provider: '',

  isCancelled: false,

  dialogOpen: false,

  preCheckinIdWasUpdated: false,
};

class BookingCheckin extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = initState;
    this.bloc = new Bloc(this._getCurrentAppointmentId(), props.history);
  }

  _getCurrentAppointmentId = () => {
    return this.props.match.params.appointmentId
      ? this.props.match.params.appointmentId
      : this.props.appointmentId;
  };

  render() {
    const { classes, history } = this.props;
    const { loading, appointment, preCheckinIdWasUpdated } = this.state;

    const { messageId, message, component } = this.__renderContent();

    return (
      <>
        {preCheckinIdWasUpdated ? (
          <PageContainer
            loading={loading}
            paperBackground={true}
            onBack={() => {
              if (appointment) {
                history.replace(
                  routeUtil.buildBookingStatusRouteWithAppointmentID(appointment?.id),
                );
              } else {
                history.replace(BOOKING_LIST_ROUTE);
              }
            }}
          >
            <ScrollableQuinnContainer messageId={messageId} message={message} applyFormat={true}>
              {component}
            </ScrollableQuinnContainer>
          </PageContainer>
        ) : (
          <>{component}</>
        )}
      </>
    );
  }

  handleNext = () => {
    this.setState((state) => ({
      preCheckinIdWasUpdated: true,
    }));
  };

  __renderContent = () => {
    const { initialised, appointment, queueNumber, preCheckinIdWasUpdated } = this.state;

    if (!initialised) {
      return {
        messageId: 'booking.checkin.process.loading.bubble',
        message: 'Before we check in lets determine the right process.',
        component: (
          <Grid item xs={12} sx={{ margin: '64px 0' }}>
            <Loading isFullscreen={false} />
          </Grid>
        ),
      };
    }

    if (!preCheckinIdWasUpdated) {
      return {
        component: (
          <>
            <IdentityDocument appointmentId={appointment.id} handleNextStep={this.handleNext} />
          </>
        ),
      };
    }

    const checkinProcess = serviceUtil.determinCheckinProcess(appointment);

    if (initialised && checkinProcess) {
      switch (checkinProcess) {
        case 'standard':
          return {
            messageId: 'booking.checkin.process.standard.bubble',
            message: `Lets get you checked in! ${this.__determineWording(queueNumber)}`,
            component: (
              <>
                <Standard bloc={this.bloc} />
              </>
            ),
          };
        case 'occhealth':
          return {
            messageId: 'booking.checkin.process.occhealth.bubble',
            message: `I have notified the staff of your arrival! ${this.__determineWording(
              queueNumber,
            )}`,
            component: (
              <>
                <OccHealth bloc={this.bloc} />
              </>
            ),
          };
        case 'selfpay':
          return {
            messageId: 'booking.checkin.process.selfpay.bubble',
            message: `Lets get you checked in! ${this.__determineWording(queueNumber)}`,
            component: (
              <>
                <SelfPay bloc={this.bloc} />
              </>
            ),
          };
        case 'subscription':
          return {
            messageId: 'booking.checkin.process.subscription.bubble',
            message: `Lets get you checked in! ${this.__determineWording(queueNumber)}`,
            component: (
              <>
                <Subscription bloc={this.bloc} />
              </>
            ),
          };
        default:
          return {
            messageId: 'booking.checkin.process.manual.bubble',
            message: `Lets get you checked in! ${this.__determineWording(queueNumber)}`,
            component: (
              <>
                <Manual bloc={this.bloc} />
              </>
            ),
          };
      }
    } else {
      return {
        messageId: 'booking.checkin.process.loading.bubble',
        message: 'Before we check in lets determine the right process.',
        component: (
          <Grid item xs={12} sx={{ margin: '64px 0' }}>
            <Loading isFullscreen={false} />
          </Grid>
        ),
      };
    }
  };

  __determineWording = (queueNumber) => {
    if (queueNumber === 1) {
      return `There is currently ${queueNumber} patient waiting in line at the clinic.`;
    }
    return `There are currently ${queueNumber} patients waiting in line at the clinic.`;
  };
}

export default withStyles(styles)(withRouter(BookingCheckin));
