import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';

import { authService } from '../../utils/auth';

import Loading from '../shared/Loading';
import { linksApi } from '../../utils/services/links.api';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';
import Checkin from '../Secure/BookingStatus';
import PageContainer from '../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../common/ScrollableContainer';
import { Box, Grid } from '@mui/material';
import { H2TextTitleContent } from '../shared/Typography';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import DecodedButton from '../common/DecodedButton';
import { providerStorage } from '../../utils/provider.qs';
import { logger } from '../../utils/logging';
import { appointmentApi } from '../../utils/services/appointments.api';
import { uriStorage } from '../../utils/storage';

const styles = (theme) => ({
  root: {
    overflow: 'auto',
    overscrollBehavior: 'contain',
    position: 'absolute',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    color: '#fff',
  },
});

class CheckinLink extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    this.state = {
      loading: true,
      message: 'Processing link.',
      appointmentId: params.appointmentId,
    };

    this._initialise(params);
  }

  _initialise = (params) => {
    analyticsEventLogger.log(AnalyticsEvent.LINK_CHECKIN_OPEN, {
      appointmentId: params.appointmentId,
    });

    linksApi
      .authenticateLink('checkin.link.authentication', params)
      .then((value) => this._authenticateUser(params, value))
      .catch((reason) => {
        analyticsEventLogger.log(AnalyticsEvent.LINK_CHECKIN_ERROR, {
          appointmentId: params.appointmentId,
          reason: `${reason}`,
        });

        this.setState({
          loading: false,
          error: reason,
        });
      });
  };

  _authenticateUser = (params, value) => {
    const appointmentId = params.appointmentId;

    analyticsEventLogger.log(AnalyticsEvent.LINK_CHECKIN_VALID, {
      appointmentId: params.appointmentId,
    });
    this.setState({
      message: 'Almost done.',
    });

    authService
      .loginWithToken(value.data.token)
      .then((user) => {
        analyticsEventLogger.setUser(user.uid);

        user
          .getIdToken()
          .then((token) => {
            analyticsEventLogger.log(AnalyticsEvent.LINK_CHECKIN_AUTHENTICATED, {
              appointmentId: appointmentId,
            });

            this.setState({
              message: 'Checking appointment.',
              loading: false,
            });

            appointmentApi.getAppointmentStatus(appointmentId).then((result) => {
              analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);
              if (result.data.status === 'COMPLETE') {
                authService.logout().then(() => {
                  uriStorage.clearPath();
                  providerStorage.clearProvider();
                });

                this.setState({
                  message: 'Link invalid.',
                  error: 'Appointment link expired.',
                  loading: false,
                });
              } else {
                this.setState({
                  message: 'Redirecting...',
                  loading: false,
                });
              }
            });
          })
          .catch((reason) => this._authenticationError(appointmentId, reason));
      })
      .catch((reason) => this._authenticationError(appointmentId, reason));
  };

  _authenticationError = (reason) => {
    analyticsEventLogger.log(AnalyticsEvent.LINK_CHECKIN_ERROR, {
      reason: `${reason}`,
    });

    this.setState({
      loading: false,
      error: reason,
    });
  };

  render() {
    let { classes } = this.props;

    let { loading, message, error } = this.state;

    if (!loading && error) {
      return this.__errorRender();
    }

    return (
      <div className={classes.root}>
        {loading ? (
          <Loading message={message} />
        ) : (
          <>
            <Checkin appointmentId={this.props.match.params.appointmentId} />
          </>
        )}
      </div>
    );
  }

  __errorRender = () => {
    return (
      <PageContainer loading={false}>
        <ScrollableQuinnContainer
          messageId={`link.checkin.error.bubble`}
          message="There is a problem with the link."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <H2TextTitleContent>
                  <FormattedMarkdown
                    id={`link.checkin.error.text`}
                    defaultMessage={`The link has either expired or is invalid. Please click restart to start new session.`}
                  />
                </H2TextTitleContent>
              </Box>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid xs={12} item>
            <DecodedButton
              onClick={async () => {
                try {
                  await authService.logout();
                  providerStorage.clearProvider();
                } catch (e) {
                  logger.debug('Error clearing storage from checkin link.');
                }
                this.props.history.push('/');
              }}
            >
              Restart
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  };
}

export default withStyles(styles)(CheckinLink);
