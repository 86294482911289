import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Button, CircularProgress, Grid } from '@mui/material';

const styles = (theme) => ({
  root: {},
  fill: {
    flex: '1 1 auto',
  },
  buttonHolder: {
    marginTop: '18px',
  },
  submitButton: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },

  progressHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'hidden',
    },
  },
  progress: {
    color: '#F29202',
  },
});

class BaseWidget extends Component {
  render() {
    const {
      classes,
      initialising,
      processing,
      submitEnabled,
      submit,
      submitLabel,
      containerProps,
    } = this.props;

    if (initialising) {
      return (
        <Grid container>
          <Grid row xs={12} className={classes.progressHolder}>
            <div className={classes.fill} />
            <CircularProgress classes={{ circle: classes.progress }} />
            <div className={classes.fill} />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid {...containerProps} container className={classes.root}>
        {this.props.children}
        <Grid xs={12} className={classes.buttonHolder}>
          <Button
            disabled={!submitEnabled}
            onClick={submit}
            className={classes.submitButton}
            variant={'contained'}
            color={'primary'}
          >
            {processing ? <CircularProgress size={25} thickness={2.6} /> : <>{submitLabel}</>}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(BaseWidget);
