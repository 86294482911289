import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { dateUtil } from '../../../../../utils/date';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../../utils/events';
import BaseWidget from './BaseWidget';
import { RescheduleBookingContext } from '../../bloc';

const styles = (theme) => ({
  root: {},
  fill: {
    flex: '1 1 auto',
  },
  cardHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'auto',
    },
  },
  buttonHolder: {
    marginTop: '18px',
  },
  button: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  progressHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'hidden',
    },
  },
  progress: {
    color: '#F29202',
  },
});

class DateSelection extends Component {
  timer;
  state = {};

  constructor(props) {
    super(props);

    const now = new Date();

    this.state = {
      initialising: true,
      max: new Date(new Date().setDate(now.getDate() + 54)),
      selectedDate: null,
      processing: false,
    };

    this.stateSubscription = this.props.bloc.subscribeToState(this.__handleStateUpdate);
  }

  componentDidMount() {
    this.props.bloc.loadSelectedDoctorSchedule();
  }

  componentWillUnmount() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentDidUpdate() {
    if (this.state.selectedDate === null) {
      this.setState((prevState) => ({
        ...prevState,
        selectedDate:
          this.context && this.context.start ? new Date(this.context.start) : new Date(),
      }));
    }
  }

  __handleStateUpdate = (state) => {
    const selectedDate = state.booking.selectedDate || this.state.selectedDate;

    this.setState({
      ...state,
      selectedDate: selectedDate,
    });
  };

  __onDateSelected = (date) => {
    this.setState({
      selectedDate: date,
    });
  };

  __submit = () => {
    if (this.state.selectedDate && !this.state.processing) {
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_SELECT, {});

      this.props.bloc.setSelectedDate(this.state.selectedDate);
      this.setState({
        processing: true,
      });
      this.timer = setTimeout(() => {
        this.setState({ processing: false });
      }, 5000);
    }
  };

  render() {
    const { classes } = this.props;

    const { initialising, max, calendarSummary, selectedDate } = this.state;

    return (
      <BaseWidget
        initialising={initialising}
        processing={false}
        submitEnabled={selectedDate}
        submit={this.__submit}
        submitLabel={'SELECT'}
      >
        <Grid row xs={12} className={classes.cardHolder}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={`${classes.root}`}>
              <CalendarPicker
                openTo="day"
                disablePast={true}
                views={['day']}
                value={selectedDate}
                onChange={this.__onDateSelected}
                shouldDisableDate={(day) => {
                  const date = dateUtil.formatIsoDateAdjust(day);

                  if (day > max) return false;

                  let capacity = 0;
                  if (calendarSummary && calendarSummary.slots) {
                    const summaryInformation = calendarSummary.slots.filter(
                      (_item) => _item.date === date,
                    );

                    if (summaryInformation.length > 0) {
                      capacity = summaryInformation[0].capacity;
                    }
                  }

                  return capacity === 0;
                }}
              />
            </div>
          </LocalizationProvider>
        </Grid>
      </BaseWidget>
    );
  }
}

DateSelection.contextType = RescheduleBookingContext;

export default withStyles(styles)(DateSelection);
