import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { CircularProgress, FormControl, IconButton, Input, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DecodedComponent from '../../../shared/DecodedComponent';

const styles = (theme) => ({
  root: {},
});

class ChatTextInput extends DecodedComponent {
  constructor(props) {
    super(props);

    const { bloc } = this.props;

    this.bloc = bloc;

    this.state = {
      initialised: false,
      localChatValue: '',
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  __handleChange = (e) => {
    this.setState({
      localChatValue: e.target.value,
    });
  };

  __handleSubmit = (e) => {
    const { localChatValue, chatInputEnabled } = this.state;
    if (chatInputEnabled && localChatValue?.length > 0) {
      this.bloc.sendMessage(localChatValue);
      this.setState({
        localChatValue: '',
      });
    }

    e.preventDefault();
  };

  render() {
    const { initialised, chatAvailable, chatInputEnabled, localChatValue } = this.state;

    return (
      <>
        {initialised && chatAvailable && chatInputEnabled && (
          <>
            <form
              autoComplete={'off'}
              onSubmit={this.__handleSubmit}
              style={{ width: '100%', border: 0 }}
            >
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <Input
                  sx={{
                    fontSize: '14pt !important',
                    borderRadius: '9px',
                    padding: '10px 1px 10px 18px !important',
                    backgroundColor: '#000',
                    color: '#fff',
                  }}
                  id={'chat-text-input'}
                  autoComplete={'off'}
                  placeholder={'Type a message...'}
                  type={'text'}
                  required={true}
                  onChange={this.__handleChange}
                  value={localChatValue}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton size={'large'} aria-label="send" onClick={this.__handleSubmit}>
                        <SendIcon fontSize={'large'} scale={4} sx={{ color: '#fff' }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </form>
          </>
        )}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChatTextInput);
