import { AnalyticsEvent, analyticsEventLogger } from './events';

class ProviderStorage {
  constructor() {
    this.storageKey = 'currentProvider';
    this.terminalKey = 'currentTerminal';
    this.terminalLocationKey = 'currentTerminalLocation';
    this.queryString = 'provider';
    this.cachedProvider = '';
  }

  setPersistent = (providerId, terminalId, terminalLocation) => {
    if (providerId?.length > 0) {
      localStorage.setItem(this.storageKey, providerId);
    } else {
      localStorage.setItem(this.storageKey, '');
    }
    if (terminalId?.length > 0) {
      localStorage.setItem(this.terminalKey, terminalId);
    } else {
      localStorage.setItem(this.terminalKey, '');
    }
    if (terminalLocation?.length > 0) {
      localStorage.setItem(this.terminalLocationKey, terminalLocation);
    } else {
      localStorage.setItem(this.terminalLocationKey, '');
    }
  };

  getCurrentProvider = () => {
    if (this.isKiosk()) {
      return this.getPersistedProvider();
    }
    return sessionStorage.getItem(this.storageKey) || this.cachedProvider || '';
  };
  getPersistedProvider = () => {
    return localStorage.getItem(this.storageKey) || '';
  };
  getPersistedTerminal = () => {
    return localStorage.getItem(this.terminalKey) || '';
  };
  getPersistedTerminalLocation = () => {
    return localStorage.getItem(this.terminalLocationKey) || '';
  };
  getCurrentTerminal = () => {
    return localStorage.getItem(this.terminalKey) || '';
  };
  hasTerminal = () => {
    return this.getPersistedTerminal()?.length > 0;
  };
  isKiosk = () => {
    const provider = this.getPersistedProvider();
    return provider?.length > 0;
  };
  isWalkin = () => {
    const provider = this.getCurrentProvider();
    return provider?.length > 0;
  };

  setPreferredLocation = (providerId) => {
    sessionStorage.setItem('preferredLocation', providerId);
    analyticsEventLogger.log(AnalyticsEvent.SESSION_SET, {
      place: 'provider',
      key: 'preferredLocation',
      providerId: providerId,
    });
  };

  preferredLocation = () => {
    return sessionStorage.getItem('preferredLocation');
  };

  setProviderFromLink = (providerId) => {
    this.cachedProvider = providerId;
    sessionStorage.setItem(this.storageKey, providerId);
    analyticsEventLogger.log(AnalyticsEvent.SESSION_SET, {
      place: 'provider',
      key: this.storageKey,
      providerId: providerId,
    });
  };

  clearProvider = () => {
    analyticsEventLogger.log(AnalyticsEvent.SESSION_CLEAR, { place: 'provider' });
    const provider = this.getCurrentProvider();
    sessionStorage.clear();
    if (provider && provider !== '') {
      this.setProviderFromLink(provider);
    }
  };

  clearPersistent = () => {
    localStorage.clear();
  };
}

export const providerStorage = new ProviderStorage();
