import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Button, styled } from '@mui/material';

const StyledAccessTimeIcon = styled(AccessTimeIcon)((props) => ({
  marginRight: '12px',
  color: props.isSelected ? '#ffffff' : undefined,
}));

const StyledTimeIntervalButton = styled(Button)(({ theme, isSelected }) => ({
  color: isSelected ? undefined : 'rgba(0, 0, 0, 0.87)',
  backgroundColor: isSelected ? undefined : '#F6F6F6',
  paddingLeft: '0',
  paddingRight: '0',
  marginRight: '24px',
  marginBottom: '24px',
  width: '145px',
  maxWidth: '145px',
  borderRadius: '8px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '135px',
    width: '135px',
    maxWidth: '135px',
  },
  '&:hover': {
    color: isSelected ? undefined : 'rgba(0, 0, 0, 0.87)',
    backgroundColor: isSelected ? undefined : '#d5d5d5',
  },
}));

export const TimeIntervalButton = ({ isSelected, children, ...props }) => {
  return (
    <StyledTimeIntervalButton
      {...props}
      variant="contained"
      color={isSelected ? 'primary' : undefined}
      isSelected={isSelected}
    >
      <StyledAccessTimeIcon color="accent" isSelected={isSelected} /> {children}
    </StyledTimeIntervalButton>
  );
};


const StyledPeriodIntervalButton = styled(Button)(({ theme, isSelected }) => ({
  color: isSelected ? undefined : 'rgba(0, 0, 0, 0.87)',
  backgroundColor: isSelected ? undefined : '#F6F6F6',
  paddingLeft: '0',
  paddingRight: '0',
  marginRight: '24px',
  marginBottom: '24px',
  width: '220px',
  maxWidth: '220px',
  borderRadius: '8px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '220px',
    width: '220px',
    maxWidth: '220px',
  },
  '&:hover': {
    color: isSelected ? undefined : 'rgba(0, 0, 0, 0.87)',
    backgroundColor: isSelected ? undefined : '#d5d5d5',
  },
}));

export const PeriodIntervalButton = ({ isSelected, children, ...props }) => {
  return (
    <StyledPeriodIntervalButton
      {...props}
      variant="contained"
      color={isSelected ? 'primary' : undefined}
      isSelected={isSelected}
    >
      <StyledAccessTimeIcon color="accent" isSelected={isSelected} /> {children}
    </StyledPeriodIntervalButton>
  );
};
