import { Grid } from '@mui/material';
import * as React from 'react';
import { useBookingWizardContext } from '../BookingWizard/booking-wizard-context';
import { TimeIntervalButton } from './TimeIntervalButton';

const ReminderSelector = () => {
  const { bookingBloc, booking } = useBookingWizardContext();

  const reminderOptions = React.useMemo(
    () => [
      {
        value: 15,
        label: '15 minutes',
      },
      {
        value: 60,
        label: '1 hour',
      },
      {
        value: 120,
        label: '2 hours',
      },
      {
        value: 240,
        label: '4 hours',
      },
    ],
    [],
  );

  const selectedReminder = booking.reminderTime;

  const handleReminderSelected = React.useCallback(
    (slot) => () => {
      bookingBloc.setReminderTime(slot);
    },
    [bookingBloc],
  );

  return (
    <Grid row xs={12}>
      <div>
        {reminderOptions.map((option) => (
          <TimeIntervalButton
            key={`btn-reminder-${option.value}`}
            onClick={handleReminderSelected(option.value)}
            isSelected={option.value === selectedReminder}
          >
            {option.label}
          </TimeIntervalButton>
        ))}
      </div>
    </Grid>
  );
};

export default ReminderSelector;
