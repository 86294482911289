import {FormattedMarkdown} from '@decodedhealth/react-library';
import * as React from 'react';
import {useWizardContext} from '../../../shared-library';
import PageContainer from '../../common/PageContainer';
import {DefaultDisconnectedPageFooter, ScrollableQuinnContainer} from '../../common/ScrollableContainer';
import DecodedButton from "../../common/DecodedButton";
import {H2TextTitleContent} from '../../shared/Typography';
import {providerStorage} from "../../../utils/provider.qs";

export const EmployerExist = ({ bloc }) => {
  const { goBack } = useWizardContext();

  function walkinWording() {

      if(bloc.isWorkers()) {
          return (<FormattedMarkdown
              id="booking.paymentmethod.employer.workercomp.exists.walkin.body"
              defaultMessage={`Please see the front desk to complete your check-in process for your workers comp visit. Please make sure to present your authorization form to the staff.`}
          />)
      } else {
          return (<FormattedMarkdown
              id="booking.paymentmethod.employer.exists.walkin.body"
              defaultMessage={`Please see the front desk to complete your check-in process for your Occupational Health visit. Please make sure to present your authorization form to the staff.`}
          />)
      }
  }
  function remoteWording() {

      if(bloc.isWorkers()) {
          return (<FormattedMarkdown
                id="booking.paymentmethod.employer.workercomp.exists.remote.body"
                defaultMessage="Please make sure to bring your authorization form."
            />);
      } else {
          return (<FormattedMarkdown
              id="booking.paymentmethod.employer.exists.remote.body"
              defaultMessage="Please make sure to bring your authorization form with you or ask your employer send it to the clinic prior to your visit."
          />);
      }
  }

  return (
    <PageContainer>
        <ScrollableQuinnContainer
            messageId="employerInformation.employerExist"
            message="Thank you for the information so far, to complete the booking of your visit, read the statement below then click accept.">
            <H2TextTitleContent>
                {
                    providerStorage.isWalkin() ? walkinWording() : remoteWording()
                }
            </H2TextTitleContent>
        </ScrollableQuinnContainer>

      <DefaultDisconnectedPageFooter justifyContent={"left"}>
        <DecodedButton
            onClick={() => {
              bloc.save();
            }}
          >ACCEPT</DecodedButton>
      </DefaultDisconnectedPageFooter>
    </PageContainer>
  );
};
