import { appointmentApi } from '../../../utils/services/appointments.api';
import { notificationService } from '../../../utils/notification';
import { DecodedBloc } from '../../shared/DecodedComponent/bloc';
import { uriStorage } from '../../../utils/storage';
import { authService } from '../../../utils/auth';
import { providerStorage } from '../../../utils/provider.qs';
import { dateUtil } from '../../../utils/date';
import { ErrorMessage } from '../../../utils/error.resolver';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { differenceInMinutes, isSameDay } from 'date-fns';
import { providerUtil } from '../../../utils/provider';
import { serviceUtil } from '../../../utils/service';
import { routeUtil } from '../../../utils/route.name';

export class Bloc extends DecodedBloc {
  constructor(appointmentId, history) {
    super({
      appointmentId: appointmentId,
    });

    this.history = history;

    this.__initialise(appointmentId);
  }

  __markAsArrived = (appointment) => {
    const request = {
      command: 'update_state',
      state: 'ARRIVED',
    };

    appointmentApi.command(appointment.id, request).then(
      (value) => {
        analyticsEventLogger.log(AnalyticsEvent.ARRIVED_SUCCESS);
      },
      (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.ARRIVED_ERROR, { reason: reason });
      },
    );
  };

  __initialise = (appointmentId) => {
    this.__updateSubject({
      loading: true,
      inClinic: providerStorage.isWalkin(),
    });

    appointmentApi
      .getAppointmentStatus(appointmentId)
      .then((result) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);

        if (result.data.status === 'COMPLETE') {
          this.__updateSubject({
            loading: false,
          });

          authService.logout().then(() => {
            uriStorage.clearPath();
            providerStorage.clearProvider();
          });
          return;
        }

        if (result.data.status === 'RESERVED') {
          this.__markAsArrived(result.data);
        }

        const startTime = dateUtil.parseDate(result.data.start);
        let newState = {};

        if (result.data.status === 'WAITING') newState.checkinSuccess = true;
        else newState.checkinAvailable = isSameDay(new Date(), startTime);

        newState.queueNumber = result.data.waitingRoomSummary.numberInQueue;
        newState.waitTime = differenceInMinutes(startTime, new Date());
        newState.startTime = startTime;
        newState.timezone = result.data.timezone;
        newState.loading = false;
        newState.appointment = result.data;

        this.__updateSubject({
          ...newState,
        });

        this._getProviderLocation(result.data.provider);
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_ERROR, {
          appointmentId: appointmentId,
          reason: error,
        });

        this.__updateSubject({
          checkinSuccess: false,
          waitTime: 'unknown',
          queueNumber: '_',
          checkinAvailable: false,
          loading: false,
        });

        notificationService.error('Error checking in for your visit. ' + ErrorMessage.CALL_SUPPORT);
      });
  };

  _getProviderLocation = (providerId) => {
    this.__updateSubject({
      loading: true,
    });
    appointmentApi
      .getAvailableProviders()
      .then((response) => {
        const providers = response.data.items.filter((place) => place.id === providerId);

        let providerDetails = providers.map((place) => ({
          lat: place.contactInformation.address.geoLocation.latitude,
          lng: place.contactInformation.address.geoLocation.longitude,
        }));

        if (providerDetails.length === 1) {
          this.__updateSubject({
            provider: providers[0],
            providerDetails: providerDetails[0],
            providerContactNumber: providerUtil.formatOrganisationContactNumber(providers[0]),
          });
        }
      })
      .catch((error) => {
        notificationService.error(
          'Error finding the clinic location. ' + ErrorMessage.CALL_SUPPORT,
        );
      })
      .finally(() => {
        this.__updateSubject({
          loading: false,
          initialised: true,
        });
      });
  };

  checkin = () => {
    this.__updateSubject({ loading: false });

    const { appointment } = this.subject.value;

    const checkinProcess = serviceUtil.determinCheckinProcess(appointment);

    if (!checkinProcess) {
      this.history.replace(routeUtil.buildAppointmentCheckinException(appointment.id));
      return;
    }

    if (appointment.status === 'WAITING') {
      this.history.replace(routeUtil.buildAppointmentCheckinConfirmation(appointment.id));
      return;
    }

    if (appointment.status !== 'RESERVED') {
      this.history.replace(routeUtil.buildAppointmentCheckinException(appointment.id));
      return;
    }

    switch (checkinProcess) {
      default:
        const request = { command: 'checkin' };
        appointmentApi
          .command(appointment.id, request)
          .then(
            (response) => {
              this.history.replace(routeUtil.buildAppointmentCheckinConfirmation(appointment.id));
            },
            (reason) => {
              notificationService.httpError(reason);
            },
          )
          .finally(() => {
            this.__updateSubject({ loading: false });
          });
    }
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
}
