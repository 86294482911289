import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import VirtualClinicContext from '../context';
import { appointmentApi } from '../../../../utils/services/appointments.api';
import TelehealthPanel from '../../../Telehealth';
import Loading from '../../../shared/Loading';
import { appointmentEventService } from '../WaitingRoom/service/appointment.event.service';
import { uriStorage } from '../../../../utils/storage';
import { authService } from '../../../../utils/auth';
import { clinicBloc } from '../clinic.bloc';
import { CloudEvent } from 'cloudevents';
import { eventApi } from '../../../../utils/services/event.api';
import { analyticsEventLogger } from '../../../../utils/events';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    height: '100vh',
    overflow: 'auto',
  },
  wrapper: {
    height: '100%',
    width: '100%',
  },
  fill: {
    flex: '1 1 auto',
  },
});

class Encounter extends Component {
  subscription;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      telehealth: { detail: undefined, state: '', audio: true, video: true },
    };

    this._appointmentStatusCallback = this._appointmentStatusCallback.bind(this);
    this.statusPoller = null;
  }

  componentDidMount() {
    const {
      match: { params, url },
    } = this.props;

    this.startPoller();

    if (
      !this.props.location.state ||
      !this.props.location.state.fromWaitingRoom ||
      !authService.getUser()
    ) {
      let waitingRoomPath =
        url.indexOf('/link/encounter/') > -1
          ? `/link/waiting-room/${params.encounterId}`
          : `/virtual-clinic/waiting-room/${params.encounterId}`;

      this.props.history.replace(waitingRoomPath);
    } else {
      let { telehealth } = this.state;

      setTimeout(() => {
        appointmentApi.getAppointmentTelehealthDetails(params.encounterId).then((value) => {
          telehealth.detail = value.data;

          this.setState({
            loading: false,
            telehealth: telehealth,
          });
        });
      }, 500);

      appointmentEventService.registerAppointmentStatusCallback(this._appointmentStatusCallback);
      appointmentEventService.startPollingForAppointmentStatus(params.encounterId, 2000);

      clinicBloc.setEncounterId(params.encounterId);

      uriStorage.setCurrentPath(url);
    }
  }

  componentWillUnmount() {
    appointmentEventService.stopPollingForAppointmentStatus();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.clearPoller();
  }

  clearPoller = () => {
    if (this.statusPoller) {
      clearInterval(this.statusPoller);
      this.statusPoller = null;
    }
  };

  startPoller = () => {
    this.statusPoller = setInterval(() => {
      this.eventPublisher('online');
    }, 5000);
  };

  eventPublisher = (event) => {
    const {
      match: { params, url },
    } = this.props;

    const data = {
      status: event,
      appointmentId: params.encounterId,
    };

    let ce = new CloudEvent({
      type: 'vfd.telehealth.patient.status',
      datacontenttype: 'application/json',
      source: '/consumer-webapp',
      dataschema: 'https://schema.decodedhealth.com/events/telehealth.json',
      data,
    });

    ce = ce.cloneWith({ xdhvendor: 'dh' });
    ce = ce.cloneWith({ xdhsystem: 'vonage' });
    eventApi.publish(ce.toJSON());
    analyticsEventLogger.log('vfd.telehealth.patient.status', data);
  };

  _appointmentStatusCallback = (status) => {
    if (status.status === 'COMPLETE') {
      this.props.history.replace('/virtual-clinic/post-encounter');
    }
  };

  render() {
    let { classes } = this.props;
    let { loading, telehealth } = this.state;

    const appointment = telehealth.detail;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          {loading ? (
            <Loading />
          ) : (
            <TelehealthPanel
              id={appointment.session}
              platformId={appointment.id}
              sessionId={appointment.session}
              token={appointment.password}
              clearPoller={this.clearPoller}
              startPoller={this.startPoller}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)((props) => (
  <VirtualClinicContext.Consumer>
    {(value) => {
      return <Encounter context={value} {...props} />;
    }}
  </VirtualClinicContext.Consumer>
));
