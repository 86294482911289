import axios from 'axios';
import { VERSION } from '../version';
import { tenantUtil } from '../tenant';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../user';
import { AnalyticsEvent, analyticsEventLogger } from '../events';
import retry, { retryOn502 } from '../retry';

class AppointmentApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';

    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  getAppointmentsQueueSummary = () => {
    return this.instance.get(`${this.baseUrl}/api/appointments/_queue_summary`);
  };

  getAppointmentStatus = (appointmentId) => {
    return retry(() =>
      axios.get(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/status`),
    ).then(
      (value) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);
        return value;
      },
      (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_ERROR, { error: reason });
        return reason;
      },
    );
  };

  getAppointmentTelehealthDetails = (appointmentId) => {
    return axios.get(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/telehealth`);
  };

  getPrecallTelehealthDetails = () => {
    return axios.get(`${this.baseUrl}/api/consumers/me/appointments/_precall`);
  };

  getAvailableProviders = () => {
    return axios.post(`${this.baseUrl}/api/consumers/me/appointments/providers/_search`, {});
  };

  getAvailableOrgs = (service) => {
    return retry(() =>
      axios.post(`${this.baseUrl}/api/organisations/_search`, {
        serviceCodes: [service],
        roles: ['O_SRV_PROV'],
      }),
    );
  };

  getServiceProviderSummary = (providerId) => {
    return retry(() =>
      axios.get(`${this.baseUrl}/api/consumers/me/appointments/providers/${providerId}/_summary`),
    );
  };

  getServiceProviderSummaryForService = (providerId, serviceCode) => {
    return retry(() =>
      axios.get(`${this.baseUrl}/api/consumers/me/appointments/providers/${providerId}/_summary`, {
        params: { service: serviceCode },
      }),
    );
  };

  getAvailableAppointmentSchedule = (selectedDate, selectedProvider, serviceCode, doctor) => {
    function subtractDays(numOfDays, date = new Date()) {
      date.setDate(date.getDate() - numOfDays);
      return date;
    }
    function addDays(numOfDays, date = new Date()) {
      date.setDate(date.getDate() + numOfDays);
      return date;
    }

    let request = {
      period: {
        start: subtractDays(1, new Date(selectedDate)),
        end: addDays(1, new Date(selectedDate)),
      },
      // date: selectedDate,
      serviceProvider: {
        value: selectedProvider,
      },
      services: [
        {
          code: {
            value: serviceCode,
          },
        },
      ],
    };

    if (doctor) {
      request.staff = [
        { role: 'PROVIDER', identifier: { system: 'decoded', code: 'id', value: doctor } },
      ];
    }

    return retry(() =>
      axios.post(`${this.baseUrl}/api/consumers/me/appointments/schedules/_search`, request),
    );
  };

  updateAppointment = (appointmentId, request) => {
    return axios.patch(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}`, request);
  };

  command = (appointmentId, request) => {
    return retryOn502(() =>
      axios.post(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/_command`, {
        payload: request,
      }),
    );
  };

  paymentCommand = (appointmentId, request) => {
    return retryOn502(() =>
      axios.post(
        `${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/payments/_command`,
        request,
      ),
    );
  };

  confirmAppointment = (appointmentId, data) => {
    return retryOn502(() =>
      axios.patch(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/_confirm`, data),
    );
  };

  checkInAppointment = (appointmentId) => {
    return axios.patch(
      `${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/_checkin`,
      {},
    );
  };

  getCurrentAppointment = (timeWindow) => {
    return axios.post(`${this.baseUrl}/api/consumers/me/appointments`, {
      timeWindow: timeWindow,
      status: ['WAITING', 'RESERVED'],
    });
  };

  getAllFutureAppointments = () => {
    let now = new Date();
    now.setDate(now.getDate() - 1);

    return axios.post(`${this.baseUrl}/api/consumers/me/appointments`, {
      period: {
        start: format(now, DATE_FORMAT),
      },
      status: ['WAITING', 'RESERVED', 'REQUESTED', 'ARRIVED', 'CHECKING_IN'],
    });
  };

  getAppointmentForDate = (date) => {
    return axios.post(`${this.baseUrl}/api/consumers/me/appointments`, {
      date: date,
      status: ['WAITING', 'RESERVED'],
    });
  };

  getNextAvailableAppointmentService = (organisationId, channel) => {
    return axios.get(
      `${this.baseUrl}/api/consumers/me/appointments/services/${
        organisationId ? organisationId : '_'
      }/_search?channel=${channel}`,
    );
  };

  getScheduleSummary = (date, serviceCode, serviceProvider) => {
    return axios.post(`${this.baseUrl}/api/consumers/me/appointments/schedules/_summary`, {
      serviceProvider: {
        value: serviceProvider ? serviceProvider : '',
      },
      date: date,
      services: [
        {
          code: {
            value: serviceCode,
          },
        },
      ],
    });
  };

  getServiceScheduleSummary = (start, end, serviceCode, serviceProvider, doctor) => {
    let request = {
      serviceProvider: {
        value: serviceProvider ? serviceProvider : '',
      },
      period: {
        start: start,
        end: end,
      },
      services: [
        {
          code: {
            value: serviceCode,
          },
        },
      ],
    };

    if (doctor) {
      request.staff = [
        { role: 'PROVIDER', identifier: { system: 'decoded', code: 'id', value: doctor } },
      ];
    }

    return axios.post(`${this.baseUrl}/api/consumers/me/appointments/schedules/_summary`, request);
  };

  getServiceStaff = (serviceProvider) => {
    return axios.get(
      `${this.baseUrl}/api/consumers/me/appointments/providers/${serviceProvider}/people`,
    );
  };

  getServiceStaffWithPermission = (serviceProvider, permission) => {
    return axios.get(
      `${this.baseUrl}/api/consumers/me/appointments/providers/${serviceProvider}/people?permissions=${permission}`,
    );
  };

  getChatHistory = (appointmentId) => {
    return axios.get(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/chat/history`);
  };

  cancelAppointment = (appointmentId, request) => {
    return axios.post(
      `${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/_cancel`,
      request,
    );
  };

  getCancelReasons = () => {
    return retry(() => axios.get(`${this.baseUrl}/api/consumers/me/appointments/cancel_reasons`));
  };

  getDraftAppointment = (appointmentId) => {
    return axios.post(`${this.baseUrl}/api/consumers/me/appointments/_draft`, {
      appointmentId,
    });
  };
}

export const appointmentApi = new AppointmentApi();
