import patientPlusLogo from '../assets/patient_plus.svg';
import patientPlusMobileLogo from '../assets/mobile_pp.svg';

export const getLogoBasedOnTenant = () => {
  return patientPlusLogo;
};

export const getMobileLogoBasedOnTenant = () => {
  return patientPlusMobileLogo;
};

export const getFullMobileLogoBasedOnTenant = () => {
  return patientPlusLogo;
};
