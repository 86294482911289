import * as rxjs from 'rxjs';
import { notificationService } from '../../../utils/notification';
import { userInfoUtil } from '../../../utils/user';
import { phoneUtil } from '../../../utils/phone';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { extensionApi } from '../../../utils/services/extension.api';

const initial = {
  initialised: false,
  insuranceCardData: {},
};

export class Bloc {
  callback;

  appointmentApi;
  extensionApi;
  codesetApi;
  insuranceApi;
  paymentApi;
  isKiosk;
  logoObject;
  AnalyticsEvent;
  analyticsEventLogger;
  notificationService;

  constructor(props, callback, switchToSelfPay, onBack) {
    this.codesetApi = props.codesetApi;
    this.appointmentApi = props.appointmentApi;
    this.extensionApi = props.extensionApi;
    this.insuranceApi = props.insuranceApi;
    this.paymentApi = props.paymentApi;
    this.isKiosk = props.isKiosk;
    this.logoObject = props.logoObject;
    this.AnalyticsEvent = props.AnalyticsEvent;
    this.analyticsEventLogger = props.analyticsEventLogger;
    this.notificationService = props.notificationService;

    this.subject = new rxjs.BehaviorSubject({
      ...initial,
      ...props,
    });

    this.events = new rxjs.Subject();

    this.callback = callback;
    this.switchToSelfPay = switchToSelfPay;
    this.onBack = onBack;
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  __publishEvent = (type, data) =>
    this.events.next({
      type: type,
      data: data,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    Promise.all([
      this.codesetApi.getPolicyHolderRelationshipCodesets(),
      this.insuranceApi.searchPlan({ strategy: 'all_plans' }),
      this.paymentApi.getPaymentMethods(),
    ])
      .then(
        (result) => {
          const insuranceResult = result[1];

          /* insurance */
          let availablePlansSearchIndex = [];
          insuranceResult.data.items.forEach((_insurance) => {
            for (let indexItem of availablePlansSearchIndex) {
              if (indexItem['plan_name'].toLowerCase() === _insurance['plan_name'].toLowerCase()) {
                return;
              }
            }

            availablePlansSearchIndex.push({ plan_name: _insurance['plan_name'] });
          });

          const availablePlans = insuranceResult.data.items;

          availablePlansSearchIndex.sort((a, b) => {
            return a['plan_name'].localeCompare(b['plan_name']);
          });

          /* Payment methods */
          const paymentMethodResult = result[2];

          let insurances = [];
          let deletedInsurance = [];

          if (paymentMethodResult.data.page.size > 0) {
            insurances = paymentMethodResult.data.items
              .filter((item) => item.type.code === '1')
              .sort((a, b) => {
                if (a.primary === 1 && b.primary === 0) {
                  return -1;
                } else if (a.primary === 0 && b.primary === 1) {
                  return 1;
                }

                if (a.order > b.order) {
                  return 1;
                }

                if (a.order < b.order) {
                  return -1;
                }

                return 0;
              });
          }

          this.__updateSubject({
            availablePlans: availablePlans,
            availablePlansSearchIndex: availablePlansSearchIndex,
            relationshipCodes: result[0].data.items,
            original: [...insurances],
            edit: insurances.length === 0,
            insurances: insurances,
            deletedInsurance: deletedInsurance,
          });

          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_PAYMENT_METHOD_LOADED, {});
        },
        (reason) => {
          this.__updateSubject({ error: true });
          notificationService.httpError(reason);
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_PAYMENT_METHOD_LOAD_ERROR, {
            error: reason,
          });
        },
      )
      .finally(() => {
        this.__updateSubject({ initialised: true });
      });
  };

  appendInsurance = (insurance) => {
    let { insurances } = this.subject.value;

    insurance.dirty = true;

    let policyHolder = [];

    if (insurance.policyHolder.relationship !== 'SELF') {
      let phoneNumberParts = phoneUtil.getNumberParts(insurance.policyHolder.phoneNumber);

      policyHolder.push({
        name: {
          given: insurance.policyHolder.firstName,
          family: insurance.policyHolder.lastName,
        },
        role: 'POLICY_HOLDER',
        relationship: {
          system: 'decoded/person/relationship',
          code: 'code',
          value: insurance.policyHolder.relationship,
        },
        dob: userInfoUtil.formatDate(insurance.policyHolder.dateOfBirth),
        sex: insurance.policyHolder.gender,
        contactDetails: {
          address: {
            type: '',
            line1: insurance.policyHolder.address,
            city: insurance.policyHolder.city,
            postcode: insurance.policyHolder.postcode,
            administrativeArea: insurance.policyHolder.state,
            country: insurance.policyHolder.country,
          },
          number: {
            type: 'CELL',
            country: '+1',
            area: phoneNumberParts[0],
            prefix: phoneNumberParts[1],
            line: phoneNumberParts[2],
          },
        },
      });
    }

    const insuranceContactDetails =
      insurance.planId === '-1'
        ? {
            address: insurance.address,
          }
        : undefined;

    let entry = {
      primary: insurances.filter((_item) => _item.primary === 1).length === 0 ? 1 : 0,
      order: insurances.length,
      type: {
        system: 'decoded/payment_method',
        code: '1',
      },
      insuranceInformation: {
        memberId: insurance.memberId,
        groupId: insurance.groupId,
        type: 'INSURANCE',
        provider: {
          name: 'default',
          contactDetails: insuranceContactDetails,
        },
        plan: {
          id: insurance.planId,
          name: insurance.plan,
          type: 'default',
        },
        members: policyHolder,
      },
      dirty: true,
    };

    if (
      Array.isArray(insurance.attachments) &&
      insurance.attachments.every(
        (attachment) =>
          attachment.reference &&
          attachment.reference.code &&
          attachment.reference.system &&
          attachment.reference.value,
      )
    ) {
      entry.attachments = insurance.attachments;
    } else {
      entry.attachments = [];
    }

    insurances.push(entry);

    this.__updateSubject({ insurances: insurances });
  };

  removeInsurance = (insurance) => {
    let { insurances, deletedInsurance } = this.subject.value;

    deletedInsurance.push(insurance);
    insurances = insurances.filter((_item) => _item !== insurance);
    this.__updateSubject({ insurances: insurances, deletedInsurance: deletedInsurance });
  };

  edit = () => {
    let { insurances } = this.subject.value;
    this.__updateSubject({ insurances: [], deletedInsurance: insurances, edit: true });
  };

  selfPay = () => {
    this.switchToSelfPay();
  };

  unknownInsurance = () => {
    const { appointment } = this.subject.value;

    appointmentApi
      .command(appointment.id, {
        command: 'set_payment_method',
        code: 'U',
      })
      .then(
        (value) => {
          this.extensionApi
            .command('consumer.insurance.unknown', {
              command: 'general',
              input: {
                appointmentId: appointment.id,
              },
            })
            .then(
              (o) => {
                this.callback();
              },
              (reason) => {
                notificationService.error(
                  'Unable to set payment method. Please try again. ',
                  reason.message,
                );
              },
            );
        },
        (reason) => {
          notificationService.error(
            'Unable to set payment method. Please try again. ',
            reason.message,
          );
        },
      );
  };

  save = () => {
    const { insurances, deletedInsurance } = this.subject.value;

    this.__updateSubject({ loading: true });

    if (
      insurances.filter((insurance) => insurance.dirty).length > 0 ||
      deletedInsurance?.length > 0
    ) {
      const updates =
        insurances.length > 0
          ? insurances
          : [
              {
                primary: 1,
                order: 0,
                type: {
                  system: 'decoded/payment_method',
                  code: '0',
                },
                attachments: [],
              },
            ];

      let request = {
        command: 'replace_insurance',
        paymentMethods: updates,
      };

      this.paymentApi
        .submitPaymentCommand(request)
        .then(
          (value) => {
            if (insurances.length > 0) {
              this.__updateSubject({ confirm: true });
            } else {
              this.onCallback();
            }
            analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_UPDATE_SUCCESS, {});
          },
          (reason) => {
            notificationService.httpError(reason);
            analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_UPDATE_ERROR, {});
          },
        )
        .finally(() => this.__updateSubject({ loading: false }));
    } else {
      if (insurances.length > 0) {
        this.__updateSubject({ confirm: true });
        analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_UPDATE_SUCCESS, {});
      } else {
        this.onCallback();
        analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_UPDATE_SUCCESS, {});
      }
      this.__updateSubject({ loading: false });
    }
  };

  onCallback = () => {
    let { appointment } = this.subject.value;
    this.appointmentApi
      .command(appointment.id, {
        command: 'set_payment_method',
        code: 'I',
      })
      .then(
        (value) => {
          this.callback();
        },
        (reason) => {
          notificationService.error(reason);
        },
      );
  };

  closeAccept = () => {
    this.__updateSubject({ confirm: false });
  };
  accept = () => {
    this.onCallback();
  };

  back = () => {
    const { source } = this.subject.value;
    this.onBack(source);
  };

  close = () => {};
}

export class Constants {}

export class BlocEvent {}
