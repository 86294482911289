import {FormattedMarkdown} from '@decodedhealth/react-library';
import * as React from 'react';
import {useWizardContext} from '../../../shared-library';
import PageContainer from '../../common/PageContainer';
import {DefaultDisconnectedPageFooter, ScrollableQuinnContainer} from '../../common/ScrollableContainer';
import DecodedButton from "../../common/DecodedButton";
import {H2TextTitleContent} from '../../shared/Typography';

export const EmployerWalkin = ({ bloc }) => {
  const { goBack } = useWizardContext();

  return (
    <PageContainer>
        <ScrollableQuinnContainer
            messageId="employerInformation.employerExist"
            message="Thank you for the information so far, to complete the booking of your visit, read the statement below then click accept.">
            <H2TextTitleContent>
                { bloc.isWorkers()  ? (<FormattedMarkdown
                    id="booking.paymentmethod.employer.workerscomp.walkin.body"
                    defaultMessage="After you click accept, please take a seat and a member of our team will call you shortly to complete your check-in for your workers comp visit."
                />) : (<FormattedMarkdown
                    id="booking.paymentmethod.employer.notexists.walkin.body"
                    defaultMessage="After you click accept, please take a seat and a member of our team will call you shortly to complete your check-in for your Occupational Health visit."
                />) }
            </H2TextTitleContent>
        </ScrollableQuinnContainer>

      <DefaultDisconnectedPageFooter justifyContent={"left"}>
        <DecodedButton
            onClick={() => {
                bloc.save();
            }}
          >ACCEPT</DecodedButton>
      </DefaultDisconnectedPageFooter>
    </PageContainer>
  );
};
