import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { authService } from '../../../utils/auth';
import { uriStorage } from '../../../utils/storage';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { VIRTUAL_SCHEDULE } from '../../../utils/route.name';
import { dateUtil } from '../../../utils/date';
import { notificationService } from '../../../utils/notification';
import { ErrorMessage } from '../../../utils/error.resolver';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { CheckDevicePage } from './CheckDevice';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import PageContainer from '../../common/PageContainer';
import { DisconnectedPageFooter, ScrollableQuinnContainer } from '../../common/ScrollableContainer';
import { providerStorage } from '../../../utils/provider.qs';
import { AppointmentCard } from './AppointmentCard';
import { serviceUtil } from '../../../utils/service';
import DecodedButton from '../../common/DecodedButton';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    margin: 'auto',
    marginLeft: '0',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
    },
  },
  text: {
    marginTop: '1em',
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '4.2em',
    lineHeight: '1',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '3.8em',
      textAlign: 'center',
    },
  },
  thank: {
    marginTop: '1em',
    textAlign: 'left',
    fontSize: '40px',
    lineHeight: '1',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  confirmation: {
    marginTop: '1em',
    textAlign: 'left',
    fontWeight: '500',
    fontSize: '2.2em',
    lineHeight: '1',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8em',
      textAlign: 'center',
    },
  },
  linkBox: {
    marginTop: '4em',
    marginBottom: '4em',
    textAlign: 'left',
    lineHeight: '1',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  link: {
    textDecoration: 'underline',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
});

class PostBooking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      visitTime: '',
      atLocation: providerStorage.isWalkin(),
      usedKisok: providerStorage.isKiosk(),
    };
  }

  componentDidMount() {
    let { appointmentId, atLocation } = this.props.match.params;

    Promise.all([
      appointmentApi.getAppointmentStatus(appointmentId),
      appointmentApi.getAvailableProviders(),
    ])
      .then((result) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);

        const appointment = result[0].data;

        if (atLocation) {
          setTimeout(() => {
            window.location.reload();
          }, 30000);
          setTimeout(() => {
            uriStorage.clearPath();
            authService.logout();
          });
        } else if (appointment.type !== 'VIRTUAL') {
          setTimeout(() => {
            uriStorage.clearPath();
            authService.logout();
          });
        }

        if (appointment.status === 'DRAFT') {
          this.setState({
            appointment: appointment,
          });
        } else {
          const organisation = result[1].data.items.filter(
            (_provider) => _provider.id === appointment.provider,
          )[0];

          const startTime = dateUtil.parseDate(appointment.start);

          this.setState({
            appointment: appointment,
            organisation: organisation,
            visitTime: dateUtil.fullFriendlyOutputExcludeYear(startTime),
          });
        }
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_ERROR, {
          appointmentId: appointmentId,
          reason: error,
        });

        notificationService.error('Error showing your visit time. ' + ErrorMessage.CALL_SUPPORT);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { loading, visitTime, appointment, organisation, atLocation } = this.state;
    let code = 'loading';
    if (appointment) {
      if (appointment.type && typeof appointment.type === 'string') {
        code = `${appointment.type.replace(/_-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }

      if (appointment?.status === 'DRAFT') {
        return this.__renderDraft();
      }
    }

    if (appointment?.type === 'VIRTUAL') {
      return this.__renderVirtual();
    }

    if (atLocation) {
      return this.__renderWalkin();
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`postbooking.user.${code}.bubble`}
          message="That’s it. We are done! You will be getting a reminder from us closer to your reservation. Please make sure to check-in at our lobby kiosk as soon as you arrive."
        >
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              {!loading && (
                <AppointmentCard
                  appointment={appointment}
                  organisation={organisation}
                  visitTime={visitTime}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="bodyText" className={classes.thank}>
                <FormattedMarkdown
                  id={`postbooking.thankyou.${code}.message`}
                  defaultMessage={`Thank You!`}
                />
              </Typography>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }

  __renderDraft() {
    const { classes } = this.props;
    const { loading, appointment } = this.state;

    const process = serviceUtil.determinCheckinProcess(appointment);
    if (process === 'workerscomp') {
      return (
        <PageContainer loading={loading} hideBack={true}>
          <ScrollableQuinnContainer
            messageId={`postbooking.user.workerscomp.walkin.bubble`}
            message="Please note, that your place in line is not yet reserved. Please have a seat and our staff will call you to the front desk, to complete your check-in process as soon as possible."
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="bodyText" className={classes.thank}>
                  <FormattedMarkdown
                    id={`postbooking.user.workerscomp.walkin.message`}
                    defaultMessage={`Thank You!`}
                  />
                </Typography>

                <Typography variant="body1" className={classes.confirmation}>
                  <FormattedMarkdown
                    id={`postbooking.user.workerscomp.walkin.text`}
                    defaultMessage={``}
                  />
                </Typography>
              </Grid>
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    if (process === 'occhealth') {
      return (
        <PageContainer loading={loading} hideBack={true}>
          <ScrollableQuinnContainer
            messageId={`postbooking.user.occhealth.walkin.bubble`}
            message="Please note, that your place in line is not yet reserved. Please have a seat and our staff will call you to the front desk, to complete your check-in process as soon as possible."
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="bodyText" className={classes.thank}>
                  <FormattedMarkdown
                    id={`postbooking.user.occhealth.walkin.message`}
                    defaultMessage={`Thank You!`}
                  />
                </Typography>

                <Typography variant="body1" className={classes.confirmation}>
                  <FormattedMarkdown
                    id={`postbooking.user.occhealth.walkin.text`}
                    defaultMessage={``}
                  />
                </Typography>
              </Grid>
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    let code = 'loading';
    if (appointment) {
      if (appointment.service && typeof appointment.service === 'string') {
        code = `${appointment.service.replace(/-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`postbooking.user.${code}.bubble`}
          message="Please note, that your reservation has not been made yet. We will contact you shortly to finalize your reservation."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="bodyText" className={classes.thank}>
                  <FormattedMarkdown
                    id={`postbooking.thankyou.${code}.message`}
                    defaultMessage={`Thank You!`}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }

  __renderVirtual() {
    const { classes } = this.props;
    const { loading, visitTime, appointment } = this.state;
    const { appointmentId } = this.props.match.params;

    let code = 'loading';
    if (appointment) {
      if (appointment.service && typeof appointment.service === 'string') {
        code = `${appointment.service.replace(/-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <CheckDevicePage appointmentId={appointmentId} />
      </PageContainer>
    );
  }

  __renderWalkin() {
    const { classes } = this.props;
    const { loading, visitTime, appointment, usedKisok } = this.state;

    const process = serviceUtil.determinCheckinProcess(appointment);
    if (['occhealth', 'workerscomp'].includes(process)) {
      return (
        <PageContainer loading={loading} hideBack={true}>
          <ScrollableQuinnContainer
            messageId={`postbooking.user.occhealth.walkin.bubble`}
            message="Please note, that your place in line is not yet reserved. Please have a seat and our staff will call you to the front desk, to complete your check-in process as soon as possible."
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="bodyText" className={classes.thank}>
                  <FormattedMarkdown
                    id={`postbooking.user.occhealth.walkin.message`}
                    defaultMessage={`Thank You!`}
                  />
                </Typography>

                <Typography variant="body1" className={classes.confirmation}>
                  <FormattedMarkdown
                    id={`postbooking.user.occhealth.walkin.text`}
                    defaultMessage={``}
                  />
                </Typography>
              </Grid>
            </Grid>
          </ScrollableQuinnContainer>
          <DisconnectedPageFooter>
            {usedKisok && (
              <DecodedButton
                onClick={() => {
                  window.location.reload();
                }}
              >
                <FormattedMarkdown id={'window.restart'} defaultMessage={'RESTART'} />
              </DecodedButton>
            )}
          </DisconnectedPageFooter>
        </PageContainer>
      );
    }

    let code = 'loading';
    if (appointment) {
      if (appointment.service && typeof appointment.service === 'string') {
        code = `${appointment.service.replace(/-/g, '').toLowerCase()}`;
      } else {
        code = `general`;
      }
    }

    return (
      <PageContainer loading={loading} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`postbooking.user.${code}.bubble`}
          message="That's it. We're done! You will be getting a reminder from us closer to your appointment."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="bodyText" className={classes.thank}>
                <FormattedMarkdown
                  id={`postbooking.thankyou.${code}.message`}
                  defaultMessage={`Thank You!`}
                />
              </Typography>

              <Typography variant="body1" className={classes.confirmation}>
                <FormattedMarkdown
                  id={`postbooking.reservation.${code}.message`}
                  defaultMessage={`Your visit has been reserved`}
                />
              </Typography>

              {visitTime && (
                <Typography variant="body1" className={classes.confirmation}>
                  <FormattedMarkdown
                    id="postbooking.time.message"
                    defaultMessage={`at ${visitTime}`}
                  />
                </Typography>
              )}

              {this.props.match.params.appointmentType === VIRTUAL_SCHEDULE && (
                <Typography variant={'body2'} className={classes.confirmation} component={'div'}>
                  <FormattedMarkdown
                    id="postbooking.virtual.message"
                    defaultMessage="You can close the browser. We will send you a reminder via SMS when the provider is ready to receive you."
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(PostBooking);
