export class TenantUtil {
  tenantId() {
    return process.env.REACT_APP_TENANT_ID || 'patientplus';
  }

  tenant() {
    return this.tenantId().substring(0, this.tenantId().indexOf('-'));
  }

  tenantToken() {
    return process.env.REACT_APP_TENANT_TOKEN;
  }
}

export const tenantUtil = new TenantUtil();
