import { appointmentApi } from '../../utils/services/appointments.api';
import { notificationService } from '../../utils/notification';
import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { uriStorage } from '../../utils/storage';
import { authService } from '../../utils/auth';
import { providerStorage } from '../../utils/provider.qs';
import { dateUtil } from '../../utils/date';

export class Bloc extends DecodedBloc {
  constructor(appointmentId) {
    super({
      appointmentId: appointmentId,
    });

    this.__initialise(appointmentId);
  }

  __initialise = (appointmentId) => {
    appointmentApi
      .getAppointmentStatus(appointmentId)
      .then(
        (value) => {
          const appointment = value.data;

          const paid = appointment?.properties?.find(
            (value) => value.code === 'visit_paid' && value.system === 'decoded/appointment',
          );

          const startTime = dateUtil.parseDate(appointment.start);
          const queueNumber = appointment.waitingRoomSummary?.numberInQueue;
          this.__updateSubject({
            madePayment: paid,
            appointment: appointment,
            startTime: startTime,
            queueNumber: queueNumber,
          });
        },
        (reason) => {
          notificationService.error(
            'Error loading providers and schedules. Please refresh and if the problem persists call the clinic.',
          );
        },
      )
      .finally(() => {
        this.__updateSubject({ initialising: false });
        authService.logout().then(() => {
          uriStorage.clearPath();
          providerStorage.clearProvider();
        });
      });
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
}
