import React, { createRef, useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box, Grid, useTheme } from '@mui/material';
import OrgCard from './OrgCard';
import { useBookingWizardContext } from '../BookingWizard/booking-wizard-context';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { providerStorage } from '../../../utils/provider.qs';

const OrgSelector = () => {
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const orgRefs = useRef({});


  const theme = useTheme();

  const { booking, bookingBloc, availableOrganisations, loadingData } = useBookingWizardContext();

  useEffect(() => {
    const preferredOrgId = providerStorage.preferredLocation();
    if (preferredOrgId) {
      setSelectedOrgId(preferredOrgId);
      bookingBloc.setSelectedOrg(preferredOrgId);
    }
  }, [bookingBloc]);

  useEffect(() => {
    //?--> create refs for each org card
    availableOrganisations.forEach(org => {
      orgRefs.current[org.id] = orgRefs.current[org.id] || createRef();
    });
  }, [availableOrganisations]);

  useEffect(() => {
    if (selectedOrgId && orgRefs.current[selectedOrgId]) {
      const selectedRef = orgRefs.current[selectedOrgId];

      if (selectedRef.current) {
        selectedRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        });
      }
    }
  }, [selectedOrgId, orgRefs]);

  const isPreferredOrg = (id) => {
    return providerStorage.preferredLocation() === id;
  };

  const handleClick = (id) => {
    return () => {
      if (selectedOrgId !== id) {
        sessionStorage.removeItem('preferredLocation');
        setSelectedOrgId(id);
        bookingBloc.setSelectedOrg(id);
      }
    };
  };

  const selectedOrg = React.useMemo(() => {
    return availableOrganisations.find((_org) => _org.id === booking.selectedOrg);
  }, [availableOrganisations, booking.selectedOrg]);
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
            version: 'weekly',
            libraries: ['places'],
          }}
          defaultCenter={{
            lat: 30.45537972887279,
            lng: -91.0783767180397,
          }}
          center={
            selectedOrg
              ? {
                  lat: selectedOrg?.contactInformation?.address?.geoLocation?.latitude,
                  lng: selectedOrg?.contactInformation?.address?.geoLocation?.longitude,
                }
              : {
                  lat: 30.45537972887279,
                  lng: -91.0783767180397,
                }
          }
          defaultZoom={16}
          options={{
            zoomControl: false,
            fullscreenControl: false,
          }}
        >
          {selectedOrg && (
            <>
              <LocationOnIcon
                sx={{
                  fontSize: '36px',
                  color: theme.palette.accent.main,
                }}
                fontSize={'large'}
                color={'primary'}
                lat={selectedOrg?.contactInformation?.address?.geoLocation?.latitude}
                lng={selectedOrg?.contactInformation?.address?.geoLocation?.longitude}
              />
            </>
          )}
          {availableOrganisations?.map((_org) => (
            <LocationOnIcon
              sx={{
                fontSize: '36px',
                color: theme.palette.accent.main,
              }}
              fontSize={'large'}
              color={'primary'}
              lat={_org?.contactInformation?.address?.geoLocation?.latitude}
              lng={_org?.contactInformation?.address?.geoLocation?.longitude}
            />
          ))}
        </GoogleMapReact>
      </div>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        height="100%"
        sx={(theme) => ({
          paddingLeft: '10em',
          marginBottom: '12px',
          [theme.breakpoints.down('md')]: {
            paddingLeft: '24px',
          },
        })}
      >
        <Grid item container>
          <Box
            sx={{
              backgroundColor: 'rgb(255, 255, 255)',
              padding: '0 1em',
              borderRadius: '6px',
              fontStyle: 'normal',
              fontSize: '16px',
              lineHeight: '18px',
            }}
          >
            <FormattedMarkdown
              id={'org.selection.title'}
              defaultMessage={'Please, select a clinic'}
            />
          </Box>
        </Grid>
        <Grid item container flexWrap="nowrap" width="100%" sx={{ overflowX: 'scroll', maxWidth: '85vw !important' }}>
          {availableOrganisations
            ? availableOrganisations.map((org) => (
              <OrgCard
                ref={orgRefs.current[org.id]}
                key={org.id}
                org={org}
                onClick={handleClick(org.id)}
                isSelected={isPreferredOrg(org.id) || selectedOrgId === org.id}
              />
            ))
            : null}
        </Grid>
      </Grid>
    </>
  );
};

export default OrgSelector;
