import * as rxjs from 'rxjs';
import { Button, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const initial = {
  initialised: false,
};

export class Bloc {
  constructor(props, callback, onBack) {
    this.subject = new rxjs.BehaviorSubject({
      ...initial,
      ...props,
    });

    this.events = new rxjs.Subject();

    this.callback = callback;
    this.onBack = onBack;
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  __publishEvent = (type, data) =>
    this.events.next({
      type: type,
      data: data,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    const { service } = this.subject.value;

    let options = [];

    let selfPay = undefined;
    let insurance = undefined;
    let subscription = undefined;
    let employer = undefined;

    service.paymentOptions.forEach((option) => {
      if (option?.code === 'S') {
        selfPay = {
          code: option.code,
          label: (
            <FormattedMessage
              id="paymentmethod.choice.selfpay.button.label"
              defaultMessage={`Self pay`}
            />
          ),
        };
      }
      if (option?.code === 'I') {
        insurance = {
          code: option.code,
          label: (
            <FormattedMessage
              id="paymentmethod.choice.insurance.button.label"
              defaultMessage={`Insurance`}
            />
          ),
        };
      }
      if (option?.code === 'D') {
        subscription = {
          code: option.code,
          label: (
            <FormattedMessage
              id="paymentmethod.choice.subscription.button.label"
              defaultMessage={`Continue`}
            />
          ),
        };
      }
      if (option?.code === 'E') {
        employer = {
          code: option.code,
          label: (
            <FormattedMessage
              id="paymentmethod.choice.employer.button.label"
              defaultMessage={`Employer`}
            />
          ),
        };
      }
    });

    options = [subscription, insurance, employer, selfPay].filter((value) => value !== undefined);

    this.__updateSubject({
      initialised: true,
      subscription: subscription !== undefined,
      options: options,
    });
  };

  submit = (option) => {
    this.callback(option);
  };

  back = () => {
    this.onBack(-1);
  };

  close = () => {};
}

export class Constants {}

export class BlocEvent {}
