import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent, TextBody } from '../../shared/Typography';
import DecodedButton from '../../common/DecodedButton';
import { routeUtil } from '../../../utils/route.name';
import { PageContent } from '../../common/ScrollableContainer';
import { authService } from '../../../utils/auth';
import { uriStorage } from '../../../utils/storage';

const styles = (theme) => ({});

class OccHealth extends Component {
  logout;

  constructor(props) {
    super(props);
    this.bloc = props.bloc;
  }

  componentDidMount() {
    this.logout = setTimeout(() => {
      authService.logout().then(() => {
        uriStorage.clearPath();
        window.location.reload();
      });
    }, 20000);
  }

  componentWillUnmount() {
    if (this.logout) {
      clearTimeout(this.logout);
    }
  }

  render() {
    return (
      <>
        <PageContent>
          <Grid item xs={12} sx={{ margin: '64px 0' }}>
            <H2TextTitleContent>
              <FormattedMarkdown
                id={'booking.checkin.occhealth.text'}
                defaultMessage={
                  'Please have a seat and the front desk staff will call them to complete their registration shortly.'
                }
              />
            </H2TextTitleContent>
          </Grid>
        </PageContent>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(OccHealth));
