import {FormattedMarkdown} from '@decodedhealth/react-library';
import {Grid} from '@mui/material';
import React from 'react';
import withStyles from "@mui/styles/withStyles";
import DecodedComponent from "../../shared/DecodedComponent";
import {BookingWizardConsumer} from "../BookingWizard/booking-wizard-context";
import {BookingBlocEvent} from "../bloc";
import {H2TextTitleContent} from "../../shared/Typography";

const GridItem = (props) => <Grid {...props} item />;

const styles = (theme) => ({
    root: {},
});

class WalkinConfirmation extends DecodedComponent {

    constructor(props) {
        super(props);

        let max = new Date();
        max.setHours(max.getHours() + 10);
        let low = new Date();
        low.setHours(low.getHours() - 1);

        this.bloc = props.context.bookingBloc;
        this.state = {
            local: { max: max, low: low, today: new Date() },
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.bloc.estimateQueueStats();
    }

    __handleEvent = (event) => {

        switch (event.type) {
            case BookingBlocEvent.WALKIN_DETAILS_LOADED:
                const { local, } = this.state;
                const { schedulingIntervals, } = this.bloc.subject.value;

                let available = [];

                if(schedulingIntervals) {
                    available = schedulingIntervals
                        .filter(interval => interval.start >= local.low && interval.start < local.max)
                        .filter(interval => interval.slots?.length > 0)
                        .filter(interval => interval.waitTimes < interval.capacity);
                }

                const firstSlot = available.length > 0 ? available[0] : undefined;

                let _default = new Date();
                _default.setMinutes(_default.getMinutes() + 6);

                // this.bloc.setWalkinDetails(firstSlot?.start || _default);
                this.bloc.setWalkinDetails(_default);

                this.setState({
                    firstSlot: firstSlot,
                });
        }
    }


    render() {

        // const { firstSlot, } = this.state;

        return (
            <Grid container direction="column">
                <H2TextTitleContent component={GridItem}>
                    <FormattedMarkdown
                        id="interaction.appointment.walkin.confirmation.message1"
                        defaultMessage="Please know that we make every effort to see you promptly, however, wait times can vary. Patients with serious conditions may be seen first."
                    />
                </H2TextTitleContent>
                <H2TextTitleContent component={GridItem}>
                    <FormattedMarkdown
                        id="interaction.appointment.walkin.confirmation.message2"
                        defaultMessage="Thanks for your understanding."
                    />
                </H2TextTitleContent>
            </Grid>
        );
    }
}

export default withStyles(styles)((props) => (
    <BookingWizardConsumer>
        {(value) => {
            return <WalkinConfirmation context={value} {...props} />;
        }}
    </BookingWizardConsumer>
));
