import { FormattedMarkdown } from '@decodedhealth/react-library';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createSession, preloadScript } from 'opentok-react';
import * as React from 'react';
import { CloudEvent } from 'cloudevents';
import { appointmentApi } from '../../../../utils/services/appointments.api';
import PageContainer from '../../../common/PageContainer';
import { appointmentEventService } from './service/appointment.event.service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { eventApi } from '../../../../utils/services/event.api';
import { consumerApi } from '../../../../utils/services/consumers.api';
import { Publisher } from './Publisher';
import { notificationService } from '../../../../utils/notification';
import { analyticsEventLogger } from '../../../../utils/events';
import { useLogoutBeforeUnload } from '../../../../utils/logoutOnBeforeUnload';
import { useAuthServiceAvailable } from '../../../../utils/auth';
import { PagePanel, ScrollableBox, ScrollableWrapper } from '../../../common/ScrollableContainer';
import { set } from 'date-fns';

const INTERACTION_EVENTS = {
  enter: 'interaction.virtual.precall.enter',
  start: 'interaction.virtual.precall.start',
  retry: 'interaction.virtual.precall.retry',
  error: 'interaction.virtual.precall.error',
  complete: 'interaction.virtual.precall.complete',
  consentReminder: 'interaction.virtual.consented.reminder',
  consented: 'interaction.virtual.consented',
  transition: 'interaction.virtual.transition',
  status: 'vfd.telehealth.patient.status',
};

const useInteractionEventsPrecall = ({ sessionId, token, appointmentId }) => {
  return React.useCallback(
    (evt, err) => {
      if (sessionId) {
        const data = {
          sessionId,
          token,
          appointmentId,
          ...(err ? { err } : {}),
        };

        let ce = new CloudEvent({
          type: evt,
          datacontenttype: 'application/json',
          source: '/consumer-webapp',
          dataschema: 'https://schema.decodedhealth.com/events/telehealth.json',
          data,
        });

        ce = ce.cloneWith({ xdhvendor: 'dh' });
        ce = ce.cloneWith({ xdhsystem: 'vonage' });
        eventApi.publish(ce.toJSON());
        analyticsEventLogger.log(evt, data);
      }
    },
    [sessionId, token, appointmentId],
  );
};

const useInteractionStatusEvents = ({ appointmentId }) => {
  return React.useCallback(
    (status, err) => {
      const data = {
        status: status,
        appointmentId,
        ...(err ? { err } : {}),
      };

      let ce = new CloudEvent({
        type: 'vfd.telehealth.patient.status',
        datacontenttype: 'application/json',
        source: '/consumer-webapp',
        dataschema: 'https://schema.decodedhealth.com/events/telehealth.json',
        data,
      });

      ce = ce.cloneWith({ xdhvendor: 'dh' });
      ce = ce.cloneWith({ xdhsystem: 'vonage' });
      eventApi.publish(ce.toJSON());
      analyticsEventLogger.log('vfd.telehealth.patient.status', data);
    },
    [appointmentId],
  );
};

const useAppointmentEventServicePolling = (callback, appointmentId) => {
  React.useEffect(() => {
    appointmentEventService.registerAppointmentStatusCallback(callback);
    appointmentEventService.startPollingForAppointmentStatus(appointmentId);

    return () => {
      appointmentEventService.stopPollingForAppointmentStatus();
    };
  }, [callback, appointmentId]);
};

const usePrecallDetails = () => {
  const [precall, setPrecall] = React.useState(null);
  React.useEffect(() => {
    appointmentApi
      .getPrecallTelehealthDetails()
      .then((value) => {
        setPrecall(value.data);
      })
      .catch((err) => {
        console.warn('Error while fetching Precall Telehealth Details', err);
        notificationService.error('Error loading telehealth precall test details. ' + err);
        setPrecall(null);
      });
  }, []);
  return precall;
};

const isAppointmentWaitingOrReserved = (appointment) => {
  return appointment && (appointment.status === 'WAITING' || appointment.status === 'RESERVED');
};

const isAppointmentReviewingOrStarting = (appointment) => {
  return (
    appointment &&
    (appointment.status === 'REVIEWING' ||
      appointment.status === 'STARTED' ||
      appointment.status === 'A_REVIEWING' ||
      appointment.status === 'A_STARTED')
  );
};

const usePreCallStyles = makeStyles((theme) => ({
  root: {
    // flex: '1',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '12px',
  },
  retryButton: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  errorText: {
    color: theme.palette.accent.main,
  },
  acceptCheckBox: {
    transform: 'scale(2)',
    marginRight: '0.5em',
  },
  consentText: {
    color: theme.palette.primary.main,
  },
  radioButton: {
    color: theme.palette.primary.main,
  },
}));

const DEVICE = {
  IOS: 'ios',
  ANDROID: 'android',
};

const detectIosOrAndroidDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return DEVICE.IOS;
  }
  if (/android/.test(userAgent.toLowerCase())) {
    return DEVICE.ANDROID;
  }
  return null;
};

const PreCallSession = (props) => {
  const appointmentId = props.match.params.appointmentId;
  const classes = usePreCallStyles();
  const history = props.history;
  const location = props.location;
  const precall = usePrecallDetails();
  const isLinkUrl = location.pathname.includes('/link');
  const [publishError, setPublishError] = React.useState(null);
  const [publishSuccess, setPublishSuccess] = React.useState(false);
  const appointmentStatusRef = React.useRef(null);
  const [appointmentStatus, setAppointmentStatus] = React.useState(null);
  const [userStatus, setUserStatus] = React.useState(null);
  const [consent, setConsent] = React.useState(false);

  const sessionRef = React.useRef(null);

  const handleInteractionEventCallback = useInteractionEventsPrecall({
    sessionId: precall?.session,
    token: precall?.password,
    appointmentId,
  });

  const handleStatusEventCallback = useInteractionStatusEvents({
    appointmentId,
  });

  React.useEffect(() => {
    let intervalId;
    if (userStatus) {
      console.log('Setting interval');
      intervalId = setInterval(() => {
        handleStatusEventCallback(userStatus, null);
      }, 5000);
    }

    return () => {
      console.log('Clearing interval');
      clearInterval(intervalId);
    };
  }, [userStatus]);

  if (precall && sessionRef.current === null) {
    sessionRef.current = createSession({
      apiKey: precall.id,
      sessionId: precall.session,
      token: precall.password,
      onStreamsUpdated: (streams) => {
        console.log('Current subscriber streams:', streams);
      },
    });
  }
  const session = sessionRef.current;

  const handleConsentChange = React.useCallback(
    (event) => {
      if (event.target.checked) {
        handleInteractionEventCallback(INTERACTION_EVENTS.consented);
        setUserStatus('online');
      } else {
        setUserStatus('waiting-room');
      }
      setConsent(event.target.checked);
    },
    [handleInteractionEventCallback],
  );

  React.useEffect(() => {
    window.OT.on('exception', (event) => {
      console.error('OT exception:', event);
      setPublishError(event);
      handleInteractionEventCallback(INTERACTION_EVENTS.error, {
        code: event.code,
        message: event.message,
      });
    });

    return () => {
      window.OT.off('exception');
    };
  }, [handleInteractionEventCallback]);

  React.useEffect(() => {
    handleInteractionEventCallback(INTERACTION_EVENTS.enter);
  }, [handleInteractionEventCallback]);

  useAppointmentEventServicePolling((appointment) => {
    if (appointmentStatusRef.current !== appointment.status) {
      appointmentStatusRef.current = appointment.status;
      setAppointmentStatus(appointment);
    }
  }, appointmentId);

  React.useEffect(() => {
    if (appointmentStatus?.status === 'STARTED') {
      if (publishSuccess && !consent) {
        handleInteractionEventCallback(INTERACTION_EVENTS.consentReminder);
      }
      if (publishSuccess && consent) {
        consumerApi
          .consentTelehealthCommunication()
          .then(() => {
            handleInteractionEventCallback(INTERACTION_EVENTS.transition);
            const path = isLinkUrl
              ? `/link/encounter/${appointmentId}`
              : `/virtual-clinic/encounter/${appointmentId}`;

            history.replace({
              pathname: path,
              state: { fromWaitingRoom: true },
            });
            setUserStatus('online');
          })
          .catch((err) => {
            console.warn('Error while consenting', err);
            notificationService.error('Unable to update your consent.');
            setConsent(false);
          });
      }
    }
  }, [
    appointmentId,
    appointmentStatus,
    consent,
    history,
    isLinkUrl,
    handleInteractionEventCallback,
    publishSuccess,
  ]);
  const shouldLogout = isLinkUrl ? false : props.location?.state?.showHandleBack ? false : true;
  // logout incase user refreshes the page after appintment confirmation
  useLogoutBeforeUnload(shouldLogout, true);

  const handlePublishSuccess = React.useCallback(() => {
    handleInteractionEventCallback(INTERACTION_EVENTS.complete);
    setPublishSuccess(true);
    setPublishError(null);
    setUserStatus('waiting-room');
  }, [handleInteractionEventCallback]);

  const handlePublishError = React.useCallback(
    (err) => {
      handleInteractionEventCallback(INTERACTION_EVENTS.error, {
        code: err.code,
        message: err.message,
      });
      setPublishError(err);
      setPublishSuccess(false);
    },
    [handleInteractionEventCallback],
  );

  const handlePublishInit = React.useCallback(() => {
    handleInteractionEventCallback(INTERACTION_EVENTS.start);
    setUserStatus('online');
  }, [handleInteractionEventCallback]);
  
  return (
    <PageContainer
      loading={!precall}
      handleBack={
        props.location?.state?.showHandleBack
          ? () => {
              history.goBack();
            }
          : undefined
      }
    >
      <ScrollableWrapper>
        <ScrollableBox>
          <PagePanel>
            <Grid container className={classes.root}>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2" component="div">
                  {publishSuccess ? (
                    <FormattedMarkdown
                      id="waitingRoom.precall.publishSuccess"
                      defaultMessage="You are ready to go!"
                    />
                  ) : null}
                  {publishSuccess && isAppointmentWaitingOrReserved(appointmentStatus) ? (
                    <FormattedMarkdown
                      id="waitingRoom.precall.publishSuccess.waiting"
                      defaultMessage="Your browser is setup correctly for a telehealth visit."
                    />
                  ) : null}
                  {publishSuccess && isAppointmentReviewingOrStarting(appointmentStatus) ? (
                    <div
                      className={
                        appointmentStatus.status === 'STARTED' ? classes.errorText : undefined
                      }
                    >
                      <FormattedMarkdown
                        id="waitingRoom.precall.publishSuccess.reviewing"
                        defaultMessage="Please review and accept the consent below and you will be added to the virtual waiting room."
                      />
                    </div>
                  ) : null}
                  {!publishError && !publishSuccess ? (
                    <>
                      <FormattedMarkdown
                        id="waitingRoom.precall.publish"
                        defaultMessage="Please wait while we make sure your audio and video are working properly"
                      />
                      <FormattedMarkdown
                        id="waitingRoom.precall.message"
                        defaultMessage="We will add you to virtual waiting room as soons as your system is ready."
                      />
                    </>
                  ) : null}

                  {publishError && !publishSuccess ? (
                    <div className={classes.errorText}>
                      <FormattedMarkdown
                        id={`waitingRoom.precall.publishError${
                          detectIosOrAndroidDevice() ? `.${detectIosOrAndroidDevice()}` : ''
                        }.${publishError.code}.heading`}
                        defaultMessage="Unexpected Error"
                      />
                      <FormattedMarkdown
                        id={`waitingRoom.precall.publishError${
                          detectIosOrAndroidDevice() ? `.${detectIosOrAndroidDevice()}` : ''
                        }.${publishError.code}.message`}
                        defaultMessage="An error occurred. Please try again later. If the problem persists please contact the clinic directly."
                      />
                    </div>
                  ) : null}
                </Typography>
              </Grid>
              {publishError && !publishSuccess ? (
                <Grid item className={classes.retryButton} xs={12} sm={12}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleInteractionEventCallback(INTERACTION_EVENTS.retry);
                      setPublishError(null);
                      setPublishSuccess(false);
                    }}
                  >
                    TRY AGAIN
                  </Button>
                </Grid>
              ) : null}
              {!!!publishError && isAppointmentReviewingOrStarting(appointmentStatus) ? (
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        icon={<RadioButtonUncheckedIcon className={classes.radioButton} />}
                        checkedIcon={<CheckCircleIcon />}
                        checked={consent}
                        onChange={handleConsentChange}
                        name="acceptVisitConsent"
                        color="primary"
                        className={classes.acceptCheckBox}
                      />
                    }
                    label={
                      <div className={classes.consentText}>
                        <FormattedMarkdown
                          id="waitingRoom.precall.consent"
                          defaultMessage="I consent to take part in a virtual visit"
                        />
                      </div>
                    }
                  />
                </Grid>
              ) : null}
              {session ? (
                <Publisher
                  session={session.session}
                  onError={handlePublishError}
                  onPublish={handlePublishSuccess}
                  onInit={handlePublishInit}
                  disabled={!!publishError}
                />
              ) : null}
            </Grid>
          </PagePanel>
        </ScrollableBox>
      </ScrollableWrapper>
    </PageContainer>
  );
};

const WithAuthService = (props) => {
  const isAuthEnabled = useAuthServiceAvailable();
  return isAuthEnabled ? <PreCallSession {...props} /> : null;
};

export default preloadScript(WithAuthService);
