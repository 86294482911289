import { useCallback, useState } from 'react';

export const useWizardSteps = (steps, startAtStep, overrides) => {
  const [currentStep, setCurrentStep] = useState(startAtStep ?? 0);
  const totalSteps = steps.length;
  const isFirstStep = currentStep === 0;
  const isLastStep = totalSteps - 1 === currentStep;

  const goNext = useCallback(() => {
    setCurrentStep((step) => Math.min(step + 1, totalSteps - 1));
  }, [totalSteps]);

  const goBack = useCallback(() => {
    setCurrentStep((step) => Math.max(0, step - 1));
  }, []);

  const goStepByIndex = useCallback((step) => {
    setCurrentStep(step);
  }, []);

  const goStepById = useCallback(
    (stepId) => {
      const stepIndex = steps.findIndex((step) => step.id === stepId);
      if (stepIndex >= 0) setCurrentStep(stepIndex);
    },
    [steps],
  );

  return {
    activeStep: steps[currentStep],
    isLastStep,
    isFirstStep,
    goNext,
    goBack,
    goStepByIndex,
    goStepById,
  };
};
