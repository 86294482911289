import { Card, CardContent, CardHeader, useTheme } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import * as React from 'react';
import { providerUtil } from '../../../utils/provider';
import {useBookingWizardContext} from "../BookingWizard/booking-wizard-context";
import {useState} from "react";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import {dateUtil} from "../../../utils/date";

const OrgCard = React.forwardRef((props, ref)=> {

  const { org, onClick, isSelected } = props;
  const theme = useTheme();
  const [firstSlot, setFirstSlot] = useState(null);

  const { bookingBloc } = useBookingWizardContext();

    React.useEffect(() => {
        bookingBloc?.loadFirstAvailable(org)
            .then(value => {
                if(value.data.firstOpenSlot) {
                    try {
                        setFirstSlot(new Date(value.data.firstOpenSlot))
                        return;
                    } catch (e) {}
                    setFirstSlot(value.data.firstOpenSlot || " > 2 days")
                } else {
                    setFirstSlot(" > 2 days")
                }

                console.log(value.data.firstOpenSlot)
            }, reason => {
                setFirstSlot("unable to load")
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [org]);

  return (
    <div ref={ref}>
    <Card
      onClick={onClick}
      sx={{
        width: '240px',
        minWidth: '240px',
        maxWidth: '240px',
        margin: '12px 24px 12px 0',
        backgroundColor: isSelected ? theme.palette.primary.main : '#FFFFFF',
        border: '0 solid #ffffff',
        borderRadius: '6px',
        cursor: "pointer",
      }}
      variant="outlined"
    >
      <CardHeader
        sx={{
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '16px',
          lineHeight: '18px',
          color: isSelected ? '#ffffff' : '#000000',
        }}
        title={org.name}
      />
      <CardContent
        sx={{
          paddingTop: '0',
          paddingBottom: '6px !important',
        }}
      >
        <div
          style={{
            display: 'flex',
            marginBottom: '14px',
          }}
        >
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '18px',
              color: isSelected ? '#fff' : '#5C5D5F',
            }}
          >
            <LocationOnIcon
              sx={{
                fontSize: '18px',
                color: isSelected ? '#fff' : theme.palette.accent.main,
              }}
              fontSize={'large'}
              color={'primary'}
            />
          </span>
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '18px',
              color: isSelected ? '#FFF' : '#5C5D5F',
              paddingLeft: '18px',
            }}
          >
            {providerUtil.formatOrganisationAddress(org)}
          </span>
        </div>
          <div
              style={{
                  display: 'flex',
                  marginBottom: '14px',
              }}
          >
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '18px',
              color: isSelected ? '#fff' : '#5C5D5F',
            }}
          >
            <QueryBuilderIcon
                sx={{
                    fontSize: '18px',
                    color: isSelected ? '#fff' : theme.palette.accent.main,
                }}
                fontSize={'large'}
                color={'primary'}
            />
          </span>
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '18px',
              color: isSelected ? '#FFF' : '#5C5D5F',
              paddingLeft: '18px',
            }}
          >
            {firstSlot ? dateUtil.longFriendlyOutputExcludeYear(firstSlot) : 'Loading...'}
          </span>
        </div>
      </CardContent>
    </Card>
    </div>
  );
});

export default OrgCard;
