import * as React from 'react';
import {Wizard} from '../../../shared-library';
import {EmployerExist} from './EmployerExist';
import {EmployerForm} from './EmployerForm';
import {EmployerWalkin} from "./EmployerWalkin";


// [TODO] add a route for Employer information
export const EmployerWizard = ({ bloc }) => {

  const steps = bloc.isWalkin() ? [
    {
      id: 'employer-walkin',
      component: <EmployerWalkin bloc={bloc} />
    }
  ] : [
    {
      id: 'employer-exist-or-not',
      component: <EmployerExist bloc={ bloc }  />
    }
  ]
  return  <Wizard steps={steps} />
}
