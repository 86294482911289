import axios from 'axios';
import { VERSION } from '../version';
import { tenantUtil } from '../tenant';
import { providerStorage } from '../provider.qs';

class UserApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';

    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Content-Type'] = 'application/json';
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  requestOneTimePin(data, currentProvider = null) {
    const locationToken =
      localStorage.getItem('locationToken') || sessionStorage.getItem('locationToken');
    const provider = currentProvider ?? providerStorage.getCurrentProvider();
    const headers = { 'Content-Type': 'application/json' };

    const requestData = {
      ...data,
      ...(provider && { location: provider }),
      ...(locationToken && { locationCode: locationToken }),
    };

    return this.instance.post(`${this.baseUrl}/api/accounts/me/otp/auth`, requestData, { headers });
  }

  validateOneTimePin(data) {
    return this.instance.post(`${this.baseUrl}/api/accounts/me/otp/_validate`, data);
  }
}

export const userApi = new UserApi();
