import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../shared/Typography';
import { PageContent, PageFooter } from '../../common/ScrollableContainer';
import DecodedButton from '../../common/DecodedButton';

const styles = (theme) => ({});

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.bloc = props.bloc;
  }

  render() {
    const { classes, history } = this.props;

    return (
      <>
        <PageContent>
          <Grid item xs={12} sx={{ margin: '64px 0' }}>
            <H2TextTitleContent>
              <FormattedMarkdown
                id={'booking.checkin.subscription.text'}
                defaultMessage={'Please click continue to let the staff know you are here.'}
              />
            </H2TextTitleContent>
          </Grid>
        </PageContent>
        <PageFooter>
          <Grid item xs={12} sm={6}>
            <DecodedButton
              onClick={() => {
                this.bloc.checkin();
              }}
            >
              <FormattedMarkdown
                id={'booking.checkin.process.subscription.button'}
                defaultMessage={'CHECK-IN'}
              ></FormattedMarkdown>
            </DecodedButton>
          </Grid>
        </PageFooter>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Subscription));
