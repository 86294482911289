import * as React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useUserSummary } from '../../../utils/services/consumers.api';
import ChatBubble from '../../shared/ChatBubble';
import { useHistory } from 'react-router-dom';
import { useLogoutBeforeUnload } from '../../../utils/logoutOnBeforeUnload';
import {DefaultDisconnectedPageFooter, ScrollableQuinnContainer} from "../../common/ScrollableContainer";
import DecodedButton from "../../common/DecodedButton";
import { H2TextTitleContent } from '../../shared/Typography';
const useCheckDeviceStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  header: {
    marginBottom: '25px',
  },
}));

export const CheckDevicePage = ({ appointmentId }) => {
  const classes = useCheckDeviceStyles();
  const user = useUserSummary();
  const history = useHistory();

  // logout incase user refreshes the page after appintment confirmation
  useLogoutBeforeUnload(true, true);

  const handleCheckDevice = React.useCallback(() => {
    history.replace(`/virtual-clinic/waiting-room/${appointmentId}`);
  }, [history, appointmentId]);
  return (
      <>
    <ScrollableQuinnContainer messageId="waitingRoom.id"
                              message={`Thank you${
                                  user ? `, ${user.name.given}` : ''
                              }. Your appointment is now confirmed.`}>
        <Grid sx={{ marginTop: "12px" }} container>
          <H2TextTitleContent>
            If this is the device that you will be using for your virtual appointment, we suggest that
            you check the device and make sure it is configured properly.
          </H2TextTitleContent>
        </Grid>
    </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter>
      <Grid container item justifyContent="left">
        <DecodedButton onClick={handleCheckDevice}>
          CHECK DEVICE
        </DecodedButton>
      </Grid>
    </DefaultDisconnectedPageFooter>
</>
  );
};
