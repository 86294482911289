import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { globalBloc } from '../../../global.bloc';
import Scheduled from './Scheduled';

const styles = (theme) => ({
  content: {
    flex: '1',
    paddingTop: '2em',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textItem: {
    flex: '1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontWeight: '700',
  },
  questionText: {
    color: '#20516A',
    fontWeight: '700',
  },
  noteText: {
    color: '#000000',
    fontSize: '1.4em',
    paddingTop: '18px',
  },
  graph: {
    position: 'relative',
    width: '100%',
    minWidth: '90%',
    height: '50%',
    minHeight: '40%',
    [theme.breakpoints.down('sm')]: {
      minHeight: '160px',
      maxHeight: '160px',
    },
  },
  confirmation: {
    width: '100%',
    lineHeight: '1.75',
    color: '#F37421',
    fontWeight: '900',
    marginBottom: '1.875em',
    paddingTop: '0.5em',
    padding: '3em',
    [theme.breakpoints.down('sm')]: {
      padding: '1em',
    },
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'right',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
  },
  formInput: {
    flex: '1',
  },
  inputGrid: {
    paddingTop: '1.5em',
  },
  backdrop: {
    margin: '0 0 3.5em 4em',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.12)',
    zIndex: theme.zIndex.drawer + 1,
    color: '#0000006b',
  },
  dialogTitle: {
    color: '#F37421',
    fontWeight: '900',
  },
  dialogContentText: {
    color: '#F37421',
  },
  dialogButton: {
    maxWidth: '150px',
    margin: '1em',
  },
  phoneNumberText: {
    color: '#20516A',
    fontWeight: '550',
    fontSize: '1.5em',
    paddingBottom: '1em',
  },
  specificTimeWrapper: {
    minHeight: '54px',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      padding: '10px 10px 10px 0',
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px 0 0 0',
    },
  },
  specificTime: {
    marginRight: '10px',
    width: '144px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  specificTimeSelected: {
    marginRight: '10px',
    width: '144px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
});

const initState = {
  loading: true,
};

class Appointment extends Component {
  constructor(props) {
    super(props);

    this.state = initState;
  }

  componentDidMount() {
    this.setState({
      loading: false,
      type: globalBloc.fullScheduledAppointmentBooking(),
    });
  }

  render() {
    const { loading } = this.state;

    if (loading) return <></>;

    return (
      <Scheduled
        {...this.props}
        appointmentType={this.props.appointmentType}
        draftId={this.props.draftId}
        setLoading={this.props.setLoading}
        bloc={this.props.bloc}
      />
    );
  }
}

export default withStyles(styles)(withRouter(Appointment));
