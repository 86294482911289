import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';

import Loading from '../shared/Loading';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';
import { appointmentApi } from '../../utils/services/appointments.api';
import { Redirect, withRouter } from 'react-router-dom';
import { routeUtil, VIRTUAL_SCHEDULE } from '../../utils/route.name';
import { uriStorage } from '../../utils/storage';

const styles = (theme) => ({
  root: {
    overflow: 'auto',
    overscrollBehavior: 'contain',
    position: 'absolute',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    color: '#fff',
  },
});

class PaymentCallback extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    this.state = {
      loading: true,
      message: `Processing payment callback for ${params.vendor}.`,
      appointmentId: params.appointmentId,
      vendor: params.vendor,
      next: params.next,
    };

    setTimeout(() => {
      this._initialise(props, params);
    }, 1000);
  }

  _initialise = (props, params) => {
    const appointmentId = params.appointmentId;
    analyticsEventLogger.log(AnalyticsEvent.CALLBACK_INITIATED, {
      appointmentId: appointmentId,
      vendor: params.vendor,
    });
    appointmentApi.getAppointmentStatus(appointmentId).then(
      (appointmentValue) => {
        uriStorage.clearPath(true);

        this.setState({
          message: 'Checking in...',
          appointment: appointmentValue.data,
        });

        this.__recordTransaction(appointmentId).then(
          (value) => {
            this.setState({
              message: 'Checking in...',
            });

            this.__checkin(appointmentValue.data);
            analyticsEventLogger.log(AnalyticsEvent.APP_PAY_TRANS_RECORD);
          },
          (reason) => {
            this.setState({
              message: 'Checking in...',
            });

            this.__checkin(appointmentValue.data);
            analyticsEventLogger.log(AnalyticsEvent.APP_PAY_TRANS_RECORD_ERROR, { reason: reason });
          },
        );
      },
      (reason) => {
        this.setState({
          message: 'Retry...',
        });
        notificationService.error('Error processing callback response.');
      },
    );
  };

  __recordTransaction = (appointmentId) => {
    const {
      match: { params },
    } = this.props;
    const queryParams = new URLSearchParams(this.props.location.search);

    const request = {
      vendor: 'stripe',
      payload: {
        requestId: queryParams.get('payment_intent'),
        vendor: 'stripe',
        amount: queryParams.get('amount') || '0',
        reference: queryParams.get('payment_intent'),
        command: 'record_payment',
        detail: {
          appointment: appointmentId,
          vendor: params.vendor,
          reference: queryParams.get('payment_intent'),
          [params.vendor]: {
            paymentIntent: queryParams.get('payment_intent'),
          },
        },
      },
    };

    return appointmentApi.paymentCommand(appointmentId, request);
  };

  __checkin = (appointment) => {
    if (appointment.status === 'WAITING' || appointment.type === 'VIRTUAL') {
      this.setState({
        appointment: appointment,
        loading: false,
      });
      return;
    }

    appointmentApi.checkInAppointment(appointment.id).then(
      (checkinAppointment) => {
        uriStorage.clearPath(true);

        this.setState({
          appointment: appointment,
          loading: false,
        });
      },
      (reason) => {
        notificationService.error('Unable to checkin your appointment');
        this.setState({
          error: 'Unable to check in. Please go to the front desk.',
          appointment: appointment,
          loading: false,
        });
      },
    );
  };

  render() {
    let { classes } = this.props;

    let { loading, message, error, appointment } = this.state;

    return (
      <div className={classes.root}>
        {loading ? (
          <Loading message={message} />
        ) : (
          <Redirect to={this.__buildUrl(appointment, error)} />
        )}
      </div>
    );
  }

  __buildUrl = (appointment, error) => {
    if (appointment.type === 'VIRTUAL') {
      return `${routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
        appointment.id,
        VIRTUAL_SCHEDULE,
      )}?preserve=true`;
    }

    if (error) {
      return `${routeUtil.buildAppointmentCheckinException(appointment.id)}?preserve=true`;
    }

    return `${routeUtil.buildAppointmentCheckinConfirmation(appointment.id)}?preserve=true`;
  };
}

export default withStyles(styles)(withRouter(PaymentCallback));
