import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../../utils/events';
import BaseWidget from './BaseWidget';
import { TimeIntervalButton } from '../../components/TimeIntervals';

const styles = (theme) => ({
  root: {},
  dates: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '55vh',
    overflow: 'auto',
  },
  fill: {
    flex: '1 1 auto',
  },
  cardHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'auto',
    },
  },
  buttonHolder: {
    marginTop: '18px',
  },
  button: {
    paddingLeft: '0',
    paddingRight: '0',
    marginRight: '24px',
    marginBottom: '24px',
    width: '145px',
    maxWidth: '145px',
    borderRadius: '8px',
  },
  buttonIcon: {
    marginRight: '12px',
    color: '#ffffff',
  },
  unselectedButton: {
    backgroundColor: '#F6F6F6',
    paddingLeft: '0',
    paddingRight: '0',
    marginRight: '24px',
    marginBottom: '24px',
    width: '145px',
    maxWidth: '145px',
    borderRadius: '8px',
  },
  unselectedButtonIcon: {
    marginRight: '12px',
    color: '#F29202',
  },
  submitButton: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  progressHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'hidden',
    },
  },
  progress: {
    color: '#F29202',
  },
});

const reminderOptions = [
  {
    value: 15,
    label: '15 minutes',
  },
  {
    value: 60,
    label: '1 hour',
  },
  {
    value: 120,
    label: '2 hours',
  },
  {
    value: 240,
    label: '4 hours',
  },
];

class ReminderSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialising: true,
      selectedReminder: undefined,
    };

    this.__selectReminderTime = this.__selectReminderTime.bind(this);
  }

  componentDidMount() {
    this.stateSubscription = this.props.bloc.subscribeToState(this.__handleStateUpdate);
  }

  componentWillUnmount() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  __handleStateUpdate = (state) => {
    this.setState({
      ...state,
    });
  };

  __selectReminderTime = (_value) => {
    const $this = this;
    return () => {
      $this.setState({
        selectedReminder: _value,
      });
    };
  };
  __submit = () => {
    if (this.state.selectedReminder && !this.state.processing) {
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_REMINDER_SELECT, {});

      this.props.bloc.setReminderTime(this.state.selectedReminder);
      this.setState({
        processing: true,
      });
      this.timer = setTimeout(() => {
        this.setState({ processing: false });
      }, 5000);
    }
  };

  render() {
    const { classes, message, messageId } = this.props;

    const { initialising, processing, selectedReminder } = this.state;

    return (
      <BaseWidget
        initialising={initialising}
        processing={processing}
        submitEnabled={selectedReminder && !processing}
        submit={this.__submit}
        submitLabel={'SELECT'}
        message={message}
        messageId={messageId}
      >
        <Grid row xs={12} className={classes.cardHolder}>
          <div className={`${classes.dates}`}>
            {this.__renderReminders(classes, selectedReminder)}
          </div>
        </Grid>
      </BaseWidget>
    );
  }

  __renderReminders(classes, selectedValue) {
    return (
      <>
        {reminderOptions.map((_option) => (
          <TimeIntervalButton
            key={`btn-reminder-${_option.value}`}
            onClick={this.__selectReminderTime(_option.value)}
            isSelected={_option.value === selectedValue}
          >
            {_option.label}
          </TimeIntervalButton>
        ))}
      </>
    );
  }
}

export default withStyles(styles)(ReminderSelection);
