import * as React from "react";
import PageContainer from "../common/PageContainer";
import { Button, Grid, Link, Typography } from "@mui/material";
import ChatBubble from "../shared/ChatBubble";
import { makeStyles } from "@mui/styles";
import {
    DefaultDisconnectedPageFooter, DisconnectedPageFooter,
    ScrollableBox,
    ScrollableQuinnContainer,
    ScrollableWrapper
} from "../common/ScrollableContainer";
import {FormattedMessage} from "react-intl";
import {FormattedMarkdown} from "@decodedhealth/react-library";
import {useEffect} from "react";
import {uriStorage} from "../../utils/storage";
import {authService} from "../../utils/auth";
import {AnalyticsEvent, analyticsEventLogger} from "../../utils/events";
import { H1TextTitleSecondary } from '../shared/Typography';
import DecodedButton from "../common/DecodedButton";

const useStepStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '1 1 auto',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    skip: {
        color: theme.palette.primary.main,
        fontSize: '1.6rem',
    },
    skipButton: {
        color: theme.palette.accent.main,
        textDecoration: 'underline',
        fontSize: '1.6rem',
    },
    text: {
        marginTop: '24px',
        fontWeight: '700',
        fontSize: '4.2em',
        lineHeight: '1',
        paddingBottom: '3em',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '0em',
        },
    },
}));

const GridItem = (props) => <Grid {...props} item />;

export const IdentityVerificationEnd = ({}) => {

    const classes = useStepStyles();

    useEffect(() => {
        setTimeout(() => {
            uriStorage.clearPath();
            authService.logout();
        });

        setTimeout(() => {
            window.location.reload();
        }, 45000);

        analyticsEventLogger.log(AnalyticsEvent.CLINIC_POST_ENCOUNTER_OPEN);
    });

    return (
        <PageContainer loading={false} hideBack={true}>
            <Grid container direction="column" justifyContent="space-between" flex="1">
                <ScrollableQuinnContainer messageId="registration.scan.document.intro.walkin.nocard.end.bubble"
                                               message={`Please note, that your place in line is not yet reserved. Make sure to see the front desk to complete your checkin process.`}>

                        <H1TextTitleSecondary>
                            <FormattedMarkdown
                                id="registration.scan.document.intro.walkin.nocard.end.content"
                                defaultMessage="Thank you!"
                            />
                        </H1TextTitleSecondary>
                </ScrollableQuinnContainer>
                <DisconnectedPageFooter>
                    <DecodedButton
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        <FormattedMarkdown id={'window.restart'} defaultMessage={'RESTART'} />
                    </DecodedButton>
                </DisconnectedPageFooter>
            </Grid>
        </PageContainer>
    );
};
