const VIRTUAL_SCHEDULE = 'virtual-schedule';
const VIRTUAL_WALK_IN = 'virtual-walkin';
const IN_PERSON_SCHEDULE = 'inperson-schedule';
const IN_PERSON_WALK_IN = 'inperson-walkin';
const APPOINTMENT_SCHEDULE = 'appointment-schedule';
const QUINN_SCHEDULED = 'quinn-schedule';

// public routes
const INVALID_ROUTE = '/invalid';

const TEST = '/test';

const REGISTRATION_ROUTE = '/register';
const LOGIN_ROUTE = '/login';
const LINK_ROUTE = '/link';

const KIOSK_ROUTE = '/kiosk';

const KIOSK_ADMIN_ROUTE = '/kiosk-admin';

const LOCATION_LINK_ROUTE = '/link/loc/:providerId';

const QR_LINK_ROUTE = '/link/qr/:providerId/static';
const QR_LINK_WITH_TOKEN_ROUTE = '/link/qr/:providerId/:token';
const TELEHEALTH_LINK_ROUTE = '/link/appointment/:appointmentId/:userId/:password';
const CHECKIN_LINK_ROUTE = '/link/checkin/:appointmentId/:userId/:password';

const CALLBACK_ROUTE = '/callback';
const CALLBACK_PAYMENT_ROUTE = '/callback/payment/appointment/:appointmentId/:vendor/:next';

// Protected routes
const ROOT_ROUTE = '/';
const ACCOUNT_PAYMENT_METHOD_ROUTE = '/account';
const ACCOUNT_PAYMENT_METHOD_UPDATE_ROUTE = '/account/update';
const ACCOUNT_PAYMENT_DETAILS_ROUTE = '/account/details';
const ACCOUNT_PAYMENT_METHOD_INSURANCE_UPDATE_ROUTE = '/account/insurance/details';
const ACCOUNT_PAYMENT_METHOD_INSURANCE_SCAN_ROUTE = '/account/insurance/card';

const BOOKING_LIST_ROUTE = '/booking/list';
const BOOKING_CONFIRMATION_ROUTE = '/booking/walkin/confirmation/:appointmentId/:appointmentType';
const BOOKING_ROUTE = '/booking/schedule/:draftId/:appointmentType';

const BOOKING_PAYMENT_METHODS_REDIRECT_ROUTE =
  '/booking/payment_methods_redirect/:draftId/:appointmentType';
const BOOKING_PAYMENT_METHODS_ROUTE = '/booking/payment_methods/:draftId/:appointmentType';
const POST_BOOKING_ROUTE = '/booking/confirmation/:appointmentId/:appointmentType/end';
const BOOKING_STATUS_ROUTE = '/booking/status/:appointmentId';

const BOOKING_CHECKIN_ROUTE = '/booking/checkin/:appointmentId';

const BOOKING_PAYMENT_ROUTE = '/booking/payment/:appointmentId/:appointmentType';
const BOOKING_DETAILS_ROUTE = '/booking/details/:appointmentId/:appointmentType';
const BOOKING_RESCHEDULE_ROUTE =
  '/booking/reschedule/:appointmentId/draft/:draftId/:appointmentType';

const QUINN_ROUTE = '/conversation';
const QUINN_TERMINATE_ROUTE = '/conversation/post';

const USER_INFORMATION_ROUTE = '/user';

const VIRTUAL_CLINIC_ROUTE = '/virtual-clinic';
const VIRTUAL_CLINIC_POST_ENCOUNTER_ROUTE = '/virtual-clinic/post-encounter';
const VIRTUAL_CLINIC_WAITING_ROOM_ROUTE = '/virtual-clinic/waiting-room/:appointmentId';

const SCHEDULE_LINK_ROUTE = '/link/schedule/:appointmentId/:userId/:password';

const TELEHEALTH_LINK_VIRTUAL_CLINIC_WAITING_ROOM_ROUTE = '/link/waiting-room/:appointmentId';
const TELEHEALTH_LINK_ENCOUNTER_ROUTE = '/link/encounter/:encounterId';

const APPOINTMENT_CHECKIN_CONFIRMATION = '/appointment/checkin/:appointmentId/confirmation';

const APPOINTMENT_CHECKIN_EXCEPTION = '/appointment/checkin/:appointmentId/exception';

class RouteUtil {
  buildBookingConfirmationRouteWithAppointmentID = (appointmentId, appointmentType) => {
    return BOOKING_CONFIRMATION_ROUTE.replace(':appointmentId', appointmentId).replace(
      ':appointmentType',
      appointmentType,
    );
  };

  buildBookingPaymentMethodRedirectWithDraftAppointmentID = (draftId, appointmentType) => {
    return BOOKING_PAYMENT_METHODS_REDIRECT_ROUTE.replace(':draftId', draftId).replace(
      ':appointmentType',
      appointmentType,
    );
  };

  buildBookingPaymentMethodRouteWithDraftAppointmentID = (draftId, appointmentType) => {
    return BOOKING_PAYMENT_METHODS_ROUTE.replace(':draftId', draftId).replace(
      ':appointmentType',
      appointmentType,
    );
  };

  buildBookingRouteWithDraftAppointmentID = (draftId, appointmentType) => {
    return BOOKING_ROUTE.replace(':draftId', draftId).replace(':appointmentType', appointmentType);
  };

  buildPostBookingConfirmationRouteWithAppointmentID = (appointmentId, appointmentType) => {
    return POST_BOOKING_ROUTE.replace(':appointmentId', appointmentId).replace(
      ':appointmentType',
      appointmentType,
    );
  };
  buildBookingPaymentRouteWithAppointmentID = (appointmentId, appointmentType) => {
    return BOOKING_PAYMENT_ROUTE.replace(':appointmentId', appointmentId).replace(
      ':appointmentType',
      appointmentType,
    );
  };

  buildVirtualWaitingRoomRouteWithAppointmentID = (appointmentId) => {
    return VIRTUAL_CLINIC_WAITING_ROOM_ROUTE.replace(':appointmentId', appointmentId);
  };

  buildBookingStatusRouteWithAppointmentID = (appointmentId) => {
    return BOOKING_STATUS_ROUTE.replace(':appointmentId', appointmentId);
  };
  buildBookingCheckinRouteWithAppointmentID = (appointmentId) => {
    return BOOKING_CHECKIN_ROUTE.replace(':appointmentId', appointmentId);
  };

  buildBookingDetailsRouteWithAppointmentID = (appointmentId, appointmentType) => {
    return BOOKING_DETAILS_ROUTE.replace(':appointmentId', appointmentId).replace(
      ':appointmentType',
      appointmentType,
    );
  };

  buildBookingRescheduleRoute = (appointmentId, draftId, appointmentType) => {
    return BOOKING_RESCHEDULE_ROUTE.replace(':appointmentId', appointmentId)
      .replace(':draftId', draftId)
      .replace(':appointmentType', appointmentType);
  };

  buildAppointmentCheckinConfirmation = (appointmentId) => {
    return APPOINTMENT_CHECKIN_CONFIRMATION.replace(':appointmentId', appointmentId);
  };

  buildAppointmentCheckinException = (appointmentId) => {
    return APPOINTMENT_CHECKIN_EXCEPTION.replace(':appointmentId', appointmentId);
  };
}

export const routeUtil = new RouteUtil();

export {
  ROOT_ROUTE,
  KIOSK_ROUTE,
  KIOSK_ADMIN_ROUTE,
  INVALID_ROUTE,
  REGISTRATION_ROUTE,
  LOGIN_ROUTE,
  LINK_ROUTE,
  LOCATION_LINK_ROUTE,
  QR_LINK_ROUTE,
  QR_LINK_WITH_TOKEN_ROUTE,
  SCHEDULE_LINK_ROUTE,
  TELEHEALTH_LINK_ROUTE,
  CHECKIN_LINK_ROUTE,
  CALLBACK_ROUTE,
  CALLBACK_PAYMENT_ROUTE,
  ACCOUNT_PAYMENT_METHOD_ROUTE,
  ACCOUNT_PAYMENT_DETAILS_ROUTE,
  ACCOUNT_PAYMENT_METHOD_UPDATE_ROUTE,
  ACCOUNT_PAYMENT_METHOD_INSURANCE_UPDATE_ROUTE,
  ACCOUNT_PAYMENT_METHOD_INSURANCE_SCAN_ROUTE,
  BOOKING_LIST_ROUTE,
  BOOKING_ROUTE,
  BOOKING_CONFIRMATION_ROUTE,
  POST_BOOKING_ROUTE,
  BOOKING_STATUS_ROUTE,
  BOOKING_CHECKIN_ROUTE,
  BOOKING_PAYMENT_METHODS_REDIRECT_ROUTE,
  BOOKING_PAYMENT_METHODS_ROUTE,
  BOOKING_PAYMENT_ROUTE,
  BOOKING_DETAILS_ROUTE,
  BOOKING_RESCHEDULE_ROUTE,
  QUINN_ROUTE,
  QUINN_TERMINATE_ROUTE,
  USER_INFORMATION_ROUTE,
  VIRTUAL_CLINIC_ROUTE,
  VIRTUAL_CLINIC_WAITING_ROOM_ROUTE,
  VIRTUAL_CLINIC_POST_ENCOUNTER_ROUTE,
  TELEHEALTH_LINK_VIRTUAL_CLINIC_WAITING_ROOM_ROUTE,
  TELEHEALTH_LINK_ENCOUNTER_ROUTE,
  APPOINTMENT_SCHEDULE,
  VIRTUAL_SCHEDULE,
  VIRTUAL_WALK_IN,
  IN_PERSON_SCHEDULE,
  IN_PERSON_WALK_IN,
  QUINN_SCHEDULED,
  APPOINTMENT_CHECKIN_CONFIRMATION,
  APPOINTMENT_CHECKIN_EXCEPTION,
  TEST,
};
