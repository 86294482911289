import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, DialogActions, DialogContent, DialogContentText, Grid, styled } from '@mui/material';
import BackButton from './BackButton';
import {getLogoBasedOnTenant, getMobileLogoBasedOnTenant} from '../../utils/logo';

import {PortalContainer, useIsMobile} from '../../shared-library';
import {providerStorage} from "../../utils/provider.qs";
import LeaveButton from "../../shared-library/components/LeaveButton";
import {uriStorage} from "../../utils/storage";
import {authService} from "../../utils/auth";
import { DecodedDialog, DecodedDialogTitle } from '../shared/Dialog/Dialog';
import DecodedButton, { DecodedSecondaryButton } from './DecodedButton';
import { FormattedMessage } from 'react-intl';

const isKiosk = providerStorage.isKiosk()

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        position: 'fixed',
        top: '0px',
        width: '100%',
        zIndex: '1500',
    },
}))(LinearProgress);

const WrapperMobileToolGridItem = styled(Grid)(({theme}) => ({
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: '1em',
    paddingBottom: '1em',
    backgroundColor: 'rgba(255,255,255,0.7)',
}));

const MobileToolbar = ({onBack, backButtonIcon}) => {
    return (
        <WrapperMobileToolGridItem item container pb="2em">
            <Grid xs={12} sx={{display: "flex"}} item>
                {onBack && (
                    <BackButton
                        onClick={onBack}
                        icon={backButtonIcon}
                        sx={{maxHeight: "42px"}}
                    />
                )}
                <Box sx={{flex: "1", display: "flex"}}></Box>
                <img src={getMobileLogoBasedOnTenant()} alt="Logo"/>
            </Grid>
        </WrapperMobileToolGridItem>
    );
};

const WrapperDesktopToolGridItem = styled(Grid)(({theme}) => ({
    paddingLeft: '13em',
    paddingRight: '13em',
    paddingTop: '3em',
    paddingBottom: '3em',
    backgroundColor: 'rgba(255,255,255,0.7)',
    [theme.breakpoints.down('md')]: {
        paddingLeft: '3em',
        paddingRight: '3em',
    },
}));

const handleLeave = () => {
    authService.logout()
    uriStorage.clearPath()
    window.location.href = '/';
}

const DesktopToolbar = ({onBack, backButtonIcon}) => {
  const [open, setOpen] = useState(false);

  return (
    <WrapperDesktopToolGridItem item container pb="2em">
      <Grid xs={12} sx={{display: "flex"}} item>
        {onBack && (
          <BackButton
            onClick={onBack}
            icon={backButtonIcon}
            sx={{maxHeight: "42px"}}
          />
        )}
        <Box sx={{flex: "1", display: "flex"}}></Box>
        {isKiosk ? (
          <LeaveButton onClick={() => setOpen(true)}/>
        ) : (
          <img src={getLogoBasedOnTenant()} alt="Logo"/>
        )}
      </Grid>
      <DecodedDialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DecodedDialogTitle id="alert-dialog-title">
          <FormattedMessage
            id="kiosk.restart.title"
            description="Restart kiosk session"
            defaultMessage="Restart?"
          />
        </DecodedDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage
              id="kiosk.restart.message"
              description="Confirm kiosk restart"
              defaultMessage="Are you sure you want to restart?"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DecodedSecondaryButton onClick={() => setOpen(false)}>
            <FormattedMessage
              id="common.no"
              description="No"
              defaultMessage="No"
            />
          </DecodedSecondaryButton>
          <DecodedButton
            onClick={handleLeave}
            autoFocus
          >
            <FormattedMessage
              id="common.yes"
              description="Yes"
              defaultMessage="Yes"
            />
          </DecodedButton>
        </DialogActions>
      </DecodedDialog>
    </WrapperDesktopToolGridItem>);
};

const WrapperGrid = styled(Grid)(({theme}) => ({
    flex: '1',
    marginRight: 'auto',
    padding: '0em',
    marginLeft: '0em',
    maxWidth: '100vw',
    height: 'inherit',
    [theme.breakpoints.down('md')]: {
        marginLeft: '0em',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '0em',
        paddingBottom: '8px',
    },
    [theme.breakpoints.down('xs')]: {
        marginLeft: '0em',
        paddingBottom: '8px',
    },
}));

const WrapperGridItem = styled(Grid)(({theme}) => ({
    padding: '0 0 0 0',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
        padding: '0 0 0 0',
    },
}));

const PageContainerBackButton = ({onBack, backButtonIcon}) => {

    const isMobile = useIsMobile();

    return (<>
        {isMobile ? (
            <MobileToolbar onBack={onBack} backButtonIcon={backButtonIcon}/>
        ) : (
            <DesktopToolbar onBack={onBack} backButtonIcon={backButtonIcon}/>
        )}
    </>);
}

const PageContainer = ({children, noPadding, onBack, backButtonIcon, loading, hideBack}) => {

    if (hideBack) {

        return (
            <>
                <PortalContainer/>
                <WrapperGrid container direction="column" justifyContent="flex-start">
                    {loading && <BorderLinearProgress/>}
                    <PageContainerBackButton onBack={onBack} backButtonIcon={backButtonIcon}/>
                    <WrapperGridItem item container direction="column" flex="1" sx={(theme) => {
                        if (noPadding) {
                            return {padding: "0 !important"};
                        }
                        return {};
                    }}>
                        {children}
                    </WrapperGridItem>
                </WrapperGrid>
            </>
        );
    }

    return (
        <>
            <PortalContainer/>
            <WrapperGrid container direction="column" justifyContent="flex-start">
                {loading && <BorderLinearProgress/>}
                <PageContainerBackButton onBack={onBack} backButtonIcon={backButtonIcon}/>
                <WrapperGridItem item container direction="column" flex="1" sx={(theme) => {
                    if (noPadding) {
                        return {padding: "0 !important"};
                    }
                    return {};
                }}>
                    {children}
                </WrapperGridItem>
            </WrapperGrid>
        </>
    );
};

export default PageContainer;
