import React from 'react';
import DecodedComponent from '../shared/DecodedComponent';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Loading } from '../../shared-library';
import PageContainer from '../common/PageContainer';
import { Bloc, BlocEvent } from './bloc';
import { withRouter } from 'react-router-dom';
import { ScrollableQuinnContainer } from '../common/ScrollableContainer';
import { uriStorage } from '../../utils/storage';
import { accountApi } from '../../utils/services/account.api';
import { appointmentApi } from '../../utils/services/appointments.api';
import { unblockBack } from '../../utils/nav';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

const initState = {
  loading: false,
};

class Payment extends DecodedComponent {
  constructor(props) {
    super(props);

    const { appointmentId, appointmentType } = props.match.params;

    this.state = { ...initState };

    this.bloc = new Bloc({
      appointmentId,
      appointmentType,
      accountApi: accountApi,
      appointmentApi: appointmentApi,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    uriStorage.setCurrentPath(this.props.match.url);
  }

  __handleEvent = (event) => {
    if (event.type === BlocEvent.NAVIGATE_TO) {
      this.setState({
        complete: true,
      });

      setTimeout(() => {
        this.props.history.push(event.url);
      }, 200);
    }
  };

  render() {
    const { history } = this.props;
    const { component, message } = this.state;

    if (component) {
      return component;
    }
    if (message) {
      return (
        <PageContainer
          loading={false}
          onBack={() => {
            history.goBack();
          }}
        >
          <ScrollableQuinnContainer
            messageId="payment.finalising.bubble"
            message="Finalising account."
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Loading isFullscreen={false} />
              </Grid>
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          history.goBack();
        }}
      >
        <ScrollableQuinnContainer
          messageId="payment.loading.bubble"
          message="Please wait while I check your account for any required payments."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Loading isFullscreen={false} />
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(Payment));
