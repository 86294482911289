import * as rxjs from 'rxjs';
import { systemApi } from '../utils/services/system.api';
import { notificationService } from '../utils/notification';
import { AnalyticsEvent, analyticsEventLogger } from '../utils/events';
import { providerStorage } from '../utils/provider.qs';

export class GlobalBloc {
  constructor() {
    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      systemProperties: [],
      providerId: undefined,
    });

    this.events = new rxjs.Subject();
  }

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  initialise = () => {
    this.__loadProperties();
  };

  makeInitialised = () => {
    this.subject.next({
      ...this.subject.value,
      initialising: false,
    });
  };

  __loadProperties = () => {
    systemApi.systemProperties().then(
      (value) => {
        analyticsEventLogger.log(AnalyticsEvent.SYSTEM_PROPERTIES_LOADED_SUCCESS);

        const systemProperties = value.data.items;
        this.subject.next({
          ...this.subject.value,
          initialising: false,
          systemProperties: systemProperties,
        });
      },
      (reason) => {
        setTimeout(() => {
          this.__loadProperties();
        }, 10000);

        analyticsEventLogger.log(AnalyticsEvent.SYSTEM_PROPERTIES_LOADED_ERROR, {
          reason: reason,
        });
        notificationService.error('Unable to load system properties. Please retry later.');
      },
    );
  };

  setPreferredLocation = (preferredLocationId) => {
    analyticsEventLogger.log(AnalyticsEvent.SESSION_SET, {
      place: 'global',
      preferredLocationId: preferredLocationId,
    });
    providerStorage.setPreferredLocation(preferredLocationId);
  };

  getPreferredLocation = () => {
    return providerStorage.preferredLocation();
  };

  setProvider = (providerId) => {
    analyticsEventLogger.log(AnalyticsEvent.SESSION_SET, {
      place: 'global',
      providerId: providerId,
    });
    this.__updateSubject({ providerId: providerId });
    providerStorage.setProviderFromLink(providerId);
  };

  isKiosk = () => {
    const provider = providerStorage.getPersistedProvider();
    return provider?.length > 0;
  };

  isWalkin = () => {
    const provider = this.getCurrentProvider();
    return provider?.length > 0;
  };

  ensureProvider = () => {
    const globalProvider = globalBloc.getCurrentProvider();

    if (
      globalProvider !== undefined &&
      globalProvider !== '' &&
      globalProvider !== providerStorage.getCurrentProvider()
    ) {
      analyticsEventLogger.log(AnalyticsEvent.SESSION_SET, {
        place: 'ensure',
        providerId: globalProvider,
      });

      providerStorage.setProviderFromLink(globalProvider);
    }
  };

  getCurrentProvider = () => {
    if (this.isKiosk()) {
      return providerStorage.getPersistedProvider();
    }
    const { providerId } = this.subject.value;
    return providerId || providerStorage.getCurrentProvider();
  };

  isTelehealth = () => {
    const { systemProperties } = this.subject.value;
    let result = false;
    if (systemProperties && systemProperties.length > 0) {
      const telehealth = systemProperties.find((prop) => prop.code === 'telehealth.organisations');
      if (!telehealth) {
        return result;
      }

      result = telehealth.status === this.getPreferredLocation();
    }

    return result;
  };

  quinnChat = () => {
    const values = this.subject.value.systemProperties.filter(
      (_property) => _property.code === 'quinn.chat',
    );
    if (values.length === 0) {
      return true;
    }

    return values[0].status === '1';
  };

  fullScheduledAppointmentBooking = () => {
    const values = this.subject.value.systemProperties.filter(
      (_property) => _property.code === 'interaction.appointment.schedule.type',
    );
    if (values.length === 0) {
      return 'quick';
    }

    return values[0].status;
  };

  objective = () => {
    return this.subject.value.objective;
  };

  clearSession = () => {
    providerStorage.clearProvider();
  };
}

export class GlobalBlocEvent {}

export const globalBloc = new GlobalBloc();
