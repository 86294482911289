import axios from 'axios';
import retry from '../retry';

class ExtensionApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
  }

  command(extension, payload) {
    const request = {
      extension: extension,
      payload: payload,
    };
    return retry(() => axios.post(`${this.baseUrl}/api/ext/_command`, request));
  }
}

export const extensionApi = new ExtensionApi();
