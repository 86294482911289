import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { dateUtil } from '../../../../../utils/date';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../../utils/events';
import BaseWidget from './BaseWidget';
import { RescheduleBookingContext } from '../../bloc';
import { CalendarPicker as MuiCalenderPicker, LocalizationProvider } from '@mui/x-date-pickers';

const styles = (theme) => ({
  root: {
    fontSize: '12px',
  },
  fill: {
    flex: '1 1 auto',
  },
  cardHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'auto',
    },
  },
  buttonHolder: {
    marginTop: '18px',
  },
  button: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  progressHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'hidden',
    },
  },
  progress: {
    color: '#F29202',
  },
});

const CalendarPicker = styled(MuiCalenderPicker)(({ theme }) => {
  const selectedDateStyles = {
    backgroundColor: theme.palette.accent.main,
    color: '#FFFFFF',
  };
  return {
    '& div[role="presentation"]': {
      fontSize: '20px',
    },
    '& .MuiCalendarPicker-viewTransitionContainer': {
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiPickersDay-root': {
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        '&:active': selectedDateStyles,
      },
      '& .MuiPickersDay-today': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '& .Mui-selected': {
        '&:hover': selectedDateStyles,
        '&:active': selectedDateStyles,
        '&:focus': selectedDateStyles,
        ...selectedDateStyles,
      },
    },
  };
});

class DateSelection extends Component {
  timer;

  constructor(props) {
    super(props);

    const now = new Date();

    this.state = {
      initialising: true,
      max: new Date(new Date().setDate(now.getDate() + 54)),
      selectedDate: new Date(),
      processing: false,
    };

    this.stateSubscription = this.props.bloc.subscribeToState(this.__handleStateUpdate);
  }

  componentDidMount() {
    this.props.bloc.loadServiceSchedule();
  }

  componentWillUnmount() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentDidUpdate() {
    if (this.state.selectedDate === null) {
      this.setState((prevState) => ({
        ...prevState,
        selectedDate:
          this.context && this.context.start ? new Date(this.context.start) : new Date(),
      }));
    }
  }

  __handleStateUpdate = (state) => {
    const selectedDate = state.booking.selectedDate || this.state.selectedDate;

    this.setState({
      ...state,
      selectedDate: selectedDate,
    });
  };

  __onDateSelected = (date) => {
    this.setState({
      selectedDate: date,
    });
  };

  __submit = () => {
    if (this.state.selectedDate && !this.state.processing) {
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_SELECT, {});

      this.props.bloc.setSelectedDate(this.state.selectedDate);
      this.setState({
        processing: true,
      });
      this.timer = setTimeout(() => {
        this.setState({ processing: false });
      }, 5000);
    }
  };

  render() {
    const { classes, message, messageId } = this.props;

    const { initialising, max, calendarSummary, selectedDate } = this.state;

    // const date = selectedDate ?? (appointment && appointment.start ? new Date(appointment.start) : new Date())

    return (
      <BaseWidget
        initialising={initialising}
        processing={false}
        submitEnabled={selectedDate}
        submit={this.__submit}
        submitLabel={'SELECT'}
        message={message}
        messageId={messageId}
      >
        {/*<Grid item row xs={12} className={classes.cardHolder}>*/}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {/*<div className={`${classes.root}`}>*/}
          <CalendarPicker
            sx={{ margin: '0' }}
            openTo="day"
            disablePast={true}
            views={['day']}
            date={selectedDate}
            onChange={this.__onDateSelected}
            shouldDisableDate={(day) => {
              const date = dateUtil.formatIsoDateAdjust(day);

              if (day > max) return false;

              let capacity = 0;
              if (calendarSummary && calendarSummary.slots) {
                const summaryInformation = calendarSummary.slots.filter(
                  (_item) => _item.date === date,
                );

                if (summaryInformation.length > 0) {
                  capacity = summaryInformation[0].capacity;
                }
              }

              return capacity === 0;
            }}
          />
          {/*</div>*/}
        </LocalizationProvider>
        {/*</Grid>*/}
      </BaseWidget>
    );
  }
}

DateSelection.contextType = RescheduleBookingContext;

export default withStyles(styles)(DateSelection);
