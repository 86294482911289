import * as rxjs from 'rxjs';
import { assistantApi } from '../../../utils/services/assistant.api';
import { notificationService } from '../../../utils/notification';
import { QUINN_SCHEDULED, QUINN_TERMINATE_ROUTE, routeUtil } from '../../../utils/route.name';
import { userAnalyticData } from '../../../utils/userAnalytics/userAnalyticData';

export class AssistantBloc {
  timeout;
  constructor(chatRef) {
    this.chatRef = chatRef;

    this.subject = new rxjs.BehaviorSubject({
      locale: 'en',
      chatAvailable: false,
      initialised: false,
    });

    this.events = new rxjs.Subject();
  }

  __updateSubject = (newProps) => {
    this.subject.next({
      ...this.subject.value,
      ...newProps,
    });
  };

  setChatRef = (ref) => {
    this.chatRef = ref;
  };

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    assistantApi
      .generateToken()
      .then((value) => {
        this.subject.next({
          ...this.subject.value,
          token: value.data.token,
          initialised: true,
        });
      })
      .catch((reason) => notificationService.error('Error generating token for assistant.'));
  };

  handleBotEvent = (event) => {
    let { chatAvailable } = this.subject.value;

    if (event.attachment) {
      if (event.attachment.type === 'dh_card') {
        this.disableChat();
      } else if (event.attachment.type === 'dh_event') {
        const payload = event.attachment.payload;

        userAnalyticData({
          appointmentId: payload.id,
        });

        if (payload.type === 'schedule.appointment.drafted') {
          this.disableChat();

          this.events.next({
            type: AssistantBlocEvent.NAVIGATE_TO,
            url: routeUtil.buildBookingPaymentMethodRedirectWithDraftAppointmentID(
              payload.id,
              QUINN_SCHEDULED,
            ),
          });
        } else if (payload.type === 'quinn.conversation.terminated') {
          this.disableChat();
          this.__redirectToThankyou();
        }
      }
    } else {
      this.enableChat();
    }

    if (event.text === 'TERMINATE_CONVERSATION|') {
      this.disableChat();
      this.__redirectToThankyou();
    } else if (!chatAvailable) {
      this.__updateSubject({ chatAvailable: true });
    }
  };

  __redirectToThankyou = () => {
    setTimeout(() => {
      this.events.next({
        type: AssistantBlocEvent.NAVIGATE_TO,
        url: QUINN_TERMINATE_ROUTE,
      });
    }, 2000);
  };

  quickTextReply = (reply, text) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.chatRef.current.sendMessage(reply, text);
    }
  };

  disableChat = () => {
    this.__updateSubject({ chatInputEnabled: false });
  };

  enableChat = () => {
    this.__updateSubject({ chatInputEnabled: true });
    clearTimeout(this.timeout);
  };

  sendCommand = (command) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.chatRef.current.sendMessage(command);
    }
  };
  sendMessage = (text) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.disableChat();
      this.chatRef.current.sendMessage(text, text);
    }
  };

  sendMessageWithReply = (text, reply) => {
    if (this.chatRef.current && this.chatRef.current.sendMessage) {
      this.disableChat();
      this.chatRef.current.sendMessage(text, reply);
    }
  };
}

export class AssistantBlocEvent {
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
