import * as React from 'react';
import PageContainer from "../../common/PageContainer";
import {
  Button,
  Grid,
  styled,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useUserSummary } from '../../../utils/services/consumers.api';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import {ScrollableBox, ScrollableQuinnContainer, DefaultDisconnectedPageFooter, ScrollableWrapper} from "../../common/ScrollableContainer";
import DecodedButton from "../../common/DecodedButton";
import { H2TextTitleContent } from '../../shared/Typography';

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  width: '50%',
  fontSize: '14px',
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  '&.Mui-selected': {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const ToggleButtonLeft = styled(ToggleButton)({
  borderTopLeftRadius: '12px',
  borderBottomLeftRadius: '12px'
});
const ToggleButtonRight = styled(ToggleButton)({
  borderTopRightRadius: '12px',
  borderBottomRightRadius: '12px'
});

const ToggleButtonGroup = styled(MuiToggleButtonGroup)({
  width: '100%',
});

const usePreScanStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1 1 auto',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  skip: {
    color: theme.palette.primary.main,
    fontSize: '1.6rem',
  },
  skipButton: {
    textDecoration: 'underline',
    fontSize: '1.6rem',
  },
}));

export const PreScan = ({ handleBack, onNext, userFirstName, isPatientMyself, isFirstTimeVisit, onChange }) => {
  const classes = usePreScanStyles();
  const user = useUserSummary();
  const handlePatientMySelfChange = (_, val) => {
    onChange({ isPatientMyself: val });
  };
  const handleFirstTimeVisitChange = (_, val) => {
    onChange({ isFirstTimeVisit: val });
  };
  return (
    <PageContainer loading={false} onBack={handleBack}>
      <ScrollableQuinnContainer messageId="registration.scan.document.intro"
                                message={`Thank you${
                                    user ? ` ${user.name.given}` : ` ${userFirstName}`
                                }, In just a few steps, we can check you in and complete your registration for today visit.`}>
        <div>
            <H2TextTitleContent>
              <FormattedMarkdown
                  id="registeration.preScan.question"
                  defaultMessage="Are you booking this visit yourself or is someone else booking it on our behalf, like your partner, your child etc.?"
              />
            </H2TextTitleContent>
            <ToggleButtonGroup
                value={isPatientMyself}
                color="primary"
                exclusive
                onChange={handlePatientMySelfChange}
            >
              <ToggleButtonLeft value="true">ME</ToggleButtonLeft>
              <ToggleButtonRight value="false">SOMEONE ELSE</ToggleButtonRight>
            </ToggleButtonGroup>
          </div>

        </ScrollableQuinnContainer>
      <DefaultDisconnectedPageFooter justifyContent={"left"}>
        <Grid xs={12} sm={6} item>
          <DecodedButton onClick={onNext}>NEXT</DecodedButton>
        </Grid>
      </DefaultDisconnectedPageFooter>
    </PageContainer>
  );
};
