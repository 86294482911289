import * as React from 'react';
import { AddressForm, FormattedMarkdown } from '@decodedhealth/react-library';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PageContainer from '../common/PageContainer';
import ChatBubble from '../shared/ChatBubble';
import {
  ScrollableBox,
  ScrollableQuinnContainer,
  ScrollableWrapper,
} from '../common/ScrollableContainer';

const useStyles = makeStyles({
  title: {
    paddingTop: '1em',
    color: '#FF7700',
  },
});

const Address = ({
  isLoading,
  doSubmit,
  handleBack,
  additionalFields,
  code,
  updateAddressChange,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <PageContainer loading={isLoading} onBack={handleBack}>
      <ScrollableQuinnContainer
        messageId="registration.user.bubble.two"
        message={'Please enter your home address.'}
      >
        <AddressForm
          enableLine2OptionalField
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          updateAddressData={updateAddressChange}
          addressDefaultvalue={{
            addressLine1: rest.addressLine1,
            addressLine2: rest.addressLine2,
            addressAdministrativeArea: rest.addressAdministrativeArea,
            addressCity: rest.addressCity,
            addressPostcode: rest.addressPostcode,
            addressCountry: rest.addressCountry,
          }}
          heading={
            <Typography variant="body1" component={'div'} className={classes.title}>
              <FormattedMarkdown
                id="registration.user.step.two"
                defaultMessage="2 of 5 Personal Information - Address"
              />
            </Typography>
          }
          additionalFields={additionalFields}
          isLoading={isLoading}
          doSubmit={doSubmit}
        />
      </ScrollableQuinnContainer>
    </PageContainer>
  );
};

export default Address;
