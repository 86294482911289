import React, { Component } from 'react';

import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { authService } from '../../../utils/auth';
import { notificationService } from '../../../utils/notification';
import { Redirect } from 'react-router-dom';
import { Grid, InputLabel, TextField } from '@mui/material';
import { ROOT_ROUTE } from '../../../utils/route.name';
import { PinCodeMask } from '../../shared/InputTextMask';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { errorResolver } from '../../../utils/error.resolver';
import { userInfoUtil } from '../../../utils/user';
import { phoneUtil } from '../../../utils/phone';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { buttonGroupStyle } from '../../shared/CommonStyle';
import PageContainer from '../../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import { H2TextTitleContent } from '../../shared/Typography';
import DecodedButton, {
  DecodedSecondaryButton,
  DecodedSubmitButton,
} from '../../common/DecodedButton';
import { providerStorage } from '../../../utils/provider.qs';

const styles = (theme) => ({
  ...buttonGroupStyle(theme),
  validation: {
    flex: '1',
    width: '100%',
    paddingTop: '4em',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2em',
    },
  },
  note: {
    width: '100%',
    lineHeight: '1.75',
    color: '#575757',
    paddingTop: '0.5em',
    textAlign: 'left',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
    justifyContent: 'space-between',
    paddingTop: '1.5em',
  },
  formInput: {
    paddingBottom: '2em',
  },
  textInput: {
    fontSize: '2.6em',
  },
  buttonGroup: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    flex: '1 1 1',
    padding: '1em',
    textAlign: 'right',
  },
  buttonGridItem: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: '0',
      textAlign: 'center',
    },
  },
});

const initState = {
  verificationSuccess: false,
  pin: '',
};

class PinCode extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initState, isWalkin: providerStorage.isWalkin() };
  }

  handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  doSubmit = () => {
    let { setLoadingStatus } = this.props;

    if (this.state.pin.length !== 6) {
      notificationService.error('Please enter a valid six digit pin code');
      return;
    }

    setLoadingStatus(true);

    authService
      .confirmVerificationCode(this._createAuthRequest())
      .then((token) => {
        authService
          .loginWithToken(token)
          .then((user) => {
            analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_SUCCESS);

            this.setState({
              verificationSuccess: true,
            });

            analyticsEventLogger.setUser(user.uid);
          })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
              reason: `${error}`,
            });
            notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
            setLoadingStatus(false);
          });
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
          reason: `${error}`,
        });
        setLoadingStatus(false);
        notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
      })
      .finally(() => {
        sessionStorage.removeItem('currentPath');
      });
  };

  _createAuthRequest = () => {
    let { email, firstName, lastName, dateOfBirth, number, code, codeCountry } = this.props;
    return {
      pin: this.state.pin,
      name: {
        given: firstName,
        family: lastName,
      },
      dob: userInfoUtil.formatDate(dateOfBirth),
      number: number ? phoneUtil.formatPhoneNumberForRegistration(number, code, codeCountry) : null,
      email: email,
    };
  };

  backToLogin = () => {
    let { handleBack } = this.props;
    handleBack();
  };

  isLoading = () => {
    return this.props.loading || authService.isLoading();
  };

  render() {
    let { pin, verificationSuccess, isWalkin } = this.state;

    let { classes, loading, firstName, lastName, dateOfBirth, email, number, code, codeCountry } =
      this.props;

    if (verificationSuccess || this.props.verificationSuccess) {
      return (
        <Redirect
          to={{
            pathname: ROOT_ROUTE,
            state: {
              firstName,
              lastName,
              dateOfBirth,
              email,
              number,
              code,
              codeCountry,
              addressCountry: codeCountry,
            },
          }}
        />
      );
    }

    return (
      <PageContainer loading={loading}>
        <ScrollableQuinnContainer
          messageId={'login.pincode.quinn.bubble'}
          message={
            'A 6-digit pin code has been sent to your mobile phone and email address (if one was added).'
          }
          applyFormat={true}
        >
          <H2TextTitleContent>
            <FormattedMarkdown
              id="login.pincode.text"
              defaultMessage={
                'Please check your mobile phone or email, enter the pin code, and click on the "Verify" button.'
              }
            />
          </H2TextTitleContent>

          <ValidatorForm onSubmit={this.doSubmit} className={classes.validation}>
            <div className={classes.form}>
              <div className={classes.formInput}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      fullWidth
                      id="pin"
                      name="pin"
                      autoComplete="one-time-code"
                      inputMode={'numeric'}
                      label={
                        <FormattedMessage
                          id="login.pin.field.label.pin"
                          defaultMessage={'Six Digit Pin Code'}
                        />
                      }
                      value={pin}
                      onChange={this.handleTextChange}
                      variant="standard"
                      inputProps={{
                        style: { fontSize: '2em' },
                        maxLength: 6,
                        minLength: 6,
                        pattern: '[0-9]{6,6}',
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.note}>
                      <H2TextTitleContent>
                        <FormattedMarkdown
                          id={`login.pin${isWalkin ? '.walkin' : ''}.note`}
                          defaultMessage={
                            isWalkin
                              ? `If you do not receive a pin code, please see the front desk.`
                              : `If you do not receive a pin code, please phone the clinic.`
                          }
                        />
                      </H2TextTitleContent>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={2} className={classes.buttonGroup}>
                <Grid item xs={12} sm={6} className={classes.buttonGridItem}>
                  <DecodedSecondaryButton disabled={this.isLoading()} onClick={this.backToLogin}>
                    <FormattedMessage
                      id="login.pin.button.resend"
                      defaultMessage="RESEND PIN-CODE"
                    />
                  </DecodedSecondaryButton>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.buttonGridItem}>
                  <DecodedSubmitButton
                    id={'submit-pin-button'}
                    disabled={this.isLoading()}
                    loading={this.isLoading()}
                  >
                    <FormattedMessage id="login.pin.button.submit" defaultMessage="VERIFY" />
                  </DecodedSubmitButton>
                </Grid>
              </Grid>
            </div>
          </ValidatorForm>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(PinCode);
