import * as React from 'react';
import { Wizard, Loading } from '../../shared-library';
import { useBookingBloc } from './bloc';
import BookingWizard from './BookingWizard/BookingWizard';
import { QUINN_SCHEDULED, routeUtil } from '../../utils/route.name';
import {uriStorage} from "../../utils/storage";
import {serviceUtil} from "../../utils/service";

/**
 * steps : {
 *  id: string,
 *  message: {
 *    id: 'booking.appointment',
 *    text: ''
 *  }
 *  component: <Component />,
 *  onBack: () => {} // first step back
 *  onSubmit: () => {} // last step submit
 * }
 */
export const Booking = ({ match, history }) => {
  const { draftId, appointmentType } = match.params;
  const bookingState = useBookingBloc(draftId, appointmentType, history);

  React.useEffect(() => {
    uriStorage.setCurrentPath(match.url);
  }, [])

  const handleBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSubmit = React.useCallback(async () => {
    await bookingState.bookingBloc.submit();
  }, [bookingState.bookingBloc, draftId, history]);

  if(!bookingState?.steps) {
    return (<Loading isFullscreen />);
  }

  return (
    <Wizard steps={bookingState.steps}>
      {bookingState.steps && bookingState.appointmentType ? (
        <BookingWizard  bookingState={bookingState} onBack={handleBack} onSubmit={handleSubmit} />
      ) : (
        <Loading isFullscreen />
      )}
    </Wizard>
  );
};
