import * as rxjs from 'rxjs';

import VisitCost from './VisitCost';
import Balance from './Balance';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { paymentApi } from '../../../utils/services/payment.api';

const initial = {
  initialised: false,
  step: 0,
  steps: [],
};

export class Bloc {
  callback;
  onException;

  constructor(options, callback, onException) {
    this.subject = new rxjs.BehaviorSubject({
      ...initial,
      ...options,
      visitCostOverride: -1,
    });

    this.events = new rxjs.Subject();

    this.callback = callback;
    this.onException = onException;
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  __publishEvent = (type, data) =>
    this.events.next({
      type: type,
      data: data,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    const { paymentDetails } = this.subject.value;

    let visitPaymentType = paymentDetails.paymentType;
    let visitCost = paymentDetails.amount ? Number(paymentDetails.amount) : 0;

    let accountBalance = paymentDetails.accountBalance;

    let minimum = 30;
    let amount = 0;

    if (accountBalance < minimum) {
      minimum = accountBalance;
      amount = accountBalance;
    }

    this.__updateSubject({
      initialised: true,
      visitPaymentType: visitPaymentType,
      visitCost: visitCost,
      amount: amount,
      minimum: minimum,
      balance: accountBalance,
      steps: this.buildSteps(accountBalance),
    });
  };

  buildSteps = (accountBalance) => {
    let steps = [<VisitCost />];

    if (accountBalance > 0) {
      steps.push(<Balance />);
    }

    return steps;
  };

  setAccountPaymentAmount = (amount) => {
    this.__updateSubject({ amount: amount });
  };

  seeFrontDeskWording = () => {
    const { appointment } = this.subject.value;

    if (appointment?.type === 'VIRTUAL') {
      return (
        <FormattedMessage id={`payment.account.virtual.button.end`} defaultMessage={'Pay later'} />
      );
    }

    return (
      <FormattedMessage
        id={`payment.account.inperson.button.end`}
        defaultMessage={'Finish/complete'}
      />
    );
  };

  setVisitCostOverride = () => {
    this.__updateSubject({ visitCostOverride: 0 });
  };

  next = () => {
    const { step, steps, amount, visitCost, visitCostOverride } = this.subject.value;
    let nextStep = step + 1;
    if (nextStep >= steps.length) {
      if (visitCostOverride > -1) {
        this.callback(amount + visitCostOverride, amount, visitCostOverride);
      } else {
        this.callback(amount + visitCost, amount, visitCost);
      }
    }
    this.__updateSubject({ step: nextStep });
  };

  end = () => {
    const { appointment } = this.subject.value;

    this.onException();
    return paymentApi.skipPayment(appointment.id);
  };

  back = () => {
    const { step } = this.subject.value;
    let nextStep = step - 1;
    if (nextStep >= 0) {
      this.__updateSubject({ step: nextStep });
    }
  };

  close = () => {};
}

export class Constants {}

export class BlocEvent {}
