import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import ProviderCard from './ProviderCard';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../../utils/events';
import BaseWidget from './BaseWidget';
import { RescheduleBookingContext } from '../../bloc';

const styles = (theme) => ({
  root: {},
  fill: {
    flex: '1 1 auto',
  },
  cardHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'auto',
    },
  },
  card: {
    width: '240px',
    minWidth: '240px',
    maxWidth: '240px',
    margin: '12px 24px 12px 0',
    backgroundColor: '#F6F6F6',
    border: '0 solid #ffffff',
    borderRadius: '6px',
  },

  cardSelected: {
    width: '240px',
    minWidth: '240px',
    maxWidth: '240px',
    margin: '12px 24px 12px 0',
    backgroundColor: theme.palette.primary.main,
    border: '0 solid #ffffff',
    borderRadius: '6px',
  },

  cardHeader: {},

  cardSelectedHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#ffffff',
  },

  cardHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
  },

  cardHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
  },
  cardSelectedHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#FFF',
  },
  cardBody: {
    paddingTop: '0',
    paddingBottom: '6px !important',
  },

  cardBodyLine: {
    display: 'flex',
    marginBottom: '14px',
  },

  cardBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
  },
  cardSelectedBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
  },

  cardBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
    paddingLeft: '18px',
  },
  cardSelectedBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
    paddingLeft: '18px',
  },

  buttonHolder: {
    marginTop: '48px',
  },
  button: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  iconLarge: {
    fontSize: '18px',
  },
  iconLargeWhite: {
    fontSize: '18px',
    color: '#fff',
  },
  progressHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'hidden',
    },
  },
  progress: {
    color: '#F29202',
  },
});

class ProviderSelection extends Component {
  state = {
    initialising: true,
    selected: undefined,
    staff: [],
  };

  constructor(props) {
    super(props);

    this.__selectStaff = this.__selectStaff.bind(this);
  }

  componentDidMount() {
    const { bloc } = this.props;

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_PROVIDER_LOADED, {});

    this.setState({
      staff: bloc.subject.value.availabilityStaff,
      initialising: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selected === undefined && prevState.selected === undefined && this.context) {
      this.setState((prevState) => {
        return {
          ...prevState,
          selected: this.context ? this.context.selectedProvider : undefined,
        };
      });
    }
  }

  __selectStaff = (id) => {
    const $this = this;
    return () => {
      const { selected } = $this.state;

      $this.setState({
        selected: selected === id ? undefined : id,
      });
    };
  };

  __submit = () => {
    const { selected, staff } = this.state;

    if (!selected) return;

    let _person = undefined;
    if (selected) {
      _person = staff.filter((_item) => _item.id === selected)[0];
    }

    this.props.bloc.setDoctor(selected, _person);
  };

  render() {
    const { classes, bloc } = this.props;

    const { initialising, staff, selected } = this.state;

    return (
      <BaseWidget
        initialising={initialising}
        processing={false}
        submitEnabled={selected}
        submit={this.__submit}
        submitLabel={'SELECT'}
      >
        <Grid row xs={12} className={classes.cardHolder}>
          {staff.map((_person) => (
            <ProviderCard
              onClick={this.__selectStaff(_person.id)}
              selected={selected}
              bloc={bloc}
              person={_person}
            />
          ))}
        </Grid>
      </BaseWidget>
    );
  }
}

ProviderSelection.contextType = RescheduleBookingContext;

export default withStyles(styles)(ProviderSelection);
