import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { authService } from '../../../utils/auth';
import { Grid } from '@mui/material';
import { DateMask } from '../../shared/InputTextMask';
import Typography from '@mui/material/Typography';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { userInfoUtil } from '../../../utils/user';
import { FormattedMessage } from 'react-intl';
import { phoneUtil } from '../../../utils/phone';
import PageContainer from '../../common/PageContainer';
import { ScrollableQuinnContainer } from '../../common/ScrollableContainer';
import { PhoneNumber } from '../../shared/PhoneNumber/PhoneNumber';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { withTheme } from '@mui/styles';
import { H2TextTitleContent } from '../../shared/Typography';
import DecodedButton, { DecodedSubmitButton } from '../../common/DecodedButton';
import { providerStorage } from '../../../utils/provider.qs';
import { userAnalyticData } from '../../../utils/userAnalytics/userAnalyticData';

const styles = (theme) => ({
  validation: {
    flex: '1',
    width: '100%',
    paddingTop: '3em',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1em',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
    justifyContent: 'space-between',
  },
  formInput: {
    flex: '1',
    paddingBottom: '2em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonGroup: {
    paddingTop: '2em',
    flexDirection: 'row',
    textAlign: 'center',
  },
  button: {
    flex: '1 1 30%',
    padding: '1em',
  },
  disabledButton: {
    color: '#05167e',
    backgroundColor: '#ffffff',
  },
  warning: {
    width: '100%',
    lineHeight: '1.75',
    color: '#1A0C00',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
});

class UserAuth extends Component {
  constructor() {
    super();
    this.state = {
      atLocation: providerStorage.isWalkin(),
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isContactProvided', (value) => {
      return this.props.email || this.props.number;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isContactProvided');
  }

  doSubmit = (event) => {
    event.preventDefault();

    let { email, firstName, lastName, dateOfBirth, number, handleNext, setLoadingStatus } =
      this.props;

    userAnalyticData({
      firstName: firstName,
      dateOfBirth: dateOfBirth,
    });

    setLoadingStatus(true);

    analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_REQUEST);

    authService
      .requestAuthentication(this._createAuthRequest())
      .then((authResult) => {
        switch (typeof authResult) {
          case 'boolean':
            if (authResult) {
              this.handleOTPProcess(
                email,
                firstName,
                lastName,
                dateOfBirth,
                number,
                handleNext,
                setLoadingStatus,
              );
            }
            break;
          case 'string':
            this.handleTokenLogin(authResult, setLoadingStatus, handleNext);
            break;
          default:
            throw new Error('Unexpected response type from requestAuthentication');
        }
      })
      .catch((error) => {
        setLoadingStatus(false);
      });
  };

  handleOTPProcess = (
    email,
    firstName,
    lastName,
    dateOfBirth,
    number,
    handleNext,
    setLoadingStatus,
  ) => {
    const loginDetails = {
      firstName,
      lastName,
      dateOfBirth,
      email,
      number,
    };
    sessionStorage.setItem('loginDetails', JSON.stringify(loginDetails));
    setLoadingStatus(false);
    handleNext();
  };

  handleTokenLogin = (token, setLoadingStatus, handleNext) => {
    return authService.loginWithToken(token).then(() => {
      this.props.verificationSuccess(true);
      setLoadingStatus(false);
      handleNext();
    });
  };

  _createAuthRequest = () => {
    let { email, firstName, lastName, dateOfBirth, number, code, codeCountry } = this.props;

    return {
      name: {
        given: firstName.trim(),
        family: lastName.trim(),
      },
      dob: userInfoUtil.formatDate(dateOfBirth),
      number: number ? phoneUtil.formatPhoneNumberForRegistration(number, code, codeCountry) : null,
      email: email,
    };
  };

  isLoading = () => {
    return this.props.loading || authService.isLoading();
  };

  render() {
    let {
      classes,
      loading,
      firstName,
      lastName,
      dateOfBirth,
      email,
      number,
      handleTextChange,
      theme,
    } = this.props;

    const { atLocation } = this.state;

    const defaultMessageId = atLocation ? 'login.user.walkin.bubble' : 'login.user.bubble';
    const defaultMessage = atLocation
      ? 'We would like to quickly identify you before updating your appointment. Please enter patient first name, last name, date of birth, mobile number and/or email. Then click on the "Verify" button to start your visit.'
      : 'Please login or register and I will get a provider to see you quickly.';

    let messageId = atLocation
      ? 'login.userauth.quinn.walkin.bubble'
      : 'login.userauth.quinn.bubble';
    let message = atLocation
      ? 'We will not make you memorize a password. Help us verify your account.'
      : 'We will not make you memorize a password. Help us verify your account by allowing us to email or text you a PIN-code.';

    let textId = atLocation
      ? `user.login.redirect.reason.${this.props.redirectReason}.walkin.bubble`
      : `user.login.redirect.reason.${this.props.redirectReason}.bubble`;
    let text = atLocation
      ? 'We would like to quickly identify you before updating your appointment. Please enter patient first name, last name, date of birth, mobile number and email (optional). Then click on the "Verify" button to start your visit.'
      : 'We would like to quickly identify you before updating your appointment. Please enter patient first name, last name, date of birth, and either email or mobile number. Then click on the "Send PIN-Code" button to start your visit.';

    return (
      <PageContainer
        loading={loading}
        onBack={
          atLocation
            ? () => {
                this.props.history.goBack();
              }
            : undefined
        }
      >
        <ScrollableQuinnContainer messageId={messageId} message={message} applyFormat={true}>
          <H2TextTitleContent>
            {this.props.redirectReason ? (
              <FormattedMarkdown id={textId} defaultMessage={text} />
            ) : (
              <FormattedMarkdown id={defaultMessageId} defaultMessage={defaultMessage} />
            )}
          </H2TextTitleContent>

          <ValidatorForm autoComplete="off" onSubmit={this.doSubmit} className={classes.validation}>
            <div className={classes.form}>
              <div className={classes.formInput}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      id="firstName"
                      label="Patient First Name"
                      name="firstName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.given"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      value={firstName}
                      onChange={handleTextChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      id="lastName"
                      label="Patient Last Name"
                      name="lastName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.family"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      value={lastName}
                      onChange={handleTextChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      id="dateOfBirth"
                      label="Patient Date of Birth"
                      name="dateOfBirth"
                      placeholder="MM/dd/yyyy e.g. 12/31/2000"
                      autoComplete="bday"
                      value={dateOfBirth}
                      onChange={handleTextChange}
                      validators={['isDateValid']}
                      errorMessages={['Invalid date of birth']}
                      InputProps={{
                        inputComponent: DateMask,
                      }}
                      inputProps={{ inputMode: 'numeric' }}
                    />
                  </Grid>

                  <Grid container item xs={12} sm={6}>
                    <PhoneNumber
                      autoComplete="off"
                      numberRequired={atLocation}
                      numberValue={number}
                      numberLabel={
                        <FormattedMessage
                          id="login.field.label.mobile"
                          defaultMessage={`Mobile Number ${!atLocation ? '(optional)' : ''}`}
                        />
                      }
                      onNumberChange={handleTextChange}
                      codeValue={{ callingCode: this.props.code, code: this.props.codeCountry }}
                      onCodeChange={(e) => {
                        const codeValues = e.target.value;
                        this.props.onCodeChange(codeValues.callingCode, codeValues.code);
                      }}
                      selectedCountry={this.props.codeCountry}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      fullWidth
                      id="email"
                      label="Email Address (optional)"
                      name="email"
                      type="email"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                        inputMode: 'email',
                      }}
                      value={email}
                      onChange={handleTextChange}
                      validators={['isEmail', 'isContactProvided']}
                      errorMessages={[
                        'email is not valid',
                        'Either email or phone number must be provided',
                      ]}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.buttonGroup}>
                  {atLocation && (
                    <Grid item xs={12}>
                      <H2TextTitleContent sx={{ textAlign: 'left' }}>
                        <FormattedMarkdown
                          id="login.no.phonenumber"
                          defaultMessage="No phone? Please go to the front desk."
                        />
                      </H2TextTitleContent>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <DecodedSubmitButton
                      id="sign-in-button"
                      type="submit"
                      disabled={this.isLoading()}
                      loading={this.isLoading()}
                    >
                      <FormattedMessage
                        id={
                          atLocation
                            ? 'login.userauth.button.submit.walkin'
                            : 'login.userauth.button.submit'
                        }
                        defaultMessage={atLocation ? 'VERIFY' : 'SEND PIN-CODE'}
                      />
                    </DecodedSubmitButton>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.warning}>
                {atLocation ? (
                  <Typography
                    variant="body1"
                    component={'div'}
                    sx={{
                      color: theme.palette.accent.main,
                    }}
                  >
                    <FormattedMarkdown
                      id="global.instruction.emergency.atLocation"
                      defaultMessage=" If this is a medical emergency, please see one of our team members immediately."
                    />
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    component={'div'}
                    sx={{
                      color: theme.palette.accent.main,
                    }}
                  >
                    <FormattedMarkdown
                      id="global.instruction.emergency"
                      defaultMessage="If this is a medical emergency , please call 911 immediately."
                    />
                  </Typography>
                )}
              </div>
            </div>
          </ValidatorForm>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withTheme(withStyles(styles)(UserAuth));
