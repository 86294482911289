import axios from 'axios';
import { VERSION } from '../../../../utils/version';
import { tenantUtil } from '../../../../utils/tenant';

class PaymentMockApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';

    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['Content-Type'] = `application/json; version=1.0.0`;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  payerEmployers() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: {
            items: [
              {
                id: '1',
                value: 'Employer 1',
              },
              {
                id: '2',
                value: 'Employer 2',
              },
            ],
          },
        });
      }, 500);
    });
    //
    // return this.instance.get(`${this.baseUrl}/api/paymentmethods/payers?type=` + type);
  }
}

export const paymentApi = new PaymentMockApi();
