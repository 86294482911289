import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Redirect } from 'react-router-dom';

import { authService } from '../../utils/auth';

import Loading from '../shared/Loading';
import { linksApi } from '../../utils/services/links.api';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';

import {
  LOGIN_ROUTE,
  TELEHEALTH_LINK_VIRTUAL_CLINIC_WAITING_ROOM_ROUTE,
} from '../../utils/route.name';
import { uriStorage } from '../../utils/storage';
import { ErrorMessage } from '../../utils/error.resolver';
import { providerStorage } from '../../utils/provider.qs';
import { globalBloc } from '../global.bloc';

const styles = (theme) => ({
  root: {
    overflow: 'auto',
    overscrollBehavior: 'contain',
    backgroundColor: '#313941',
    position: 'absolute',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    color: '#fff',
  },
});

class QrLink extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    this.state = {
      loading: true,
      providerId: params.providerId,
    };

    this._initialise(params);
  }

  _initialise = (params) => {
    if (params.token) {
      sessionStorage.setItem('locationToken', params.token);
    }

    analyticsEventLogger.log(AnalyticsEvent.LINK_OPEN, {
      type: 'qrcode',
      provider: params.providerId,
    });
    globalBloc.setProvider(params.providerId);
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }

  componentWillUnmount() {}

  render() {
    let { classes } = this.props;

    let { loading, providerId } = this.state;

    return (
      <div className={classes.root}>
        {loading ? (
          <Loading message={'Loading'} />
        ) : (
          <>
            <Redirect to={`/login?provider=${providerId}`} />
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(QrLink);
