import React from 'react';
import { Redirect } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { uriStorage } from '../../utils/storage';
import BasicInformation from './BasicInformation';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Address from './AddressForm';
import { registrationApi } from '../../utils/services/register.api';
import { authService } from '../../utils/auth';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';
import { ErrorMessage, errorResolver } from '../../utils/error.resolver';
import { userInfoUtil } from '../../utils/user';
import { phoneUtil } from '../../utils/phone';
import { BOOKING_LIST_ROUTE, QUINN_ROUTE } from '../../utils/route.name';
import {
  InvalidPhoneNumberModal,
  NotConnectedPhoneNumberModal,
} from './PhoneNumberVerificationModals';
import { IntroStep } from './IdentityVerification';
import { Checkbox } from '../shared/components/Checkbox';
import { PreScan } from './PreScan';
import { Bloc as PharmacyBloc } from './Pharmacies/bloc';
import AdditionalInformation from './AdditionalInformation';
import Pharmacies from './Pharmacies';
import { EmergencyContact } from './EmergencyContact';
import { IdentityVerificationRequired } from './IdentityVerificationRequired';
import { providerStorage } from '../../utils/provider.qs';
import DecodedComponent from '../shared/DecodedComponent';
import { Bloc } from './bloc';
import { IdentityVerificationEnd } from './IdentityVerificationEnd';
import { pharmacyApi } from '../../utils/services/pharmacy.api';
import FrontScan from './FrontScan';
import BackScan from './BackScan';
import { appointmentApi } from '../../utils/services/appointments.api';
import DecodedButton from '../common/DecodedButton';
import { consumerApi } from '../../utils/services/consumers.api';
import { logger } from '../../utils/logging';
import { userAnalyticData } from '../../utils/userAnalytics/userAnalyticData';

const styles = (theme) => ({
  grid: {
    flex: '1',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  checkBox: {
    transform: 'scale(2)',
    marginLeft: '1em',
    marginRight: '1em',
  },
  button: {
    width: '100%',
    padding: '3em',
    textAlign: 'center',
  },
});

const initState = {
  loading: false,
  activeStep: 0,
  scanSkipped: false,

  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  addressAdministrativeArea: '',
  addressCity: '',
  addressPostcode: '',
  addressCountry: 'US',
  codeCountry: 'US',
  code: '+1',
  number: '',
  email: '',
  gender: '',
  ssn: '',
  dateOfBirth: '',

  hearAboutUs: '',
  race: '',
  ethnicity: '',
  language: '',

  acceptTerms: false,

  registrationSuccess: false,

  invalidPhoneNumber: false,
  notConnectedPhoneNumber: false,

  frontImageValue: {},
  backImageValue: {},
  idVerificationReference: null,
  isPatientMyself: '',
  isFirstTimeVisit: '',

  pharmacy: {},
  responsibleParty: { relationship: 'SELF' },
  emergency: {},
};

class Registration extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = new Bloc({});
    this.state = initState;
  }

  componentDidMount() {
    super.componentDidMount();

    const { loginDetails } = this.bloc.subject.value;

    uriStorage.setCurrentPath(this.props.match.url);

    analyticsEventLogger.log(AnalyticsEvent.REGISTER_OPEN);

    if (loginDetails) {
      this.setState({
        ...this.props.location.state,
        firstName: loginDetails?.firstName,
        dateOfBirth: this.__getCorrectDob(
          { data: { dob: 'DATE_OF_BIRTH' } },
          loginDetails?.dateOfBirth,
        ),
        email: loginDetails?.email,
        number: loginDetails?.number,
      });
    }

    this.setState({
      loading: false,
    });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  handleDataChange = (data) => {
    this.setState(data);
  };

  handleCodeChange = (callingCode, addressCountry, countryCode) => {
    this.setState({
      code: callingCode,
      codeCountry: countryCode,
      ...(addressCountry ? { addressCountry } : {}),
      number: '',
    });
  };

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  handleGenderChange = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };

  handlePhoneVerification = () => {
    const { loading } = this.state;

    if (loading) return;

    this.setState({
      loading: true,
    });
    const formattedNumber = phoneUtil.formatPhoneNumberForRegistration(
      this.state.number,
      this.state.code,
      this.state.codeCountry,
    );
    const number =
      formattedNumber.country +
      formattedNumber.area +
      formattedNumber.prefix +
      formattedNumber.line;
    registrationApi
      .verifyPhoneNumber(number)
      .then((response) => {
        const verificationStatus = response.data;
        if (verificationStatus.status === 3) {
          this.setState({
            invalidPhoneNumber: true,
          });
          return;
        } else if (verificationStatus.status === 0) {
          if (verificationStatus.current_carrier.network_type !== 'mobile') {
            this.setState({
              notConnectedPhoneNumber: true,
            });
            return;
          }
        }
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_PHONE_VERIFICATION_SUCCESS);
        this.handleNext();
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_PHONE_VERIFICATION_ERROR, {
          reason: error,
        });

        this.handleNext();
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleNextPersonalVerification = (_, submitWithPhoneVerification = true) => {
    if (submitWithPhoneVerification) {
      this.handlePhoneVerification();
    } else {
      this.handleNext();
    }
  };

  doSubmit = (emergencyData) => {
    const { loading } = this.state;

    if (loading) return;

    this.setState({ loading: true });
    // TODO: integrate new fields with api
    const formRequest = this.createAccountRequest(emergencyData);

    registrationApi
      .register(formRequest)
      .then((value) => {
        authService
          .loginWithToken(value.data.token)
          .then((value) => {
            analyticsEventLogger.log(AnalyticsEvent.REGISTER_SUCCESS);

            this.submitPharmacyRequest();

            this.getNextFlow();

            consumerApi
              .getPersonSummary()
              .then((result) => {
                userAnalyticData({
                  id: result.data.id,
                  dateOfBirth: result.data.dob,
                  firstName: result.data.name.given,
                });
              })
              .catch((error) => {
                logger.error('Error getting person summary', error);
              });

            // this.setState({ registrationSuccess: true });
          })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.REGISTER_AUTH_ERROR, {
              reason: `${error}`,
            });

            notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
            this.setState({
              registrationFailed: true,
              loading: false,
            });
          });
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_ERROR, {
          reason: error,
        });

        notificationService.error(errorResolver.resolveRegistrationErrorDisplay(error));
        this.setState({ registrationFailed: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  submitPharmacyRequest = () => {
    const pharmacy = this.createPharmacyRequest();

    pharmacyApi
      .setPharmacy(pharmacy)
      .then((result) => {
        analyticsEventLogger.log(AnalyticsEvent.PHARMACY_UPDATE_SUCCESS);
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.PHARMACY_UPDATE_ERROR, {
          reason: `${error}`,
        });
        notificationService.error(
          'Unable to update pharmacy information.' + ErrorMessage.CALL_SUPPORT,
        );
      });
  };

  createEmergencyContactRequest = (emergencyData) => {
    const {
      relationship,
      firstName,
      lastName,
      phoneNumber,
      address,
      city,
      postCode,
      state,
      gender,
      dateOfBirth,
    } = emergencyData;

    if (relationship === 'SELF') return { relationship: relationship };

    return {
      relationship: relationship,
      name: userInfoUtil.formatName(firstName, lastName),
      gender: gender,
      contactNumber: phoneUtil.formatPhoneNumberForRegistration(phoneNumber, '+1', 'US'),
      address: {
        type: '',
        line1: address,
        line2: '',
        line3: '',
        city: city,
        postcode: postCode,
        administrativeArea: state,
        country: 'US',
      },
    };
  };

  createGuarantorRequest = (guarantorData) => {
    const {
      relationship,
      firstName,
      lastName,
      phoneNumber,
      address,
      city,
      postCode,
      state,
      gender,
      dateOfBirth,
    } = guarantorData;

    if (relationship === 'SELF') return { relationship: relationship };

    return {
      relationship: relationship,
      name: userInfoUtil.formatName(firstName, lastName),
      dob: dateOfBirth ? userInfoUtil.formatDate(dateOfBirth) : null,
      gender: gender,
      contactNumber: phoneUtil.formatPhoneNumberForRegistration(phoneNumber, '+1', 'US'),
      address: {
        type: '',
        line1: address,
        line2: '',
        line3: '',
        city: city,
        postcode: postCode,
        administrativeArea: state,
        country: 'US',
      },
    };
  };

  createPharmacyRequest = () => {
    const { pharmacy } = this.state;
    if (pharmacy.contactInformation) {
      return pharmacy;
    }
    return {
      id: pharmacy.id,
      name: pharmacy.name,
      group: pharmacy.group,
      contactInformation: pharmacy.contactInformation,
    };
  };

  createAccountRequest = (emergencyData) => {
    let {
      firstName,
      lastName,
      gender,
      dateOfBirth,
      ssn,
      acceptTerms,
      number,
      email,
      code,
      codeCountry,
      idVerificationReference,
      addressLine1,
      addressLine2,
      addressCity,
      addressAdministrativeArea,
      addressPostcode,
      addressCountry,
      race,
      ethnicity,
      hearAboutUs,
    } = this.state;

    let externalReferences = [];

    if (ssn) {
      externalReferences.push({
        code: 'SS',
        reference: ssn,
      });
    }

    if (idVerificationReference) {
      externalReferences.push({
        code: 'verificationId',
        reference: idVerificationReference,
      });
    }

    let properties = [];
    if (hearAboutUs) {
      properties.push({
        code: 'marketingChannel',
        value: hearAboutUs,
      });
    }

    const location = providerStorage.getCurrentProvider() || '';
    if (location !== '') {
      properties.push({
        system: 'decoded',
        code: 'regLoc',
        value: location,
      });
    }

    let demographic = [];
    if (race)
      demographic.push({
        code: 'race',
        value: race,
      });
    if (ethnicity)
      demographic.push({
        code: 'ethnicity',
        value: ethnicity,
      });

    const emergency = this.createEmergencyContactRequest(emergencyData);
    const responsibleParty = this.createGuarantorRequest(this.state.responsibleParty);

    return {
      code: 'enrollment.register',
      type: 'client',
      payload: {
        name: userInfoUtil.formatName(firstName, lastName),
        dob: userInfoUtil.formatDate(dateOfBirth),
        gender: gender,
        externalReferences: externalReferences,
        contactDetails: {
          address: {
            type: '',
            line1: addressLine1,
            line2: addressLine2,
            line3: '',
            city: addressCity,
            postcode: addressPostcode,
            administrativeArea: addressAdministrativeArea,
            country: addressCountry,
          },
          number: phoneUtil.formatPhoneNumberForRegistration(number, code, codeCountry),
          email: email,
        },
        acceptTerms: acceptTerms,
        communicationConsent: acceptTerms,
        acceptLocationTerm: true,
        emergency,
        guarantor: responsibleParty,
        demographic: demographic,
        properties: properties,
      },
    };
  };

  handleGoBackDialog = () => {
    this.setState({
      // activeStep: 0,
      notConnectedPhoneNumber: false,
      invalidPhoneNumber: false,
    });
  };

  handleContinueOnDialog = () => {
    this.setState((state, props) => ({
      activeStep: state.activeStep + 1,
      notConnectedPhoneNumber: false,
    }));
  };

  handleScanFinish = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      firstName:
        data?.name?.given?.trim().length > 0 ? data?.name?.given.trim() : prevState.firstName,
      lastName:
        data?.name?.family?.trim().length > 0 ? data?.name?.family.trim() : prevState.lastName,
      dateOfBirth: this.__getCorrectDob(data, prevState.dateOfBirth),
      addressLine1: data?.address?.line1?.trim() ?? prevState.addressLine1,
      addressLine2: data?.address?.line2?.trim() ?? prevState.addressLine2,
      addressAdministrativeArea:
        (data?.address?.administrativeArea || data?.address?.state) ??
        prevState.addressAdministrativeArea,
      addressCity: data?.address?.city ?? prevState.addressCity,
      addressPostcode:
        data?.address?.postcode?.length > 5
          ? ''
          : data?.address?.postcode ?? prevState.addressPostcode,
      idVerificationReference: data?.ref,
    }));
    this.handleNext();
  };

  __getCorrectDob = (data, originalDob) => {
    let result;
    if (
      data?.dob === undefined ||
      data?.dob === '' ||
      (data?.dob === 'DATE_OF_BIRTH' && originalDob)
    ) {
      result = originalDob;
    } else {
      let extractedDate = data?.dob?.replace('-', '/');
      const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
      result = dateRegex.test(extractedDate) ? extractedDate : originalDob;
    }

    return result;
  };

  handleFrontScanCapture = (file) => {
    this.setState({ frontImageValue: { image: URL.createObjectURL(file), imageBlob: file } });
  };
  handleBackScanCapture = (file) => {
    this.setState({ backImageValue: { image: URL.createObjectURL(file), imageBlob: file } });
  };

  handleBackImageSubmit = () => {
    // api call
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append('front', this.state.frontImageValue.imageBlob);
    formData.append('back', this.state.backImageValue.imageBlob);
    registrationApi
      .analyzeDocument(formData)
      .then((r) => {
        this.handleScanFinish(r.data);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  basicInformationBack = () => {
    this.setState((ps) => ({
      ...ps,
      activeStep: ps.scanSkipped ? 1 : ps.activeStep - 1,
    }));
  };

  handlePreScan = (newState) => {
    this.setState(newState);
  };

  getNextFlow = () => {
    appointmentApi
      .getAllFutureAppointments()
      .then((result) => {
        if (result.data.items && result.data.items.length > 0) {
          this.props.history.replace(BOOKING_LIST_ROUTE);
        } else {
          this.props.history.replace(QUINN_ROUTE);
        }
      })
      .catch((error) => {
        this.props.history.replace(QUINN_ROUTE);
      });
  };

  getStepContent = (step) => {
    const { consentDialog } = this.state;

    const providerId = providerStorage.getCurrentProvider();
    const isWalkin = providerId && providerId !== '';

    const isLoading = this.state.loading || authService.isLoading();

    switch (step) {
      case 0:
        return (
          <PreScan
            {...this.state}
            onChange={this.handlePreScan}
            onNext={this.handleNext}
            userFirstName={this.state.firstName}
          />
        );
      case 1:
        return (
          <IntroStep
            isWalkin={isWalkin}
            onSkip={() => this.setState({ scanSkipped: true, activeStep: isWalkin ? 9 : 4 })}
            handleBack={this.handleBack}
            userFirstName={this.state.firstName}
            onScanID={() =>
              this.setState((ps) => ({ scanSkipped: false, activeStep: ps.activeStep + 1 }))
            }
          />
        );
      case 2:
        return (
          <FrontScan
            loading={isLoading}
            onBack={this.handleBack}
            fileName={'front.jpg'}
            imageValue={this.state.frontImageValue.image}
            onScanCapture={this.handleFrontScanCapture}
            heading={
              <FormattedMessage
                id="registration.scan.document.front.heading"
                defaultMessage="Step 1: Front side of the card"
              />
            }
            onImageSubmit={this.handleNext}
            submitLabel={
              <FormattedMessage
                id="registration.scan.document.front.submit.label"
                defaultMessage="SUBMIT"
              />
            }
          />
        );
      case 3:
        return (
          <BackScan
            loading={isLoading}
            onBack={this.handleBack}
            fileName={'back.jpg'}
            frontImageValue={this.state.frontImageValue.image}
            imageValue={this.state.backImageValue.image}
            onScanCapture={this.handleBackScanCapture}
            heading={
              <FormattedMessage
                id="registration.scan.document.back.heading"
                defaultMessage="Step 2: Back side of the card"
              />
            }
            onImageSubmit={this.handleBackImageSubmit}
            submitLabel={
              <FormattedMessage
                id="registration.scan.document.back.submit.label"
                defaultMessage="SUBMIT"
              />
            }
          />
        );
      case 4:
        return (
          <BasicInformation
            {...this.state}
            handleBack={this.basicInformationBack}
            handleNext={this.handleNextPersonalVerification}
            onCodeChange={this.handleCodeChange}
            handleTextChange={this.handleTextChange}
            handleGenderChange={this.handleGenderChange}
          />
        );
      case 5:
        return (
          <>
            <Address
              {...this.state}
              isLoading={isLoading}
              handleBack={this.handleBack}
              doSubmit={(newState) => {
                this.setState(newState);
                this.handleNext();
              }}
              code={this.state.code}
              updateAddressChange={this.handleDataChange}
              additionalFields={[
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      checked={this.state.acceptTerms}
                      onChange={this.handleCheckboxChange}
                      className={this.props.classes.checkBox}
                      name="acceptTerms"
                      color="primary"
                    />
                  }
                  label={
                    <React.Fragment>
                      <FormattedMessage
                        id="registration.user.consent"
                        defaultMessage="I acknowledge and consent to"
                      />
                      <br />
                      <FormattedMessage id="global.consent.link">
                        {(chunks) => (
                          <Link
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                consentDialog: true,
                              });
                            }}
                            // href={chunks}
                            href={`#`}
                            underline="hover"
                          >
                            <FormattedMessage
                              id="registration.user.tnc"
                              defaultMessage="The Terms of Use, Consent to Treatment, and Notice of Privacy Practices"
                            />
                          </Link>
                        )}
                      </FormattedMessage>
                    </React.Fragment>
                  }
                />,
              ]}
            />
            <Dialog
              open={consentDialog}
              scroll={'paper'}
              onClose={() => {
                this.setState({
                  consentDialog: false,
                });
              }}
              fullWidth={true}
              maxWidth={`xl`}
            >
              <DialogTitle title={'Terms and Conditions'}></DialogTitle>
              <DialogContent>
                <iframe
                  src={`https://patientplusuc.com/privacy-policy`}
                  style={{ height: '70vh', width: '100%' }}
                ></iframe>
              </DialogContent>
              <DialogActions>
                <DecodedButton
                  onClick={() => {
                    this.setState({
                      consentDialog: false,
                    });
                  }}
                >
                  CLOSE
                </DecodedButton>
              </DialogActions>
            </Dialog>
          </>
        );
      case 6:
        return (
          <AdditionalInformation
            {...this.state}
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            handleTextChange={this.handleTextChange}
          />
        );
      case 7: {
        const pharmacyBloc = new PharmacyBloc(
          {},
          {
            pharmacyApi: pharmacyApi,
          },
          this.state.pharmacy,
        );
        return (
          <Pharmacies
            {...this.state}
            bloc={pharmacyBloc}
            handleBack={this.handleBack}
            handleNext={(_pharmacy) => {
              this.setState({ pharmacy: _pharmacy });
              this.handleNext();
            }}
            handleTextChange={this.handleTextChange}
          />
        );
      }
      case 8:
        return (
          <EmergencyContact
            {...this.state}
            handleBack={this.handleBack}
            handleSubmit={(formState) => {
              this.doSubmit(formState);
            }}
          />
        );
      case 9:
        return (
          <IdentityVerificationRequired
            {...this.state}
            isWalkin={isWalkin}
            onNext={() => {
              this.setState((state) => ({
                activeStep: 10,
              }));
            }}
            handleBack={() => {
              this.setState((state) => ({
                activeStep: 1,
              }));
            }}
          />
        );
      case 10:
        return <IdentityVerificationEnd {...this.state} />;
      default:
        throw new Error('Unknown step');
    }
  };

  render() {
    let { activeStep, registrationSuccess, invalidPhoneNumber, notConnectedPhoneNumber, email } =
      this.state;

    return (
      <>
        {this.getStepContent(activeStep)}
        <InvalidPhoneNumberModal open={invalidPhoneNumber} onGoBack={this.handleGoBackDialog} />
        <NotConnectedPhoneNumberModal
          open={notConnectedPhoneNumber}
          email={email}
          onContinue={this.handleContinueOnDialog}
          onGoBack={this.handleGoBackDialog}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Registration);
