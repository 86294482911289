import { Button, css, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { useHistory } from 'react-router-dom';
import { useWizardContext } from '../../../shared-library';
import PageContainer from '../../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableBox,
  ScrollableQuinnContainer,
  ScrollableWrapper
} from '../../common/ScrollableContainer';
import { Select } from '../../Registration/components/Select';
import ChatBubble from '../../shared/ChatBubble';
import {DecodedSubmitButton} from "../../common/DecodedButton";

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1 0 0',
  },
});

export const EmployerForm = ({ bloc }) => {
  const classes = useStyles();
  const context = useWizardContext();
  const history = useHistory();
  const handleChange = (e) => {
    bloc.setPayer(e.target.value);
  };
  return (
    <PageContainer onBack={() => history.goBack()}>
      <ValidatorForm
        onSubmit={() => {
          bloc.next(context);
        }}
        className={classes.form}
      >
        <ScrollableQuinnContainer
            messageId="employerInformation.form"
            message="Please select the employer that is going to pay for this visit.">
            <Select
              id="employer"
              name="employer"
              label="Employer Name"
              value={bloc.payer() ?? ''}
              onChange={handleChange}
              options={bloc.payers()}
              getItemLabel={(item) => item.name}
              getItemValue={(item) => item.id}
            />
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={"left"}>
          <DecodedSubmitButton>
            <FormattedMessage id="registration.button.label.submit" defaultMessage={'NEXT'} />
          </DecodedSubmitButton>
        </DefaultDisconnectedPageFooter>
      </ValidatorForm>
    </PageContainer>
  );
};
