import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';

import callIcon from '../../assets/icons/start_call.svg';
import { telehealthEventService } from './service/telehealth.event.service';

const styles = (theme) => ({
  root: {
    height: '100vh',
    backgroundColor: '#C4C4C430',
    display: 'flex',
    flexDirection: 'column',
  },
  fill: {
    flex: '1 1 auto',
  },
  wrapper: {
    margin: 'auto',
  },
  text: {
    cursor: 'pointer',
    textTransform: 'capitalize',

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '22px',
    lineHeight: '33px',
    textAlign: 'center',

    color: '#FFFFFF',
  },
  click: {
    cursor: 'pointer',
  },
});

class Join extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.props.clearPoller();
  }

  _join = () => {
    telehealthEventService.update({
      type: 'ENCOUNTER_CALL_JOIN',
      data: {},
    });

    this.props.startPoller();
  };

  render() {
    let { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <img
            className={classes.click}
            onClick={this._join}
            src={callIcon}
            alt="telehealth join icon"
          />
          <br />
          <div className={classes.text} onClick={this._join}>
            Join video call
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Join);
