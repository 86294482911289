import * as React from 'react';
import { CalendarPicker as MuiCalenderPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { dateUtil } from '../../../utils/date';
import { useBookingWizardContext } from '../BookingWizard/booking-wizard-context';
import { Loading } from '../../../shared-library';
import { styled } from '@mui/material';
import {globalBloc} from "../../global.bloc";

const CalendarPicker = styled(MuiCalenderPicker)(({ theme }) => {
  const selectedDateStyles = {
    backgroundColor: theme.palette.accent.main,
    color: '#FFFFFF',
  };
  return {
    '& div[role="presentation"]': {
      fontSize: '20px',
    },
    '& .MuiCalendarPicker-viewTransitionContainer': {
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiPickersDay-root': {
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        '&:active': selectedDateStyles,
      },
      '& .MuiPickersDay-today': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '& .Mui-selected': {
        '&:hover': selectedDateStyles,
        '&:active': selectedDateStyles,
        '&:focus': selectedDateStyles,
        ...selectedDateStyles,
      },
    },
  };
});

const DateSelector = () => {
  const { calendarSummary, bookingBloc, loadingData, booking } = useBookingWizardContext();
  const maxDate = React.useMemo(() => {
    if(globalBloc.isTelehealth()) {
      return new Date(new Date().setDate(new Date().getDate() + 6));
    } else {
      return new Date(new Date().setDate(new Date().getDate() + 30));
    }
  }, []);
  const selectedDate = booking?.selectedDate ?? new Date();

  React.useEffect(() => {
    bookingBloc?.loadServiceSchedule()
    .then(() => {
      if(globalBloc.isTelehealth()) {
        bookingBloc.setSelectedDate(selectedDate);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShouldDisableDate = React.useCallback(
    (day) => {
      const date = dateUtil.formatIsoDateAdjust(day);

      if (day > maxDate) return true;

      if(date === '2023-12-25') {
        return true;
      }

      let capacity = 0;
      if (calendarSummary && calendarSummary.schedule) {
        const summaryInformation = calendarSummary.schedule.filter((_item) => _item.date === date);

        if (summaryInformation.length > 0) {
          capacity = summaryInformation[0].capacity;
        }
      }

      return capacity === 0;
    },
    [maxDate, calendarSummary],
  );

  const handleChange = React.useCallback(
    (date) => {
      bookingBloc.setSelectedDate(date);
    },
    [bookingBloc],
  );

  return loadingData ? (
    <Loading />
  ) : (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CalendarPicker
        sx={{ marginLeft: "0" }}
        openTo="day"
        disablePast={true}
        views={['day']}
        date={selectedDate}
        onChange={handleChange}
        shouldDisableDate={handleShouldDisableDate} />
    </LocalizationProvider>
  );
};

export default DateSelector;
