import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';

import { Box, CircularProgress } from '@mui/material';
import DecodedButton, { DecodedSecondaryButton } from '../../common/DecodedButton';
import { globalBloc } from '../../global.bloc';
import LeaveButton from '../../../shared-library/components/LeaveButton';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';

const styles = (theme) => ({
  fullscreen: {
    display: 'flex',
    position: 'fixed',
    right: '0',
    bottom: '0',
    minWidth: '100%',
    minHeight: '100%',
    backgroundColor: '#ffffff',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    margin: '0 auto',
    height: '80px',
  },
  progress: {
    color: '#F05726',
  },
  message: {
    paddingLeft: '24px',
    color: '#ccc',
  },
});

class Loading extends Component {
  timeout;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.shotTimeout) {
      this.timeout = setTimeout(() => {
        this.setState({
          reload: true,
        });
      }, 30000);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    let { classes, message } = this.props;
    let { reload } = this.state;

    return (
      <div className={classes.fullscreen}>
        <Box>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress classes={{ circle: classes.progress }} />
            {message ? <p className={classes.message}>{message}</p> : <></>}
          </Box>
          {reload && (
            <Box sx={{ paddingTop: '24px' }}>
              <Button
                onClick={() => {
                  globalBloc.clearSession();
                  window.location.reload();
                }}
                variant="contained"
                sx={{
                  backgroundColor: '#F05726!important',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'auto',
                  borderRadius: '20px !important',
                }}
              >
                Restart
              </Button>
            </Box>
          )}
        </Box>
      </div>
    );
  }
}

export default withStyles(styles)(Loading);
