import axios from 'axios';

class AssistantApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
  }

  generateToken() {
    return axios.get(`${this.baseUrl}/api/assistant/_auth`);
  }

  generateDirectlineToken() {
    return axios.get(`${this.baseUrl}/api/consumers/me/conversations/_start`);
  }
}

export const assistantApi = new AssistantApi();
