
import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import {authService} from '../../../utils/auth';
import {SelectValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {FormattedMessage} from 'react-intl';


import CircularProgress from '@mui/material/CircularProgress';
import {Grid, MenuItem, styled} from '@mui/material';
import Typography from '@mui/material/Typography';

import ChatBubble from '../../shared/ChatBubble';
import PageContainer from "../../common/PageContainer";
import {FormattedMarkdown} from '@decodedhealth/react-library';
import {useEthnicCodesets, useMarketingChannelCodesets, useRaceCodesets} from '../../../utils/services/register.api';
import {ScrollableBox, ScrollableQuinnContainer, ScrollableWrapper} from "../../common/ScrollableContainer";

const styles = (theme) => ({
    validation: {
        flex: '1',
        width: '100%',
    },
    title: {
        paddingTop: '1em',
        color: '#FF7700',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '100%',
    },
    formInput: {
        flex: '1',
    },
    buttonGroup: {
        paddingTop: '2em',
    },
    button: {
        width: '100%',
        padding: '1em',
    },


});

const channels = [
    'Passed by clinic',
    'Referral',
    'Internet/ Online Search',
    'Magazine',
    'Radio',
    'TV',
    'Billboard',
    'Social Media',
    'Other'
]

const Item = styled(MenuItem)({'&:hover': {color: '#ffffff'}});

const AdditionalInformation = (props) => {

    const isLoading = () => {
        return props.loading || authService.isLoading();
    };
    const {
        classes,
        handleNext,
        handleBack,
        hearAboutUs,
        race,
        ethnicity,
        handleTextChange,
        setDirty,
    } = props;


    const [readOnly, setReadOnly] = useState(true);
    const [loading, setLoading] = useState(true)

    const [races, racesLoaded] = useRaceCodesets();
    const [ethnicities, ethnicitiesLoaded] = useEthnicCodesets();
    const [marketingChannels, marketingChannelsLoaded] = useMarketingChannelCodesets();

    const onEdit = () => {
        setReadOnly(false);
        setDirty();
    };

    const checkProps = () => {
        const propsArray = [
            hearAboutUs,
            race,
            ethnicity,
        ];

        const isAnyPropEmpty = propsArray.some(prop => prop == null || prop === "");

        if (isAnyPropEmpty) {
            setReadOnly(false);
            setLoading(false);
            setDirty();
        }else {
            setLoading(false);
        }

    };

    useEffect(() => {
        checkProps();
    }, [ hearAboutUs, race, ethnicity]);

    return (
        <PageContainer loading={loading} onBack={handleBack}>
            {loading ? (
                <ScrollableQuinnContainer
                    messageId="registration.user.bubble.loading.readonly"
                    message={'Loading your additional information.'}
                ></ScrollableQuinnContainer>
            ) : (
            <ScrollableQuinnContainer
                messageId="update.user.bubble.additionalInformation"
                message={'Please confirm or update your information below.'}>
                <ValidatorForm autoComplete="off" onSubmit={handleNext} className={classes.validation}>
                    <div className={classes.form}>
                        <div className={classes.formInput}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" component={'div'} className={classes.title}>
                                        <FormattedMarkdown
                                            id="registration.user.step.two"
                                            defaultMessage="3 of 5 Personal Information - Additional Information"
                                        />
                                    </Typography>
                                </Grid>

                                {racesLoaded && (
                                    <Grid item xs={12} sm={12}>
                                        <SelectValidator
                                            fullWidth
                                            select
                                            id="race"
                                            name="race"
                                            label="Race"
                                            value={race}
                                            onChange={handleTextChange}
                                            disabled={readOnly}
                                            required
                                            validators={['required']}
                                            errorMessages={[
                                                <FormattedMessage
                                                    id="registration.field.error.race"
                                                    defaultMessage={'This field is required'}
                                                />,
                                            ]}
                                        >
                                            {races.map((r, i) => (
                                                <Item key={r.code} value={r.code}>
                                                    {r.value}
                                                </Item>
                                            ))}
                                        </SelectValidator>
                                    </Grid>
                                )}

                                {ethnicitiesLoaded && (
                                    <Grid item xs={12} sm={12}>
                                        <SelectValidator
                                            fullWidth
                                            select
                                            id="ethnicity"
                                            name="ethnicity"
                                            label="Ethnicity"
                                            value={ethnicity}
                                            onChange={handleTextChange}
                                            required
                                            validators={['required']}
                                            errorMessages={[
                                                <FormattedMessage
                                                    id="registration.field.error.ethnicity"
                                                    defaultMessage={'This field is required'}
                                                />,
                                            ]}
                                            disabled={readOnly}
                                        >
                                            {ethnicities.map((r, i) => (
                                                <Item key={r.code} value={r.code}>
                                                    {r.value}
                                                </Item>
                                            ))}
                                        </SelectValidator>
                                    </Grid>
                                )}

                                {marketingChannelsLoaded &&
                                    <Grid item xs={12} sm={12}>
                                        <SelectValidator
                                            fullWidth
                                            select
                                            id="hearAboutUs"
                                            name="hearAboutUs"
                                            label="How did you hear about us?"
                                            value={hearAboutUs}
                                            onChange={handleTextChange}
                                            disabled={readOnly}
                                            validators={['required']}
                                            errorMessages={[
                                                                <FormattedMessage
                                                                                    id="registration.field.error.marketingChannel"
                                                                                    defaultMessage={'This field is required'}
                                                                />,
                                            ]}
                                        >
                                            {marketingChannels.map((r, i) => (
                                                <Item key={r.code} value={r.code}>
                                                    {r.value}
                                                </Item>
                                            ))}
                                        </SelectValidator>
                                    </Grid>
                                }

                            </Grid>
                        </div>

                        <Grid
                            container
                            spacing={2}
                            justifyContent="flex-start"
                            className={classes.buttonGroup}
                        >
                            {readOnly && <Grid item xs={12} sm={6} className={classes.button}>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    disabled={isLoading()}
                                    onClick={onEdit}
                                >
                                    {isLoading() ? (
                                        <CircularProgress size="1.5em"/>
                                    ) : (
                                        <FormattedMessage
                                            id="registration.button.label.edit"
                                            defaultMessage="EDIT"
                                        />
                                    )}
                                </Button>
                            </Grid>}
                            <Grid item xs={12} sm={6} className={classes.button}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isLoading()}
                                >
                                    {isLoading() ? (
                                        <CircularProgress size="1.5em"/>
                                    ) : (
                                        <FormattedMessage
                                            id="registration.button.label.submit"
                                            defaultMessage="NEXT"
                                        />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>


                    </div>
                </ValidatorForm>
            </ScrollableQuinnContainer>
                )}
        </PageContainer>
    );

};

export default withStyles(styles)(AdditionalInformation);
