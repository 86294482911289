import React, { useEffect, useState } from 'react';
import { AppBar, Typography, Grid, Box, Button, CircularProgress } from '@mui/material';
import logo from '../../../../assets/KioskWelcomeAssets/logo-white.svg';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import QRCode from 'react-qr-code';
import { providerStorage } from '../../../../utils/provider.qs';
import { administrationApi } from '../../../../utils/services/administration.api';
import {authService} from "../../../../utils/auth";
import {uriStorage} from "../../../../utils/storage";

const orange = '#F05726 !important';

const font = 'Montserrat, sans-serif !important';

const baseFontColor = '#414042';

const gradientHeader = 'linear-gradient(180deg, #038AAB, #4BAEC6)';

const gradientBG = 'linear-gradient(180deg, #4BAEC6, #038AAB)';

const bebo = 'https://storage.googleapis.com/dh-public-production/patientplus/vfd/bebo-kiosk.svg';

//==> FIRST SCREEN CHILD COMPONENT

export const CheckInOptions = ({ handleNext }) => {
  const [loading, setLoading] = useState(false);
  const [qrValue, setQrValue] = useState('');
  const currentProvider = providerStorage.getCurrentProvider();

  useEffect(() => {
    administrationApi
      .getLocationToken(currentProvider)
      .then((response) => {
        const value = `${window.location.protocol}//${window.location.host}/link/qr/${currentProvider}/${response.data}`;

        localStorage.setItem('locationToken', response.data);

        setQrValue(value);
      })
      .catch(() => {
        const prevLocationToken = localStorage.getItem('locationToken') || ''; // if no prev token, use empty string

        const value = `${window.location.protocol}//${window.location.host}/link/qr/${currentProvider}/${prevLocationToken}`;

        setQrValue(value);
      });
  }, []);


  const mediaQuery = useMediaQueries();

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      handleNext();
      setLoading(false);
    }, 1000);
  };

  return (
    <Box sx={{ width: '100%', minHeight: '100vh' }}>
      <AppBar
        position="static"
        style={{
          background: gradientHeader,
          minHeight: '15%',
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',

            marginLeft: '20px',
            marginRight: '20px',
          }}
        >
          <img
            src={logo}
            alt="logo-white"
            style={{
              width: mediaQuery.isSm ? '200px' : '250px',
            }}
          />
          <img
            src={bebo}
            alt="bebo"
            style={{
              width: mediaQuery.isSm ? '150px' : '250px',

              position: 'absolute',
              bottom: '0',
              right: '20px',
            }}
          />
        </Box>
      </AppBar>
      <Box mt={3} p={3}>
        <Grid container direction="column" spacing={3}>
          <Grid xs={12} item>
            <Typography
              align="center"
              sx={{
                fontSize: '2rem !important',
                fontWeight: '500',
                fontFamily: font,
                color: baseFontColor,
              }}
            >
              Please choose from the options below for your preferred check-in method.
            </Typography>
          </Grid>

          <Grid container display="flex" flexDirection="row" flexWrap="wrap" item spacing={2}>
            <Grid
              item
              gap={3}
              xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '3rem 3rem !important',
              }}
            >
              <Typography
                sx={{
                  color: orange,
                  fontSize: '4rem !important',
                  fontWeight: '600',
                }}
                align="center"
              >
                Mobile Phone
              </Typography>
              <Typography
                align="center"
                sx={{
                  fontSize: '2rem !important',
                  fontWeight: '500',
                  fontFamily: font,
                  color: baseFontColor,
                }}
              >
                Scan the QR code with your mobile device to complete check-in on your phone.
              </Typography>
              {qrValue ? (
                <QRCode size={200} value={qrValue} />
              ) : (
                <Box
                  sx={{
                    paddingTop: '75px',
                  }}
                >
                  <CircularProgress
                    sx={{
                      '& .MuiCircularProgress-circle': {
                        color: `${orange}`,
                      },
                    }}
                    size={50}
                  />
                </Box>
              )}
            </Grid>
            <Grid
              item
              gap={3}
              xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '3rem 3rem !important',
              }}
            >
              <Typography
                sx={{
                  color: orange,
                  fontSize: '4rem !important',
                  fontWeight: '600',
                }}
                align="center"
              >
                Kiosk
              </Typography>
              <Typography
                align="center"
                sx={{
                  fontSize: '2rem !important',
                  fontWeight: '500',
                  fontFamily: font,
                  color: baseFontColor,
                }}
              >
                Press the button below to check-in on the kiosk.
              </Typography>
              <Button
                onClick={handleClick}
                variant="contained"
                sx={{
                  padding: mediaQuery.isMd ? null : '.61111em 1.22222em !important',
                  fontSize: mediaQuery.isMd ? '2rem' : '3rem',
                  backgroundColor: orange,
                  borderRadius: '20px !important',
                  boxShadow: '0px 2px 10px 4px rgba(0, 0, 0, 0.25) !important',
                  fontFamily: font,
                }}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={mediaQuery.isMd ? '2.5rem' : '3.5rem'} />
                ) : (
                  'CONTINUE >'
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

//==> SECOND SCREEN CHILD COMPONENT

const LetsGetStarted = ({ handleNextStep }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      authService.logout().then(() => {
        uriStorage.clearPath();
        providerStorage.clearProvider();
      }).finally(() => handleNextStep());
      setLoading(false);
    }, 1000);
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        background: gradientBG,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Typography
        variant="h1"
        color="white"
        align="center"
        sx={{
          fontWeight: '600',
          fontSize: '8em !important',
          marginBottom: '2rem',
          fontFamily: font,
          width: '80%',
        }}
      >
        Let's get you checked-in
      </Typography>
      <Button
        onClick={handleClick}
        disabled={loading}
        variant="contained"
        sx={{
          padding: '.61111em 1.22222em !important',
          fontSize: '3rem',
          backgroundColor: orange,
          borderRadius: '20px !important',
          boxShadow: '0px 2px 10px 4px rgba(0, 0, 0, 0.25) !important',
          fontFamily: font,
        }}
      >
        {loading ? <CircularProgress color="inherit" size="3rem" /> : 'GET STARTED'}
      </Button>
    </Box>
  );
};

//==> PARENT COMPONENT

const KioskWelcome = ({ handleNext }) => {
  const [step, setStep] = useState(0);

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <>
      {step === 0 && <LetsGetStarted handleNextStep={handleNextStep} />}
      {step === 1 && <CheckInOptions handleNext={handleNext} />}
    </>
  );
};

export default KioskWelcome;
