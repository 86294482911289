import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Loading } from '../../../shared-library';
import { FormattedMessage } from 'react-intl';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../shared/Typography';
import PageContainer from '../../common/PageContainer';

import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import { logger } from '../../../utils/logging';
import { DecodedDialog, DecodedDialogTitle } from '../../shared/Dialog/Dialog';
import { DecodedButton, DecodedSecondaryButton } from '../../common/DecodedButton';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class MultipleChoices extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = {
      ...this.bloc.subject.value,
      showDialog: false,
      selectedOption: null,
      loading: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  handleButtonClick = (option) => {
    if (option.code === 'S') {
      this.setState({ showDialog: true, selectedOption: option });
    } else {
      this.bloc.submit(option.code);
    }
  };

  handleConfirmButtonClick = () => {
    this.setState({ showDialog: false, loading: true }, () => {
      this.bloc.submit(this.state.selectedOption.code);
    });
  };

  render() {
    const { loading, initialised, appointment, subscription, options, showDialog, selectedOption } =
      this.state;

    if (!initialised) {
      return (
        <PageContainer loading={false} onBack={() => {}}>
          <ScrollableQuinnContainer
            messageId={`paymentmethod.multiplechoices.loading.bubble`}
            message="Looking up payment options."
          >
            <Grid container direction="column" justifyContent="space-between" flex="1">
              <Loading isFullscreen={false} />
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.bloc.back();
        }}
      >
        <ScrollableQuinnContainer
          messageId={`paymentmethod.${appointment.service}.multiplechoice.bubble`}
          message="How would you like to pay for the visit?"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <H2TextTitleContent>
                  {subscription ? (
                    <>
                      <FormattedMarkdown
                        id={`paymentmethod.${appointment.service}.multiplechoice.text`}
                        defaultMessage={
                          'To use your Direct Plus membership for this visit, select Continue below. To select another form of payment for today’s visit, select another option below.'
                        }
                      />
                    </>
                  ) : (
                    <>
                      <FormattedMarkdown
                        id={`paymentmethod.${appointment.service}.multiplechoice.text`}
                        defaultMessage={'Please select one of the below options.'}
                      />
                    </>
                  )}
                </H2TextTitleContent>
              </Box>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          {options?.map((option) => (
            <>{this.__renderButton(option)}</>
          ))}
        </DefaultDisconnectedPageFooter>
        <DecodedDialog open={showDialog} onClose={() => this.setState({ showDialog: false })}>
          <DecodedDialogTitle>Confirm Payment Method</DecodedDialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to proceed with self-pay?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DecodedSecondaryButton onClick={() => this.setState({ showDialog: false })}>
              Cancel
            </DecodedSecondaryButton>
            <DecodedButton onClick={this.handleConfirmButtonClick}>Confirm</DecodedButton>
          </DialogActions>
        </DecodedDialog>
      </PageContainer>
    );
  }

  __renderButton = (option) => {
    return (
      <Grid item xs={12}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => this.handleButtonClick(option)}
        >
          {option.label}
        </Button>
      </Grid>
    );
  };
}

export default withStyles(styles)(withRouter(MultipleChoices));
