import { appointmentApi } from '../../../utils/services/appointments.api';
import { notificationService } from '../../../utils/notification';
import { DecodedBloc } from '../../shared/DecodedComponent/bloc';
import { uriStorage } from '../../../utils/storage';
import { authService } from '../../../utils/auth';
import { providerStorage } from '../../../utils/provider.qs';
import { dateUtil } from '../../../utils/date';
import { ErrorMessage } from '../../../utils/error.resolver';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { differenceInMinutes, isSameDay } from 'date-fns';
import { providerUtil } from '../../../utils/provider';
import { QUINN_SCHEDULED, routeUtil } from '../../../utils/route.name';

export class Bloc extends DecodedBloc {
  constructor(appointmentId) {
    super({
      appointmentId: appointmentId,
    });

    this.__initialise(appointmentId);
  }

  __initialise = (appointmentId) => {
    this.__updateSubject({
      loading: true,
      inClinic: providerStorage.isWalkin(),
    });

    appointmentApi
      .getAppointmentStatus(appointmentId)
      .then((result) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);

        if (result.data.status === 'COMPLETE') {
          this.__updateSubject({
            loading: false,
          });

          authService.logout().then(() => {
            uriStorage.clearPath();
            providerStorage.clearProvider();
          });
          return;
        }

        const startTime = dateUtil.parseDate(result.data.start);
        let newState = {};

        if (
          ['WAITING', 'CHECKING_IN'].includes(result.data.status) ||
          result.data.type === 'IN_PERSON_WALK_IN'
        ) {
          newState.checkinSuccess = true;
        } else if (result.data.type !== 'VIRTUAL') {
          newState.checkinAvailable = isSameDay(new Date(), startTime);
        }

        newState.queueNumber = result.data.waitingRoomSummary.numberInQueue;
        newState.waitTime = differenceInMinutes(startTime, new Date());
        newState.startTime = startTime;
        newState.timezone = result.data.timezone;
        newState.loading = false;
        newState.appointment = result.data;

        this.__updateSubject({
          ...newState,
        });

        if (result.data.provider) this._getProviderLocation(result.data.provider);
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_ERROR, {
          appointmentId: appointmentId,
          reason: error,
        });

        this.__updateSubject({
          checkinSuccess: false,
          waitTime: 'unknown',
          queueNumber: '_',
          checkinAvailable: false,
          loading: false,
        });

        notificationService.error('Error checking in for your visit. ' + ErrorMessage.CALL_SUPPORT);
      });
  };

  _getProviderLocation = (providerId) => {
    this.__updateSubject({
      loading: true,
    });
    appointmentApi
      .getAvailableProviders()
      .then((response) => {
        const providers = response.data.items.filter((place) => place.id === providerId);

        let providerDetails = providers.map((place) => ({
          lat: place.contactInformation.address.geoLocation.latitude,
          lng: place.contactInformation.address.geoLocation.longitude,
        }));

        if (providerDetails.length === 1) {
          this.__updateSubject({
            provider: providers[0],
            providerDetails: providerDetails[0],
            providerContactNumber: providerUtil.formatOrganisationContactNumber(providers[0]),
          });
        }
      })
      .catch((error) => {
        notificationService.error(
          'Error finding the clinic location. ' + ErrorMessage.CALL_SUPPORT,
        );
      })
      .finally(() => {
        this.__updateSubject({
          loading: false,
        });
      });
  };

  rescheduleDraft = () => {
    const { appointment } = this.subject.value;

    appointmentApi.getDraftAppointment(appointment.id).then(
      (res) => {
        const location = res.headers.location.split(':');
        const uuid = location[location.length - 1];
        const url = routeUtil.buildBookingRouteWithDraftAppointmentID(uuid, QUINN_SCHEDULED);
        uriStorage.setCurrentPath(url);

        this.__publishEvent(BlocEvent.NAVIGATE_TO, {
          url: url,
        });
      },
      (reason) => {
        notificationService.error(
          'There was an error trying to reschedule your appointment. Please try again.',
        );
      },
    );
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
