import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import { EmployerWizard } from './EmployerWizard';
import EmployerContext from './context';
import PayersLoading from './components/PayersLoading';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class Employer extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = { initialised: false };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    let context = { bloc: this.bloc };

    const component = this.__render();

    return (
      <EmployerContext.Provider value={context}>
        <>{component}</>
      </EmployerContext.Provider>
    );
  }

  __render() {
    const { initialised, payers, payer } = this.state;

    if (initialised && payers) {
      return <EmployerWizard bloc={this.bloc} />;
    }

    return <PayersLoading />;
  }
}

export default withStyles(styles)(withRouter(Employer));
