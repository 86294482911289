import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { OTSession, OTStreams } from 'opentok-react';

import Publisher from './Publisher';
import Subscriber from './Subscriber';
import Join from './Join';
import End from './End';
import TelehealthContext from './context';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';
import { FormattedMessage } from 'react-intl';
import Retry from './Retry';
import Chat from './Chat';
import { Drawer, Fab } from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { isIOS } from 'react-device-detect';
import { clinicBloc } from '../Secure/VirtualClinic/clinic.bloc';
import Dialin from './Dialin';
import { configurationUtil } from '../../utils/configuration';

const styles = (theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
  },
  messageWrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  messageText: {
    margin: 'auto',

    textTransform: 'capitalize',

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '22px',
    lineHeight: '33px',
    textAlign: 'center',

    color: '#FFFFFF',
  },

  chatButton: {
    zIndex: '1000',
    position: 'fixed',
    right: '10px',
    top: isIOS ? '40px' : '20px',
  },
  chatOpenButton: {
    zIndex: '1000',
    position: 'fixed',
    right: '42%',
    top: isIOS ? '40px' : '20px',
  },

  drawer: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  drawerCloseButton: {
    marginLeft: 'auto',
  },
  fabPrimary: {
    backgroundColor: '#07C6FF',
  },
  fabSecondary: {
    backgroundColor: '#FD1F54',
  },
  fab: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
});

class Display extends Component {
  constructor(props, context) {
    super(props, context);

    this.otSession = React.createRef();

    this.state = {
      error: null,
      connected: false,
      drawerOpen: false,
      messageUnread: false,
    };

    this.sessionEvents = {
      sessionConnected: () => {
        this.setState({ connected: true });
      },
      sessionDisconnected: () => {
        this.setState({ connected: false });
      },
    };
  }

  componentDidMount() {
    this.stateSubscription = clinicBloc.subscribeToState(this._handleState);
  }

  componentWillUnmount() {
    if (this.stateSubscription) this.stateSubscription.unsubscribe();
  }

  _handleState = (e) => {
    this.setState({
      messageUnread: e.messageUnread,
    });
  };

  _onError = (error) => {
    this.setState({
      error: error,
    });

    notificationService.error(`Error loading the telehealth session due to '${error.message}'`);

    analyticsEventLogger.log(AnalyticsEvent.TELEHEALTH_ERROR, {
      sessionId: this.props.sessionId,
      code: error.code,
    });
  };

  _toggleDrawer = (value) => {
    if (value != null) {
      this.setState({ drawerOpen: value });
      if (value) clinicBloc.setMessageUnread(false);
    } else {
      this.setState((state, props) => {
        if (!state.drawerOpen) clinicBloc.setMessageUnread(false);

        return {
          drawerOpen: !state.drawerOpen,
        };
      });
    }
  };

  _renderChatButtonColor = () => {
    const { messageUnread, drawerOpen } = this.state;

    if (!drawerOpen && messageUnread) return 'secondary';

    return drawerOpen ? 'default' : 'primary';
  };

  render() {
    const { classes } = this.props;
    const { telehealth } = this.props.context;
    const { error, drawerOpen } = this.state;

    if (!telehealth) {
      return <div className={classes.root}>Loading</div>;
    }

    if (telehealth.state === 'CONNECTED') {
      analyticsEventLogger.log(AnalyticsEvent.TELEHEALTH_START, {
        sessionId: this.props.sessionId,
      });

      return (
        <div className={classes.root}>
          {configurationUtil.getEnableTelehealthChatFlag() === 1 &&
            this.state.connected &&
            this.otSession.current && (
              <React.Fragment>
                <div className={drawerOpen ? classes.chatOpenButton : classes.chatButton}>
                  <Fab
                    color={this._renderChatButtonColor()}
                    onClick={() => this._toggleDrawer()}
                    classes={{
                      secondary: classes.fabSecondary,
                    }}
                    className={classes.fab}
                  >
                    <ForumIcon />
                  </Fab>
                </div>

                <Drawer
                  classes={{ paperAnchorRight: classes.drawer }}
                  anchor="right"
                  open={drawerOpen}
                  variant="persistent"
                  onClose={() => this._toggleDrawer(false)}
                >
                  <IconButton
                    aria-label="back"
                    onClick={() => this._toggleDrawer(false)}
                    className={classes.drawerCloseButton}
                    size="large"
                  >
                    <CloseIcon fontSize="large" />
                  </IconButton>

                  <Chat otSession={this.otSession} isChatOpen={drawerOpen} />
                </Drawer>
              </React.Fragment>
            )}

          <OTSession
            ref={this.otSession}
            apiKey={this.props.apiKey}
            sessionId={this.props.sessionId}
            token={this.props.token}
            eventHandlers={this.sessionEvents}
            onError={this._onError}
          >
            {error ? (
              <>
                <Retry error={error} />
              </>
            ) : null}

            <Publisher />
            {this.state.connected ? (
              <>
                <OTStreams>
                  <Subscriber isChatOpen={drawerOpen} />
                </OTStreams>
                <End isChatOpen={drawerOpen} />
                <Dialin isChatOpen={drawerOpen} />
              </>
            ) : (
              <div className={classes.messageWrapper}>
                <div className={classes.messageText}>
                  <FormattedMessage id={`connecting.text`} defaultMessage={'Connecting'} />
                </div>
              </div>
            )}
          </OTSession>
        </div>
      );
    }

    // eslint-disable-next-line react/react-in-jsx-scope
    return (
      <div className={classes.root}>
        <Join clearPoller={this.props.clearPoller} startPoller={this.props.startPoller} />
      </div>
    );
  }
}

export default withStyles(styles)((props) => (
  <TelehealthContext.Consumer>
    {(value) => {
      return <Display context={value} {...props} />;
    }}
  </TelehealthContext.Consumer>
));
