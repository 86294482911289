import { parse } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';
import { configurationUtil } from './configuration';

class DateUtil {
  fullFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' });
  longFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short' });

  encloses(at, from, to) {
    return at.getTime() >= from.getTime() && at.getTime() <= to.getTime();
  }

  parseDate(dateString) {
    const format = "yyyy-MM-dd'T'HH:mm:ssX";
    return parse(dateString, format, new Date());
  }

  formatIsoDateAdjust(date) {
    return `${date.getFullYear()}-${this.__formatTwoDigits(
      date.getMonth() + 1,
    )}-${this.__formatTwoDigits(date.getDate())}`;
  }

  __formatTwoDigits(digit) {
    return digit < 10 ? '0' + digit : digit;
  }

  formatDateTimeWithTimeZone = (startTime, timezone) => {
    return this.formatDateTimeWithTimeZoneInFormat(startTime, timezone, 'PPpp zzz');
  };

  formatDateTimeWithTimeZoneInFormat = (startTime, timezone, outputFormat) => {
    const displayTimezone =
      configurationUtil.getEnableTimeZoneFlag() === 1
        ? timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone;
    const clinicTime = utcToZonedTime(startTime, displayTimezone);

    return format(clinicTime, outputFormat, {
      timeZone: displayTimezone,
      locale: enUS,
    });
  };

  fullFriendlyOutput = (date) => {
    if (!date || typeof date === 'string') return date;
    return this.fullFormatter.format(date);
  };

  fullFriendlyOutputExcludeYear = (date) => {
    if (!date || typeof date === 'string') return date;
    return this.fullFormatter.format(date).replace(` ${date.getFullYear()}`, '');
  };

  longFriendlyOutputExcludeYear = (date) => {
    if (!date || typeof date === 'string') return date;
    return this.longFormatter.format(date).replace(` ${date.getFullYear()}`, '');
  };
}

export const dateUtil = new DateUtil();
