import * as React from "react";
import PageContainer from "../common/PageContainer";
import { Button, Grid, Link, Typography } from "@mui/material";
import ChatBubble from "../shared/ChatBubble";
import { makeStyles } from "@mui/styles";
import {
    DefaultDisconnectedPageFooter,
    ScrollableBox,
    ScrollableQuinnContainer,
    ScrollableWrapper
} from "../common/ScrollableContainer";
import {FormattedMessage} from "react-intl";
import {FormattedMarkdown} from "@decodedhealth/react-library";
import { H2TextTitleContent } from '../shared/Typography';
import DecodedButton from "../common/DecodedButton";

const useStepStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '1 1 auto',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    skip: {
        color: theme.palette.primary.main,
        fontSize: '1.6rem',
    },
    skipButton: {
        color: theme.palette.accent.main,
        textDecoration: 'underline',
        fontSize: '1.6rem',
    }
}));

const GridItem = (props) => <Grid {...props} item />;

export const IdentityVerificationRequired = ({ handleBack, onNext,}) => {

    const classes = useStepStyles();

    return (
        <PageContainer loading={false} onBack={handleBack}>
            <ScrollableQuinnContainer
                messageId="registration.scan.document.intro.walkin.nocard.bubble"
                message={`Please note, that you must present your ID card before we can see you.`} >

                        <Grid container direction="column">
                            <H2TextTitleContent>
                                <FormattedMarkdown
                                    id="registration.scan.document.intro.walkin.nocard.content"
                                    defaultMessage="If you don’t have your ID card with you, please see the front desk to complete your check-in process.

Otherwise, please click the back arrow and scan your ID card to complete your check-in process."
                                />
                            </H2TextTitleContent>
                        </Grid>
                </ScrollableQuinnContainer>
            <DefaultDisconnectedPageFooter justifyContent={"left"}>
                <Grid xs={12} sm={6} item>
                    <DecodedButton onClick={onNext}>END</DecodedButton>
                </Grid>
            </DefaultDisconnectedPageFooter>

        </PageContainer>
    );
};
