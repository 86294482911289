import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Loading } from '../../../shared-library';
import PageContainer from '../../common/PageContainer';

import { ScrollableQuinnContainer } from '../../common/ScrollableContainer';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class AutoRedirect extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = {
      ...this.bloc.subject.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.props.history.goBack();
        }}
      >
        <ScrollableQuinnContainer
          messageId={`paymentmethod.autoredirect.loading.bubble`}
          message="I am just verifying your information."
        >
          <Grid container direction="column" justifyContent="space-between" flex="1">
            <Loading isFullscreen={false} />
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(AutoRedirect));
