import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { notificationService } from '../../../../utils/notification';
import { routeUtil } from '../../../../utils/route.name';
import { appointmentApi } from '../../../../utils/services/appointments.api';
import ChatBubble from '../../../shared/ChatBubble';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';
import { format, parse } from 'date-fns';
import { buttonGroupStyle } from '../../../shared/CommonStyle';
import PageContainer from '../../../shared/Container';
import { FormattedMarkdown } from '@decodedhealth/react-library';

const styles = (theme) => ({
  ...buttonGroupStyle(theme),
  grid: {
    flex: '1',
    paddingTop: '2em',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textItem: {
    flex: '1',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  buttonGroup: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '3em',
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'right',
  },
  estimationText: {
    fontWeight: '700',
    textAlign: 'left',
    color: '#20516A',
    paddingBottom: '1.5em',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  scheduleText: {
    textAlign: 'left',
    color: '#20516A',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  progress: {
    color: '#F29202',
  },
});

const initState = {
  serviceCode: '',
  serviceCodeId: '',
  scheduleSummary: '',
};

class InpersonWalkin extends Component {
  constructor(props) {
    super(props);

    this.state = initState;
  }

  componentDidMount() {
    this.__checkSlot();

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_INPERSON_WALKIN_RESERVATION_OPEN, {
      appointmentId: this.props.draftId,
    });
  }

  _handleButtonClick = () => {
    let { history, draftId, appointmentType, setLoading } = this.props;

    let appointmentRequest = this.createWalkinAppointmentRequestData();

    setLoading(true);
    appointmentApi
      .updateAppointment(draftId, appointmentRequest)
      .then((response) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_INPERSON_WALKIN_RESERVE_SUCCESS, {
          appointmentId: draftId,
        });

        history.push(
          routeUtil.buildBookingConfirmationRouteWithAppointmentID(draftId, appointmentType),
        );
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_INPERSON_WALKIN_RESERVE_ERROR, {
          appointmentId: draftId,
          reason: error,
        });
        notificationService.error('Error reserving an in-person visit. Error - ' + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  _formatSlotTime = (slotTime) => {
    return format(slotTime, 'PPpp');
  };

  createWalkinAppointmentRequestData = () => {
    const { appointment } = this.props.bloc.subject.value;

    return {
      service: {
        code: {
          value: appointment.service,
        },
        channel: {
          value: 'IN_PERSON',
        },
      },
      reminder: 30,
      participants: [
        {
          identifier: {
            value: appointment.provider,
          },
        },
      ],
    };
  };

  __checkSlot = () => {
    const { availabilitySummary } = this.props.bloc.subject.value;

    if (availabilitySummary.firstOpenSlot) {
      const parsedTime = parse(
        availabilitySummary.firstOpenSlot,
        "yyyy-MM-dd'T'HH:mm:ssX",
        new Date(),
      );

      let dt = new Date();
      dt.setHours(dt.getHours() + 11);
      if (parsedTime.getTime() < dt.getTime()) {
        this.setState({
          firstSlot: parsedTime,
        });
      }
    }
  };

  render() {
    const { classes, loading } = this.props;
    const { firstSlot } = this.state;

    const assistantMessage =
      'We are booking an in-person visit. Please confirm and reserve a spot.';

    return (
      <PageContainer loading={loading}>
        <div>
          <ChatBubble messageId="booking.inperson.walkin.bubble" message={assistantMessage} />
        </div>

        <div className={classes.grid}>
          <div className={classes.textItem}>
            {loading ? (
              <CircularProgress classes={{ circle: classes.progress }} />
            ) : (
              <div className={classes.confirmation}>
                {firstSlot ? (
                  <Typography variant={'h4'} className={classes.scheduleText}>
                    <FormattedMarkdown
                      id={'booking.inperson.walkin.slot'}
                      defaultMessage={'The next available reservation time is: '}
                    />
                    <span>{this._formatSlotTime(firstSlot)}</span>
                  </Typography>
                ) : (
                  <Typography variant={'h4'} className={classes.scheduleText}>
                    <FormattedMarkdown
                      id={'booking.inperson.walkin.unavailable'}
                      defaultMessage={
                        'There is no available time today. Please check with the staff for more information.'
                      }
                    />
                  </Typography>
                )}
              </div>
            )}
          </div>

          <Grid container spacing={2} className={classes.buttonGroup}>
            <Grid item xs={12} sm={6} className={`${classes.button} ${classes.buttonGridItem}`}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={() => this.props.bloc.switchType('reservation')}
              >
                <FormattedMessage
                  id={'interaction.walkin.switch.to.schedule.btn'}
                  defaultMessage={'ANOTHER TIME'}
                />
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} className={`${classes.button} ${classes.buttonGridItem}`}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || !firstSlot}
                onClick={this._handleButtonClick}
              >
                {loading && <CircularProgress size="1.5em" />}
                {!loading && 'RESERVE YOUR SPOT'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(InpersonWalkin));
