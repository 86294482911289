import * as rxjs from 'rxjs';
import { appointmentApi } from '../../utils/services/appointments.api';
import { notificationService } from '../../utils/notification';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import React from 'react';
import { insurancesApi } from '../../utils/services/insurances.api';
import { paymentApi } from '../../utils/services/payment.api';
import { registrationApi } from '../../utils/services/register.api';
import { Bloc as InsuranceBloc } from './Insurance/bloc';
import { BOOKING_LIST_ROUTE, INVALID_ROUTE, QUINN_ROUTE, routeUtil } from '../../utils/route.name';
import { Bloc as NoticeBloc } from './Notice/bloc';
import { Bloc as EmployerBloc } from './Employer/bloc';
import { Bloc as SelfPayBloc } from './SelfPay/bloc';
import { Bloc as AutoRedirectBloc } from './AutoRedirect/bloc';
import { Bloc as MultipleChoicesBloc } from './MultipleChoices/bloc';
import { Bloc as SubscriptionBloc } from './Subscription/bloc';
import Notice from './Notice';
import Employer from './Employer';
import MultipleChoices from './MultipleChoices';
import Subscription from './Subscription';
import { AppointmentStatus, AppointmentType, PaymentMethod } from '../../utils/appointment';
import { catalogApi } from '../../utils/services/catalog.api';
import { providerStorage } from '../../utils/provider.qs';
import { ServiceSearchWrapper, ServiceWrapper } from '../../utils/service';
import SelfPay from './SelfPay';
import AutoRedirect from './AutoRedirect';
import { extensionApi } from '../../utils/services/extension.api';
import InsuranceComponent from './Insurance';
import mobileLogo from '../../assets/mobile_pp.svg';
import desktopLogo from '../../assets/patient_plus.svg';
import Bebo from '../../assets/bebo.svg';

export class Bloc {
  constructor(appointmentId, appointmentType) {
    const isWalkin = providerStorage.isWalkin();
    this.isKiosk = providerStorage.isKiosk();
    this.logoObject = {
      mobile: mobileLogo,
      desktop: desktopLogo,
    };

    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      loading: true,
      loadingData: true,
      isWalkin: isWalkin,
      appointmentId: appointmentId,
      appointmentType: appointmentType,
    });

    this.events = new rxjs.Subject();

    this.__initialise(appointmentId, isWalkin);
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  __initialise = (appointmentId, isWalkin) => {
    appointmentApi.getAppointmentStatus(appointmentId).then(
      (value) => {
        const appointment = value.data;

        if (
          appointment.type === AppointmentType.IN_PERSON &&
          [AppointmentStatus.ARRIVED, AppointmentStatus.CHECKING_IN].includes(appointment.status) &&
          [PaymentMethod.SUBSCRIPTION, PaymentMethod.SELF, PaymentMethod.UNKNOWN].includes(
            appointment.paymentMethod?.code,
          )
        ) {
          this.__onChoiceMade(appointment);
        } else {
          appointmentApi
            .getServiceProviderSummaryForService(appointment.provider, appointment.service)
            .then((serviceSearchResultValue) => {
              const serviceSearchResult = serviceSearchResultValue.data;

              const serviceWrapper = new ServiceSearchWrapper(serviceSearchResult);

              const service = serviceWrapper.loadFirstService(appointment.service);

              this.__updateSubject({
                appointment: appointment,
                service: service,
                loading: false,
                loadingData: false,
              });

              this.__initialisePaymentMethod(appointment, service);

              this.events.next({
                type: BlocEvent.INITIALISED,
                data: {
                  appointment: appointment,
                },
              });
            });
        }
      },
      (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR, {
          reason: `${reason}`,
        });
        notificationService.error(
          'Unable to load your appointment status. Please try refreshing your browser and if the problem persists call the clinic.',
        );
      },
    );
  };

  __initialisePaymentMethod = (appointment, service) => {
    const serviceObj = new ServiceWrapper(service);
    if (serviceObj.isManual()) {
      const autoRedirectBloc = new AutoRedirectBloc(
        {
          appointmentApi: appointmentApi,
          catalogApi: catalogApi,
          appointment: appointment,
        },
        this.__onManualSuccess,
      );
      autoRedirectBloc.initialise();

      this.__updateSubject({
        component: <AutoRedirect bloc={autoRedirectBloc} />,
      });
    } else if (serviceObj.isOnlySelfPay()) {
      const selfpayBloc = new SelfPayBloc(
        { appointment: appointment, service: service },
        this.__onInsuranceUpdateSuccess,
      );
      this.__updateSubject({
        component: <SelfPay bloc={selfpayBloc} />,
      });
    } else if (serviceObj.hasMultipleChoices()) {
      this.__loadMultiChoice(appointment, service);
    } else if (appointment.service.includes('-OCC') || appointment.service.includes('WC-')) {
      const employerBloc = new EmployerBloc(
        {
          appointmentApi: appointmentApi,
          catalogApi: catalogApi,
          appointment: appointment,
        },
        this.__onEmployerSuccess,
      );
      employerBloc.initialise();

      this.__updateSubject({
        component: <Employer bloc={employerBloc} />,
      });
    } else if (appointment.service.includes('TT-UC')) {
      const noticeBloc = new NoticeBloc({ appointment: appointment }, this.__onNoticeSuccess);

      this.__updateSubject({
        component: <Notice bloc={noticeBloc} />,
      });
    } else if (appointment.service.includes('-UC') || appointment.type === 'VIRTUAL') {
      this.__loadInsurance(appointment, service, 0);
    } else {
      const bloc = new InsuranceBloc(
        {
          appointmentApi: appointmentApi,
          extensionApi: extensionApi,
          insuranceApi: insurancesApi,
          paymentApi: paymentApi,
          codesetApi: registrationApi,
          appointment: appointment,
          AnalyticsEvent: AnalyticsEvent,
          analyticsEventLogger: analyticsEventLogger,
          notificationService: notificationService,
          isKiosk: this.isKiosk,
          logoObject: this.logoObject,
          avatar: Bebo,
          avatarName: 'Bebo',
        },
        this.__onInsuranceUpdateSuccess,
        this.__handleSelfPay,
        this.__onBack,
      );

      this.__updateSubject({
        initialising: false,
        component: <InsuranceComponent bloc={bloc} />,
      });
    }
  };

  __onChoiceSelected = (choice) => {
    const { appointment, service } = this.subject.value;

    if (choice === 'I') {
      this.__loadInsurance(appointment, service, 0);
    } else if (choice === 'S') {
      this.__handleSelfPay();
    } else if (choice === 'D') {
      this.__loadSubscription(appointment, service, 0);
    } else {
      notificationService.error('Invalid choice.');
    }
  };

  __handleSelfPay = () => {
    const { appointment } = this.subject.value;

    appointmentApi
      .command(appointment.id, {
        command: 'set_payment_method',
        code: 'S',
      })
      .then(
        (value) => {
          this.__onSelfPaySuccess();
        },
        (reason) => {
          notificationService.error(
            'Unable to set payment method. Please try again. ',
            reason.message,
          );
        },
      );
  };

  __onEmployerSuccess = () => {
    const { appointmentId, appointmentType } = this.subject.value;

    this.events.next({
      type: BlocEvent.NAVIGATE_TO,
      url: routeUtil.buildBookingRouteWithDraftAppointmentID(appointmentId, appointmentType),
    });
  };

  __onManualSuccess = () => {
    const { appointmentId, appointmentType } = this.subject.value;

    this.events.next({
      type: BlocEvent.NAVIGATE_TO,
      url: routeUtil.buildBookingRouteWithDraftAppointmentID(appointmentId, appointmentType),
    });
  };

  __onBack = (option) => {
    const { appointment, service } = this.subject.value;

    if (option === -1) {
      if (['DRAFT', 'PENDING'].includes(appointment.status)) {
        this.events.next({
          type: BlocEvent.NAVIGATE_TO,
          url: QUINN_ROUTE,
        });
      } else {
        this.events.next({
          type: BlocEvent.NAVIGATE_TO,
          url: BOOKING_LIST_ROUTE,
        });
      }
    } else if (option === 0) {
      this.__loadMultiChoice(appointment, service);
    }
  };

  __loadMultiChoice = (appointment, service) => {
    const multipleChoicesBloc = new MultipleChoicesBloc(
      { appointment: appointment, service: service },
      this.__onChoiceSelected,
      this.__onBack,
    );
    this.__updateSubject({
      component: <MultipleChoices bloc={multipleChoicesBloc} />,
    });
  };

  __loadInsurance(appointment, service, source) {
    const bloc = new InsuranceBloc(
      {
        appointmentApi: appointmentApi,
        extensionApi: extensionApi,
        insuranceApi: insurancesApi,
        paymentApi: paymentApi,
        codesetApi: registrationApi,
        appointment: appointment,
        source: source,
        AnalyticsEvent: AnalyticsEvent,
        analyticsEventLogger: analyticsEventLogger,
        notificationService: notificationService,
        isKiosk: this.isKiosk,
        logoObject: this.logoObject,
        avatar: Bebo,
        avatarName: 'Bebo',
      },
      this.__onInsuranceUpdateSuccess,
      this.__handleSelfPay,
      this.__onBack,
    );
    bloc.initialise();

    this.__updateSubject({
      component: <InsuranceComponent bloc={bloc} />,
    });
  }

  __loadSubscription(appointment, service, source) {
    const subscriptionBloc = new SubscriptionBloc(
      { appointment: appointment, service: service, type: 'choice', source: source },
      this.__onInsuranceUpdateSuccess,
      this.__onBack,
    );
    this.__updateSubject({
      component: <Subscription bloc={subscriptionBloc} />,
    });
  }

  __onChoiceMade = () => {
    const { appointmentId, appointmentType } = this.subject.value;

    this.events.next({
      type: BlocEvent.NAVIGATE_TO,
      url: routeUtil.buildBookingPaymentRouteWithAppointmentID(appointmentId, appointmentType),
    });
  };

  __onSelfPaySuccess = () => {
    this.__onInsuranceUpdateSuccess();
  };

  __onInsuranceUpdateSuccess = () => {
    const { appointmentId, appointmentType, appointment } = this.subject.value;

    if (
      appointment.status === AppointmentStatus.DRAFT ||
      appointment.status === AppointmentStatus.PENDING
    ) {
      this.events.next({
        type: BlocEvent.NAVIGATE_TO,
        url: routeUtil.buildBookingRouteWithDraftAppointmentID(appointmentId, appointmentType),
      });
    } else if (
      appointment.status === AppointmentStatus.RESERVED ||
      appointment.status === AppointmentStatus.REQUESTED ||
      appointment.status === AppointmentStatus.ARRIVED ||
      appointment.status === AppointmentStatus.CHECKING_IN
    ) {
      this.events.next({
        type: BlocEvent.NAVIGATE_TO,
        url: routeUtil.buildBookingPaymentRouteWithAppointmentID(appointmentId, appointmentType),
      });
    } else {
      this.events.next({
        type: BlocEvent.NAVIGATE_TO,
        url: INVALID_ROUTE,
      });
    }
  };

  __onNoticeSuccess = () => {
    const { appointmentId, appointmentType } = this.subject.value;

    this.events.next({
      type: BlocEvent.NAVIGATE_TO,
      url: routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
        appointmentId,
        appointmentType,
      ),
    });
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
