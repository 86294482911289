import axios from 'axios';
import { VERSION } from '../version';
import { tenantUtil } from '../tenant';
import retry from '../retry';

class AdministrationApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';

    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Content-Type'] = 'application/json';
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  createPin(handle) {
    const request = {
      payload: {
        command: 'create_admin_pin',
        handle: handle,
      },
    };
    return this.instance.post(`${this.baseUrl}/api/administration/_command`, request);
  }

  checkPin(token) {
    const request = {
      payload: {
        command: 'check_admin_pin',
        token: token,
      },
    };
    return this.instance.post(`${this.baseUrl}/api/administration/_command`, request);
  }

  terminals(pin) {
    const request = {
      payload: {
        command: 'list_terminals',
        pin: pin,
      },
    };
    return this.instance.post(`${this.baseUrl}/api/administration/_command`, request);
  }

  terminalConnectionToken(location, token) {
    const request = {
      payload: {
        command: 'create_terminal_token',
        token: token,
        location: location,
      },
    };
    return this.instance.post(`${this.baseUrl}/api/administration/_command`, request);
  }

  getLocationToken(location) {
    return retry(() => this.instance.get(`${this.baseUrl}/api/organisations/${location}/code`));
  }
}

export const administrationApi = new AdministrationApi();
