import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { notificationService } from '../../../../utils/notification';
import ChatBubble from '../../../shared/ChatBubble';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { parse, format, compareAsc, isAfter } from 'date-fns';
import { appointmentApi } from '../../../../utils/services/appointments.api';
import { QUINN_ROUTE, QUINN_SCHEDULED, routeUtil } from '../../../../utils/route.name';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';
import { FormattedMessage } from 'react-intl';
import { phoneUtil } from '../../../../utils/phone';
import { dateUtil } from '../../../../utils/date';
import { BookingBlocEvent } from '../bloc';
import DateSelection from './components/DateSelection';
import TimeSelection from './components/TimeSelection';
import Confirmation from './components/Confirmation';
import ReminderSelection from './components/ReminderSelection';
import PageContainer from '../../../common/PageContainer';
import { uriStorage } from '../../../../utils/storage';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { ScrollableQuinnContainer } from '../../../common/ScrollableContainer';

const styles = (theme) => ({
  content: {
    flex: '1',
    paddingTop: '2em',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textItem: {
    flex: '1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontWeight: '700',
  },
  questionText: {
    color: '#20516A',
    fontWeight: '700',
  },
  noteText: {
    color: '#000000',
    fontSize: '1.4em',
    paddingTop: '18px',
  },
  graph: {
    position: 'relative',
    width: '100%',
    minWidth: '90%',
    height: '50%',
    minHeight: '40%',
    [theme.breakpoints.down('sm')]: {
      minHeight: '160px',
      maxHeight: '160px',
    },
  },
  confirmation: {
    width: '100%',
    lineHeight: '1.75',
    color: '#F37421',
    fontWeight: '900',
    marginBottom: '1.875em',
    paddingTop: '0.5em',
    padding: '3em',
    [theme.breakpoints.down('sm')]: {
      padding: '1em',
    },
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'right',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
  },
  formInput: {
    flex: '1',
  },
  inputGrid: {
    paddingTop: '1.5em',
  },
  dialogTitle: {
    color: '#F37421',
    fontWeight: '900',
  },
  dialogContentText: {
    color: '#F37421',
  },
  dialogButton: {
    maxWidth: '150px',
    margin: '1em',
  },
  phoneNumberText: {
    color: '#20516A',
    fontWeight: '550',
    fontSize: '1.5em',
    paddingBottom: '1em',
  },
  specificTimeWrapper: {
    minHeight: '54px',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      padding: '10px 10px 10px 0',
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px 0 0 0',
    },
  },
  specificTime: {
    marginRight: '10px',
    width: '144px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  specificTimeSelected: {
    marginRight: '10px',
    width: '144px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
});

class Reservation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      schedulingIntervals: [],
      ...this.__lookupStep(0),
    };

    this.__handleStateUpdate = this.__handleStateUpdate.bind(this);
    this._selectSlot = this._selectSlot.bind(this);
  }

  componentDidMount() {
    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RESERVATION_OPEN, {
      appointmentId: this.props.draftId,
    });

    this.stateSubscription = this.props.bloc.subscribeToState(this.__handleStateUpdate);
    this.eventSubscription = this.props.bloc.subscribeToEvents(this.__watchEvents);
  }

  componentWillUnmount() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  __handleStateUpdate = (state) => {
    this.setState({
      ...state,
    });
  };

  __watchEvents = (event) => {
    const { type } = event;
    if (type === BookingBlocEvent.DATE_SELECTED) {
      this.setState({
        ...this.__lookupStep(1),
      });
    } else if (type === BookingBlocEvent.TIME_SELECTED) {
      this.setState({
        ...this.__lookupStep(2),
      });
    } else if (type === BookingBlocEvent.REMINDER_SELECTED) {
      this.setState({
        ...this.__lookupStep(3),
      });
    } else if (type === BookingBlocEvent.BOOKING_CONFIRMED) {
      this.props.history.push(
        routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
          this.state.appointment.id,
          QUINN_SCHEDULED,
        ),
      );
    }
  };

  __back = () => {
    const step = this.state.step - 1;
    this.setState({
      ...this.__lookupStep(step),
    });
  };

  __backToConversation = () => {
    uriStorage.clearPath(true);
    this.props.history.push(QUINN_ROUTE);
  };

  __lookupStep = (step) => {
    switch (`${step}`) {
      case '0':
        return {
          step: step,
          component: (
            <DateSelection
              messageId="interaction.appointment.reservation.chatbubble.selectdate"
              message="Please, select the reservation date:"
              bloc={this.props.bloc}
            />
          ),
        };
      case '1':
        return {
          step: step,
          component: (
            <TimeSelection
              messageId="interaction.appointment.reservation.chatbubble.selecttime"
              message="When would you like to come in?"
              bloc={this.props.bloc}
            />
          ),
        };
      case '2':
        return {
          step: step,
          component: (
            <ReminderSelection
              messageId="interaction.appointment.reservation.chatbubble.selectreminder"
              message="How long before do you want to get a reminder?"
              bloc={this.props.bloc}
            />
          ),
        };
      default:
        return {
          step: step,
          component: (
            <Confirmation
              messageId="interaction.appointment.reservation.chatbubble.confirm"
              message="Please, check your appointment details and confirm:"
              bloc={this.props.bloc}
            />
          ),
        };
    }
  };

  _checkExistingAppointments = () => {
    let { setLoading } = this.props;

    setLoading(true);

    appointmentApi
      .getAppointmentForDate(this.state.selectedDate)
      .then((result) => {
        if (result.data.items && result.data.items.length > 0) {
          this.setState({
            dialogOpen: true,
            existingAppointments: result.data.items,
          });

          setLoading(false);
        } else {
          this.handleSubmit();
        }
      })
      .catch((error) => {
        notificationService.error('Error checking for existing reservations. Error - ' + error);
        this.handleSubmit();
      });
  };

  handleDateChange = (date) => {
    this.setState({
      searchDisplayDate: date,
      selectedInterval: '',
      selectedStart: undefined,
    });

    this.props.bloc.searchServiceSchedule(date);
  };

  _selectSlot = (slot) => {
    const $this = this;
    return () => {
      $this.setState({
        selectedStart: slot.start,
      });
    };
  };

  handleSubmit = () => {
    let { draftId, appointmentType, history, setLoading } = this.props;

    let appointmentRequest = this.createAppointmentRequestData();

    setLoading(true);
    appointmentApi
      .updateAppointment(draftId, appointmentRequest)
      .then((response) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RESERVE_SUCCESS, {
          appointmentId: draftId,
        });

        history.push(
          routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(draftId, appointmentType),
        );
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_RESERVE_ERROR, {
          appointmentId: draftId,
          reason: error,
        });

        notificationService.error('Error reserving an in-person visit. Error - ' + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  createAppointmentRequestData = () => {
    let { reminder, selectedInterval, selectedStart, serviceCodeId } = this.state;

    if (selectedStart) {
      selectedInterval.setMinutes(selectedStart.split(':')[1]);
    }

    return {
      service: {
        code: {
          value: serviceCodeId,
        },
        channel: {
          value: 'IN_PERSON',
        },
      },
      slot: {
        intervalStart: selectedInterval,
      },
      reminder: reminder,
      participants: [
        {
          identifier: {
            value: this.state.appointment.provider,
          },
        },
      ],
    };
  };

  handleReminderChange = (event) => {
    this.setState({
      reminder: event.target.value,
    });
  };

  handleTimeChange = (event) => {
    this.setState({
      selectedInterval: event.target.value,
      selectedStart: undefined,
    });
  };

  _handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  __clickOnBar = (event, index) => {
    if (!this.props.loading && event && event.activePayload && event.activePayload.length > 0) {
      const item = event.activePayload[0];
      this.setState({
        selectedInterval:
          item.payload.start && this._isIntervalAvailable(item.payload) ? item.payload.start : '',
        selectedStart: undefined,
      });
    }
  };

  _isIntervalAvailable = (interval) => {
    const now = new Date();
    return interval.capacity > 0 && isAfter(interval.parsedTime, now);
  };

  renderCellColour = (entry, index) => {
    let colour = index === this.state.activeIndex ? '#F37421' : '#F29202';
    return !this._isIntervalAvailable(entry) ? '#696767' : colour;
  };

  _renderDialogBox = () => {
    let { loading, classes } = this.props;
    let { dialogOpen, existingAppointments } = this.state;

    let appointment = existingAppointments.sort((appointment1, appointment2) => {
      const date1 = dateUtil.parseDate(appointment1.slot.start);
      const date2 = dateUtil.parseDate(appointment2.slot.start);
      return compareAsc(date1, date2);
    })[0];
    let appointmentType =
      appointment.type === 'IN_PERSON_WALK_IN' || appointment.type === 'IN_PERSON'
        ? 'In person'
        : 'Virtual';

    return (
      <Dialog
        maxWidth={'md'}
        open={dialogOpen}
        onClose={this._handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4" component={'div'} className={classes.dialogTitle}>
            <FormattedMarkdown
              id={'booking.appointment.schedule.dialog.title'}
              defaultMessage={'Existing Appointment'}
            />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" component={'div'}>
            <FormattedMarkdown
              id={'booking.appointment.schedule.dialog.content1'}
              defaultMessage={'You have an existing appointment.'}
            />
          </Typography>

          <br />
          <Typography variant="body2" component={'div'}>
            <span className={classes.dialogContentText}>Time: </span>
            {format(parse(appointment.slot.start, "yyyy-MM-dd'T'HH:mm:ssX", new Date()), 'PPpp')}
          </Typography>
          <Typography variant="body2" component={'div'}>
            <span className={classes.dialogContentText}>Reason: </span>
            {appointment.note}
          </Typography>
          <Typography variant="body2" component={'div'}>
            <span className={classes.dialogContentText}>Type: </span>
            {appointmentType}
          </Typography>
          <br />

          <Typography variant="body2" component={'div'}>
            <FormattedMarkdown
              id={'booking.appointment.schedule.dialog.content2'}
              defaultMessage={
                'If you continue, you will have multiple appointments on the same day.'
              }
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMarkdown
              id={'booking.appointment.schedule.dialog.phone.message'}
              defaultMessage={
                'You can update this appointment or call the clinic to make a change to your existing reservation:'
              }
            />
          </Typography>
          <Typography variant="body2" className={classes.phoneNumberText}>
            <FormattedMessage
              id={'checkin.phone.number'}
              values={{
                p: (chunks) => <p>{chunks}</p>,
                tel: (chunks) => (
                  <a href={`tel:+1${phoneUtil.stripCharacters(chunks)}`}>{chunks}</a>
                ),
              }}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            onClick={this._handleDialogClose}
            disabled={loading}
          >
            CANCEL
          </Button>
          <Button
            className={classes.dialogButton}
            onClick={this.handleSubmit}
            variant={'contained'}
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size="1.5em" /> : 'CONTINUE'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    let { loading, classes, handleBackToStepZero } = this.props;
    let { step, messageId, message, component } = this.state;

    return (
      <PageContainer
        loading={loading}
        onBack={step !== 0 ? this.__back : handleBackToStepZero ?? this.__backToConversation}
      >
        {component}
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(Reservation));
