export const userAnalyticData = ({ id, appointmentId, dateOfBirth, firstName }) => {
  if (window.heap) {
    window.heap.addUserProperties({
      appointmentId: appointmentId || '',
      dateOfBirth: dateOfBirth || '',
      firstName: firstName || '',
    });

    if (id) {
      //--> identify should only be called once per user and if they have an id
      window.heap.identify(id);
    }
  }
};
