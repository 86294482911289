import { FormattedMarkdown } from '@decodedhealth/react-library';
import { Grid, Typography } from '@mui/material';
import React, { Component } from 'react';
import { H2TextTitleContent } from '../../shared/Typography';
import withStyles from "@mui/styles/withStyles";
import { globalBloc } from "../../global.bloc";

const GridItem = (props) => <Grid {...props} item />;

const styles = (theme) => ({
    root: {},
});

class Confirmation extends Component {

  __renderConfirmationMessage = () => {
    if(globalBloc.isTelehealth()) {
      return (
          <H2TextTitleContent component={GridItem}>
            <FormattedMarkdown
                id="interaction.appointment.reservation.confirmation.heading"
                defaultMessage="**You will be texted 5 minutes before your selected appointment time with a link to join your telehealth visit.**"
            />
          </H2TextTitleContent>
      );
    }
    return (
        <>
          <H2TextTitleContent component={GridItem}>
            <FormattedMarkdown
                id="interaction.appointment.reservation.confirmation.heading"
                defaultMessage={`**Your reservation is an estimated time and not an appointment.**`}
            />
          </H2TextTitleContent>
          <H2TextTitleContent component={GridItem}>
            <FormattedMarkdown
                id="interaction.appointment.reservation.confirmation.message1"
                defaultMessage="Please know that we make every effort to see you promptly, however, wait times can vary. Patients with serious conditions may be seen first."
            />
          </H2TextTitleContent>
          <H2TextTitleContent component={GridItem}>
            <FormattedMarkdown
                id="interaction.appointment.reservation.confirmation.message2"
                defaultMessage="Thanks for your understanding."
            />
          </H2TextTitleContent>
        </>
    );
  }

  render() {

    return (
        <Grid container direction="column">
          {this.__renderConfirmationMessage()}
        </Grid>
    );
  }
}

export default withStyles(styles)(Confirmation);
