import { Grid, Typography } from '@mui/material';
import PageContainer from '../../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import * as React from 'react';
import DecodedComponent from '../../shared/DecodedComponent';
import withStyles from '@mui/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import AccountContext from './context';
import DecodedButton, { DecodedSecondaryButton } from '../../common/DecodedButton';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { withRouter } from 'react-router-dom';
import { H2TextTitleContent } from '../../shared/Typography';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { DecodedDialog, DecodedDialogTitle } from '../../shared/Dialog/Dialog';
import { globalBloc } from '../../global.bloc';
import { AppointmentType } from '../../../utils/appointment';
import { routeUtil } from '../../../utils/route.name';
import { paymentApi } from '../../../utils/services/payment.api';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class VisitCost extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.context.bloc;

    this.state = {
      visitPaymentType: this.bloc.subject.value.visitPaymentType,
      visitCost: this.bloc.subject.value.visitCost,
      appointment: this.bloc.subject.value.appointment,
      paymentDetails: this.bloc.subject.value.paymentDetails,
      showDialog: false,
      isTelehealth: globalBloc.isTelehealth(),
    };
  }

  lookupAdditionalWording = (paymentDetails, visitPaymentType, type) => {
    switch (visitPaymentType) {
      case 'insurance': {
        if (
          this.state.isTelehealth &&
          visitPaymentType === 'insurance' &&
          paymentDetails.insuranceEligibility !== 'eligible'
        ) {
          return (
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMarkdown
                  id={`payment.account.${visitPaymentType}.virtual.eligibility.unknown.text`}
                  defaultMessage={`We were unable to determine your payment responsibility based on the insurance you provided. Our staff will reach out to confirm details and complete registration process.`}
                ></FormattedMarkdown>
                <FormattedMarkdown
                  id={`payment.account.${visitPaymentType}.virtual.eligibility.unknown.text`}
                  defaultMessage={`Please select “Continue” below to test your devices audio and video functionality for your telehealth visit.`}
                ></FormattedMarkdown>
              </H2TextTitleContent>
            </Grid>
          );
        } else if (
          visitPaymentType === 'insurance' &&
          paymentDetails.insuranceEligibility === 'unknown' &&
          type !== AppointmentType.VIRTUAL
        ) {
          return (
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMarkdown
                  id={`payment.account.${visitPaymentType}.inperson.eligibility.unknown.text`}
                  defaultMessage={`Please have a seat and our front desk will call you to complete registration momentarily`}
                ></FormattedMarkdown>
              </H2TextTitleContent>
            </Grid>
          );
        } else if (
          visitPaymentType === 'insurance' &&
          paymentDetails.insuranceEligibility !== 'eligible' &&
          type !== AppointmentType.VIRTUAL
        ) {
          return (
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMarkdown
                  id={`payment.account.${visitPaymentType}.virtual.eligibility.unknown.text`}
                  defaultMessage={`We were unable to determine your payment responsibility based on the insurance you provided.`}
                ></FormattedMarkdown>
                <FormattedMarkdown
                  id={`payment.account.${visitPaymentType}.virtual.eligibility.unknown.text`}
                  defaultMessage={`Please select “Finish/complete” below and have a seat and our staff call you up shortly to complete your registration process.`}
                ></FormattedMarkdown>
              </H2TextTitleContent>
            </Grid>
          );
        } else if (
          visitPaymentType === 'insurance' &&
          paymentDetails.insuranceEligibility !== 'eligible' &&
          type === AppointmentType.VIRTUAL
        ) {
          return (
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMarkdown
                  id={`payment.account.${visitPaymentType}.virtual.eligibility.unknown.text`}
                  defaultMessage={`We were unable to verify your insurance eligibility. We will do our best to bill your insurance, but you will be held liable for the cost.`}
                ></FormattedMarkdown>
              </H2TextTitleContent>
            </Grid>
          );
        }
      }
      case 'subscription':
      case 'fixed':
      case 'selfpay': {
        return (
          <Grid item xs={12}>
            <H2TextTitleContent>
              <FormattedMarkdown
                id={`payment.account.${visitPaymentType}.text`}
                defaultMessage={``}
              ></FormattedMarkdown>
            </H2TextTitleContent>
          </Grid>
        );
      }
      default: {
        if (type === 'VIRTUAL') {
          return (
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={`payment.account.${visitPaymentType}.virtual.text`}
                  defaultMessage={`We were unable to verify your insurance eligibility. We will do our best to bill your insurance, but you will be held liable for the cost.`}
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
          );
        }

        return (
          <Grid item xs={12}>
            <H2TextTitleContent>
              <FormattedMessage
                id={`payment.account.${visitPaymentType}.text`}
                defaultMessage={`Please have a seat and our front desk will call you to complete registration momentarily.`}
              ></FormattedMessage>
            </H2TextTitleContent>
          </Grid>
        );
      }
    }
  };

  lookupWording = (paymentDetails, visitPaymentType, appointmentType) => {
    switch (visitPaymentType) {
      case 'subscription': {
        return {
          id: `payment.account.${visitPaymentType}.bubble`,
          message: 'Thank you. We have validated your subscription.',
          contentId: `payment.account.${visitPaymentType}.heading`,
          content: ` `,
          subtextId: `payment.account.${visitPaymentType}.subtext`,
          subtext: ``,
        };
      }
      case 'insurance': {
        if (paymentDetails.insuranceEligibility === 'unknown') {
          if (appointmentType === 'VIRTUAL') {
            return {
              id: `payment.account.${visitPaymentType}.bubble`,
              message:
                'Thank you. We were unable to determine your insurance eligibility for your virtual visit.',
              contentId: `payment.account.${visitPaymentType}.heading`,
              content: `Per your insurance verification your patient responsibility for today's visit is listed below.`,
              subtextId: `payment.account.${visitPaymentType}.subtext`,
              subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists please select skip, otherwise click Continue.`,
            };
          }
          return {
            id: `payment.account.${visitPaymentType}.bubble`,
            message: 'Thank you. We were unable to determine your insurance eligibility.',
            contentId: `payment.account.${visitPaymentType}.heading`,
            content: ` `,
            subtextId: `payment.account.${visitPaymentType}.subtext`,
            subtext: ``,
          };
        }

        if (paymentDetails.insuranceEligibility === 'not_eligible') {
          if (appointmentType === 'VIRTUAL') {
            return {
              id: `payment.account.${visitPaymentType}.not_eligible.bubble`,
              message:
                'Thank you. We have validated your insurance eligibility for your a virtual visit.',
              contentId: `payment.account.${visitPaymentType}.not_eligible.heading`,
              noticeId: `payment.account.${visitPaymentType}.notice`,
              notice: `*All other service charges for today’s visit will be completed at checkout*`,
              content: `Per your insurance verification your patient responsibility for today's visit is listed below.`,
              subtextId: `payment.account.${visitPaymentType}.not_eligible.subtext`,
              subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists please select skip, otherwise click Continue.`,
            };
          }
          return {
            id: `payment.account.${visitPaymentType}.not_eligible.bubble`,
            message: 'Thank you. We have validated your insurance eligibility.',
            contentId: `payment.account.${visitPaymentType}.not_eligible.heading`,
            content: `Per your insurance verification your patient responsibility for today's visit is listed below.`,
            subtextId: `payment.account.${visitPaymentType}.not_eligible.subtext`,
            subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists, you can click End and go see the front desk to complete your check-in process, otherwise click Continue.`,
          };
        }

        if (appointmentType === 'VIRTUAL') {
          return {
            id: `payment.account.${visitPaymentType}.bubble`,
            message:
              'Thank you. We have validated your insurance eligibility for your virtual visit.',
            contentId: `payment.account.${visitPaymentType}.heading`,
            content: `Per your insurance verification your patient responsibility for today's visit is listed below.`,
            subtextId: `payment.account.${visitPaymentType}.subtext`,
            subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists please select skip, otherwise click Continue.`,
          };
        }

        if (paymentDetails.amount < 1) {
          return {
            id: `payment.account.${visitPaymentType}.zero.bubble`,
            message: 'Thank you. We have validated your insurance eligibility.',
            contentId: `payment.account.${visitPaymentType}.zero.heading`,
            content: `Per your insurance verification you have no patient responsibility payment today.`,
            subtextId: `payment.account.${visitPaymentType}.zero.subtext`,
            subtext: ``,
          };
        }

        return {
          id: `payment.account.${visitPaymentType}.bubble`,
          message: 'Thank you. We have validated your insurance eligibility.',
          contentId: `payment.account.${visitPaymentType}.heading`,
          content: `Per your insurance verification your patient responsibility for today's visit is listed below.`,
          noticeId: `payment.account.${visitPaymentType}.notice`,
          notice: `*All other service charges for today’s visit will be completed at checkout*`,
          subtextId: `payment.account.${visitPaymentType}.subtext`,
          subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists, you can click End and go see the front desk to complete your check-in process, otherwise click Continue.`,
        };
      }
      case 'selfpay': {
        return {
          id: `payment.account.${visitPaymentType}.bubble`,
          message: 'Thank you. This visit will be selfpay.',
          contentId: `payment.account.${visitPaymentType}.heading`,
          content: `Your visit cost for today is`,
          noticeId: `payment.account.${visitPaymentType}.notice`,
          notice: `*All other service charges for today’s visit will be completed at checkout*`,
          subtextId: `payment.account.${visitPaymentType}.subtext`,
          subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists, you can click End${
            this.state.isTelehealth
              ? '.'
              : ' and go see the front desk to complete your check-in process, otherwise click Continue.'
          } `,
        };
      }
      default: {
        return {
          id: `payment.account.${visitPaymentType}.bubble`,
          message: 'Thank you. We were not able to determine how the visit will be paid for.',
          contentId: `payment.account.${visitPaymentType}.heading`,
          content: `Your visit cost for today is`,
          noticeId: `payment.account.${visitPaymentType}.notice`,
          notice: `*All other service charges for today’s visit will be completed at checkout*`,
          subtextId: `payment.account.${visitPaymentType}.subtext`,
          subtext: `\\* If this amount is wrong, please go back, make sure your details are correct and try again. If the problem persists, you can click End and go see the front desk to complete your check-in process, otherwise click Continue.`,
        };
      }
    }
  };

  __canProceed = (paymentDetails, visitPaymentType, type) => {
    if (
      this.state.isTelehealth &&
      visitPaymentType === 'insurance' &&
      paymentDetails.insuranceEligibility !== 'eligible'
    ) {
      return false;
    }

    if (type === 'VIRTUAL') {
      return true;
    }

    if (
      visitPaymentType === 'insurance' &&
      paymentDetails.insuranceEligibility === 'unknown' &&
      type !== 'VIRTUAL'
    ) {
      return false;
    }

    return (
      type !== 'VIRTUAL' && ['selfpay', 'insurance', 'subscription'].includes(visitPaymentType)
    );
  };

  handleOpenDialog = () => {
    this.setState({ showDialog: true });
  };

  handleNavigateToCheckDevice = () => {
    let { history } = this.props;
    const { appointment } = this.state;
    paymentApi.skipPayment(appointment.id).then(() => {
      history.push(routeUtil.buildVirtualWaitingRoomRouteWithAppointmentID(appointment.id));
    });
  };

  handleConfirmDialog = () => {
    this.setState({ showDialog: false });
    this.bloc.end();
  };

  handleCloseDialog = () => {
    this.setState({ showDialog: false });
  };

  render() {
    const { appointment, paymentDetails, visitPaymentType, visitCost, isWalkin, showDialog } =
      this.state;

    const dialog = (
      <DecodedDialog open={showDialog} onClose={this.handleCloseDialog}>
        <DecodedDialogTitle>Are you sure</DecodedDialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to end virtual registration. Staff will be notified of your arrival and
            will assist you with any further steps required for registration.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DecodedSecondaryButton onClick={this.handleCloseDialog}>Cancel</DecodedSecondaryButton>
          <DecodedButton onClick={this.handleConfirmDialog}>Confirm</DecodedButton>
        </DialogActions>
      </DecodedDialog>
    );

    if (visitPaymentType === 'unknown') {
      return this.__renderUnknown();
    }

    const wording = this.lookupWording(paymentDetails, visitPaymentType, appointment.type);
    const additionalWording = this.lookupAdditionalWording(
      paymentDetails,
      visitPaymentType,
      appointment.type,
    );
    const canProceed = this.__canProceed(paymentDetails, visitPaymentType, appointment.type);

    let cost = <></>;
    if (
      !(paymentDetails.insuranceEligibility === 'unknown' && appointment.type !== 'VIRTUAL') &&
      paymentDetails.amount > 0
    ) {
      cost = (
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '40px !important',
              lineHeight: '32px !important',
              color: '#002F59',
            }}
          >
            ${visitCost?.toFixed(2)}
          </Typography>
        </Grid>
      );
    }

    let showContent = true;
    if (this.state.isTelehealth) {
      showContent =
        visitPaymentType !== 'insurance' ||
        (visitPaymentType === 'insurance' && 'eligible' === paymentDetails.insuranceEligibility);
    }

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.props.history.goBack();
        }}
      >
        <ScrollableQuinnContainer messageId={wording.id} message={wording.message}>
          <Grid container spacing={3}>
            {showContent && (
              <Grid item xs={12}>
                <H2TextTitleContent>
                  <FormattedMessage
                    id={wording.contentId}
                    defaultMessage={wording.content}
                  ></FormattedMessage>
                </H2TextTitleContent>
              </Grid>
            )}
            {showContent && cost}
            {!this.state.isTelehealth && wording.noticeId && (
              <Grid item xs={12}>
                <H2TextTitleContent>
                  <FormattedMarkdown
                    id={wording.noticeId}
                    defaultMessage={wording.notice}
                  ></FormattedMarkdown>
                </H2TextTitleContent>
              </Grid>
            )}
            {additionalWording}
            {showContent && (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '14px !important',
                    lineHeight: '26px !important',
                    color: '#777777',
                  }}
                >
                  <FormattedMarkdown
                    id={wording.subtextId}
                    defaultMessage={wording.subtext}
                  ></FormattedMarkdown>
                </Typography>
              </Grid>
            )}
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          {appointment.type === 'VIRTUAL' &&
            visitPaymentType === 'insurance' &&
            paymentDetails.insuranceEligibility !== 'eligible' &&
            this.state.isTelehealth && (
              <Grid item xs={12} sm={6}>
                <DecodedButton onClick={this.handleNavigateToCheckDevice}>Continue</DecodedButton>
              </Grid>
            )}
          {appointment.type === 'VIRTUAL' && !this.state.isTelehealth && visitCost > 0 && (
            <Grid item xs={12} sm={6}>
              <DecodedSecondaryButton
                onClick={(e) => {
                  this.bloc.setVisitCostOverride();
                  this.bloc.next();
                }}
              >
                {this.bloc.seeFrontDeskWording()}
              </DecodedSecondaryButton>
            </Grid>
          )}
          {appointment.type !== 'VIRTUAL' && !this.state.isTelehealth && visitCost > 0 && (
            <Grid item xs={12} sm={6}>
              <DecodedSecondaryButton onClick={this.handleOpenDialog}>
                {this.bloc.seeFrontDeskWording()}
              </DecodedSecondaryButton>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            {canProceed ? (
              <DecodedButton onClick={(e) => this.bloc.next()}>
                {visitCost > 0 ? (
                  <FormattedMessage
                    id={`payment.account.${visitPaymentType}.submit`}
                    defaultMessage={'CONTINUE TO PAYMENT'}
                  />
                ) : (
                  <FormattedMessage
                    id={`payment.account.${visitPaymentType}.zero.submit`}
                    defaultMessage={'CONTINUE'}
                  />
                )}
              </DecodedButton>
            ) : (
              <></>
            )}
          </Grid>
        </DefaultDisconnectedPageFooter>
        {dialog}
      </PageContainer>
    );
  }

  __renderUnknown() {
    const { visitPaymentType, visitCost } = this.state;

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.props.history.goBack();
        }}
      >
        <ScrollableQuinnContainer
          messageId={`payment.account.${visitPaymentType}.bubble`}
          message={'Thank you. We were unable to verify your insurance.'}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={`payment.account.unknown.heading`}
                  defaultMessage={`We were unable to verify your insurance eligibility. We will do our best to bill your insurance, but you will be held liable for the cost.`}
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid item xs={12} sm={6}>
            <DecodedButton
              onClick={(e) => {
                this.bloc.end();
              }}
            >
              {this.bloc.seeFrontDeskWording()}
            </DecodedButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DecodedButton onClick={(e) => this.bloc.next()}>
              <FormattedMessage
                id={`payment.account.${visitPaymentType}.submit`}
                defaultMessage={'Continue for payment'}
              />
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(
  withRouter((props) => (
    <AccountContext.Consumer>
      {(value) => {
        return <VisitCost context={value} {...props} />;
      }}
    </AccountContext.Consumer>
  )),
);
