import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import Typography from '@mui/material/Typography';
import DecodedButton, { DecodedSecondaryButton } from '../../common/DecodedButton';

const styles = (theme) => ({
  progress: {
    color: theme.palette.secondary.main,
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'right',
  },
  dialogTitle: {
    color: theme.palette.primary.main,
  },
});

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { open, onConfirm, onConfirmLabel, onClose, onCloseLabel, title, content, classes } =
      this.props;

    return (
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={onClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title" className={classes.dialogTitle}>
          <Typography variant="h4" component={'p'}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2" component={'p'}>
              {content}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DecodedSecondaryButton onClick={onClose}>{onCloseLabel}</DecodedSecondaryButton>
          <DecodedButton onClick={onConfirm}>{onConfirmLabel}</DecodedButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmDialog);
