import React from 'react';

import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { authService } from '../../../utils/auth';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { FormattedMessage } from 'react-intl';

import CircularProgress from '@mui/material/CircularProgress';
import { Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import ChatBubble from '../../shared/ChatBubble';
import PageContainer from '../../common/PageContainer';
import { FormattedMarkdown, MapsAutocompleteField } from '@decodedhealth/react-library';
import { usePharmacies } from '../../../utils/services/register.api';
import { Select } from '../components/Select';
import DecodedComponent from '../../shared/DecodedComponent';
import {
  ScrollableBox,
  ScrollableQuinnContainer,
  ScrollableWrapper,
} from '../../common/ScrollableContainer';
import { STATES } from '../const';
import { ZipTextMask } from '../../shared/InputTextMask';

const styles = (theme) => ({
  validation: {
    flex: '1',
    width: '100%',
  },
  title: {
    paddingTop: '1em',
    color: '#FF7700',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
  },
  formInput: {
    flex: '1',
  },
  buttonGroup: {
    paddingTop: '2em',
  },
  button: {
    width: '100%',
    padding: '1em',
  },
});

class Pharmacies extends DecodedComponent {
  constructor(props) {
    super(props);
    this.bloc = props.bloc;
    this.state = { initialising: true };
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  handleTextChange = (event) =>
    this.bloc.setPharmacyData({ [event.target.name]: event.target.value });

  handleAddressTextChange = (event) => {
    this.bloc.setPharmacyAddressTextChange({ [event.target.name]: event.target.value });
  };

  handlePharmacyAddressChange = (event) => {
    this.bloc.setPharmacyData({ pharmacyId: event.target.value });
  };

  handleAutoCompleteChange = (formValues) => {
    this.bloc.handleAddressAutoCompleteChange(formValues);
  };

  getItemValue = (item) => {
    return item.id;
  };

  getItemLabel = (item) => {
    return item.label;
  };

  __submit = () => {
    const pharmacy = this.bloc.submit();
    this.props.handleNext(pharmacy);
  };

  __edit = (e) => {
    this.bloc.edit();
    e.preventDefault();
  };

  render() {
    const { classes } = this.props;
    const {
      initialising,
      readonly,
      cities,
      pharmacyCity,
      groups,
      pharmacyName,
      pharmacyNewName,
      addresses,
      pharmacyId,
      address,
    } = this.state;

    if (readonly) {
      return this.__renderReadOnly();
    }

    return (
      <PageContainer loading={initialising} onBack={this.props.handleBack}>
        {initialising ? (
          <ScrollableQuinnContainer
            messageId="registration.user.bubble.loading"
            message={'Loading pharmacies.'}
          ></ScrollableQuinnContainer>
        ) : (
          <>
            <ScrollableQuinnContainer
              messageId="registration.user.bubble.pharmacyInformation"
              message={'Please select your preferred pharmacy.'}
            >
              <ValidatorForm
                autoComplete="off"
                onSubmit={this.__submit}
                className={classes.validation}
              >
                <div className={classes.form}>
                  <div className={classes.formInput}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                          <FormattedMarkdown
                            id="registration.user.step.4"
                            defaultMessage="4 of 5 Personal Information - Preferred Pharmacy"
                          />
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Select
                          required
                          id="pharmacyCity"
                          name="pharmacyCity"
                          label="City"
                          value={pharmacyCity}
                          onChange={this.handleTextChange}
                          options={cities}
                        />
                      </Grid>

                      {pharmacyCity && pharmacyCity.length > 0 ? (
                        <Grid item xs={12} sm={12}>
                          <Select
                            required
                            id="pharmacyName"
                            name="pharmacyName"
                            label="Pharmacy Name"
                            value={pharmacyName}
                            onChange={this.handleTextChange}
                            options={groups[pharmacyCity] ?? []}
                          />
                        </Grid>
                      ) : null}

                      {pharmacyName &&
                      pharmacyName.length > 0 &&
                      pharmacyName !== 'None of these' ? (
                        <Grid item xs={12} sm={12}>
                          <Select
                            required
                            id="pharmacyAddress"
                            name="pharmacyAddress"
                            label="Pharmacy Address"
                            value={pharmacyId}
                            onChange={this.handlePharmacyAddressChange}
                            options={addresses[pharmacyName] ?? []}
                            getItemLabel={this.getItemLabel}
                            getItemValue={this.getItemValue}
                          />
                        </Grid>
                      ) : null}

                      {pharmacyId === '-1' && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="h5" className={classes.title}>
                              <FormattedMarkdown
                                id="pharmacy.unknown.address.title"
                                defaultMessage="Pharmacy contact details"
                              />
                            </Typography>
                          </Grid>

                          {pharmacyName === 'None of these' && (
                            <Grid item xs={12} sm={12}>
                              <TextValidator
                                fullWidth
                                autoFocus={true}
                                id="pharmacyNewName"
                                label="Pharmacy name"
                                name="pharmacyNewName"
                                autoComplete="off"
                                required
                                inputProps={{
                                  autoComplete: 'off',
                                }}
                                value={pharmacyNewName}
                                onChange={this.handleTextChange}
                              />
                            </Grid>
                          )}

                          <Grid item xs={12} sm={12}>
                            <MapsAutocompleteField
                              restrictCountry="US"
                              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                              id="line1"
                              label="Address"
                              name="line1"
                              value={address.line1 || ''}
                              onAutocompleteChange={this.handleAutoCompleteChange}
                              onInputTextChange={this.handleAddressTextChange}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <TextValidator
                              fullWidth
                              autoFocus={true}
                              id="city"
                              label="City"
                              name="city"
                              autoComplete="off"
                              inputProps={{
                                autoComplete: 'off',
                              }}
                              value={address.city || ''}
                              onChange={this.handleAddressTextChange}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <Select
                              id="administrativeArea"
                              name="administrativeArea"
                              label="State"
                              value={address.administrativeArea || ''}
                              onChange={this.handleAddressTextChange}
                              options={STATES}
                              getItemLabel={(item) => item.display}
                              getItemValue={(item) => item.code}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <TextValidator
                              id="postcode"
                              name="postcode"
                              label="Zip Code"
                              fullWidth
                              value={address.postcode || ''}
                              onChange={this.handleAddressTextChange}
                              validators={['matchRegexp:(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)']}
                              errorMessages={['Invalid zip code']}
                              InputProps={{
                                inputComponent: ZipTextMask,
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </div>

                  <Grid
                    container
                    spacing={2}
                    justifyContent="flex-start"
                    className={classes.buttonGroup}
                  >
                    <Grid item xs={12} sm={6} className={classes.button}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={initialising}
                      >
                        {initialising ? (
                          <CircularProgress size="1.5em" />
                        ) : (
                          <FormattedMessage
                            id="registration.button.label.submit"
                            defaultMessage="NEXT"
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </ValidatorForm>
            </ScrollableQuinnContainer>
          </>
        )}
      </PageContainer>
    );
  }

  __renderReadOnly = () => {
    const { classes } = this.props;
    const { initialising, pharmacyCity, pharmacyName, originalAddress } = this.state;

    return (
      <PageContainer loading={initialising} onBack={this.props.handleBack}>
        {initialising ? (
          <ScrollableQuinnContainer
            messageId="registration.user.bubble.loading.readonly"
            message={'Loading your preferred pharmacy.'}
          ></ScrollableQuinnContainer>
        ) : (
          <>
            <ScrollableQuinnContainer
              messageId="registration.user.bubble.pharmacyInformation.readonly"
              message={'Is this still your preferred pharmacy?'}
            >
              <ValidatorForm
                autoComplete="off"
                onSubmit={this.__submit}
                className={classes.validation}
              >
                <div className={classes.form}>
                  <div className={classes.formInput}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1" component={'div'} className={classes.title}>
                          <FormattedMarkdown
                            id="registration.user.step.4"
                            defaultMessage="4 of 5 Personal Information - Preferred Pharmacy"
                          />
                        </Typography>
                      </Grid>

                      {pharmacyCity !== '' && (
                        <Grid item xs={12} sm={12}>
                          <TextField
                            sx={{ width: '100%' }}
                            value={pharmacyCity}
                            label="City"
                            disabled={true}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12}>
                        <TextField
                          sx={{ width: '100%' }}
                          value={pharmacyName}
                          label="Name"
                          disabled={true}
                        />
                      </Grid>

                      {originalAddress !== 'Unknown address' && (
                        <Grid item xs={12} sm={12}>
                          <TextField
                            sx={{ width: '100%' }}
                            value={originalAddress}
                            label="Address"
                            disabled={true}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </div>

                  <Grid
                    container
                    spacing={2}
                    justifyContent="flex-start"
                    className={classes.buttonGroup}
                  >
                    <Grid item xs={12} sm={6} className={classes.button}>
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        disabled={initialising}
                        onClick={this.__edit}
                      >
                        {initialising ? (
                          <CircularProgress size="1.5em" />
                        ) : (
                          <FormattedMessage
                            id="registration.button.label.edit"
                            defaultMessage="EDIT"
                          />
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.button}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={initialising}
                      >
                        {initialising ? (
                          <CircularProgress size="1.5em" />
                        ) : (
                          <FormattedMessage
                            id="registration.button.label.submit"
                            defaultMessage="NEXT"
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </ValidatorForm>
            </ScrollableQuinnContainer>
          </>
        )}
      </PageContainer>
    );
  };
}

export default withStyles(styles)(Pharmacies);
