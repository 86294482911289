export class AppointmentStatus {
  static DRAFT = 'DRAFT';
  static RESERVED = 'RESERVED';
  static ARRIVED = 'ARRIVED';
  static CHECKING_IN = 'CHECKING_IN';
  static REQUESTED = 'REQUESTED';
  static PENDING = 'PENDING';
  static WAITING = 'WAITING';
}

export class AppointmentType {
  static IN_PERSON = 'IN_PERSON';
  static IN_PERSON_WALKIN = 'IN_PERSON_WALKIN';
  static VIRTUAL = 'VIRTUAL';
}

export class PaymentMethod {
  static INSURANCE = 'I';
  static SELF = 'S';
  static SUBSCRIPTION = 'D';
  static UNKNOWN = 'U';
}
