import { Box, Button, CircularProgress, Grid } from '@mui/material';
import * as React from 'react';
import { deepGet, useWizardContext, Loading } from '../../../shared-library';
import PageContainer from '../../common/PageContainer';
import {
  DisconnectedPageFooter,
  PageContent,
  PagePanel, QuinnContent,
  ScrollableBox,
  ScrollableWrapper
} from '../../common/ScrollableContainer';
import ChatBubble from '../../shared/ChatBubble';
import { BookingWizardProvider } from './booking-wizard-context';
import {notificationService} from "../../../utils/notification";

const BookingWizard = ({ onBack, onSubmit, bookingState }) => {
  const { isFirstStep, activeStep, isLastStep, goBack, goNext } = useWizardContext();

  const handleBack = React.useCallback(() => {
    if (isFirstStep) {
      onBack?.();
      return;
    }
    goBack();
  }, [isFirstStep, goBack, onBack]);

  const handleSubmit = React.useCallback(async () => {
    let noError = true;
    if (activeStep.onSubmit) {
      try {
        const result = await activeStep.onSubmit();
        if(result === 'stop') {
          return;
        }
      } catch (e) {
        noError = false;
        console.warn(`Error occurred while submitting on step ${activeStep.id}`, e);
        notificationService.error("An error occurred trying to book your appointment. Please call the clinic.")
        return;
      }
    }

    if (isLastStep) {
      onSubmit?.();
      return;
    }

    if (noError && !isLastStep) {
      goNext();
    }
  }, [activeStep, goNext, isLastStep, onSubmit]);

  const currentStepValue = deepGet(bookingState, activeStep.dataKey, undefined);

  return (
    <BookingWizardProvider
      value={{
        ...bookingState,
      }}
    >
      <PageContainer
        loading={bookingState.loadingData}
        onBack={!activeStep.disableBack ? handleBack : undefined}
      >
        <Grid container direction="column" justifyContent="space-between" flex="1">
          <ScrollableWrapper>
            {activeStep.message && (
                <QuinnContent>
                  <ChatBubble messageId={activeStep.message.id} message={activeStep.message.text} />
                </QuinnContent>
            )}
            <ScrollableBox>
              { activeStep.message === undefined || activeStep.message.id === undefined ? <>{activeStep.component}</> :
                  <PagePanel>
                    <PageContent>
                    {activeStep.component}
                    </PageContent>
                  </PagePanel> }
            </ScrollableBox>
          </ScrollableWrapper>
          <DisconnectedPageFooter>
            { !activeStep.hideSubmit && (<Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={activeStep.dataKey ? !currentStepValue : false}
            >
              {bookingState.isConfirmingAppointment ? (
                <CircularProgress size={25} thickness={2.6} />
              ) : (
                activeStep.submitLabel ?? 'SELECT'
              )}
            </Button>) }
          </DisconnectedPageFooter>
        </Grid>
      </PageContainer>
    </BookingWizardProvider>
  );
};
export default BookingWizard;
