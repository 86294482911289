import { AnalyticsEvent, analyticsEventLogger } from './events';
import { providerStorage } from './provider.qs';

class DecodedSession {
  constructor() {}

  clearSession = () => {
    analyticsEventLogger.log(AnalyticsEvent.SESSION_CLEAR, { place: 'decoded.session' });
    //TODO: Move all session and local storage to this file.
    const provider = providerStorage.getCurrentProvider();
    sessionStorage.clear();
    if (provider && provider !== '') {
      providerStorage.setProviderFromLink(provider);
    }
  };

  clearPersistent = () => {
    localStorage.clear();
  };
}

export const decodedSession = new DecodedSession();
