import * as rxjs from 'rxjs';
import { appointmentApi } from '../../utils/services/appointments.api';
import { addHours, compareAsc, format, parse } from 'date-fns';
import { DATE_FORMAT } from '../../utils/user';
import { notificationService } from '../../utils/notification';
import { dateUtil } from '../../utils/date';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { errorResolver } from '../../utils/error.resolver';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { BookingType } from './utils';
import OrgSelector from './components/OrgSelector';
import DateSelector from './components/DateSelector';
import TimeSelector from './components/TimeSelector';
import ReminderSelector from './components/ReminderSelector';
import Confirmation from './components/Confirmation';
import { providerStorage } from '../../utils/provider.qs';
import WalkinConfirmation from './components/WalkinConfirmation';
import { serviceUtil } from '../../utils/service';
import OccHealthWalkinConfirmation from './components/OccHealthWalkinConfirmation';
import { QUINN_SCHEDULED, routeUtil } from '../../utils/route.name';
import { globalBloc } from '../global.bloc';

const remoteSteps = (bloc, appointment) => {
  if (appointment?.type === 'VIRTUAL') {
    return remoteVirtualSteps(bloc);
  }

  return [
    {
      id: 'org-selector',
      component: <OrgSelector />,
      dataKey: 'booking.selectedOrg',
    },
    {
      id: 'date-selector',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.selectdate',
        text: 'Please, select your reservation date:',
      },
      component: <DateSelector />,
      dataKey: 'booking.selectedDate',
    },
    {
      id: 'time-selector',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.selecttime',
        text: 'When would you like to come in?',
      },
      component: <TimeSelector />,
      dataKey: 'booking.selectedSlot',
    },
    {
      id: 'reminder-selector',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.selectreminder',
        text: 'How long before do you want to get a reminder?',
      },
      component: <ReminderSelector />,
      dataKey: 'booking.reminderTime',
    },
    {
      id: 'confirmation',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.confirm',
        text: 'Thank you. Before we continue, please review and confirm the message below.',
      },
      component: <Confirmation />,
      submitLabel: 'CONFIRM',
    },
  ];
};

const remoteVirtualSteps = (bloc) => {
  return [
    {
      id: 'date-selector',
      message: {
        id: 'interaction.appointment.reservation.virtual.chatbubble.selectdate',
        text: 'Please, select the reservation date:',
      },
      component: <DateSelector />,
      dataKey: 'booking.selectedDate',
    },
    {
      id: 'time-selector',
      message: {
        id: 'interaction.appointment.reservation.virtual.chatbubble.selecttime',
        text: 'When would you like to book your virtual visit?',
      },
      component: <TimeSelector />,
      dataKey: 'booking.selectedSlot',
    },
    {
      id: 'confirmation',
      message: {
        id: 'interaction.appointment.reservation.virtual.chatbubble.confirm',
        text: 'Thank you. Before we continue, please review and confirm the message below.',
      },
      component: <Confirmation />,
      submitLabel: 'CONFIRM',
    },
  ];
};

const remoteStepsNoChoice = (bloc, appointment) => {
  if (appointment?.type === 'VIRTUAL') {
    return remoteVirtualSteps(bloc);
  }

  return [
    {
      id: 'date-selector',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.selectdate',
        text: 'Please, select the reservation date:',
      },
      component: <DateSelector />,
      dataKey: 'booking.selectedDate',
    },
    {
      id: 'time-selector',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.selecttime',
        text: 'When would you like to come in?',
      },
      component: <TimeSelector />,
      dataKey: 'booking.selectedSlot',
    },
    {
      id: 'reminder-selector',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.selectreminder',
        text: 'How long before do you want to get a reminder?',
      },
      component: <ReminderSelector />,
      dataKey: 'booking.reminderTime',
    },
    {
      id: 'confirmation',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.confirm',
        text: 'Thank you. Before we continue, please review and confirm the message below.',
      },
      component: <Confirmation />,
      submitLabel: 'CONFIRM',
    },
  ];
};

const remoteStepsNoChoiceVirtual = (bloc) => {
  return [
    {
      id: 'date-selector',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.selectdate',
        text: 'Please, select the reservation date:',
      },
      component: <DateSelector />,
      dataKey: 'booking.selectedDate',
    },
    {
      id: 'time-selector',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.selecttime',
        text: 'When would you like to book your virtual visit?',
      },
      component: <TimeSelector />,
      dataKey: 'booking.selectedSlot',
    },
    {
      id: 'reminder-selector',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.selectreminder',
        text: 'How long before do you want to get a reminder?',
      },
      component: <ReminderSelector />,
      dataKey: 'booking.reminderTime',
    },
    {
      id: 'confirmation',
      message: {
        id: 'interaction.appointment.reservation.chatbubble.confirm',
        text: 'Thank you. Before we continue, please review and confirm the message below.',
      },
      component: <Confirmation />,
      submitLabel: 'CONFIRM',
    },
  ];
};

const walkinSteps = (bloc) => {
  if (bloc.isOccmed()) {
    return [
      {
        id: 'booking-occhealth-confirmation',
        message: {
          id: 'interaction.appointment.occhealth.walkin.chatbubble.selectdate',
          text: 'We are almost there. Saving your details.',
        },
        component: <OccHealthWalkinConfirmation />,
        hideSubmit: true,
      },
    ];
  }

  return [
    {
      id: 'booking-confirmation',
      message: {
        id: 'interaction.appointment.walkin.chatbubble.selectdate',
        text: 'We are almost there. Just review the below and confirm that you want reserve your place in the queue.',
      },
      component: <WalkinConfirmation />,
      submitLabel: 'CONFIRM',
      onSubmit: () => bloc.confirmWalkin().catch((reason) => 'stop'),
    },
  ];
};

export class BookingBloc {
  constructor(appointmentId, appointmentType, history) {
    const organisationId = providerStorage.getCurrentProvider();
    const isWalkin = providerStorage.isWalkin();

    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      loadingData: true,
      history: history,
      isWalkin: isWalkin,
      appointmentId: appointmentId,
      appointmentType: appointmentType,
      booking: {},
      calendarSummary: undefined,
      availableOrganisations: [],
      systemProperties: [],
      isTelehealth: globalBloc.isTelehealth(),
    });

    this.events = new rxjs.Subject();

    this.__initialise(appointmentId, organisationId);
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  __initialise = (appointmentId, organisationId) => {
    appointmentApi.getAppointmentStatus(appointmentId).then(
      (value) => {
        const appointment = value.data;

        this.__updateSubject({
          appointment: appointment,
          process: serviceUtil.determinCheckinProcess(appointment),
          loading: false,
          initialising: false,
          loadingData: false,
        });

        this.getAvailableOrganisations(appointment.service, organisationId);

        this.events.next({
          type: BookingBlocEvent.INITIALISED,
          data: {
            appointment: appointment,
          },
        });
      },
      (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR, {});
        notificationService.error(
          'Unable to load available clinics. Please try again and if the problem persists call the clinic.',
        );
      },
    );
  };

  searchServiceSchedule = (date) => {
    const { appointment } = this.subject.value;

    this.subject.next({
      ...this.subject.value,
      loading: true,
    });

    let selectedDate = format(date, DATE_FORMAT);

    appointmentApi
      .getAvailableAppointmentSchedule(selectedDate, appointment.provider, appointment.service)
      .then((value) => {
        this.subject.next({
          ...this.subject.value,
          loading: false,
          selectedDate: selectedDate,
          serviceSlots: value.data,
          schedulingIntervals: this._constructScheduleData(
            value.data.results[0].intervals,
            selectedDate,
          ).sort((a, b) => compareAsc(a.parsedTime, b.parsedTime)),
        });
      });
  };

  _constructScheduleData = (intervalData, selectedDate) => {
    const compareDate =
      typeof selectedDate === 'object' ? format(selectedDate, DATE_FORMAT) : selectedDate;

    const scheduleData = intervalData
      .map((interval) => {
        const scheduleIntervalHour = parse(interval.start, "yyyy-MM-dd'T'HH:mm:ssX", new Date());

        const hourDisplay = `${format(scheduleIntervalHour, 'h aa')}`;

        let slots = [];

        if (interval.availableSlots) {
          interval.availableSlots.forEach((_slot) => {
            slots.push({
              id: _slot.slotId,
              display: `${format(scheduleIntervalHour, 'hh')}:${_slot.start.split(':')[1]} ${format(
                scheduleIntervalHour,
                'aa',
              )}`,
              start: _slot.start,
            });
          });
        }

        const startDate = format(scheduleIntervalHour, DATE_FORMAT);

        return {
          start: scheduleIntervalHour,
          startDate: startDate,
          // waitTimes: interval.waitTimes.current,
          waitTimes: interval.current,
          capacity: interval.capacity,

          parsedTime: scheduleIntervalHour,
          hourDisplay: hourDisplay,
          slots: slots,
        };
      })
      .filter((interval) => {
        return interval.startDate === compareDate;
      });

    return scheduleData;
  };

  setDoctor = (doctor, doctorDetail) => {
    const { booking } = this.subject.value;

    if (booking.doctor !== doctor) {
      let newBooking = { ...booking };
      newBooking.doctor = doctor;
      newBooking.doctorDetail = doctorDetail;
      newBooking.reminderTime = undefined;

      this.subject.next({
        ...this.subject.value,
        booking: newBooking,
        calendarSummary: undefined,
      });
    }

    this.events.next({ type: BookingBlocEvent.DOCTOR_SELECTED, data: {} });

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_PROVIDER_SELECT, {});
  };

  loadFirstAvailable = (org) => {
    const { appointment } = this.subject.value;

    const now = new Date();
    const lastPeriod = new Date(new Date().setDate(now.getDate() + 30));

    let start = format(now, DATE_FORMAT);
    let end = format(lastPeriod, DATE_FORMAT);

    return appointmentApi.getServiceScheduleSummary(start, end, appointment.service, org.id);
  };

  loadServiceSchedule = () => {
    const { appointment, booking, calendarSummary } = this.subject.value;

    this.subject.next({
      ...this.subject.value,
      loadingData: true,
      calendarSummary: undefined,
    });

    const now = new Date();
    const lastPeriod = new Date(new Date().setDate(now.getDate() + 30));

    let start = format(now, DATE_FORMAT);
    let end = format(lastPeriod, DATE_FORMAT);

    return appointmentApi
      .getServiceScheduleSummary(start, end, appointment.service, booking.selectedOrg)
      .then(
        (value) => {
          this.subject.next({
            ...this.subject.value,
            calendarSummary: value.data,
            loadingData: false,
          });

          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {});
        },
        (reason) => {
          notificationService.error(
            'Error loading available times for appointment type. Please refresh. If the problem persists please contact the clinic.',
          );
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {
            status: 'error',
            message: `${reason}`,
          });
          this.subject.next({
            ...this.subject.value,
            loadingData: false,
          });
        },
      );
  };

  setSelectedDate = (date) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedDate = date;
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });

    this.events.next({ type: BookingBlocEvent.DATE_SELECTED, data: {} });
  };

  setReminderTime = (interval) => {
    const { booking, appointment } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.reminderTime = interval;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });

    appointmentApi.updateAppointment(appointment.id, this.__createAppointmentRequestData()).then(
      (value) => {
        this.events.next({ type: BookingBlocEvent.REMINDER_SELECTED, data: {} });
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_REMINDER_SELECT, {});
      },
      (reason) => {
        notificationService.error(
          'There was a problem selecting the desired time. Please select another time. If the problem persists please contact the clinic.',
        );
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_REMINDER_SELECT, {
          status: 'error',
          message: `${reason.message}`,
        });
      },
    );
  };

  loadSelectedDayAvailability = () => {
    const { appointment, booking } = this.subject.value;

    this.subject.next({
      ...this.subject.value,
      loadingData: true,
    });

    return appointmentApi
      .getAvailableAppointmentSchedule(
        booking.selectedDate,
        booking.selectedOrg,
        appointment.service,
        booking.doctor,
      )
      .then((value) => {
        let newBooking = { ...booking, availability: value.data, reminderTimer: undefined };

        this.subject.next({
          ...this.subject.value,
          booking: newBooking,
          schedulingIntervals: this._constructScheduleData(
            value.data.results[0].intervals,
            booking.selectedDate,
          ).sort((a, b) => compareAsc(a.parsedTime, b.parsedTime)),
        });
      })
      .finally(() => {
        this.subject.next({
          ...this.subject.value,
          loadingData: false,
        });
      });
  };

  setBookingTime = (datetime, save) => {
    const { booking, appointment } = this.subject.value;

    let newBooking = { ...booking, selectedSlot: datetime, reminderTime: undefined };

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });

    if (save) {
      appointmentApi.updateAppointment(appointment.id, this.__createAppointmentRequestData()).then(
        (value) => {
          this.events.next({ type: BookingBlocEvent.TIME_SELECTED, data: {} });
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {});
        },
        (reason) => {
          notificationService.error(
            'There was a problem selecting the desired time. Please select another time. If the problem persists please contact the clinic.',
          );
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {
            status: 'error',
            message: `${reason.message}`,
          });
        },
      );
    } else {
      this.events.next({ type: BookingBlocEvent.TIME_SELECTED, data: {} });
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {});
    }
  };

  __createAppointmentRequestData = (startTimeResolver) => {
    const { appointment, booking, isTelehealth } = this.subject.value;

    const selectedStart = startTimeResolver
      ? startTimeResolver(booking)
      : this.extractSlotStartDate(booking);

    let participants = [
      {
        role: 'O_SRV_PROV',
        identifier: {
          value: booking.selectedOrg,
        },
      },
    ];

    if (booking.doctor) {
      participants.push({
        role: 'S_PROVIDER',
        identifier: {
          value: booking.doctor,
        },
      });
    }

    return {
      service: {
        code: {
          value: appointment.service,
        },
        channel: {
          value: appointment.type,
        },
      },
      slot: {
        intervalStart: selectedStart,
      },
      reminder: booking.reminderTime || (isTelehealth ? 5 : 60),
      participants: participants,
    };
  };

  extractSlotStartDate(booking) {
    const interval = booking.availability.results
      .flatMap((_result) => _result.intervals)
      .filter((_interval) => {
        if (!_interval.availableSlots) return false;
        if (_interval.start === booking.selectedSlot) return true;
        const arr = _interval.availableSlots.filter(
          (_slot) => _slot.slotId === booking.selectedSlot,
        );
        return arr.length > 0;
      })[0];

    const slot = interval.availableSlots;

    const selectedStart = dateUtil.parseDate(interval.start);
    if (slot.length > 0) {
      selectedStart.setMinutes(slot[0].start.split(':')[1]);
    }
    return selectedStart;
  }

  confirmAppointment = (data) => {
    const { appointment } = this.subject.value;
    this.subject.next({
      ...this.subject.value,
      isConfirmingAppointment: true,
    });
    if (appointment.type === 'VIRTUAL') {
      return appointmentApi
        .confirmAppointment(appointment.id, {})
        .then(
          (response) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_VIRTUAL_SCHEDULE_CONFIRM_SUCCESS, {
              appointmentId: appointment.id,
            });
            this.events.next({ type: BookingBlocEvent.BOOKING_CONFIRMED, data: {} });
          },
          (reason) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_VIRTUAL_SCHEDULE_CONFIRM_ERROR, {
              status: 'error',
              appointmentId: appointment.id,
              message: `${reason}`,
            });
            notificationService.error(errorResolver.resolveBookingConfirmationError(reason));
          },
        )
        .finally(() => {
          this.subject.next({
            ...this.subject.value,
            isConfirmingAppointment: false,
          });
        });
    } else {
      return appointmentApi
        .confirmAppointment(appointment.id, data)
        .then(
          (value) => {
            this.events.next({ type: BookingBlocEvent.BOOKING_CONFIRMED, data: {} });
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT, {
              appointmentId: appointment.id,
            });
          },
          (reason) => {
            notificationService.error(
              'There was a problem confirming your appointment. Please try again or contact the clinic if the problem persists.',
            );
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT, {
              status: 'error',
              message: `${reason}`,
            });
          },
        )
        .finally(() => {
          this.subject.next({
            ...this.subject.value,
            isConfirmingAppointment: false,
          });
        });
    }
  };

  switchType = (newType) => {
    const { appointment } = this.subject.value;

    if (newType === 'reservation' && appointment.type === 'IN_PERSON_WALK_IN') {
      appointment.type = 'IN_PERSON';
    }

    this.subject.next({
      ...this.subject.value,
      appointment: appointment,
    });

    this.events.next({
      type: BookingBlocEvent.SWITCH_BOOKING_TYPE,
      data: {
        appointment: appointment,
        overrides: { type: newType },
      },
    });

    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_TYPE_SWITCH, {
      appointmentId: appointment.id,
    });
  };

  getAvailableOrganisations = (service, organisationId) => {
    const { appointment } = this.subject.value;

    this.__updateSubject({
      loadingData: true,
    });

    appointmentApi.getAvailableOrgs(service).then(
      (res) => {
        let organisations = res.data.items;

        let selectedOrganisation = organisationId;
        let skipSelection = false;
        if (!(organisationId?.length > 0)) {
          if (organisations.length === 1) {
            selectedOrganisation = organisations[0].id;
            skipSelection = true;
          }
        }

        this.__updateSubject({
          loadingData: false,
          availableOrganisations: organisations.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
          ),
          steps:
            organisationId?.length > 0
              ? walkinSteps(this)
              : skipSelection
              ? remoteStepsNoChoice(this, appointment)
              : remoteSteps(this, appointment),
        });

        this.setSelectedOrg(selectedOrganisation);

        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOADED, {});
      },
      (reason) => {
        notificationService.error(
          'Error loading available organisations for appointment type. Please refresh. If the problem persists please contact the clinic.',
        );
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOADED, {
          status: 'error',
          message: `${reason}`,
        });

        this.__updateSubject({ loadingData: false });
      },
    );
  };

  setSelectedOrg = (org) => {
    const { booking } = this.subject.value;

    let newBooking = { ...booking };
    newBooking.selectedOrg = org;
    newBooking.selectedDate = undefined;
    newBooking.availability = undefined;
    newBooking.reminderTime = undefined;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });
  };

  setWalkinDetails = (start) => {
    const { booking } = this.subject.value;

    const iso = start.toISOString();
    const selectedSlot = parse(iso, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date());

    let newBooking = { ...booking };
    newBooking.selectedSlot = selectedSlot;
    newBooking.reminderTime = 30;

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    });
  };

  estimateQueueStats = () => {
    const { availableOrganisations, booking } = this.subject.value;

    const target = availableOrganisations.filter((org) => org.id === booking.selectedOrg);

    if (target.length > 0) {
      const clinic = target[0];
      this.setSelectedDate(new Date());
      this.loadSelectedDayAvailability().finally(() => {
        this.events.next({ type: BookingBlocEvent.WALKIN_DETAILS_LOADED, data: {} });
      });
    } else {
      notificationService.error(
        'An error occurred trying to lookup the clinics information. Please try refresh or contact the clinic directly.',
      );
    }
  };

  isOccmed = () => {
    const { process } = this.subject.value;
    return process === 'occhealth' || process === 'workerscomp';
  };

  isWalkin = () => {
    const { isWalkin } = this.subject.value;
    return isWalkin;
  };

  isVirtual = () => {
    const { appointment } = this.subject.value;
    return appointment.type === 'VIRTUAL';
  };

  confirmWalkin = () => {
    const { appointmentId } = this.subject.value;
    const request = this.__createAppointmentRequestData((booking) => booking.selectedSlot);
    request.command = 'update_details';

    return appointmentApi.command(appointmentId, request);
  };

  submit = async () => {
    const { appointmentId, history } = this.subject.value;

    await this.confirmAppointment({});

    if (this.isOccmed()) {
      history.push(
        routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
          appointmentId,
          QUINN_SCHEDULED,
        ),
      );
    } else if (this.isWalkin() || this.isVirtual()) {
      history.push(
        routeUtil.buildBookingPaymentRouteWithAppointmentID(appointmentId, QUINN_SCHEDULED),
      );
    } else {
      history.push(
        routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
          appointmentId,
          QUINN_SCHEDULED,
        ),
      );
    }
  };
}

export class BookingBlocEvent {
  static INITIALISED = 'INITIALISED';
  static SWITCH_BOOKING_TYPE = 'SWITCH_BOOKING_TYPE';
  static DOCTOR_SELECTED = 'DOCTOR_SELECTED';
  static DATE_SELECTED = 'DATE_SELECTED';
  static TIME_SELECTED = 'TIME_SELECTED';
  static REMINDER_SELECTED = 'REMINDER_SELECTED';
  static BOOKING_CONFIRMED = 'BOOKING_CONFIRMED';

  static WALKIN_DETAILS_LOADED = 'WALKIN_DETAILS_LOADED';
}

const determineAppointmentType = (appointment, overrides) => {
  return BookingType.RESERVATION;
};

export const useBookingBloc = (appointmentId, type, history) => {
  const [state, setState] = useState({});
  const [appointmentType, setAppointmentType] = useState(null);
  const bookingBloc = useMemo(
    () => new BookingBloc(appointmentId, type, history),
    [appointmentId, type],
  );

  const subscribeToState = React.useCallback((newState) => {
    setState((state) => ({ ...state, ...newState }));
  }, []);

  const subscribeToEvents = React.useCallback((event) => {
    const { data } = event;
    switch (event.type) {
      case BookingBlocEvent.INITIALISED:
        setAppointmentType(determineAppointmentType(data.appointment, data.overrides));
        break;
      case BookingBlocEvent.SWITCH_BOOKING_TYPE:
        setAppointmentType(determineAppointmentType(data.appointment, data.overrides));
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const stateSub = bookingBloc.subscribeToState(subscribeToState);
    const eventSub = bookingBloc.subscribeToEvents(subscribeToEvents);
    return () => {
      stateSub.unsubscribe();
      eventSub.unsubscribe();
    };
  }, [bookingBloc, subscribeToState, subscribeToEvents]);

  return {
    ...state,
    appointmentType,
    bookingBloc,
  };
};
