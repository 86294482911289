import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import { Loading } from '../../../shared-library';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../shared/Typography';
import PageContainer from '../../common/PageContainer';

import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import DecodedButton from '../../common/DecodedButton';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class Subscription extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = {
      ...this.bloc.subject.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    const { initialised, appointment, subscription, options } = this.state;

    if (!initialised) {
      return (
        <PageContainer loading={false} onBack={() => {}}>
          <ScrollableQuinnContainer
            messageId={`paymentmethod.multiplechoices.loading.bubble`}
            message="Looking up payment options."
          >
            <Grid container direction="column" justifyContent="space-between" flex="1">
              <Loading isFullscreen={false} />
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.bloc.back();
        }}
      >
        <ScrollableQuinnContainer
          messageId={`paymentmethod.acceptStatement.subscription.bubble`}
          message="Thank you for your visit. To continue, please accept the below statement."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <H2TextTitleContent>
                  <FormattedMarkdown
                    id="insurance.acceptStatement.subscription.text"
                    defaultMessage={`Some services may not be covered under your Direct Plus Membership. You will be responsible for any non-covered services that are provided at the completion of your visit.`}
                  />
                </H2TextTitleContent>
              </Box>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid item xs={12}>
            <DecodedButton
              onClick={() => {
                this.bloc.submit();
              }}
            >
              <FormattedMarkdown
                id="insurance.acceptStatement.subscription.button"
                defaultMessage={`ACCEPT`}
              />
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(Subscription));
