import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import ChatBubble from '../../../shared/ChatBubble';
import { Button, Grid } from '@mui/material';
import { Loading } from '../../../../shared-library';
import PageContainer from '../../../common/PageContainer';
import EmployerContext from '../context';
import { ScrollableBox, ScrollableWrapper } from '../../../common/ScrollableContainer';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class PayersLoading extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.context.bloc;
    this.state = {};
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <PageContainer loading={false} onBack={() => {}}>
        <Grid container direction="column" justifyContent="space-between" flex="1">
          <ScrollableWrapper>
            <ChatBubble
              messageId="paymentmethods.employer.loading.bubble"
              message="Let me just check load up all the available employers."
            />

            <ScrollableBox>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Loading isFullscreen={false} />
                </Grid>
              </Grid>
            </ScrollableBox>
          </ScrollableWrapper>
        </Grid>
      </PageContainer>
    );
  }
}

export default withStyles(styles)((props) => (
  <EmployerContext.Consumer>
    {(value) => {
      return <PayersLoading context={value} {...props} />;
    }}
  </EmployerContext.Consumer>
));
