import React, { Component } from 'react';
import PageContainer from '../../../../common/PageContainer';

import withStyles from '@mui/styles/withStyles';
import { Button, CircularProgress, Grid } from '@mui/material';
import {
  DisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../../../common/ScrollableContainer';

const styles = (theme) => ({
  root: {},
  fill: {
    flex: '1 1 auto',
  },
  buttonHolder: {
    marginTop: '18px',
  },

  progressHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'hidden',
    },
  },
  progress: {
    color: '#F29202',
  },
});

class BaseWidget extends Component {
  render() {
    const {
      classes,
      initialising,
      processing,
      submitHidden,
      submitEnabled,
      submit,
      submitLabel,
      messageId,
      message,
    } = this.props;

    if (initialising) {
      return (
        <Grid container>
          <Grid row xs={12} className={classes.progressHolder}>
            <div className={classes.fill} />
            <CircularProgress classes={{ circle: classes.progress }} />
            <div className={classes.fill} />
          </Grid>
        </Grid>
      );
    }

    return (
      <React.Fragment>
        <ScrollableQuinnContainer
          size={'large'}
          messageId={messageId || 'interaction.appointment.reservation.chatbubble.default'}
          message={message}
        >
          {this.props.children}
        </ScrollableQuinnContainer>
        <DisconnectedPageFooter>
          {!submitHidden && (
            <Button
              disabled={!submitEnabled}
              onClick={submit}
              variant={'contained'}
              color={'primary'}
            >
              {processing ? <CircularProgress size={25} thickness={2.6} /> : <>{submitLabel}</>}
            </Button>
          )}
        </DisconnectedPageFooter>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(BaseWidget);
