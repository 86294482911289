import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import { Loading } from '../../../shared-library';
import { FormattedMessage } from 'react-intl';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../shared/Typography';
import PageContainer from '../../common/PageContainer';

import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class SelfPay extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = {
      ...this.bloc.subject.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    const { initialised, appointment, type } = this.state;

    if (!initialised) {
      return (
        <PageContainer loading={false} onBack={() => {}}>
          <ScrollableQuinnContainer
            messageId={`paymentmethod.selfpay.loading.bubble`}
            message="This appointment is self pay."
          >
            <Grid container direction="column" justifyContent="space-between" flex="1">
              <Loading isFullscreen={false} />
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.props.history.goBack();
        }}
      >
        <ScrollableQuinnContainer
          messageId={`paymentmethod.${appointment.service}.selfpay.bubble`}
          message="Sports physicals are self pay."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <H2TextTitleContent>
                  <FormattedMarkdown
                    id={`paymentmethod.${appointment.service}.selfpay.text`}
                    defaultMessage={
                      'This appointment type is selfpay and is not covered by insurance.'
                    }
                  />
                </H2TextTitleContent>
              </Box>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Button type="button" variant="contained" color="primary" onClick={this.bloc.submit}>
            <FormattedMessage
              id="paymentmethod.selfpay.submit.button.label"
              defaultMessage={'NEXT'}
            />
          </Button>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(SelfPay));
