import axios from 'axios';
import React, { useState } from 'react';
import retry from '../retry';
import { logger } from '../logging';

class ConsumerApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
  }

  getPersonSummary() {
    return retry(() => axios.get(`${this.baseUrl}/api/consumers/me`), 3, 500);
  }

  getPersonDetails() {
    return retry(() => axios.get(`${this.baseUrl}/api/consumers/me/_all`), 3, 500);
  }

  updatePerson(data) {
    return axios.patch(`${this.baseUrl}/api/consumers/me`, data);
  }

  consentTelehealthCommunication() {
    return axios.patch(`${this.baseUrl}/api/consumers/me/consents`, {
      consents: [
        {
          code: 'telehealth-communication-consent',
          value: 'true',
        },
      ],
    });
  }

  updateConsents(consents) {
    return axios.patch(`${this.baseUrl}/api/consumers/me/consents`, {
      consents: consents,
    });
  }
}

export const consumerApi = new ConsumerApi();

export const useUserSummary = () => {
  const [user, setUser] = useState(null);

  React.useEffect(() => {
    consumerApi
      .getPersonSummary()
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        logger.debug('==== Error white fetching user details ====', err);
        setUser(null);
      });
  }, []);

  return user;
};
