import React from 'react';

import withStyles from '@mui/styles/withStyles';
import { notificationService } from '../../../utils/notification';
import { withRouter } from 'react-router-dom';
import PageContainer from '../../common/PageContainer';
import { ReactComponent as DirectionIcon } from '../../../assets/direction.svg';
import { Grid, Link } from '@mui/material';
import { uriStorage } from '../../../utils/storage';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { authService } from '../../../utils/auth';
import { BOOKING_LIST_ROUTE, routeUtil } from '../../../utils/route.name';
import { phoneUtil } from '../../../utils/phone';
import { providerStorage } from '../../../utils/provider.qs';
import {
  CancellationBloc,
  CancellationBlocBlocEvent,
} from '../../shared/AppointmentCancelDialog/cancellation.bloc';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import ScrollableContainer from '../../common/ScrollableContainer';
import DecodedButton from '../../common/DecodedButton';
import DecodedComponent from '../../shared/DecodedComponent';
import { Bloc, BlocEvent } from './bloc';
import { AppointmentCard } from '../../shared/AppointmentCard';
import { serviceUtil } from '../../../utils/service';
import AppointmentCancelDialog from '../../shared/AppointmentCancelDialog';
import ConfirmDialog from '../../shared/ConfirmDialog';
import { TextBody } from '../../shared/Typography';
import { styles } from './styles';

const initState = {
  loading: false,
  checkinSuccess: false,
  checkinAvailable: false,
  queueNumber: '_',
  waitTime: 'unknown',
  provider: '',
  isCancelled: false,
  dialogOpen: false,
};

class BookingStatus extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = initState;
    this.bloc = new Bloc(this._getCurrentAppointmentId());
    this.cancelBloc = new CancellationBloc();
  }

  componentDidMount() {
    super.componentDidMount();
    this.cancelBlocEventSubscription = this.cancelBloc.subscribeToEvents(this._handleCancelEvent);
    uriStorage.setCurrentPath(this.props.match.url);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.cancelBlocEventSubscription?.unsubscribe();
  }

  __handleEvent = (event) => {
    const { data } = event;

    if (BlocEvent.NAVIGATE_TO === event.event) {
      this.props.history.push(data.url);
    }
  };

  _handleCancelEvent = (e) => {
    const { type } = e;
    const { isFullLogin } = this.state;

    switch (type) {
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLING:
        this.setState({
          loading: true,
        });
        break;
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLED_SUCCESS:
        this.setState({
          loading: false,
          isCancelled: true,
        });
        notificationService.success('You reservation has been cancelled.');
        if (!isFullLogin) {
          setTimeout(() => {
            this.props.history.replace('/');
          }, 5000);
        }
        break;
      case CancellationBlocBlocEvent.APPOINTMENT_CANCELLED_ERROR:
        this.setState({
          loading: false,
        });
        break;
      default:
        break;
    }
  };

  _getCurrentAppointmentId = () => {
    return this.props.match.params.appointmentId
      ? this.props.match.params.appointmentId
      : this.props.appointmentId;
  };

  _createMapUrl = (url) => {
    let { providerDetails } = this.state;
    return providerDetails
      ? `${url || 'https://www.google.com/maps/dir/Current+Location/'}${providerDetails.lat},${
          providerDetails.lng
        }`
      : '';
  };

  completeCheckin = () => {
    authService.logout().then(() => {
      uriStorage.clearPath();
      window.location.reload();
    });
  };

  _renderConfirmationDialog = (appointment) => {
    if (appointment.status !== 'RESERVED') {
      return <></>;
    }

    return (
      <>
        <AppointmentCancelDialog
          selectedAppointment={appointment.id}
          bloc={this.cancelBloc}
          onCancelSuccess={() => {
            authService.logout().then(() => {
              uriStorage.clearPath();
              providerStorage.clearProvider();
            });
          }}
        />
        <Grid item xs={12}>
          <TextBody
            sx={(theme) => ({
              lineHeight: '1',
              [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
              },
            })}
          >
            <FormattedMarkdown
              id={'appointment.status.change.text'}
              defaultMessage={'Do you need to change your booking?'}
            />
          </TextBody>
        </Grid>
        <Grid item xs={12}>
          <DecodedButton
            onClick={() => {
              this.bloc.rescheduleDraft();
            }}
          >
            RESCHEDULE
          </DecodedButton>
        </Grid>
        <Grid item xs={12}>
          <DecodedButton
            onClick={() => {
              this.cancelBloc.setCancelDialogOpen(true);
            }}
          >
            CANCEL
          </DecodedButton>
        </Grid>
      </>
    );
  };

  render() {
    const { classes, history } = this.props;
    const { loading, startTime, appointment, provider, providerContactNumber, checkinSuccess } =
      this.state;

    const occHealthCheckin = serviceUtil.determinCheckinProcess(appointment) === 'occhealth';

    return (
      <PageContainer
        loading={loading}
        paperBackground={true}
        onBack={() => {
          this.props.history.replace(BOOKING_LIST_ROUTE);
        }}
      >
        <ScrollableContainer applyFormat={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextBody>
                <FormattedMarkdown
                  id={'appointment.status.title'}
                  defaultMessage={'YOUR UPCOMING RESERVATION'}
                />
              </TextBody>
            </Grid>
            <Grid item xs={12}>
              {appointment && (
                <AppointmentCard
                  appointment={appointment}
                  organisation={provider}
                  visitTime={startTime}
                ></AppointmentCard>
              )}
            </Grid>

            {this.buildCheckinInformation(appointment, history)}

            {appointment && !occHealthCheckin && this._renderConfirmationDialog(appointment)}

            <Grid item xs={12}>
              <TextBody
                sx={(theme) => ({
                  lineHeight: '1',
                  [theme.breakpoints.down('sm')]: {
                    textAlign: 'center',
                  },
                })}
              >
                <FormattedMarkdown
                  id={'appointment.status.clinic.message'}
                  defaultMessage={'For anything else, please call the clinic:'}
                />
                <Link
                  sx={(theme) => ({
                    color: theme.palette.secondary.main,
                  })}
                  href={`tel:+1${phoneUtil.stripCharacters(providerContactNumber)}`}
                >
                  {providerContactNumber}
                </Link>
              </TextBody>
            </Grid>
            {!checkinSuccess && (
              <Grid
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  },
                })}
                item
                xs={12}
              >
                <Link
                  sx={(theme) => ({
                    fontWeight: '700',
                    fontSize: '18px !important',
                    lineHeight: '22px',
                    display: 'flex',
                    marginTop: '24px',
                    marginBottom: '24px',
                    color: theme.palette.primary.main,
                    [theme.breakpoints.down('sm')]: {
                      textAlign: 'center',
                    },
                    ['& p']: {
                      margin: '2px 0 0 0',
                    },
                  })}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={this._createMapUrl()}
                  className={classes.directionLinkText}
                  underline="hover"
                >
                  <DirectionIcon className={classes.getDirectionIcon} />
                  <FormattedMarkdown
                    id={'checkin.direction.button'}
                    defaultMessage={'GET DIRECTIONS'}
                  />
                </Link>
              </Grid>
            )}
          </Grid>
        </ScrollableContainer>
      </PageContainer>
    );
  }

  _onCheckin = () => {
    const { appointment } = this.state;
    const { history } = this.props;
    const next = routeUtil.buildBookingCheckinRouteWithAppointmentID(appointment.id);
    uriStorage.setCurrentPath(next);
    history.push(next, { showHandleBack: true });
  };

  _openCheckinConfirm = () => {
    this.setState({ arrivedConfirm: true });
  };
  _closeCheckinConfirm = () => {
    this.setState({ arrivedConfirm: false });
  };

  buildCheckinInformation(appointment, history) {
    const { inClinic, checkinAvailable, arrivedConfirm } = this.state;

    if (['WAITING', 'CHECKING_IN'].includes(appointment?.status || '')) {
      return <></>;
    }

    return (
      <>
        {!inClinic && (
          <Grid item xs={12}>
            <TextBody>
              <FormattedMarkdown
                id={'appointment.status.instructions.title'}
                defaultMessage={'HOW TO CHECK IN:'}
              />
            </TextBody>
            <TextBody>
              <FormattedMarkdown
                id={'appointment.status.instructions.text'}
                defaultMessage={
                  '1. Arrive about 15 minutes early.\n\n2. Scan QR Code in lobby to check-in.\n\n3. Take a seat, we’ll be with you soon!'
                }
              />
            </TextBody>
          </Grid>
        )}
        {checkinAvailable && (
          <Grid item xs={12}>
            {!inClinic ? (
              <TextBody>
                <FormattedMarkdown
                  id={'appointment.status.instruction.remote.text'}
                  defaultMessage={'ALREADY IN CLINIC:'}
                />
              </TextBody>
            ) : (
              <TextBody>
                <FormattedMarkdown
                  id={'appointment.status.instruction.walkin.text'}
                  defaultMessage={'Begin registration by selecting ***"Check-In"*** below'}
                />
              </TextBody>
            )}
            <DecodedButton
              onClick={() => {
                if (inClinic) {
                  this._onCheckin();
                } else {
                  this._openCheckinConfirm();
                }
              }}
            >
              <FormattedMarkdown
                id={'appointment.status.instruction.button.checkin'}
                defaultMessage={'Check-In'}
              />
            </DecodedButton>
            {!inClinic && (
              <ConfirmDialog
                open={arrivedConfirm}
                onConfirm={this._onCheckin}
                onConfirmLabel={'CONFIRM'}
                onClose={this._closeCheckinConfirm}
                onCloseLabel={'CANCEL'}
                title={'Confirm at location'}
                content={'Have you arrived at the clinic?'}
              />
            )}
          </Grid>
        )}
      </>
    );
  }
}

export default withStyles(styles)(withRouter(BookingStatus));
