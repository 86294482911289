import { AnalyticsEvent, analyticsEventLogger } from './events';
import { decodedSession } from './session';

class UriStorage {
  setUpStorage() {
    if (!this.getCurrentPath()) {
      sessionStorage.setItem('currentPath', '');
    }
  }

  getCurrentPath() {
    return sessionStorage.getItem('currentPath');
  }

  setCurrentPath(path) {
    sessionStorage.setItem('currentPath', path);
  }

  isOnRightPath(path) {
    return path === this.getCurrentPath();
  }

  clearPath(onlyPath) {
    if (onlyPath) {
      sessionStorage.removeItem('currentPath');
    } else {
      analyticsEventLogger.log(AnalyticsEvent.SESSION_CLEAR, { place: 'storage' });
      decodedSession.clearSession();
    }
  }
}

export const uriStorage = new UriStorage();
