import * as React from 'react';
import { compareAsc } from 'date-fns';
import { dateUtil } from '../../../utils/date';
import { useBookingWizardContext } from '../BookingWizard/booking-wizard-context';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { Grid, Typography } from '@mui/material';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import TimeBarChart from './TimeBarChart';
import {PeriodIntervalButton, TimeIntervalButton} from './TimeIntervalButton';
import { Loading } from '../../../shared-library';
import {globalBloc} from "../../global.bloc";

const formatTimeDisplay = (hour) => {
  const timeOfDay = hour < 12 ? 'am' : 'pm';
  let displayHour = hour % 12;
  if (displayHour === 0) displayHour = 12;

  return `${displayHour}:00 ${timeOfDay}`;
};

const formatTimeHHmmDisplay = (hour, minutes) => {
  const timeOfDay = hour < 12 ? 'am' : 'pm';
  let displayHour = hour % 12;
  if (displayHour === 0) displayHour = 12;

  return `${displayHour}:${minutes} ${timeOfDay}`;
};

const TimeSelector = () => {
  const { schedulingIntervals, booking, bookingBloc, loadingData } = useBookingWizardContext();
  const selectedSlot = booking.selectedSlot;

  React.useEffect(() => {
    bookingBloc.loadSelectedDayAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function notChristmasEve(scheduleIntervalHour) {
    return !(scheduleIntervalHour.getDate() === 24 && scheduleIntervalHour.getHours() >= 17);
  }

  const { intervals } = React.useMemo(() => {
    if (booking?.availability) {
      const start = new Date(booking.selectedDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(booking.selectedDate);
      end.setHours(23, 59, 59, 999);

      const now = new Date();
      if(globalBloc.isTelehealth()) {
        now.setMinutes(now.getMinutes() + 30);
      }

      let intervals = [];

      if (booking.availability.results.length > 0) {
        booking.availability.results[0].intervals.forEach((_interval) => {
          _interval.renderDate = dateUtil.parseDate(_interval.start);
          if (
              now.getTime() < _interval.renderDate.getTime() &&
              dateUtil.encloses(_interval.renderDate, start, end) &&
              _interval.availableSlots &&
              _interval.availableSlots.length > 0 &&
              notChristmasEve(_interval.renderDate)
          ) {
            intervals.push(_interval);
          }
        });

        intervals.sort((slot1, slot2) => {
          return compareAsc(slot1.renderDate, slot2.renderDate);
        });
      }
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_LOADED, {});
      return { intervals };
    }
    return { intervals: [] };
  }, [booking]);

  const renderTime = () => {
    if(globalBloc.isTelehealth()) {
      return intervals.map((intrvl) => {

        const hour = dateUtil.parseDate(intrvl.start).getHours();

        return !(intrvl.capacity === 0) ? intrvl.availableSlots.map(slot => (
            <PeriodIntervalButton
                key={`btn-slot-${slot.start}`}
                onClick={handleTimeSelected(intrvl.start)}
                isSelected={selectedSlot === intrvl.start}
            >
              {formatTimeHHmmDisplay(hour, slot.start.split(':')[1])}
            </PeriodIntervalButton>
        )) : null;
      })
    }

    return intervals.map((intrvl) => {
      return !(intrvl.capacity === 0) ? (
          <PeriodIntervalButton
              key={`btn-slot-${intrvl.start}`}
              onClick={handleTimeSelected(intrvl.start)}
              isSelected={selectedSlot === intrvl.start}
          >
            {formatTimeDisplay(dateUtil.parseDate(intrvl.start).getHours())} - {formatTimeDisplay(dateUtil.parseDate(intrvl.start).getHours() + 1)}
          </PeriodIntervalButton>
      ) : null;
    })
  }

  const available = intervals && intervals.length > 0;

  const handleTimeSelected = React.useCallback(
      (slot) => () => {
        bookingBloc.setBookingTime(slot, true);
      },
      [bookingBloc],
  );

  return loadingData ? (
      <Loading />
  ) : (
      <Grid>
        {available && false && (
            <>
              <Grid item row xs={12} sx={{ padding: '0 0 24px 0' }}>
                <Typography variant="body2" component={'div'}>
                  <FormattedMarkdown
                      id={'interaction.appointment.reservation.selecttime.subtext.0'}
                      defaultMessage={'You can see the approximated waiting on the graph below.'}
                  />
                </Typography>
              </Grid>
              <TimeBarChart
                  schedulingIntervals={schedulingIntervals}
              />
            </>
        )}
        <Grid item row xs={12}>
          {intervals && intervals.length > 0 ? (
              renderTime()
          ) : (
              <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '18px',
                    lineHeight: '32px',
                    color: '#FF7700',
                  }}
                  variant="body2"
                  component={'div'}
              >
                <FormattedMarkdown
                    id={'booking.appointment.schedule.chart.unavailable'}
                    defaultMessage={'No time slots are available for selected date.'}
                />
              </Typography>
          )}
        </Grid>
      </Grid>
  );
};

export default TimeSelector;
