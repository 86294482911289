import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import Loading from '../shared/Loading';
import UserAuth from './UserAuth';
import PinCode from './PinCode';
import { uriStorage } from '../../utils/storage';
import { authService } from '../../utils/auth';
import { providerStorage } from '../../utils/provider.qs';
import Welcome from '../Login/Welcome';
import { globalBloc } from '../global.bloc';

const styles = (theme) => ({
  versionTitle: {
    marginBottom: '2.2rem',
    fontStyle: 'normal',
  },
  loginTitle: {
    marginTop: '2rem',
    marginBottom: '0.1rem',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '54px',
    '& span': {
      color: '#FF9900',
    },
  },
});

const initState = {
  loading: false,
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  code: '+1',
  codeCountry: 'US',
  number: '',
  ssn: '',
  acceptLocationTerm: false,
  activeStep: 0,

  errorMessage: '',
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = initState;
  }

  componentDidMount() {
    globalBloc.ensureProvider();

    setTimeout(() => {
      if (authService.isLoading()) {
        authService.registerLoadedCallback(this._loaded);
      } else {
        this._loaded();
      }
    }, 500);
  }

  //TODO: Move to a loading/initialisation page.
  _loaded = () => {
    let { location } = this.props;
    if (
      location &&
      location.state &&
      location.state.redirectStep &&
      authService.getUser() === null
    ) {
      this.setState({
        activeStep: location.state.redirectStep,
        redirectReason: location.state.redirectReason,
      });
    }
    if (authService.getUser() === null) {
      this.setState({
        loading: false,
      });
    } else {
      this.props.history.replace(location.state.next);
    }
  };

  verificationSuccess = (result) => {
    this.setState({
      verificationSuccess: result,
    });
  };

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  handleCodeChange = (code, codeCountry) => {
    this.setState({ code, codeCountry, number: '' });
  };

  handleDateChange = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  setLoadingStatus = (value) => {
    this.setState({
      loading: value,
    });
  };

  setConfirmationResult = (value) => {
    this.setState({
      confirmationResult: value,
    });
  };

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Welcome
            location={this.props.location}
            acceptLocationTerm={this.state.acceptLocationTerm}
            handleNext={this.handleNext}
            handleCheckboxChange={this.handleCheckboxChange}
          />
        );
      case 1:
        return (
          <UserAuth
            {...this.state}
            history={this.props.history}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            onCodeChange={this.handleCodeChange}
            handleTextChange={this.handleTextChange}
            setLoadingStatus={this.setLoadingStatus}
            setConfirmationResult={this.setConfirmationResult}
            verificationSuccess={this.verificationSuccess}
          />
        );
      case 2:
        return (
          <PinCode
            {...this.state}
            history={this.props.history}
            setLoadingStatus={this.setLoadingStatus}
            handleBack={this.handleBack}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  isRedirectedToNext = () => {
    return this.props.location.state && uriStorage.getCurrentPath() != null;
  };

  render() {
    let { activeStep } = this.state;
    if (this.isRedirectedToNext()) {
      return <Loading />;
    }

    return this.getStepContent(activeStep);
  }
}

export default withStyles(styles)(Login);
