import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Link,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../../utils/events';
import { dateUtil } from '../../../../../utils/date';
import { providerUtil } from '../../../../../utils/provider';
import BaseWidget from './BaseWidget';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { SmallInfoText } from '../../../../shared/Typography';

const styles = (theme) => ({
  root: {},
  fill: {
    flex: '1 1 auto',
  },
  cardHolder: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      overflow: 'auto',
    },
  },
  card: {
    width: '240px',
    minWidth: '240px',
    maxWidth: '240px',
    margin: '12px 24px 12px 0',
    backgroundColor: '#F6F6F6',
    border: '0 solid #ffffff',
    borderRadius: '6px',
  },

  cardSelected: {
    width: '240px',
    minWidth: '240px',
    maxWidth: '240px',
    margin: '12px 24px 12px 0',
    backgroundColor: theme.palette.primary.main,
    border: '0 solid #ffffff',
    borderRadius: '6px',
  },

  cardHeader: {},

  cardSelectedHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#ffffff',
  },

  cardHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
  },

  cardHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
  },
  cardSelectedHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#FFF',
  },
  cardBody: {
    paddingTop: '0',
    paddingBottom: '6px !important',
  },

  cardBodyLine: {
    display: 'flex',
    marginBottom: '14px',
  },

  cardBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
  },
  cardSelectedBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
  },

  cardBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
    paddingLeft: '18px',
  },
  cardSelectedBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
    paddingLeft: '18px',
  },

  buttonHolder: {
    marginTop: '18px',
  },
  button: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  iconLarge: {
    fontSize: '18px',
  },
  iconLargeWhite: {
    fontSize: '18px',
    color: '#fff',
  },
  documents: {
    paddingTop: '14px',
    paddingBottom: '14px',
    lineHeight: '14px',
  },
  checkBox: {
    transform: 'scale(1.5)',
    marginRight: '1em',
    marginLeft: '0.5em',
  },
  locationTerm: {
    color: theme.palette.primary.main,
  },
});

class Confirmation extends Component {
  constructor(props) {
    super(props);

    const { bloc } = this.props;
    const { staff, booking, appointment, organisation } = bloc.subject.value;

    this.state = {
      initialising: true,
      staff: staff,
      booking: booking,
      start: bloc.extractSlotStartDate(booking),
      appointment: appointment,
      organisation: organisation,
    };

    this.__submit = this.__submit.bind(this);
  }

  componentDidMount() {
    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_LOADED, {});
  }

  componentWillUnmount() {}

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  __submit = () => {
    if (!this.state.processing) {
      let data = {};
      if (this.state.appointment.service.includes('_MDS')) {
        data.consents = [
          { system: 'decoded/consent', code: 'cosmeticTerms', value: `${this.state.consented}` },
        ];
      }
      this.props.bloc.confirmAppointment(data);
      this.setState({
        processing: true,
      });
      this.timer = setTimeout(() => {
        this.setState({ processing: false });
      }, 5000);
    }
  };

  render() {
    const { classes } = this.props;

    const { processing, staff, organisation, booking, start, consented, appointment } = this.state;

    const submitEnabled = appointment.service.includes('_MDS')
      ? !processing && consented
      : !processing;

    return (
      <BaseWidget
        initialising={false}
        processing={false}
        submitEnabled={submitEnabled}
        submit={this.__submit}
        submitLabel={'Confirm'}
      >
        <Grid row xs={12} className={classes.cardHolder}>
          {this.renderCard(classes, staff, organisation, booking, start)}
        </Grid>
        {appointment.service.includes('_MDS') ? (
          <>
            <FormControlLabel
              style={{ fontSize: '1.2em', textAlign: 'left' }}
              control={
                <Checkbox
                  required
                  checked={consented}
                  onChange={this.handleCheckboxChange}
                  name="consented"
                  color="primary"
                  className={classes.checkBox}
                />
              }
              label={
                <div className={classes.locationTerm}>
                  <FormattedMarkdown
                    id="confirmation.medspa.text"
                    defaultMessage="I have read the below instructions and consent and agree to follow the preparation instructions prior to my visit."
                  />
                </div>
              }
            />

            <div className={classes.documents}>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={
                  'https://ondutyurgentcare.com/wp-content/uploads/2021/06/Botulinum-Toxin-Treatment-Instructions-and-Consent.pdf'
                }
                underline="hover"
              >
                <Typography variant="body2" component={'div'}>
                  <FormattedMarkdown
                    id="confirmation.cosmetic.botox.consent.link"
                    defaultMessage="Botulinum Toxin treatment instructions and consent"
                  />
                </Typography>
              </Link>

              <br />

              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={
                  'https://ondutyurgentcare.com/wp-content/uploads/2021/06/Dermal-Filler-Instructions-and-Consent.pdf'
                }
                underline="hover"
              >
                <Typography variant="body2" component={'div'}>
                  <FormattedMarkdown
                    id="confirmation.cosmetic.filler.consent.link"
                    defaultMessage="Dermal filler instructions and consent"
                  />
                </Typography>
              </Link>
            </div>
          </>
        ) : null}
      </BaseWidget>
    );
  }

  renderCard = (classes, staff, organisation, booking, start) => {
    const _person = booking.doctorDetail;

    return (
      <Card className={classes.card} variant="outlined">
        <CardHeader
          classes={{
            title: classes.cardHeaderTitle,
            subheader: classes.cardHeaderTitleSubtitle,
          }}
          className={classes.cardHeader}
          avatar={
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              src={`https://storage.googleapis.com/dh-public-production/saddlebackvpc/${_person.id}.png`}
            >
              {_person.name.given.slice(0, 1)}
            </Avatar>
          }
          title={this.__formatName(_person)}
          subheader=""
        />
        <CardContent className={classes.cardBody}>
          <div className={classes.cardBodyLine}>
            <LocationOnIcon
              classes={{
                fontSizeLarge: classes.iconLarge,
              }}
              fontSize={'large'}
              color={'accent'}
            />
            <SmallInfoText>{providerUtil.formatOrganisationAddress(organisation)}</SmallInfoText>
          </div>
          <div className={classes.cardBodyLine}>
            <span className={classes.cardBodyLineIcon}>
              <QueryBuilderIcon
                classes={{
                  fontSizeLarge: classes.iconLarge,
                }}
                fontSize={'large'}
                color={'accent'}
              />
            </span>
            <SmallInfoText sx={{ paddingLeft: '18px' }}>
              {dateUtil.fullFriendlyOutputExcludeYear(start)}
            </SmallInfoText>
          </div>
        </CardContent>
      </Card>
    );
  };

  __formatName = (_person) => {
    let name = _person.name.given + ' ' + _person.name.family;
    if (_person.name.suffix) {
      name += ', ' + _person.name.suffix;
    }

    return name;
  };
}

export default withStyles(styles)(Confirmation);
