import axios from 'axios';
import { VERSION } from '../version';
import { tenantUtil } from '../tenant';
import retry from '../retry';

class SystemApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';

    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Content-Type'] = 'application/json';
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  systemProperties() {
    return retry(() => this.instance.get(`${this.baseUrl}/api/system_properties`));
  }
  providers() {
    return retry(() => this.instance.post(`${this.baseUrl}/api/organisations/_locations`, {}));
  }
  terminals() {
    return retry(() => this.instance.get(`${this.baseUrl}/api/system_properties/terminals`));
  }
}

export const systemApi = new SystemApi();
