import React, { Component } from 'react';
import DecodedComponent from '../shared/DecodedComponent';
import { Box, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ChatBubble from '../shared/ChatBubble';
import { Loading } from '../../shared-library';
import PageContainer from '../common/PageContainer';
import { Bloc, BlocEvent } from './bloc';
import { withRouter } from 'react-router-dom';
import {
  ScrollableBox,
  ScrollableQuinnContainer,
  ScrollableWrapper,
} from '../common/ScrollableContainer';
import { uriStorage } from '../../utils/storage';
import blockBack from '../../utils/nav';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

const initState = {
  loading: false,
};

class BookingPaymentMethod extends DecodedComponent {
  constructor(props) {
    super(props);

    const { draftId, appointmentType } = props.match.params;

    this.state = { ...initState };

    this.bloc = new Bloc(draftId, appointmentType);
  }

  componentDidMount() {
    super.componentDidMount();
    uriStorage.setCurrentPath(this.props.match.url);
  }

  __handleEvent = (event) => {
    if (event.type === BlocEvent.NAVIGATE_TO) {
      this.setState({
        complete: true,
      });

      setTimeout(() => {
        this.props.history.push(event.url);
      }, 1000);
    }
  };

  render() {
    const { component } = this.state;

    if (component) {
      return component;
    }

    return (
      <PageContainer loading={false} onBack={() => {}}>
        <ScrollableQuinnContainer
          messageId="payment.method.loading.bubble"
          message="Please wait while I determine the available methods of payment."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Loading isFullscreen={false} />
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(BookingPaymentMethod));
