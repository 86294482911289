import * as rxjs from 'rxjs';
import { Button, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { logger } from '../../../utils/logging';
import { notificationService } from '../../../utils/notification';

const initial = {
  initialised: false,
};

export class Bloc {
  constructor(props, callback, onBack) {
    this.subject = new rxjs.BehaviorSubject({
      ...initial,
      ...props,
    });

    this.events = new rxjs.Subject();

    this.callback = callback;
    this.onBack = onBack;
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  __publishEvent = (type, data) =>
    this.events.next({
      type: type,
      data: data,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    this.__updateSubject({ initialised: true });
  };

  submit = async () => {
    const { appointment } = this.subject.value;

    try {
      const result = await appointmentApi.command(appointment.id, {
        command: 'set_payment_method',
        code: 'D',
      });

      logger.debug('returned from command.', result);
      this.callback();
    } catch (e) {
      notificationService.error(
        'Error setting your payment preference. Please try again or contact the clinic if this problem persists. - ' +
          e.message,
      );
    }
  };

  back = () => {
    const { source } = this.subject.value;
    this.onBack(source);
  };

  close = () => {};
}

export class Constants {}

export class BlocEvent {}
