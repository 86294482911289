import * as React from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { FormattedMessage } from 'react-intl';

const useInvalidPhoneNumberStyles = makeStyles(() => ({
  dialogActions: {
    justifyContent: 'center',
  },
}));

export const InvalidPhoneNumberModal = ({ open, onGoBack }) => {
  const classes = useInvalidPhoneNumberStyles();
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      aria-labelledby="invalid-phone-number-title"
      aria-describedby="invalid-phone-number-description"
    >
      <DialogContent>
        <Typography variant="body2" component={'div'}>
          <FormattedMarkdown
            id={'registeration.user.invalid-phone-number-description'}
            defaultMessage={
              'Your mobile phone is not valid. Please go back and enter a valid phone number.'
            }
          />
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onGoBack} variant="contained" color="primary">
          <FormattedMessage
            id={'registeration.user.invalid-phone-number.goback'}
            defaultMessage="GO BACK"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NotConnectedPhoneNumberModal = ({ open, onContinue, onGoBack, email }) => {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      aria-labelledby="not-connected-phone-number-title"
      aria-describedby="not-connected-phone-number-description"
    >
      <DialogContent>
        <Typography variant="body2" component={'div'}>
          <FormattedMarkdown
            id={'registeration.user.not-connected-phone-number-description'}
            defaultMessage={`Your mobile phone cannot recieve SMS. All notifications will be sent to ${email}, Continue or go back and change your mobile phone number.`}
          />
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'centre' }}>
        <Button onClick={onContinue} variant="contained" color="primary">
          <FormattedMessage
            id={'registeration.user.not-connected-phone-number.continue'}
            defaultMessage="CONTINUE"
          />
        </Button>
        <Button onClick={onGoBack} variant="contained" color="secondary">
          <FormattedMessage
            id={'registeration.user.not-connected-phone-number.goback'}
            defaultMessage="GO BACK"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
