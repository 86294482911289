import * as rxjs from 'rxjs';
import { notificationService } from '../../../utils/notification';
import { paymentApi } from './__mocks__/payment.api';
import { providerStorage } from '../../../utils/provider.qs';

const initial = {
  initialised: false,
};

export class Bloc {
  callback;
  appointmentApi;

  catalogApi;

  constructor(props, callback) {
    this.appointmentApi = props.appointmentApi;
    this.catalogApi = props.catalogApi || paymentApi;

    this.subject = new rxjs.BehaviorSubject({
      ...initial,
      ...props,
    });

    this.events = new rxjs.Subject();

    this.callback = callback;
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  __publishEvent = (type, data) =>
    this.events.next({
      type: type,
      data: data,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    this.__updateSubject({ initialised: true, payers: [] });
  };

  isWorkers = () => {
    const appointment = this.subject.value.appointment;
    return !appointment.service.includes('-OCC');
  };

  setPayer = (payer) => {
    this.__updateSubject({ payer: payer });
  };
  payer = () => this.subject.value.payer;

  validPayor = () => {
    const payer = this.subject.value.payer;
    return payer && payer !== 'employerNotExist';
  };

  payers = () => this.subject.value.payers;

  next = (context) => {
    context.goNext();
  };

  save = () => {
    const { appointment, payer } = this.subject.value;

    if (payer && payer !== 'employerNotExist') {
      let request = {
        command: 'update_properties',
        updates: [
          {
            system: 'decoded/payment_method',
            code: 'payment_method',
            value: '3',
          },
          {
            system: 'decoded/payment_method',
            code: 'payer',
            value: payer,
          },
        ],
      };

      this.appointmentApi.command(appointment.id, request).then(
        (value) => this.callback(),
        (reason) => {
          notificationService.httpError(reason);
        },
      );
    } else {
      let request = {
        command: 'update_properties',
        updates: [
          {
            system: 'decoded/payment_method',
            code: 'payment_method',
            value: '3',
          },
        ],
      };

      this.appointmentApi.command(appointment.id, request).then(
        (value) => this.callback(),
        (reason) => {
          notificationService.httpError(reason);
          this.callback();
        },
      );
    }
  };

  isWalkin = () => {
    return providerStorage.isWalkin();
  };

  end = () => {
    this.callback();
  };

  close = () => {};
}

export class Constants {}

export class BlocEvent {}
