import * as React from "react";
import PageContainer from "../common/PageContainer";
import { Button, Grid, Link, Typography } from "@mui/material";
import ChatBubble from "../shared/ChatBubble";
import { makeStyles } from "@mui/styles";
import { useUserSummary } from "../../utils/services/consumers.api";
import {PageContent, PageFooter, ScrollableQuinnContainer} from "../common/ScrollableContainer";
import DecodedButton from "../common/DecodedButton";
import {H2TextTitleContent} from "../shared/Typography";


const useIntroStepStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '1 1 auto',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    skip: {
        color: theme.palette.primary.main,
        fontSize: '1.6rem',
    },
    skipButton: {
        color: theme.palette.accent.main,
        textDecoration: 'underline',
        fontSize: '1.6rem',
    }
}));

export const IntroStep = ({ isWalkin, onScanID, onSkip, handleBack, userFirstName }) => {
    const classes = useIntroStepStyles();
    const user = useUserSummary();
    return (
        <PageContainer loading={false} onBack={handleBack}>
            <ScrollableQuinnContainer messageId="registration.scan.document.intro"
                                      message={`Hi${user ? ` ${user.name.given}`: ` ${userFirstName}`}, It looks like you are a new patient . To start your registration process, can you please scan your driver's license or state issued identification card?`} >
                <PageContent>
                    <H2TextTitleContent>
                        If you are the parent or guardian and the patient is a minor or child and does not have an ID, please scan your ID to place in the patients account for record.
                    </H2TextTitleContent>
                </PageContent>
                <PageFooter>
                    <Grid xs={12} item>
                        <DecodedButton onClick={onScanID}>
                            Scan ID
                        </DecodedButton>
                    </Grid>
                    <Grid xs={12} style={{marginTop: '12px'}} item>
                    { isWalkin ?
                        <Typography variant="body2" component="span" className={classes.skip}>
                            {" "}
                            Click{" "}
                            <Link component="button" variant="body1" className={classes.skipButton} onClick={onSkip}>
                                here
                            </Link>{" "}
                            if you don’t have your ID with you.
                        </Typography>
                        :
                        <Typography variant="body2" component="span" className={classes.skip}>
                            {" "}
                            Click{" "}
                            <Link component="button" variant="body1" className={classes.skipButton} onClick={onSkip}>
                                here
                            </Link>{" "}
                            to skip and enter information manually
                        </Typography>
                    }
                    </Grid>
                </PageFooter>
            </ScrollableQuinnContainer>
        </PageContainer>
    );
};
