import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { LOGIN_ROUTE, CALLBACK_PAYMENT_ROUTE } from '../../utils/route.name';
import PaymentCallback from './PaymentCallback';

const styles = (theme) => ({
  root: {
    overflow: 'auto',
    overscrollBehavior: 'contain',
    backgroundColor: '#ffffff',
    position: 'absolute',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    color: '#fff',
  },
});

class CallbackHandler extends Component {
  render() {
    let { classes } = this.props;

    return (
      <div className={classes.root}>
        <Switch>
          <Route path={CALLBACK_PAYMENT_ROUTE} component={PaymentCallback} />
        </Switch>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(CallbackHandler));
