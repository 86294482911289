import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../shared/Typography';
import { PageContent, PageFooter } from '../../common/ScrollableContainer';
import Button from '@mui/material/Button';
import DecodedButton from '../../common/DecodedButton';
import { routeUtil } from '../../../utils/route.name';

const styles = (theme) => ({});

class Standard extends Component {
  constructor(props) {
    super(props);
    this.bloc = props.bloc;
  }

  render() {
    const { history } = this.props;
    const { appointment } = this.props.bloc.subject.value;

    return (
      <>
        <PageContent>
          <Grid item xs={12} sx={{ margin: '64px 0' }}>
            <H2TextTitleContent>
              <FormattedMarkdown
                id={'booking.checkin.process.standard.text'}
                defaultMessage={`Before I let the staff know you are here, let’s make sure your insurance details are correct and collect your copay and any outstanding payments you might have.`}
              />
            </H2TextTitleContent>
          </Grid>
        </PageContent>
        <PageFooter>
          <Grid item xs={12} sm={6}>
            <DecodedButton
              onClick={() => {
                history.push(
                  routeUtil.buildBookingPaymentMethodRouteWithDraftAppointmentID(
                    appointment.id,
                    appointment.type,
                  ),
                );
              }}
            >
              <FormattedMarkdown
                id={'booking.checkin.process.standard.button'}
                defaultMessage={'CONTINUE'}
              ></FormattedMarkdown>
            </DecodedButton>
          </Grid>
        </PageFooter>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Standard));
