import React, { Component } from 'react';

import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import PageContainer from '../../common/PageContainer';
import { Box, darken, Grid, Typography } from '@mui/material';
import {
  DefaultDisconnectedPageFooter,
  PagePanel,
  ScrollableBox,
  ScrollableWrapper,
} from '../../common/ScrollableContainer';
import DecodedButton from '../../common/DecodedButton';
import { providerStorage } from '../../../utils/provider.qs';
import { CaptureCardImage } from '@decodedhealth/react-component-lib';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ReplayIcon from '@mui/icons-material/Replay';
import { logger } from '../../../utils/logging';

const styles = (theme) => ({
  fab: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.secondary.main, 0.1),
    },
    '&:focus': {
      backgroundColor: darken(theme.palette.secondary.main, 0.1),
    },
  },
});

const ImageHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

class TakeImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: props.initialImage,
      cameras: 0,
      isKiosk: providerStorage.isKiosk(),
      allowUpload: !providerStorage.isKiosk(),
      allowSwitch: !providerStorage.isKiosk(),
      facingMode: providerStorage.isKiosk() ? 'user' : 'environment',
      triggerCapture: false,
      triggerUpload: false,
      isSm: window.matchMedia('(max-width: 600px)').matches,
      isMd: window.matchMedia('(min-width: 601px) and (max-width: 900px)').matches,
    };
  }

  updateMedia = () => {
    this.setState({ isSm: window.matchMedia('(max-width: 600px)').matches });
  };

  componentDidMount() {
    console.log(this.state.facingMode);
    this.updateMedia();
    window.addEventListener('resize', this.updateMedia);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMedia);
  }

  next = async () => {
    this.setState({ loading: true });
    this.props.onSubmit(() => this.setState({ loading: false }));
  };

  compressImage = (file, callback) => {
    const fileSize = file.size / 1024 / 1024; //?--> 1MB <--//
    if (fileSize > 1) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const canvasContext = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          canvasContext.drawImage(img, 0, 0, canvas.width, canvas.height);
          const compressedDataURL = canvas.toDataURL('image/jpeg', 0.8);
          callback(compressedDataURL);
        };
      };
    } else {
      //?-->  less than 1MB <--//
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        callback(event.target.result);
      };
    }
  };

  handleCapture = (imageSrc) => {
    console.log('handleCapture', imageSrc);
    this.setState((prevState) => ({
      triggerCapture: false,
      image: imageSrc,
    }));
    this.props.handleScanCapture({ dataURL: imageSrc });
  };

  handleClick = () => {
    this.setState((prevState) => ({
      triggerCapture: true,
    }));
  };

  handleClickUpload = () => {
    this.setState((prevState) => ({
      triggerUpload: true,
    }));
  };

  resetUploadTrigger = () => {
    this.setState((prevState) => ({
      triggerUpload: false,
    }));
  };

  handleClickRestart = () => {
    this.setState((prevState) => ({
      image: null,
    }));
  };

  handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      //?--> only compresses image if over 1mb <--//
      this.compressImage(file, (compressedDataURL) => {
        logger.info('compressedDataURL', compressedDataURL);
        this.setState({ image: compressedDataURL });
        this.props.handleScanCapture({ dataURL: compressedDataURL });
      });
    }
  };

  __triggerCaptureImageBtnClick = () => {
    document.getElementById('capture-image-btn').click();
  };

  renderUploadButton = () => {
    return (
      <Box>
        <Button
          id={`capture-image-btn`}
          component={'label'}
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            maxHeight: '45px',
          }}
        >
          {!this.state.image ? (
            <UploadFileIcon fontSize="large" />
          ) : (
            <ReplayIcon fontSize="large" />
          )}
          &nbsp;
          <FormattedMessage
            id="component.takeimage.upload.button"
            defaultMessage={this.state.image ? 'RE-UPLOAD' : 'UPLOAD'}
          />
          <ImageHiddenInput
            accept="image/png, image/jpeg"
            type="file"
            onChange={this.handleImageUpload}
          />
        </Button>
      </Box>
    );
  };

  render() {
    let { title, classes } = this.props;

    let { loading, image, isSm, isMd, uploadedImage, isKiosk } = this.state;

    if (isKiosk) {
      return this.__renderKiosk();
    }

    return (
      <PageContainer loading={loading} onBack={this.props.handleBack}>
        <ScrollableWrapper>
          <ScrollableBox sx={{ overflowX: 'hidden', overflowY: isSm ? 'hidden' : 'auto' }}>
            <PagePanel>
              {title}
              <Grid container spacing={3} sx={{ paddingBottom: '24px', paddingTop: '12px' }}>
                <Grid item xs={12} sm={12} sx={{ position: 'relative !important' }}>
                  <Box
                    sx={{
                      position: 'relative',
                      height: isSm ? '45%' : 'auto',
                      width: isSm ? '87vw' : isMd ? 'auto' : '650px',
                      marginRight: '-12px',
                    }}
                  >
                    {uploadedImage && (
                      <Box>
                        <img
                          src={uploadedImage}
                          alt="Uploaded"
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: isSm ? 'center' : null,
                        alignItems: isSm ? 'center' : null,
                        height: isSm ? '300px' : '450px',
                        overflow: 'hidden',
                      }}
                      onClick={this.__triggerCaptureImageBtnClick}
                    >
                      {image ? (
                        <img
                          src={image}
                          alt="Uploaded"
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      ) : (
                        <Typography
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            border: '1px dashed #ccc',
                            borderRadius: '5px',
                            color: '#ccc',
                            padding: '10px',
                            textAlign: 'center',
                          }}
                        >
                          Add image
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </PagePanel>
          </ScrollableBox>
        </ScrollableWrapper>
        <DefaultDisconnectedPageFooter spacing={2}>
          <Grid item xs={12} sm={6}>
            {this.renderUploadButton()}
          </Grid>
          <Grid item xs={12} sm={6}>
            <DecodedButton disabled={!image || loading} onClick={this.next}>
              <FormattedMessage id="component.takeimage.next.button" defaultMessage={'NEXT'} />
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }

  __renderKiosk = () => {
    let { title, classes } = this.props;

    let {
      loading,
      image,
      allowUpload,
      isSm,
      isMd,
      facingMode,
      triggerCapture,
      triggerUpload,
      uploadedImage,
    } = this.state;

    return (
      <PageContainer loading={loading} onBack={this.props.handleBack}>
        <ScrollableWrapper>
          <ScrollableBox sx={{ overflowX: 'hidden', overflowY: isSm ? 'hidden' : 'auto' }}>
            <PagePanel>
              {title}
              <Grid container spacing={3} sx={{ paddingBottom: '24px', paddingTop: '12px' }}>
                <Grid item xs={12} sm={12} sx={{ position: 'relative !important' }}>
                  <Box
                    sx={{
                      position: 'relative',
                      height: isSm ? '45%' : 'auto',
                      width: isSm ? '87vw' : isMd ? 'auto' : '650px',
                      marginRight: '-12px',
                    }}
                  >
                    {uploadedImage && (
                      <Box>
                        <img
                          src={uploadedImage}
                          alt="Uploaded"
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                      </Box>
                    )}
                    <CaptureCardImage
                      triggerCapture={triggerCapture}
                      onCapture={this.handleCapture}
                      captureImage={image}
                      triggerUpload={triggerUpload}
                      resetUploadTrigger={this.resetUploadTrigger}
                      facingMode={facingMode}
                      height={isSm ? '100vh' : 400}
                    />
                  </Box>
                </Grid>
              </Grid>
            </PagePanel>
          </ScrollableBox>
        </ScrollableWrapper>
        <DefaultDisconnectedPageFooter spacing={2}>
          <Grid item xs={12} sm={6}>
            <label htmlFor="contained-button-file">
              {image ? (
                <DecodedButton
                  id={'select-image1'}
                  onClick={this.handleClickRestart}
                  variant="outlined"
                  color="primary"
                  component="span"
                >
                  <FormattedMessage
                    id="component.takeimage.recapture.button"
                    defaultMessage={'RETAKE IMAGE'}
                  />
                </DecodedButton>
              ) : (
                <DecodedButton
                  id={'select-image1'}
                  onClick={this.handleClick}
                  variant="contained"
                  color="primary"
                  sx={{
                    maxWidth: '100%',
                  }}
                >
                  <CameraAltIcon fontSize="large" />
                  &nbsp;
                  <FormattedMessage
                    id="component.takeimage.capture.button"
                    defaultMessage={'CAPTURE'}
                  />
                </DecodedButton>
              )}
            </label>
          </Grid>

          <Grid item xs={12} sm={6}>
            {image ? (
              <DecodedButton disabled={!image || loading} onClick={this.next}>
                <FormattedMessage id="component.takeimage.next.button" defaultMessage={'NEXT'} />
              </DecodedButton>
            ) : allowUpload ? (
              <DecodedButton
                sx={{
                  maxWidth: '100%',
                }}
                variant="outlined"
                onClick={this.handleClickUpload}
              >
                <FormattedMessage id="component.takeimage.next.button" defaultMessage={'UPLOAD'} />
              </DecodedButton>
            ) : (
              <DecodedButton disabled={!image || loading} onClick={this.next}>
                <FormattedMessage id="component.takeimage.next.button" defaultMessage={'NEXT'} />
              </DecodedButton>
            )}
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  };
}

export default withStyles(styles)(TakeImage);
